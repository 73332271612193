export let LanugageDescription: { [key: string]: string } = {
  en: "English",
  fr: "French",
  sp: "Spanish",
  ch: "Chinese",
  ru: "Russian",
  ge: "German",
  it: "Italian",
  ko: "Korean",
  po: "Portuguese",
  ja: "Japanese",
  ar: "Arabic",
  du: "Dutch",
  be: "Bengali",
  hi: "Hindi",
};
