import { useState } from "react";
import { Header, NavMenu } from "../../Components";
import "./index.css";
import { createRepos } from "../../api";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { repoNameRegex } from "../../utils/helpers";

type FormType = {
  repoName: string;
  createApi: boolean;
  createAdmin: boolean;
  projectName: string;
};

function CreateRepos() {
  const [loading, setLoading] = useState(false);
  const { userStore } = useStores();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      repoName: "",
      projectName: "",
      createApi: false,
      createAdmin: false,
    },
    mode: "onTouched",
  });

  const submitForm = async (data: FormType) => {
    if (loading) {
      return;
    }
    setLoading(true);
    await createRepos({
      repoName: data.repoName,
      token: userStore.user.token,
      projectName: data.projectName,
    })
      .then(async (res) => {
        if (res) {
          showToast({
            content: "Campaign repo created successfully.",
            duration: 3000,
            error: false,
          });
          if (data.createApi) {
            await createRepos({
              repoName: data.repoName + "-api",
              token: userStore.user.token,
              projectName: data.projectName,
            }).then((res) => {
              showToast({
                content: "campaign api repo created successfully.",
                duration: 3000,
                error: false,
              });
            });
          }
          if (data.createAdmin) {
            await createRepos({
              repoName: data.repoName + "-admin",
              token: userStore.user.token,
              projectName: data.projectName,
            }).then((res) => {
              showToast({
                content: "campaign admin repo created successfully.",
                duration: 3000,
                error: false,
              });
            });
          }
          reset();
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        showToast({
          content: "Error while create the repo.",
          duration: 3000,
          error: true,
        });
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <NavMenu />
        <div className="col-sm-12 col-md-10">
          <Header />

          <div className="dashboardContent">
            <div className="tab-contents">
              {loading ? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <form onSubmit={handleSubmit(submitForm)} id="addCampaignRepos">
                  <div className="formContent ">
                    <div className="row">
                      <div className="col-sm-6 col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Project name
                          </label>
                          <input
                           {...register("projectName", {
                            required: {
                              value: true,
                              message: "Please Enter a project name",
                            },
                            maxLength: {
                              value: 43,
                              message:
                                "Project name is too long, please keep within 43 character limit",
                            },
                          })}
                            placeholder="Project Name"
                            id="exampleFormControlInput1"
                            className="form-control"
                          />

                          {errors.projectName && (
                            <div className="error">
                              <i className="fas fa-exclamation-circle" />
                              {errors.projectName.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Repo name
                          </label>
                          <input
                            {...register("repoName", {
                              onChange:(e)=>{
                                setValue('repoName',e.currentTarget.value.toLowerCase())
                               },
                              required: {
                                value: true,
                                message: "Please Enter a repo name.",
                              },
                              pattern: {
                                value: repoNameRegex,
                                message: "Invalid format.",
                              },
                              maxLength: {
                                value: 28,
                                message:
                                  "Repo name is too long, please keep within 28 character limit.",
                              },
                            })}
                            placeholder="Repo Name"
                            id="exampleFormControlInput1"
                            className="form-control"
                          />

                          {errors.repoName && (
                            <div className="error">
                              <i className="fas fa-exclamation-circle" />
                              {errors.repoName.message}
                            </div>
                          )}
                        </div>
                        <span className="italic-p">
                          Ex: test01-testcampaign-name
                        </span>
                      </div>
                    </div>

                    <div className="form-group toggleBtn">
                      <p>Also create -api</p>
                      <div className="swtichCont">
                        <label className="switch" htmlFor="checkbox1">
                          <input
                            type="checkbox"
                            {...register("createApi", { required: false })}
                            id="checkbox1"
                            className="form-check-input"
                          />

                          <div className="slider round"></div>
                        </label>
                      </div>
                    </div>

                    <div className="form-group toggleBtn">
                      <p>Also create -admin</p>
                      <div className="swtichCont">
                        <label className="switch" htmlFor="checkbox2">
                          <input
                            type="checkbox"
                            {...register("createAdmin", { required: false })}
                            id="checkbox2"
                            className="form-check-input"
                          />

                          <div className="slider round"></div>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <button className="btn form" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRepos;
