import {useNavigate} from "react-router-dom";
import {useStores} from "../stores";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {cmsCreateType, cmsGetType, cmsUpdateType} from "../api";
import {showToast} from "./Toast/ToastManager";
import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {EContentType} from "../types/portalTypes";

type FormType = {
    title: string;
    slug: string;
    content_type: EContentType;
    enabled: boolean;
};

export const ManageCMSTypeDialog = ({open, setOpen, existingEntityId: typeId}:{open:boolean, setOpen: any, existingEntityId?: string}) => {
    const navigate = useNavigate()
    const { userStore } = useStores();
    const queryClient = useQueryClient();
    const { data: type } = useQuery(`cmsGetType-${typeId}`, async () => {
        if (!typeId) return
        if (typeId === '*') return
        return cmsGetType({token: userStore.user.token, slug: typeId || ''});
    });
    const { mutate: updateType, isLoading: isUpdateLoading } = useMutation(
        cmsUpdateType,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
               return queryClient.invalidateQueries(`cmsGetType-${typeId}`).then(()=>setOpen(false));
            },
        }
    );

    const { mutate: createType, isLoading: isCreateLoading } = useMutation(
        cmsCreateType,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been created successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type creation failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: (data) => {
                if (!data?.data) return
                return queryClient.invalidateQueries(`cmsListTypes`).then(()=> {
                    setOpen(false)
                    navigate(`/cms/${data?.data.slug}`)
                });
            },
        }
    );
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isDirty },
    } = useForm<FormType>({
        defaultValues: {
            title: "",
            slug: "",
            enabled: false,
            content_type: EContentType.text
        },
    });

    useEffect(() => {
        if (type?.slug) {
            setValue('slug', type.slug)
            setValue('title', type.title)
            setValue('enabled', type.enabled)
            setValue('content_type', type.content_type)
        }
    },[setValue, type])
    const submitForm = (data: FormType) => {
        if (!typeId) return
        if (typeId === '*') return createType({token: userStore.user.token, title: data.title, enabled: data.enabled, slug: data.slug, content_type: data.content_type})
        return updateType({token: userStore.user.token, enabled: data.enabled, slug: data.slug, oldSlug: typeId, title: data.title, content_type: data.content_type})
    }

    const genSlug = () => {
        setValue('slug', uuidv4())
    }

    if (!typeId) return <div/>

    const editing = (typeId !== '*' && !!type)
    const creating = (typeId === '*' && !type)
    const isValid = editing || creating
    return <Modal show={open} onHide={()=>setOpen(false)}>
        <Modal.Header>
            <Modal.Title>Add Content Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isValid ? <div className={'container'}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="mt-2">
                        <div className="row">
                            <div className="col-sm-6 col-md-12">
                                <div className="form-group">
                                    <div className={'flex justify-between'}>
                                        <label htmlFor="">
                                            Slug
                                        </label>
                                        <Link onClick={genSlug} to={'#'}>Generate</Link>
                                    </div>
                                    <input
                                        {...register("slug", {
                                            required: {
                                                value: true,
                                                message: "Please Enter a type slug",
                                            },
                                        })}
                                        placeholder="Slug (unique)"
                                        className="form-control"
                                    />

                                    {errors.slug && (
                                        <div className="error">
                                            <i className="fas fa-exclamation-circle"/>
                                            {errors.slug.message}
                                        </div>
                                    )}
                                </div>
                                <div className={'text-muted'}>Used by the API to identify different types, required and
                                    unique.
                                </div>
                                <div className="italic-p">Example: inspiration-tag</div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-6 col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">
                                        Display Name
                                    </label>
                                    <input
                                        {...register("title")}
                                        placeholder="Title"
                                        className="form-control"
                                    />

                                    {errors.title && (
                                        <div className="error">
                                            <i className="fas fa-exclamation-circle"/>
                                            {errors.title.message}
                                        </div>
                                    )}
                                </div>
                                <div className={'text-muted'}>Used only for the admin panel, this is a user-friendly
                                    title for this CMS type
                                </div>
                                <div className="italic-p">Example: Inspiration Tag</div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="agency">Type</label>
                            <select
                                className="form-control"
                                {...register("content_type", {
                                    required: {
                                        value: true,
                                        message: "Please select a type.",
                                    },
                                })}
                            >
                                <option value="text">Text</option>
                                <option value="markdown">Markdown (styled text)</option>
                                <option value="image">Image</option>
                                <option value="document">Document</option>
                                <option value="video">Video (vimeo link)</option>
                                <option value="other">Other</option>
                            </select>

                            {errors.content_type && (
                                <p className="error">
                                    <i className="fas fa-exclamation-circle"/>{" "}
                                    {errors.content_type.message}
                                </p>
                            )}
                        </div>

                        <div className="form-group toggleBtn">
                            <p>Enabled</p>
                            <div onClick={() => setValue('enabled', !getValues('enabled'))} className="swtichCont">
                                <label className="switch" htmlFor="checkbox1">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        {...register("enabled", {required: false})}
                                    />

                                    <div className="slider round"></div>
                                </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group">
                                    <button disabled={!isDirty || isUpdateLoading || isCreateLoading} className="btn form" type="submit">
                                        {editing ? 'Save' : 'Create'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div> : null}
        </Modal.Body>
    </Modal>
}