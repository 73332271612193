import { useEffect, useState, useRef } from "react";
import { spryClient } from "../../../api";
import { Winners } from "./Winners";
import {
  EmailTemplate,
  Prize,
  PrizeWinner,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { Rejected } from "./Rejected";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { showToast } from "../../../Components/Toast/ToastManager";
import { Modal, Spinner, Button, Tabs, Tab } from "react-bootstrap";
import {
  AssignGrandPrizeWinnerArgs,
  ForfeitPrizeArgs,
  RejectGrandPrizeDrawRankingArgs,
  UnforfeitPrizeArgs,
  UnrejectGrandPrizeDrawRankingArgs,
} from "@sprycore/spry-api-client/dist/MainDbCallTypes";
import { GrandPrizeDrawRanking } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { CSVLink } from "react-csv";
import { Pending } from "./Pending";
import Popup from "../../../Components/Popup";
import dayjs from "dayjs";

import { useParams } from "react-router-dom";
import { Claimed } from "./Claimed";
import { SendEmailArgs } from "@sprycore/spry-api-client/dist/CallTypes";
import { Fulfilled } from "./Fulfilled";

type IProps = {
  campaignKey: string;
  allPrizes: any[] | undefined;
};
type DrawReport = {
  Rank: string;
  "First Name": string | null;
  "Last Name": string | null;
  Email: string | null;
  "Legal Name": string | null;
  "Phone Number": string | null;
  "Address 1": string | null;
  "Address 2": string | null;
  City: string | null;
  Province: string | null;
  Country: string | null;
  Postal: string | null;
  StqAnswer: string | null;
  "Rejection time": string | null;
  Language: string;
  "Create Time": string;
  "Forfeit Time": string;
  "Claimed Time": string;
  Status: string;
  urls: any;
};

export default function CampaignPrizeDrawDetails({
  campaignKey,
  allPrizes,
}: IProps) {
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const { grandPrizeDrawKey } = useParams() as { grandPrizeDrawKey: string };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedrecords, setSelectedRecords] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [openEmailmodal, setopenEmailModal] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [forfeitOpen, setFofeitOpen] = useState(false);
  const [prizeWinners, SetPrizeWinners] = useState<PrizeWinner[]>();
  const [grandPrizeRankings, SetGrandPrizeRankings] =
    useState<GrandPrizeDrawRanking[]>();
  const [selectedPrize, setSelectedPrize] = useState<{
    prizeKey: string;
    prizeName: string;
  }>({ prizeKey: "", prizeName: "" });
  const [emailTemplateKey, setEmailtemplateKey] = useState("");
  const [eligibleRankings, setEligibleWinners] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [pendingRannkings, setPendingRannkings] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [rejectedRankings, setRejectedRankings] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [claimedRankings, setClaimedRankings] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [fulfilledRankings, setFulfilledRankings] = useState<
    GrandPrizeDrawRanking[]
  >([]);
  const [filterloading, setfilterloading] = useState(false);
  const [activePrizes, setActivePrizes] = useState<Prize[]>([]);
  const [drawReport, setDrawreport] = useState<DrawReport[]>([]);
  const [key, setKey] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/prizedraws`);
  };
  const QueryKey = { campaignKey, grandPrizeDrawKey };

  const fetchAllApis = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { prizeWinners } = await spryClient.getPrizeWinners({ ...QueryKey });
    SetPrizeWinners(prizeWinners);
    const { grandPrizeDrawRankings } = await spryClient.getGrandPrizeDrawRankings({
      ...QueryKey,
    });
    SetGrandPrizeRankings(grandPrizeDrawRankings);

    setLoading(false);
  };

  const { isLoading: loadingtemplates, data: EmailTemplates } = useQuery(
    ["getEmailTemplates", { campaignKey }],
    async () => {
      const res = await spryClient.getEmailTemplates({ campaignKey });
      const filter_claim_templates = res.emailTemplates.filter((template) =>
        template.emailTemplateName.toLowerCase().includes("claim")
      );
      return filter_claim_templates;
    }
  );
  const { isLoading: loadingSettings } = useQuery(
    ["getCampaignLanguageSettings", { campaignKey }],
    async () => {
      const res = await spryClient.getCampaignLanguageSettings({ campaignKey });
      return res.campaignLanguageSettings;
    }
  );
  const { isLoading: loadCampaign } = useQuery(
    ["getCampaigns", { campaignKey }],
    async () => {
      const res = await spryClient.getCampaigns({ campaignKey });

      return res.campaigns[0];
    }
  );

  //****Get All the Prizes asssociated with the prize pool */

  const findPrizeName = (prizeKey: String, allPrizes: any) => {
    const matchedPrize = allPrizes.filter((prize: any) => {
      return prize.prizeKey === prizeKey;
    });
    return matchedPrize[0].prizeName;
  };

  async function findPrizes() {
    const { grandPrizeDraws } = await spryClient.getGrandPrizeDraws({ ...QueryKey });
    if (activePrizes.length > 0) {
      return;
    }
    const res = await spryClient.getPrizePoolPrizes({
      campaignKey,
      prizePoolKey: grandPrizeDraws[0].prizePoolKey,
    });
    let updatedPrizePoolPrizes = res.prizePoolPrizes.map((record: any) => {
      record["prizeName"] =
        allPrizes && findPrizeName(record.prizeKey, allPrizes);
      return record;
    });
    let activePrizes1 = updatedPrizePoolPrizes.filter((record: any) => {
      return record.active === true;
    });
    setActivePrizes(activePrizes1);
  }

  async function sendClaimEmail(emailParams: any) {
    const res = await spryClient.sendEmail(emailParams);
    return res;
  }

  async function assignWinner1(
    ranking: GrandPrizeDrawRanking,
    prizeKey: string,
    emailKey: string,
    prizeName: string
  ) {
    const selectedEmailTemplate =
      EmailTemplates &&
      EmailTemplates.filter(
        (template) => template.emailTemplateKey === emailKey
      );
    const release_code = await spryClient.generateReleaseCode();
    const queryKey_assignwinner: AssignGrandPrizeWinnerArgs = {
      grandPrizeDrawRankingKey: ranking.grandPrizeDrawRankingKey,
      campaignKey,
      prizeKey: prizeKey,
      releaseCode: release_code,
    };

    await spryClient.assignGrandPrizeWinner({ ...queryKey_assignwinner })
      .then(async (res) => {
        if (ranking.participant.email && res.prizeWinnerKey) {
          const emailparams: SendEmailArgs = {
            campaignKey,
            transactional: true,
            emailTemplateKey: emailKey,
            email: ranking.participant.email,
            sessionKey: ranking.participant.sessionKey,
            parameters: {
              firstName: ranking.participant.firstName!,
              sessionKey: ranking.participant.sessionKey,
              email: ranking.participant.email!,
              prizeName: prizeName,
              prizeWinnerKey: res.prizeWinnerKey,
              emailLanguage: selectedEmailTemplate
                ? selectedEmailTemplate[0].language
                : ranking.participant.preferredLanguage,
            },
          };
          try {
            await sendClaimEmail(emailparams);
          } catch (e) {
            showToast({
              content: "Oops! error in sending email. ",
              duration: 3000,
              error: true,
            });
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        showToast({
          content: `${e.toString()}`,
          duration: 3000,
          error: true,
        });
        return;
      });
  }

  const assignMultipleWinners = async ({
    prizeKey,
    prizeName,
  }: {
    prizeKey: string;
    prizeName: string;
  }) => {
    setIsOpen(false);
    if (loading) {
      return;
    }
    setLoading(true);

    const makeEligibleAll = Promise.all(
      selectedrecords.map(async function (record: GrandPrizeDrawRanking) {
        await assignWinner1(record, prizeKey, emailTemplateKey, prizeName);
      })
    );
    await makeEligibleAll
      .then((res) => {
        showToast({
          content:
            "Selected winners have been assigned with prize successfully. ",
          duration: 3000,
          error: false,
        });
        fetchAllApis();
        filterWinners();
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          content: `${e.toString()}`,
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };

  const rejectMultipleeligibles = async () => {
    setRejectOpen(false);
    setLoading(true);

    Promise.all(
      selectedrecords.map(async function (record: GrandPrizeDrawRanking) {
        const queryKey_rejectWinner: RejectGrandPrizeDrawRankingArgs = {
          campaignKey,
          grandPrizeDrawRankingKey: record.grandPrizeDrawRankingKey,
          rejectionReason: "",
        };
        await spryClient.rejectGrandPrizeDrawRanking({ ...queryKey_rejectWinner });
      })
    )
      .then((res) => {
        showToast({
          content: "Selected winners have been rejected successfully. ",
          duration: 3000,
          error: false,
        });
        fetchAllApis();
        filterWinners();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        showToast({
          content: "Oops,api error. ",
          duration: 3000,
          error: true,
        });
      });
  };
  const handleKeySelection = (key: string | null) => {
    if (key) {
      setKey(key);
      window.sessionStorage.setItem("tabSelection", key);
    }
  };

  async function getParticipantFiles(sessionKey: string) {
    const res = await spryClient.getUserFiles({ campaignKey, sessionKey });

    if (res.userFiles.length === 0) {
      return [];
    }
    let keys = res.userFiles && res.userFiles?.map((value) => value?.key);
    const urls = await spryClient.downloadUserFile({
      campaignKey,
      key: keys ? keys : [],
      expirySeconds: 604800,
    });
    const data = res.userFiles?.map((file) => {
      return Object.values({
        uploadDate: new Date(file.date).toLocaleString(),
        url: urls.downloadUrls[file.key],
      });
    });
    return data;
  }

  const forfeitMultiple = async () => {
    setFofeitOpen(false);
    setLoading(true);
    let filteredWinners = prizeWinners
      ? prizeWinners.filter((winner: PrizeWinner) =>
        selectedrecords.some(
          (selected: GrandPrizeDrawRanking) =>
            winner.grandPrizeDrawRankingKey ===
            selected.grandPrizeDrawRankingKey
        )
      )
      : [];
    Promise.all(
      filteredWinners.map(async function (record: PrizeWinner) {
        const queryKey_rejectWinner: ForfeitPrizeArgs = {
          campaignKey,
          prizeWinnerKey: record.prizeWinnerKey,
        };
        await spryClient.forfeitPrize({ ...queryKey_rejectWinner });
      })
    )
      .then((res) => {
        showToast({
          content: "Selected Winners has been forfeited successfully. ",
          duration: 3000,
          error: false,
        });
        fetchAllApis();
        filterWinners();
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          content: "Oops,api error. ",
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };

  async function unreject(data: UnrejectGrandPrizeDrawRankingArgs) {
    try {
      await spryClient.unrejectGrandPrizeDrawRanking({ ...data });
    } catch (e) {
      showToast({
        content: "Oops,api error. ",
        duration: 3000,
        error: true,
      });
    }
  }

  async function unforfeit(data: UnforfeitPrizeArgs) {
    try {
      await spryClient.unforfeitPrize({ ...data });
    } catch (e) {
      showToast({
        content: "Oops,api error. ",
        duration: 3000,
        error: true,
      });
    }
  }
  const makeEligiblerPending = async (selectings: GrandPrizeDrawRanking[]) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Promise.all(
      selectings &&
      selectings.map(async (ranking: GrandPrizeDrawRanking) => {
        if (ranking.rejectionTime) {
          await unreject({
            campaignKey: campaignKey,
            grandPrizeDrawRankingKey: ranking.grandPrizeDrawRankingKey,
          });
        } else {
          let prizeWinner =
            prizeWinners &&
            prizeWinners.filter(
              (winner) =>
                winner.grandPrizeDrawRankingKey ===
                ranking.grandPrizeDrawRankingKey
            );
          prizeWinner &&
            (await unforfeit({
              campaignKey,
              prizeWinnerKey: prizeWinner[0].prizeWinnerKey,
            }));
        }
      })
    )
      .then((res) => {
        fetchAllApis();
        filterWinners();
        showToast({
          content:
            "Selected Winners have been made eligible/unforfeited successfully. ",
          duration: 3000,
          error: false,
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        showToast({
          content: "Oops,api error. ",
          duration: 3000,
          error: true,
        });
      });
  };

  const resendMultipleEmail = async (emailTemplate: string) => {
    setLoading(true);
    setopenEmailModal(false);
    const selectedEmailTemplate =
      EmailTemplates &&
      EmailTemplates.filter(
        (template) => template.emailTemplateKey === emailTemplate
      );

    const sendAll = Promise.all(
      selectedrecords.map(async function (record: GrandPrizeDrawRanking) {
        if (prizeWinners) {
          const PrizeWon = prizeWinners.filter(
            (winner) =>
              winner.participant.sessionKey === record.participant.sessionKey
          );
          if (PrizeWon.length && record.participant.email) {
            const emailparams: SendEmailArgs = {
              campaignKey,
              transactional: true,
              emailTemplateKey: emailTemplate,
              email: record.participant.email,
              sessionKey: record.participant.sessionKey,
              parameters: {
                firstName: record.participant.firstName!,
                sessionKey: record.participant.sessionKey,
                email: record.participant.email!,
                prizeName:
                  allPrizes && findPrizeName(PrizeWon[0].prizeKey, allPrizes),
                prizeWinnerKey: PrizeWon[0].prizeWinnerKey,
                emailLanguage: selectedEmailTemplate
                  ? selectedEmailTemplate[0].language
                  : record.participant.preferredLanguage,
              },
            };
            await spryClient.sendEmail(emailparams);
          }
        }
      })
    );
    await sendAll
      .then((res) => {
        setLoading(false);
        showToast({
          content: "Emails have been Sent successfully. ",
          duration: 3000,
          error: false,
        });
        fetchAllApis();
        filterWinners();
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          content:
            "something went wrong, while resending email, please check. ",
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };
  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }
  const getLegalName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null
  ) => {
    if (firstName && lastName) {
      return (
        capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
      );
    } else if (firstName) {
      return capitalizeFirstLetter(firstName);
    } else return "N/A";
  };

  const generateReport = async () => {
    setLoading(true);

    let reportdata: DrawReport[] = [];

    if (grandPrizeRankings && prizeWinners)
      for (let ranking of grandPrizeRankings) {
        let prizeWinner =
          prizeWinners &&
          prizeWinners.filter(
            (winner) =>
              winner.grandPrizeDrawRankingKey ===
              ranking.grandPrizeDrawRankingKey
          );

        const files = await getParticipantFiles(ranking.participant.sessionKey);
        let status;
        if (ranking.rejectionTime) {
          status = "Rejected";
        } else if (prizeWinner[0]?.forfeitTime) {
          status = "Rejected";
        } else if (prizeWinner[0]?.fulfillTime) {
          status = "Fulfilled";
        } else if (prizeWinner[0]?.declarationAndRelease) {
          status = "Claimed";
        } else if (prizeWinner[0]) {
          status = "Pending";
        } else {
          status = "Eligible";
        }

        let data: DrawReport = {
          Rank: ranking.ranking.toString(),
          "First Name": ranking.participant.firstName,
          "Last Name": ranking.participant.lastName,
          Email: ranking.participant.email,
          "Legal Name": prizeWinner[0]?.declarationAndRelease
            ? getLegalName(
              prizeWinner[0].declarationAndRelease?.firstName,
              prizeWinner[0].declarationAndRelease?.lastName
            )
            : "N/A",
          "Phone Number": prizeWinner[0]?.declarationAndRelease?.phone || "N/A",
          "Address 1": prizeWinner[0]?.declarationAndRelease?.address || "N/A",
          "Address 2": prizeWinner[0]?.declarationAndRelease?.address2 || "N/A",
          City: prizeWinner[0]?.declarationAndRelease?.city || "N/A",
          Province: prizeWinner[0]?.declarationAndRelease?.province || "N/A",
          Country: prizeWinner[0]?.declarationAndRelease?.country || "N/A",
          Postal: prizeWinner[0]?.declarationAndRelease?.postal || "N/A",
          StqAnswer:
            prizeWinner[0]?.declarationAndRelease?.skillTestingAnswer || "N/A",
          "Rejection time": ranking.rejectionTime
            ? new Date(ranking.rejectionTime).toLocaleString()
            : "N/A",
          Language: ranking.participant.preferredLanguage || "N/A",
          "Create Time": new Date(ranking.creationTime).toLocaleString(),
          "Forfeit Time": prizeWinner[0]?.forfeitTime
            ? new Date(prizeWinner[0].forfeitTime).toLocaleString()
            : "N/A",
          "Claimed Time": prizeWinner[0]?.fulfillTime
            ? new Date(prizeWinner[0].fulfillTime).toLocaleString()
            : "N/A",
          Status: status,
          urls: files.length > 0 ? files : "N/A",
        };
        reportdata.push(data);
      }
    setDrawreport(reportdata);
    setTimeout(() => {
      csvLinkRef?.current?.link.click();
    });
    setLoading(false);
  };

  const filterWinners = async () => {
    setfilterloading(true);
    if (!grandPrizeRankings && !prizeWinners) {
      fetchAllApis();
    }
    let eligibleRankings3: GrandPrizeDrawRanking[] = [];
    let pendingRankings3: GrandPrizeDrawRanking[] = [];
    let rejectedRankings3: GrandPrizeDrawRanking[] = [];
    let claimedRankings3: GrandPrizeDrawRanking[] = [];
    let fulfilledRankings3: GrandPrizeDrawRanking[] = [];
    if (grandPrizeRankings && prizeWinners) {
      for (let ranking of grandPrizeRankings) {
        let prizeWinner = prizeWinners.filter(
          (winner) =>
            winner.grandPrizeDrawRankingKey === ranking.grandPrizeDrawRankingKey
        );
        if (prizeWinner[0]) {
          if (prizeWinner[0].fulfillTime) {
            fulfilledRankings3.push(ranking);
          } else if (prizeWinner[0].forfeitTime) {
            rejectedRankings3.push(ranking);
          } else if (prizeWinner[0].declarationAndRelease) {
            claimedRankings3.push(ranking);
          } else {
            pendingRankings3.push(ranking);
          }
        } else {
          if (ranking.rejectionTime) {
            rejectedRankings3.push(ranking);
          } else {
            eligibleRankings3.push(ranking);
          }
        }
      }
      setEligibleWinners(eligibleRankings3);
      setPendingRannkings(pendingRankings3);
      setClaimedRankings(claimedRankings3);
      setRejectedRankings(rejectedRankings3);
      setFulfilledRankings(fulfilledRankings3);
      setfilterloading(false);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("tabSelection")) {
      let activeKey =
        window.sessionStorage.getItem("tabSelection") || "eligible";
      setKey(activeKey);
    } else {
      setKey("eligible");
    }
  }, []);

  useEffect(() => {
    findPrizes();
    filterWinners();
    // eslint-disable-next-line
  }, [grandPrizeRankings, prizeWinners, activePrizes]);

  return (
    <>
      {filterloading ||
        loading ||
        loadingtemplates ||
        loadingSettings ||
        loadCampaign ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div className="dashboardContent campaignDetail tabsCont">
          <div id="tab08" className="tab-contents prizedraws">
            <div className="head prizedraw-details">
              <h3>
                <button className="btn-noborder" onClick={backHandler}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                Grand prize draw details
              </h3>
              <div style={{ float: "right" }}>
                {key !== "rejected" &&
                  key !== "pending" &&
                  key !== "fulfilled" && (
                    <>
                      <button
                        className="btn dashboard-btns"
                        onClick={() => setRejectOpen(true)}
                        disabled={selectedrecords.length > 0 ? false : true}
                      >
                        Reject
                      </button>
                      <button
                        className="btn dashboard-btns"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        disabled={selectedrecords.length > 0 ? false : true}
                      >
                        Assign prize
                      </button>
                    </>
                  )}
                {key === "rejected" && (
                  <button
                    className="btn dashboard-btns"
                    onClick={() => makeEligiblerPending(selectedrecords)}
                    disabled={selectedrecords.length > 0 ? false : true}
                  >
                    Make eligible
                  </button>
                )}

                {key === "pending" && (
                  <>
                    <button
                      className="btn dashboard-btns"
                      onClick={() => setFofeitOpen(true)}
                      disabled={selectedrecords.length > 0 ? false : true}
                    >
                      Forfeit
                    </button>
                    <button
                      className="btn dashboard-btns"
                      onClick={() => setopenEmailModal(true)}
                      disabled={selectedrecords.length > 0 ? false : true}
                    >
                      Resend claim email
                    </button>
                  </>
                )}

                <Button
                  onClick={generateReport}
                  disabled={
                    grandPrizeRankings && grandPrizeRankings?.length > 0
                      ? false
                      : true
                  }
                  className="btn"
                >
                  Download report
                </Button>
                <CSVLink
                  filename={`${dayjs().format("MMMDD-YY")}-draw-report.csv`}
                  data={drawReport ? drawReport : ""}
                  className="hidden"
                  ref={csvLinkRef}
                  onClick={() => {
                  }}
                />

                <div>
                  <></>
                </div>
              </div>
            </div>

            <Tabs
              activeKey={key}
              onSelect={(key) => handleKeySelection(key)}
              className="nav nav-tabs"
              style={{ width: "100%", marginBottom: "25px" }}
            >
              <Tab eventKey="eligible" title="Eligible">
                <Winners
                  campaignKey={campaignKey}
                  eligibleRankings={eligibleRankings}
                  setisOpen={setIsOpen}
                  activePrizes={activePrizes}
                  setSelectedWinners={setSelectedRecords}
                  emailTemplates={EmailTemplates}
                  rejectOpen={setRejectOpen}
                />
              </Tab>
              <Tab eventKey="rejected" title="Rejected">
                <Rejected
                  campaignKey={campaignKey}
                  rejectedRankings={rejectedRankings}
                  setSelectedWinners={setSelectedRecords}
                  unforfeitPrizemultiple={makeEligiblerPending}
                  prizeWinners={prizeWinners}
                />{" "}
              </Tab>
              <Tab eventKey="pending" title="Pending">
                <Pending
                  campaignKey={campaignKey}
                  pendingRankings={pendingRannkings}
                  setSelectedWinners={setSelectedRecords}
                  setForfeitopen={setFofeitOpen}
                  prizeWinners={prizeWinners}
                  resendEmail={resendMultipleEmail}
                  emailTemplates={EmailTemplates}
                />
              </Tab>
              <Tab eventKey="claimed" title="Claimed">
                <Claimed
                  campaignKey={campaignKey}
                  claimedRankings={claimedRankings}
                  grandPrizeDrawKey={grandPrizeDrawKey}
                />
              </Tab>
              <Tab eventKey="fulfilled" title="Fulfilled">
                <Fulfilled
                  campaignKey={campaignKey}
                  fulfilledRankings={fulfilledRankings}
                  grandPrizeDrawKey={grandPrizeDrawKey}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      <Modal show={isOpen} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Assign a prize</h3>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Prize</label>
            {activePrizes && (
              <select
                className="form-control"
                value={selectedPrize.prizeKey}
                onChange={(e) => {
                  setSelectedPrize({
                    prizeKey: e.currentTarget.value,
                    prizeName: e.currentTarget.name,
                  });
                }}
              >
                <option value="">Please select a prize</option>
                {activePrizes.length > 0 &&
                  activePrizes.map((prize: any, index: any) => {
                    return (
                      <option value={prize.prizeKey} key={prize.prizeKey}>
                        {prize.prizeName}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Prize</label>
            {EmailTemplates && (
              <select
                className="form-control"
                value={emailTemplateKey}
                onChange={(e) => {
                  setEmailtemplateKey(e.currentTarget.value);
                }}
              >
                <option value="">Please select a email template</option>
                {EmailTemplates.length > 0 &&
                  EmailTemplates.map((template: EmailTemplate, index: any) => {
                    return (
                      <option value={template.emailTemplateKey} key={index}>
                        {template.emailTemplateName}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={() => {
              assignMultipleWinners(selectedPrize);
            }}
            disabled={selectedPrize.prizeKey && emailTemplateKey ? false : true}
          >
            Assign
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={closeModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={openEmailmodal} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setopenEmailModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Resend prize claim email?</h3>
          <p>
            Are you sure you want to resend the prize claim email to user(s)?
          </p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"> Prize claim email</label>
            <select
              className="form-control"
              value={emailTemplateKey}
              onChange={(e) => {
                setEmailtemplateKey(e.currentTarget.value);
              }}
            >
              <option value="" key="">
                Select an option{" "}
              </option>
              {EmailTemplates &&
                EmailTemplates.map((template) => (
                  <option
                    value={template.emailTemplateKey}
                    key={template.emailTemplateKey}
                  >
                    {template.emailTemplateName}{" "}
                  </option>
                ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={() => resendMultipleEmail(emailTemplateKey)}
            disabled={emailTemplateKey ? false : true}
          >
            Resend
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setopenEmailModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Popup
        isOpen={rejectOpen}
        closeModal={() => setRejectOpen(false)}
        header="Reject Winner(s)"
        message="Are you sure you want to reject the selected winner(s)?"
        action1={() => {
          rejectMultipleeligibles();
        }}
        yesMessage="Yes, Reject"
        cancelMessage="Cancel"
        action2={() => setRejectOpen(false)}
      />
      <Popup
        isOpen={forfeitOpen}
        closeModal={() => setFofeitOpen(false)}
        header="Forfeit Winner(s)"
        message="Are you sure you want to forfeit the selected winner(s)?"
        action1={() => {
          forfeitMultiple();
        }}
        yesMessage="Yes, Forfeit"
        cancelMessage="Cancel"
        action2={() => setFofeitOpen(false)}
      />
    </>
  );
}
