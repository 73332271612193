import icoCalendar from "../../../assets/images/ico-calendar.png";
import icoClock from "../../../assets/images/ico-clock.png";
import DatePickerCalendar from "../DatePickerCalendar";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";
import { spryClient } from "../../../api";
import { showToast } from "../../../Components/Toast/ToastManager";
import { Button, Spinner } from "react-bootstrap";

type ScheduleForm = {
  state: string;
  startDate: Date;
  startTime: Date;
  metadata: Object;
};

function CampaignSchedule({ campaignKey }: { campaignKey: string }) {
  const queryClient = useQueryClient();
  const  defaultValues = {
    state: "",
    startDate: new Date(),
    startTime: new Date(),
    metadata: "",
  }
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ScheduleForm>({
    mode: "onTouched",
   defaultValues:{...defaultValues}
  });
  const formWatch = watch();
  const getDateTime = (date: Date, time: Date) => {
    let tempDate = dayjs(date);
    let updatedDate_time = dayjs(tempDate)
      .hour(dayjs(time).hour())
      .minute(dayjs(time).minute())
      .second(dayjs(time).second());
    const finalDate = updatedDate_time.toISOString();
    return finalDate;
  };
  const { mutate, isLoading } = useMutation(spryClient.createCampaignStateSchedule, {
    onSuccess: (data) => {
      showToast({
        content: "New schedule state added successfully.",
        duration: 3000,
        error: false,
      });
      reset({...defaultValues})
    },
    onError: () => {
      showToast({
        content: "Sorry, the time slot is not available.",
        duration: 3000,
        error: true,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("getCampaignStateSchedules");
    },
  });

  const handelAddSchedule = async (values: any) => {
    //console.log("manage-values:", values);
    const scheduledDateTime = getDateTime(values.startDate, values.startTime);
    const metadata = values.metadata ? JSON.parse(values.metadata) : null;

    let scheduledState = {
      campaignKey: campaignKey,
      startTime: scheduledDateTime,
      state: values.state,
      metadata,
    };
    mutate(scheduledState);
  };

  const validJson = (value: any) => {
    try {
      if (value) {
        JSON.parse(value);
      }
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="stateItem">
      <div className="state">
        <form onSubmit={handleSubmit(handelAddSchedule)}>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">State</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  {...register("state", {
                    required: { value: true, message: "Please select a state" },
                  })}
                >
                  <option value="">Please select a state</option>
                  <option value="comingsoon">Coming Soon</option>
                  <option value="open">Open</option>
                  <option value="closed">Close</option>
                  <option value="redemptiononly">Redemption Only</option>
                  <option value="suspended">Suspended</option>
                </select>
                {errors.state && (
                  <div className="error">
                    <i className="fas fa-exclamation-circle" />
                    <>{errors.state.message}</>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div className="date End">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Start Date</label>
                  <DatePickerCalendar
                    name="startDate"
                    value={formWatch.startDate}
                    onChange={(val: Date) => {
                      setValue("startDate", val);
                    }}
                    readOnly={false}
                    dateforTime={formWatch.startDate}
                  />
                  <span className="calIco">
                    <img src={icoCalendar} alt="Calendar" />
                  </span>
                </div>
                {errors.startDate && (
                  <div className="error">
                    <i className="fas fa-exclamation-circle" />
                    <>{errors.startDate.message}</>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div className="clock start">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Start Time</label>
                  <DatePickerCalendar
                    showTimePicker
                    name="startTime"
                    value={formWatch.startTime}
                    onChange={(val: Date) => setValue("startTime", val)}
                    readOnly={false}
                    dateforTime={formWatch.startTime}
                  />
                  <span className="timeIco">
                    <img src={icoClock} alt="Clock" />
                  </span>

                  {errors.startTime && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      <>{errors.startTime.message}</>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-2 col-sm-12">
              <div className="actionBtns">
                {isLoading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <button className="btn outline add" type="submit">
                    {" "}
                    Add schedule state
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="metaDataCont">
                <div className="form-group">
                  <label htmlFor="metadata">Meta Data</label>
                  <textarea
                    className="form-control"
                    id="metadata"
                    rows={5}
                    {...register("metadata", {
                      required: false,
                      validate: validJson,
                    })}
                  />
                  {errors.metadata && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      Invalid json format
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CampaignSchedule;
