import React from "react";
import { Dropdown } from "react-bootstrap";

export const COLUMNS = (fns: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdate: (data: { agencyKey: string; agencyName: string }) => void;
  mutate: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStateKey: any;
}) => {
  const COLUMNS = [
    {
      Header: "Agency Name",
      accessor: "agencyName",
      width: "80%",
    },
    {
      Header: "Updated",
      accessor: "updatedTime",
      width: "15%",
    },
    {
      Header: "",
      accessor: "agencyKey",
      width: "5%",
      Cell: (TableInfo: {
        data: { [x: string]: { agencyName: string; agencyKey: string } };
        row: { index: string | number };
      }) => {
        const agencyKey = TableInfo.data[TableInfo.row.index].agencyKey;

        const agencyName: string =
          TableInfo.data[TableInfo.row.index].agencyName;

        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  fns.handleUpdate({ agencyKey, agencyName });
                  fns.setShow(true);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  fns.setStateKey(agencyKey);
                  fns.mutate({ agencyKey });
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return COLUMNS;
};
