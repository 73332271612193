import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQueryClient, useQuery } from "react-query";
import { spryClient } from "../../../api";
import { Table } from "../../../Components";
import idelete from "../../../assets/images/ico-trash.png";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Prize } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type typeProp = {
  campaignKey: string;
};
type AddPrizePoolForm = {
  prizePoolName: string;
  selectedPrizes: string[];
  prize: string;
};
const AddPrizePool = ({ campaignKey }: typeProp) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddPrizePoolForm>({
    mode: "onTouched",
    defaultValues: {
      prizePoolName: "",
      selectedPrizes: [],
    },
  });
  const formWatch = watch();

  const { data: allPrizes, isLoading: loadingPrizes } = useQuery(
    ["getPrizes", campaignKey],
    async () => {
      const res = await spryClient.getPrizes({ campaignKey });
      return res.prizes;
    }
  );

  const finalSubmit = async ({
    prizePoolName,
    selectedPrizes,
  }: AddPrizePoolForm) => {
    if (loading) {
      return;
    }
    setLoading(true);

    if (prizePoolName) {
      await spryClient
        .createPrizePool({ campaignKey, prizePoolName })
        .then(async (res) => {
          if (res.prizePoolKey) {
            if (selectedPrizes.length > 0) {
              const addPrizes = Promise.all(
                selectedPrizes.map(async function (prizeKey) {
                  await spryClient.addPrizeToPool({
                    prizeKey,
                    prizePoolKey: res.prizePoolKey,
                  });
                })
              );
              await addPrizes
                .then((res) => {
                  showToast({
                    content: "PrizePool has been created successfully. ",
                    duration: 3000,
                    error: false,
                  });

                  setLoading(false);
                  navigate(`/campaign/${campaignKey}/prizing`);

                  return;
                })
                .catch((e) => {
                  showToast({
                    content: "Oops,api error. ",
                    duration: 3000,
                    error: true,
                  });
                  setLoading(false);
                });
            } else {
              showToast({
                content: "PrizePool has been created successfully. ",
                duration: 3000,
                error: false,
              });
              queryClient.invalidateQueries("getPrizePools");
              setLoading(false);
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          showToast({
            content: `${e.toString()}`,
            duration: 3000,
            error: true,
          });
        });
    }
  };

  const styles = {
    button: {
      border: "none",
      backgroundColor: "transparent",
    },
  } as const;
  const COLUMNS = [
    {
      Header: "Prize",
      accessor: "prizeName",
      width: "30%",
    },
    {
      Header: "",
      accessor: "prizeKey",
      width: "70%",

      Cell: (TableInfo: any) => {
        return (
          <button
            type="button"
            className="delete-Button"
            style={styles.button}
            onClick={() => {
              //       removeprizehandler(TableInfo.data[TableInfo.row.index].prizeKey);
            }}
          >
            <div className="deleteimg">
              <img src={idelete} className="trash-img" alt="delete"></img>
            </div>{" "}
          </button>
        );
      },
    },
  ];

  //const prizescolumns = useMemo(() => AddPrizescolumns(functions), [])

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont addpg prizing">
        <div id="tab04" className="tab-contents addprizepool">
          <div className="head inner">
            <h3>
              <button className="backBtn">
                <i
                  className="fas fa-arrow-left"
                  onClick={() => {
                    navigate(`/campaign/${campaignKey}/prizing`);
                  }}
                ></i>
              </button>
              Add a prize pool
            </h3>
            <button
              className="btn addbtn"
              type="submit"
              form="addPrizepoolform"
            >
              Add Prize Pool
            </button>
          </div>
          {loading ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(finalSubmit)} id="addPrizepoolform">
              <div className="formContent">
                <div className="col-sm-12 col-md-8">
                  <div className="form-group">
                    <label>Prize Pool Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("prizePoolName", {
                        required: {
                          value: true,
                          message: "Please enter a prize pool name.",
                        },
                      })}
                      aria-describedby="prizePoolName"
                      placeholder="prizePoolName"
                      id="exampleFormControlInput1"
                    />
                  </div>
                  {errors.prizePoolName && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      {errors.prizePoolName.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                {
                  <Table
                    columns={COLUMNS}
                    data={allPrizes?.filter((p) =>
                      formWatch.selectedPrizes.includes(p.prizeKey)
                    )}
                    tablePageSize={5}
                  />
                }
              </div>
              <div className="addbtnPrizePool">
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Prize</label>
                      <select
                        {...register("prize", { required: false })}
                        className="form-control"
                        id="exampleFormControlSelect2"
                      >
                        <option value="">Please select a Prize</option>
                        {loadingPrizes ? (
                          <option>Loading...</option>
                        ) : (
                          allPrizes?.map((p: Prize) => {
                            return (
                              <option
                                value={p.prizeKey}
                                key={p.prizeKey}
                                disabled={
                                  formWatch.selectedPrizes.find(
                                    (s) => p.prizeKey === s
                                  )
                                    ? true
                                    : false
                                }
                              >
                                {p.prizeName}
                              </option>
                            );
                          })
                        )}
                      </select>
                      {errors.prize && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.prize.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="actionBtns">
                      <button
                        className="btn outline add"
                        onClick={() =>
                          setValue("selectedPrizes", [
                            ...formWatch.selectedPrizes,
                            formWatch.prize,
                          ])
                        }
                        type="button"
                      >
                        {" "}
                        Add Prize
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPrizePool;
