import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {

  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 100 // <----- This prop sets the stepSize
      }
    }
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Participants by month",
    },
  },

};


export function Graph(graphLabels:{graphLabels:{x:string[],y:number[]},selectedmonths:number}) {
 

  const data = {
    labels:graphLabels.graphLabels.x,
    datasets: [
      {
        label: "Number of Participants",
        data: graphLabels.graphLabels.y,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return <Line options={options} data={data} />;
}
