import { useState, useMemo } from "react";
import { ParticipantColumns } from "./ParticipantColumns";
import { PrizeInfoColumns } from "./prizeInfoColumns";
import { useBodyClass } from "../../../utils/hooks";
import { Table } from "../../../Components";
import { Graph } from "./Graph";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { ReturnTypes, CallTypes } from "@sprycore/spry-api-client";
import { spryClient } from "../../../api";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getDaysBetween2Dates } from "../../../utils/helpers";

type typeProp = {
  campaignKey: string;
  allPrizes: ReturnTypes.Prize[] | undefined;
  allPrizePools: ReturnTypes.PrizePool[] | undefined;
};

function CampaignParticipants({
  campaignKey,
  allPrizes,
  allPrizePools,
}: typeProp) {
  const navigate = useNavigate();
  const location = useLocation();
  const pageNumber = location.state as { page: number };
  useBodyClass("adminPg");
  const [selectedPage, setselectedPage] = useState<number>(
    pageNumber?.page || 0
  );
  const [selectedmonths, setSelectedmonths] = useState(12);
  const prizeInfoColumns = useMemo(() => PrizeInfoColumns(), []);
  const noOfawardedusers = new Map<string, number>();
  let updatedParticipants;

  const getPastMonths = (past: number) => {
    let monthArray = [];
    let currentMonth = dayjs().format("MMM YY");
    do {
      monthArray.push(currentMonth);
      currentMonth = dayjs()
        .set("month", dayjs(currentMonth).month() - 1)
        .format("MMM YY");
    } while (monthArray.length < past);
    return monthArray;
  };

  const QueryKey_prizewinners = { campaignKey };
  const QueryKey_email: CallTypes.GetParticipantsArgs = {
    campaignKey: campaignKey,
  };

 
  ///set participants count for the graph when initial page render


  const findPrizeName = (
    prizeKey: String,
    allPrizes: ReturnTypes.Prize[] | undefined
  ) => {
    const matchedPrize =
      allPrizes &&
      allPrizes.filter((prize) => {
        return prize.prizeKey === prizeKey;
      });
    if (matchedPrize) {
      let name = matchedPrize[0].prizeName;
      if (noOfawardedusers.get(name) !== undefined) {
        let count = noOfawardedusers.get(name);

        noOfawardedusers.set(name, count ? count + 1 : 0);
      } else {
        noOfawardedusers.set(name, 1);
      }

      return matchedPrize[0].prizeName;
    } else {
      return "";
    }
  };

  const findPrizePoolName = (
    prizePoolKey: String,
    allPrizePools: ReturnTypes.PrizePool[] | undefined
  ) => {
    const matchedPrizePool =
      allPrizePools &&
      allPrizePools.filter((prize) => {
        return prize.prizePoolKey === prizePoolKey;
      });
    if (matchedPrizePool) {
      return matchedPrizePool[0].prizePoolName;
    } else {
      return "";
    }
  };
  const findprizestatus = (prizeWinner: ReturnTypes.PrizeWinner) => {
    let status = prizeWinner.forfeitTime
      ? "Forfeited"
      : prizeWinner.fulfillTime
      ? "Fulfilled"
      : prizeWinner.declarationAndRelease
      ? "Claimed"
      : "Pending";
    return status;
  };

  //*****check if participant won any prizes ***
  const checkPrizes = (
    sessionKey: string,
    allPrizes: ReturnTypes.Prize[] | undefined,
    prizewinners: ReturnTypes.PrizeWinner[] | undefined
  ) => {
    let matchedPrizes;

    const participantPrizes =
      prizewinners &&
      prizewinners.filter((record) => {
        return record.participant.sessionKey === sessionKey;
      });

    if (participantPrizes) {
      const prizeslist = participantPrizes.map((prize) => {
        let prizes = findPrizeName(prize.prizeKey, allPrizes);
        let poolnames = findPrizePoolName(prize.prizePoolKey, allPrizePools);
        let prizestatus = findprizestatus(prize);
        let prizeCreateTime = dayjs(prize.creationTime).format(
          "M/D/YYYY-HH:mm:ss"
        );
        return [prizes, poolnames, prizestatus, prizeCreateTime];
      });
      //console.log(prizeslist)
      matchedPrizes = prizeslist;
    }
    return matchedPrizes;
  };

  const { isLoading: loadingwinners, data: prizewinners } = useQuery(
    ["getPrizeWinners", QueryKey_prizewinners],
    async () => {
      const res = await spryClient.getPrizeWinners(QueryKey_prizewinners);

      //  uniquewinnerscount(res.prizeWinners)
      return res.prizeWinners;
    }
  );
  const { isLoading: isLoadingparticipants, data: filteredParticipants } =
    useQuery(["getParticipants", QueryKey_email], async () => {
      const res = await spryClient.getParticipants(QueryKey_email);

      return res.participants;
    });
  if (filteredParticipants && prizewinners) {
    updatedParticipants = filteredParticipants.map(
      (record: ReturnTypes.Participant) => {
        let firstname = record.firstName
          ? record.firstName.charAt(0).toUpperCase() +
            record.firstName.slice(1).toLowerCase()
          : "";
        let lastname = record.lastName
          ? record.lastName.charAt(0).toUpperCase() +
            record.lastName.slice(1).toLowerCase()
          : "";
        let fullName =
          firstname || lastname ? firstname + " " + lastname : "N/A";
        let lastAction = dayjs(record.creationTime).format("MM/DD/YYYY");
        let prizedata =
          allPrizes &&
          prizewinners &&
          checkPrizes(record.sessionKey, allPrizes, prizewinners);
        let prizing =
          prizedata && prizedata.length
            ? prizedata.map((record) => record[0]).join(",")
            : "None";
        let status =
          prizedata && prizedata.length
            ? prizedata.map((record) => record[2]).join(",")
            : "N/A";
        let prizepools = prizedata && prizedata.map((record) => record[1]);
        let prizecreationtime =
          prizedata && prizedata.map((record) => record[3]);
        let updatedRecord = {
          ...record,
          firstname1: firstname,
          lastname1: lastname,
          fullName: fullName,
          lastAction: lastAction,
          status: status,
          prizing: prizing,
          prizePool: prizepools,
          prizedata: prizedata,
          prizecreationtime: prizecreationtime,
        };

        return updatedRecord;
      }
    );
  }

  const participantColumns = useMemo(
    () =>
      ParticipantColumns({
        selectedPage,
        navigate,
        campaignKey,
      }),
    [selectedPage, navigate, campaignKey]
  );

  const graphLabels = useMemo(() => {
    if (filteredParticipants) {
      if ([12, 6, 3].includes(+selectedmonths)) {
        const xlables = getPastMonths(+selectedmonths).reverse();
        const ylables = xlables.map(
          (m) =>
            filteredParticipants.filter(
              (p) => dayjs(p.creationTime).format("MMM YY") === m
            ).length
        );
        return { x: xlables, y: ylables };
      } else {
        const endDate = dayjs().toISOString();
        let startDate = dayjs().subtract(7, "day").toISOString();
        if (selectedmonths === 1) {
          startDate = dayjs()
            .set("month", dayjs().get("month") - 1)
            .toISOString();
        }

        if (selectedmonths === 2) {
          startDate = dayjs().subtract(14, "day").toISOString();
        }
        const xlables = getDaysBetween2Dates(startDate, endDate).map((date) =>
          dayjs(date).format("MMM DD")
        );
        const ylables = xlables.map(
          (m) =>
            filteredParticipants.filter(
              (p) =>
                dayjs(p.creationTime).format("MMM DD YYYY") ===
                m + " " + dayjs().year()
            ).length
        );

        return { x: xlables, y: ylables };
      }
    }
  }, [selectedmonths, filteredParticipants]);

  let prizedata = Array.from(noOfawardedusers, ([name, value]) => ({
    name,
    value,
  }));

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont participantsPg">
        <div id="tab07" className="tab-contents emailtemplates">
          <div className="head inner">
            <h3>Participants</h3>
          </div>
          <div className="chartArea">
            <div className="leftArea">
              {prizedata &&
                (prizedata.length > 0 ? (
                  <Table
                    columns={prizeInfoColumns}
                    data={prizedata && prizedata.length > 0 ? prizedata : []}
                    tablePageSize={6}
                    // onPageChange = {handlepagechange}
                  />
                ) : (
                  <p> There are no awarded participants.</p>
                ))}
            </div>
            <div className="rightArea">
              <select
                className="form-control"
                id="exampleFormControlSelect2"
                value={selectedmonths}
                onChange={(e) => {
                  setSelectedmonths(+e.currentTarget.value);
                }}
              >
                <option value="12">12 months</option>
                <option value="6">6 months</option>
                <option value="3">3 months</option>
                <option value="1">1 month</option>
                <option value="2">2 weeks</option>
                <option value="0">1 week</option>
              </select>

              <div className="graph">
                {graphLabels && (
                  <Graph
                    graphLabels={graphLabels}
                    selectedmonths={selectedmonths}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="dataArea">
            <div className="dataGrid">
              {isLoadingparticipants || loadingwinners ? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                updatedParticipants &&
                (updatedParticipants.length > 0 ? (
                  <Table
                    columns={participantColumns}
                    data={updatedParticipants}
                    tablePageSize={10}
                    tableTitle="Recent Participants"
                    sortbyid="lastAction"
                    descending={true}
                    setselectedPage={setselectedPage}
                    selectedPage={selectedPage}
                  />
                ) : (
                  <p>There are no participants to display.</p>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignParticipants;
