import React, { useState } from 'react'
import { useStores } from '../../stores';
import { useForm } from 'react-hook-form';
import { TProgram } from '../../types/portalTypes';
import { submitChangeRequest } from '../../api';
import { showToast } from '../../Components/Toast/ToastManager';
import { Dropzone } from '../../Components/dropzone';


type CrForm = {
    title: string
    jiraLink: string;
    estimate: string;
    description: string;
    estimationUrl: string;
    id?: string;
    internalId: string
}

const CRDetails = ({ program, backHandler, newCR, selected, refetchProgram, quotePdf }: { selected?: string, program: TProgram, backHandler: Function, newCR?: boolean, refetchProgram: Function, quotePdf: any }) => {

    console.log(selected, quotePdf)
    const { userStore } = useStores();
    const [loading, setLoading] = useState(false)
    let oldQuote = selected ? program.quotes.find(q => q.id === selected) : null
    const defaultValues: CrForm = oldQuote ? { ...JSON.parse(oldQuote?.additional_fields!), id: oldQuote.id, estimationUrl: quotePdf || "", internalId: oldQuote.internalId } : { title: "", jiraLink: "", estimate: "", description: "", estimationUrl: "", internalId: "" }

    const { register, watch, setValue, formState: { errors }, handleSubmit } = useForm<CrForm>({ mode: "onTouched", defaultValues: { ...defaultValues } })

    const formwatch = watch()

    const submitCR = async (data: CrForm) => {
        if (loading) {
            return
        }
        setLoading(true)
        try {
            if (newCR) {
                const res: any = await submitChangeRequest({
                    id: program.id,
                    estimate: data.estimate,
                    title: data.title,
                    description: data.description,
                    jiraLink: data.jiraLink,
                    token: userStore.user.token,
                    companyId: program.company.id,
                    internalId: data.internalId
                })

                if (res.id) {
                    setValue("id", res.id)
                    showToast({
                        content: "The Change request has been successfully submitted.",
                        duration: 3000,
                        error: false,
                    });

                    setLoading(false)

                } else {
                    // // const res: any = await updateChangeRequest({
                    // //     estimate: data.estimate,
                    // //     title: data.title,
                    // //     description: data.description,
                    // //     jiraLink: data.jiraLink,
                    // //     token: userStore.user.token,
                    // //     companyId: program.company.id,
                    // //     id: selected!
                    // // })

                    // // if (res.id) {
                    // //     setValue("id", res.id)
                    // //     showToast({
                    // //         content: "Change request has been updated successfully.",
                    // //         duration: 3000,
                    // //         error: false,
                    // //     });
                    // //     refetchProgram()

                    //     setLoading(false)

                    //     backHandler()
                    // }
                }

            }

            setLoading(false)

        } catch (e) {
            console.log(e)
            showToast({
                content: "Error while submitting change request.",
                duration: 3000,
                error: true,
            });

        }

    }

    return (
        <div>
            <div className='back-header'>
                <h4>
                    <button onClick={() => { backHandler() }} className="backBtn">
                        <i className="fas fa-arrow-left" />  Back to change requests
                    </button>

                </h4>
            </div>
            <div className='form-head'>
                {(newCR && !formwatch.id) ? <h4 style={{ fontSize: "16px" }}><strong>Create a new change request</strong></h4> :
                    <strong><h4 style={{ fontSize: "16px" }}>Update change request</h4></strong>}



                <form onSubmit={handleSubmit(submitCR)} >
                    <div id="tab05" className="tab-contents cr-details">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="title">Change request title</label>
                                    <input
                                        autoComplete='off'
                                        className="form-control"
                                        {...register("title", { required: { value: true, message: "Please enter title for change request." } })}

                                    />
                                </div>
                                {errors.title &&
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {errors.title.message}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="title">Internal Id</label>
                                    <input
                                        autoComplete='off'
                                        className="form-control"
                                        {...register("internalId", { required: { value: true, message: "Please enter internal id." } })}

                                    />
                                </div>
                                {errors.internalId &&
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {errors.internalId.message}
                                    </p>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-3">
                                <div className="form-group">
                                    <label htmlFor="jiraLink">Jira ticket link</label>
                                    <input
                                        className="form-control"
                                        autoComplete='off'

                                        {...register("jiraLink", { required: { value: false, message: "Please add a jira ticket link." } })}

                                    />
                                </div>
                                {errors.jiraLink &&
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {errors.jiraLink.message}
                                    </p>
                                }
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div className="form-group">
                                    <label htmlFor="estimate">Price estimate</label>
                                    <input
                                        className="form-control"
                                        autoComplete='off'

                                        {...register("estimate", { required: { value: true, message: "Please enter the estimate for change request." } })}

                                    />
                                </div>
                                {errors.estimate &&
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {errors.estimate.message}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        autoComplete='off'

                                        className="form-control"
                                        {...register("description", { required: { value: true, message: "Please enter the description.." } })}

                                    />
                                </div>
                                {errors.description &&
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {errors.description.message}
                                    </p>
                                }
                            </div>


                        </div>
                        <div className='row'>
                            <div className="col-sm-12 col-md-6">
                                {(formwatch.id && !formwatch.estimationUrl) && <Dropzone fileTypes={[".pdf"]}
                                    companyId={program.company.id} autoProceed={false} entityId={formwatch.id} entityType={'quote'} onComplete={async (resp: any) => {

                                        setValue("estimationUrl", resp[0].uploadURL); showToast({
                                            content: "Estimation pdf has been uploaded successfully.",
                                            duration: 3000,
                                            error: false,
                                        });
                                        await refetchProgram()
                                        backHandler()
                                    }} />}

                            </div>
                        </div>
                        {formwatch.estimationUrl &&
                            <div className='row'>
                                <a className='link-btn' href={formwatch.estimationUrl} target="_blank" rel="noreferrer">{formwatch.estimationUrl.split('?')[0].split('--')[1]}</a>

                            </div>}

                        <div className='action-btns'>
                            <button className='btn action-buttons-sm outline' type="button" style={{ marginRight: "2rem", minWidth: "100px" }} onClick={() => backHandler()}>
                                Cancel
                            </button>
                            {newCR ?
                                <button className='btn action-buttons-sm' type='submit' disabled={formwatch.id ? true : false}>
                                    Submit change request
                                </button> :
                                <button className='btn action-buttons-sm' type='submit'>
                                    Update change request </button>}


                        </div>



                    </div>

                </form>
            </div>


        </div>
    )
}

export default CRDetails