import { DynamicFieldData, TProgramSetup } from "../../types/portalTypes";

export const overviewFormFields: { [key: string]: DynamicFieldData } = {
	program_name: {
		label: 'Program name',
		inputType: 'text',
		name: 'program_name',
		status: 'QUOTE',
		default: ""
	},
	program_brand: {
		inputType: 'text',
		label: 'Company/Brand',
		name: 'program_brand',
		status: 'QUOTE',
		default: ""
	},
	// current_address: {
	// 	inputType: 'custom_address',
	// 	label: 'Company/brand mailing address',
	// 	name: 'current_address',
	// 	status: 'PROGRAM'
	// },
	description: {
		label: 'Please give a brief description of the program mechanics?',
		inputType: 'textarea',
		name: 'description',
		status: 'QUOTE',
		default: "",
		optional: false
	},
	privacy_url: {
		label: 'Company/brand privacy policy URL',
		inputType: 'text',
		name: 'privacy_url',
		status: 'PROGRAM',
	},
	privacy_dept: {
		label: 'Company/brand privacy policy department',
		inputType: 'text',
		name: 'privacy_dept',
		status: 'PROGRAM',
	},
	mailing_address: {
		inputType: 'custom_address',
		label: 'Privacy policy mailing address',
		name: 'mailing_address',
		status: 'PROGRAM',
		default: {}
	},

	privacy_email: {
		label: 'Privacy policy contact email address',
		inputType: 'text',
		name: 'privacy_email',
		status: 'PROGRAM',
	},
	program_execute: {
		label: 'How is the program being executed?',
		inputType: 'radios',
		name: 'program_execute',
		options: [
			{
				label: 'Specific vanity URL',
				value: 'vanity_URL',
				components: [
					{
						label: '',
						inputType: 'text',
						placeHolder: 'Microsite Unique URL',
						name: 'program_execute_vanity_url',
					},
					{
						label: '',
						inputType: 'text',
						placeHolder: 'Browser tab title',
						name: 'program_execute_vanity_url_title',
					},
				],
			},
			{ label: 'Within an existing app', value: 'existing_app' },
			{
				label: 'On the company/brand website',
				value: 'brand_website',
				components: [
					{
						label: '',
						inputType: 'text',
						placeHolder: 'Company/brand URL',
						name: 'program_execute.brand_website.url',
					},
				],
			},
			{ label: 'On company/brand Facebook page', value: 'facebook' },
			{ label: 'On company/brand Twitter page', value: 'twitter' },
			// {label: "Other", value: "other",
			// components:[
			//     {
			//         label: "",
			//         inputType: "text",
			//         placeHolder: "Specify",
			//         name: "program_execute.other.specify",
			//     },
			// ]
			// },
		],
		status: 'PROGRAM',
	},
	microsite_url: {
		label:
			'Who is responsible for purchasing / providing the Microsite URL of choice?',
		inputType: 'radios',
		name: 'microsite_url',
		options: [
			{ label: 'WILY Global', value: 'wily' },
			{ label: 'Company/brand', value: 'company' },
			{ label: 'Agency representing the company/brand', value: 'agency' },
			{
				label: 'Other',
				value: 'other',
				components: [
					{
						label: '',
						inputType: 'text',
						placeHolder: 'Specify',
						name: 'other_url_provider',
					},
				],
			},
			{ label: 'N/A', value: 'N/A' },
		],
		status: 'PROGRAM',
	},

	language: {
		label: "What language(s) will this program be in?",
		inputType: "radios",
		name: "language",
		options: [{ label: "English", value: "en" }, { label: "French", value: "fr" }, { label: "English and French", value: "en&fr" }, { label: "Spanish", value: "sp" }, { label: "China", value: "cn" }, {
			label: "Other", value: "other_language", components: [
				{
					label: "",
					inputType: "textarea",
					name: "other_language",
					status: "QUOTE",
					default: "",
					optional: true
				},
			],
		},],
		status: "QUOTE",
		default: "",

	},
	deploy_region: {
		label: "What market(s) are you deploying in? (countries/cities/regions)",
		inputType: "text",
		name: "deploy_region",
		status: "QUOTE",
		default: ""
	},
	start_date: {
		label: "Project start date",
		inputType: "date",
		name: "start_date",
		status: "QUOTE"
	},
	start_time: {
		label: 'Project start time',
		inputType: 'text',
		name: 'start_time',
		status: 'QUOTE',
	},
	qa_date: {
		label: 'Microsite testing date',
		inputType: 'date',
		name: 'qa_date',
		status: 'PROGRAM',
	},
	training_date: {
		label: 'Event/Staff Training Date, if applicable  ',
		inputType: 'date',
		name: 'training_date',
		status: 'PROGRAM',
	},
	device: {
		label: "How will users participate with this program?",
		inputType: "checkbox",
		name: "device",
		options: [{ label: "Using their own device (smartphone/tablet/PC)", value: "own" }, { label: "Using a third-party device (smartphone/tablet/PC)", value: "third" },],
		status: "QUOTE",
		default: {}
	},
	access: {
		label: "How will users access/unlock your program? (i.e. what gateway mechanic will be used to unlock the digital experience?)",
		inputType: "checkbox",
		name: "access",
		options: [
			{ label: "Vanity URL", value: "url" },
			{ label: "QR code", value: "qr" },
			{ label: "SMS", value: "sms" },
			{ label: "NFC/RFID", value: "nfc" },
			{ label: "Lives within an existing website/app", value: "existing site/app" },
			{
				label: "Other", value: "other", components: [{
					label: "",
					inputType: "textarea",
					name: "other_access",
					status: "QUOTE",
					default: "",
					optional: true
				},]
			},
		],
		status: "QUOTE",
		default: {}
	},
	promoted: {
		label: "How will this program be promoted?",
		inputType: "checkbox",
		name: "promoted",
		options: [
			{ label: "On-packaging", value: "On-packaging" },
			{ label: "In-store Point of sales/merchandising", value: "point of sales" },
			{ label: "Onsite signage", value: "Onsite signage" },
			{ label: "Promotional Staff/Brand Ambassadors", value: " Promotional Staff/Brand Ambassadors" },
			{ label: "Email blast", value: "Email blast" },
			{ label: "Social media", value: "Social media" },
			{ label: "Web banners", value: "Web banners" },
			{ label: "Website", value: "Website" },
			{ label: "TV media", value: "TV media" },
			{
				label: "Other", value: "other_promoted", components: [{
					label: "",
					inputType: "textarea",
					name: "other_promoted",
					status: "QUOTE",
					default: "",
					optional: true
				},]
			},
		],
		status: "QUOTE",
		default: {}

	},

	deploy_locations:{
		label: 'Is the program being deployed at an onsite location?',
		inputType: 'radios',
		name: 'deploy_locations',
		options: [
			{ label: 'Yes', value: 'Yes' },
			{ label: 'No', value: 'No' },
		],
		status: 'QUOTE',
	},

	days_in_market: {
		label: "How many days are you in market for?",
		inputType: "text",
		name: "days_in_market",
		status: "QUOTE",
		default: ""

	},

	
	program_date_type: {
		label: "Are these consecutive days or event days?",
		inputType: "text",
		name: "program_date_type",
		status: "QUOTE",
		default: {}

	},
	

	numberoflocation: {
		label: "How many locations are you deploying at?",
		inputType: "text",
		name: "numberoflocation",
		status: "PROGRAM",
		default: ""
	},
	pay_require: {
		label:
			'Is the microsite being activated within an event where paid admission is required?',
		inputType: 'radios',
		name: 'pay_require',
		options: [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		],
		status: 'PROGRAM',
	},
	
	internet_access: {
		label:
			'Do you have reliable internet access or your event activation days?',
		inputType: 'radios',
		name: 'internet_access',
		options: [
			{ label: 'Yes', value: 'yes' },
			{
				label:
					'No, BUT: we will work in OFFLINE mode for data capture campaigns ONLY',
				value: 'no',
			},
			{
				label:
					'No, BUT: an APP is needed to award Instant Wins prizes during the event',
				value: 'offline_instant_win',
			},
			{ label: 'N/A', value: 'N/A' },
		],
		status: 'PROGRAM',
	},
	anticipate_number: {
		label: "How many consumers do you anticipate will participate?",
		inputType: "text",
		name: "anticipate_number",
		status: "PROGRAM",
		default: ""

	},
}

export const ADDITIONALFields: { [key: string]: DynamicFieldData } = {


	legal_service: {
		label: 'Do you require legal services?',
		inputType: 'radios',
		name: 'legal_service',
		options: [
			{ label: 'Yes', value: 'Yes' },
			{ label: 'No', value: 'No' },
			{
				label: 'Not sure, please quote it anyway',
				value: 'Not sure, please quote it anyway',
			},
		],
		status: 'QUOTE',
	},
	rules_responsible: {
		label:
			'Please indicate who is responsible for developing the Official Rules & Regulations and the Declaration & Release form(s) of the contest/sweepstakes?',
		inputType: 'radios',
		name: 'rules_responsible',
		options: [
			{ label: 'WILY Global', value: 'WILY' },
			{ label: 'Company/brand (Sponsor)', value: 'company' },
			{ label: 'Agency representing the Company/Brand', value: 'agency' },
		],
		status: 'PROGRAM',
	},
	quebec_responsible: {
		label:
			'Who is filing and registering for this contest with the Quebec Regie?',
		inputType: 'radios',
		name: 'quebec_responsible',
		options: [
			{ label: 'WILY Global', value: 'WILY' },
			{ label: 'Company/brand (Sponsor)', value: 'company' },
			{ label: 'Agency representing the Company/Brand', value: 'agency' },
		],
		status: 'PROGRAM',
	},

	design_service: {
		label: 'Do you require creative services for the microsite design?',
		inputType: 'radios',
		name: 'design_service',
		options: [
			{ label: 'Yes', value: 'Yes' },
			{ label: 'No', value: 'No' },
			{
				label: 'Not sure, please quote it anyway',
				value: 'Not sure, please quote it anyway',
			},
		],
		status: 'QUOTE',
	},
	creative_assets: {
		label:
			'Who is developing the contest/sweepstakes creative assets and providing final creative files? Please Note: Final creative files must be provided in a PSD format, all logos, fonts and any other assets (i.e. product shots) must be provided.',
		inputType: 'radios',
		name: 'creative_assets',
		options: [
			{ label: 'WILY Global', value: 'WILY' },
			{ label: 'Company/brand (Sponsor)', value: 'company' },
			{ label: 'Agency representing the Company/Brand', value: 'agency' },
		],
		status: 'PROGRAM',
	},
	translate_service: {
		label: 'Do you require translation services?',
		inputType: 'radios',
		name: 'translate_service',
		options: [
			{ label: 'Yes', value: 'Yes' },
			{ label: 'No', value: 'No' },
			{
				label: 'Not sure, please quote it anyway',
				value: 'Not sure, please quote it anyway',
			},
		],
		status: 'QUOTE',
	},
	translate_copy: {
		label:
			'Who is responsible for providing language translations for the contest elements(rules & regulations, creative, contest copy, declaration & release form(s))? Please Note: All final translated elements must be provided to WILY no later than 4-5 business day PRIOR to the contest testing date(s).',
		inputType: 'radios',
		name: 'translate_copy',
		options: [
			{ label: 'WILY Global', value: 'WILY' },
			{ label: 'Company/brand (Sponsor)', value: 'company' },
			{ label: 'Agency representing the Company/Brand', value: 'agency' },
		],
		status: 'PROGRAM',
	},
}

export const ReportingFields: { [key: string]: DynamicFieldData } = {


	"integration": {
		label: "Do you require your solution to be integrated into a third-party technology?(Website, CRM, App, etc.)",
		inputType: "radios",
		name: "integration",
		options: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
		status: "QUOTE",
		default: {}

	},
	"integration_detail": {
		label: "Please explain your technology integration needs",
		inputType: "textarea",
		name: "integration_detail",
		status: "QUOTE",
		default: "",
		optional: false
	},
	"report_description": {
		label: "Please describe as best you can your reporting needs for the program",
		inputType: "textarea",
		name: "report_description",
		status: "QUOTE",
		default: "",
		optional: false
	},
	"google_analytics": {
		label: "Would you like Google Analytics reporting to be set-up for this contest/sweepstakes?",
		inputType: "radios",
		name: "google_analytics",
		options: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
		status: "PROGRAM",
		default: {}
	},
}


export const CommunicationFields: { [key: string]: DynamicFieldData } = {
	communicate: {
		label: "How would you like to communicate with your audience?",
		inputType: "checkbox",
		name: "communicate",
		options: [{ label: 'Email deployment', value: 'email' }, { label: 'SMS', value: 'sms' }],
		status: "QUOTE",
		default: {}

	},
	numberofemails: {
		label: "How many emails per campaign?",
		inputType: "text",
		name: "numberofemails",
		status: "QUOTE",
		default: {}

	},
	numberofsms: {
		label: "How many text messages per campaign?",
		inputType: "text",
		name: "numberofsms",
		status: "QUOTE",
		default: {}

	},
	email_from: {
		label: 'Auto trigger confirmation email from',
		inputType: 'text',
		name: 'email_from',
		status: 'PROGRAM',
	},
	email_subject: {
		label: 'Auto trigger confirmation email Subject line copy',
		inputType: 'text',
		name: 'email_subject',
		status: 'PROGRAM',
	},
	email_body: {
		label: 'Body of email',
		inputType: 'radios',
		name: 'email_body',
		options: [
			{
				label:
					'Thank you for participating! Thank you for entering the {Contest Name}',
				value: 'thanks_contest',
			},
			{
				label: 'Thank you for participating in the {program name}!',
				value: 'thanks_program',
			},
			{
				label: 'Other',
				value: 'other',
				components: [
					{
						label: '',
						inputType: 'text',
						placeHolder: 'Specify',
						name: 'email_body.other',
					},
				],
			},
		],
		status: 'PROGRAM',
	},
	email_addr_share: {
		label: 'Please indicate the email address to be shared with participants',
		inputType: 'text',
		name: 'email_addr_share',
		status: 'PROGRAM',
	},
	email_offer: {
		label:
			'Are you offering a special offer/discount /coupon along with confirmation email?',
		inputType: 'radios',
		name: 'email_offer',
		options: [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		],
		status: 'PROGRAM',
	},

}


export const MECHANICS: TProgramSetup[] = [
	{
		title: 'Card matching',
		name: 'Card matching',
		programType: 'QUOTE',
		description: 'Flip the cards to reveal matching pairs.',
		value: 'card_matching',
		seq: 0,
		finished: 0,
		group: [
			{
				label: 'Is there a timer for the game',
				description: 'Card matching',
				inputType: 'radios',
				name: 'card_matching_timer_enable',
				questionType: 'DETAILS',
				options: [
					{ label: 'Yes', value: 'Yes' },
					{ label: 'No', value: 'No' },
				],
			},
		],
	},
	{
		title: 'Plinko',
		name: 'Plinko',
		programType: 'QUOTE',
		description:
			'Drop the ball from the top of the board into the slots below.',
		value: 'plinko',
		seq: 0,
		finished: 0,
		group: [
			{
				label:
					'How many different outcomes do you require for the game? (i.e how many slots can the ball fall into at the end of the maze?)',
				description: 'Plinko',
				inputType: 'text',
				questionType: 'DETAILS',
				name: 'plinko_how_many',
			},
		],
	},
	{
		title: 'Quiz - personalty test',
		name: 'Quiz - personalty test',
		programType: 'QUOTE',
		description: 'Answer the questions to receive personalized results',
		value: 'quiz_test',
		seq: 0,
		finished: 0,
		group: [
			{
				label: '',
				description: 'List each question for the personality test',
				inputType: 'quiz',
				name: 'quiz_test',
				questionType: 'DETAILS',
				default: {
					questions: [
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
					],
				},
			},
			{
				label: '',
				description: 'List each result for the personality test',
				inputType: 'quiz_result',
				name: 'quiz_test_result',
				questionType: 'DETAILS',
				default: {
					results: [' ', ' ', ' '],
				},
			},
		],
	},
	{
		title: 'Quiz - trivia game',
		name: 'Quiz - trivia game',
		programType: 'QUOTE',
		description:
			'Answer the trivia questions correctly to get the highest score.',
		value: 'quiz_trivia',
		seq: 0,
		finished: 0,
		group: [
			{
				label: '',
				description: 'List each question for the trivia game',
				inputType: 'quiz',
				name: 'quiz_trivia',
				questionType: 'DETAILS',
				default: {
					questions: [
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
					],
				},
			},
			{
				label: 'Is there a timer for the game',
				description: 'Quiz - trivia game',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'quiz_trivia_timer_enable',
				options: [
					{ label: 'Yes', value: 'yes' },
					{ label: 'No', value: 'no' },
				],
			},
		],
	},
	{
		title: 'Quiz - trivia game with video',
		name: 'Quiz - trivia game with video',
		programType: 'QUOTE',
		description:
			'Watch the video(s) and answer the trivia questions correctly to get the highest score.',
		value: 'quiz_trivia_video',
		seq: 0,
		finished: 0,
		group: [
			{
				label: '',
				description: 'List each question for the trivia game with video',
				inputType: 'quiz',
				name: 'quiz_trivia_video',
				questionType: 'DETAILS',
				default: {
					questions: [
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
						{ question: '', points: 0, type: '', answers: [' '] },
					],
				},
			},
			{
				label: 'Is there a timer for the game',
				description: 'Quiz - trivia game with video',
				inputType: 'radios',
				name: 'quiz_trivia_video_timer_enable',
				questionType: 'DETAILS',
				options: [
					{ label: 'Yes', value: 'yes' },
					{ label: 'No', value: 'no' },
				],
			},
		],
	},
	{
		title: 'Scavenger hunt',
		name: 'Scavenger hunt',
		programType: 'QUOTE',
		description:
			'Using clues provided, find and check in at all locations to finish the game.',
		value: 'scavenger_hunt',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'How do you want to activate?',
					description: 'Scavenger hunt',
					inputType: 'radios',
					name: 'scavenger_hunt_how_activate',
					questionType: 'DETAILS',
					options: [
						{
							label:
								'In person (Users will travel to different locations in person)',
							value: 'in_person',
						},
						{
							label: 'Digitally (Users will digitally visit locations)',
							value: 'digitally',
						},
					],
					
				},
				{
					label: 'How many locations are there?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'scavenger_hunt_how_may_location',
					status: 'PROGRAM',
				},
			],
		],
	},
	{
		title: 'Scrambled image puzzle',
		name: 'Scrambled image puzzle',
		programType: 'QUOTE',
		description: 'Rearrange the pieces to complete the scrambled image.',
		value: 'scrambled_image_puzzle',
		seq: 0,
		finished: 0,
		group: [
			{
				label: 'Is there a timer for the game',
				description: 'Scrambled image puzzle',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'scrambled_image_puzzle_timer_enable',
				options: [
					{ label: 'Yes', value: 'yes' },
					{ label: 'No', value: 'no' },
				],
			},
		],
	},
	{
		title: 'Scratch and reveal',
		name: 'Scratch and reveal',
		programType: 'QUOTE',
		description:
			'Scratch away the image and reveal a reward or prize underneath.',
		value: 'scratch',
		seq: 0,
		finished: 0,
		group: [
			{
				label: 'What prizes/rewards can users reveal?',
				description: 'Scratch and reveal',
				inputType: 'text',
				questionType: 'DETAILS',
				name: 'scratch_scratch_detail',
			},
		],
	},
	{
		title: 'Search the image',
		name: 'Search the image',
		programType: 'QUOTE',
		description:
			'Search the image to find the hidden objects placed throughout.',
		value: 'search_image',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Search the image',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'search_image_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
					
				},
				{
					label: 'How many images will there be?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'search_image_how_many_image',
				},
			],
		],
	},
	{
		title: 'Spin the wheel',
		name: 'Spin the wheel',
		programType: 'QUOTE',
		description: 'Spin wheel and get a random outcome',
		value: 'spin_wheel',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'How many segments do you want displayed on the wheel?',
					description: 'Spin the wheel',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'spin_wheel_how_many_segment',
				},
				{
					label: 'What do you want displayed on the centre button?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'spin_wheel_center_button',
				},
			],
		],
	},
	{
		title: 'Sport flick - hockey',
		name: 'Sport flick - hockey',
		programType: 'QUOTE',
		description: 'Flick the hockey puck into the net.',
		value: 'flick_hockey',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - hockey',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_hockey_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
					
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_hockey_how_many_shots',
				},
				{
					label: 'Arena',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_hockey_arena',
				},
				{
					label: 'Goalie',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_hockey_goalie',
				},
				{
					label: 'Background',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_hockey_background',
				},
			],
		],
	},
	{
		title: 'Sport flick - basketball',
		name: 'Sport flick - basketball',
		programType: 'QUOTE',
		description: 'Flick the basketball into the net.',
		value: 'flick_basketball',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - basketball',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_basketball_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
					status: 'PROGRAM',
			
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_basketball_how_many_shots',
					status: 'PROGRAM',
				},
				{
					label: 'Arena',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_basketball_arena',
					status: 'PROGRAM',
				},
				{
					label: 'Background',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_basketball_background',
					status: 'PROGRAM',
				},
			],
		],
	},
	{
		title: 'Sport flick - soccer',
		name: 'Sport flick - soccer',
		programType: 'QUOTE',
		description: 'Flick the soccer ball into the net.',
		value: 'flick_soccer',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - soccer',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_soccer_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
				
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_soccer_how_many_shots',
				},
				{
					label: 'Arena',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_soccer_arena',
				},
				{
					label: 'Goalie',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_soccer_goalie',
				},
				{
					label: 'Background',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_soccer_background',
				},
			],
		],
	},
	{
		title: 'Sport flick - football',
		name: 'Sport flick - football',
		programType: 'QUOTE',
		description: 'Flick the football through the goal posts.',
		value: 'flick_football',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - football',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_football_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
					
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_football_how_many_shots',
				},
				{
					label: 'Arena',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_football_arena',
				},
				{
					label: 'Goal posts',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_football_goal_posts',
				},
				{
					label: 'Background',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_football_background',
				},
			],
		],
	},
	{
		title: 'Sport flick - golf',
		name: 'Sport flick - golf',
		programType: 'QUOTE',
		description: 'Flick the golf ball down the course and into the hole.',
		value: 'flick_golf',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - golf',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_golf_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
					
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_golf_how_many_shots',
				},
				{
					label: 'Signs',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_golf_signs',
				},
			],
		],
	},
	{
		title: 'Sport flick - beer pong',
		name: 'Sport flick - beer pong',
		programType: 'QUOTE',
		description:
			'Flick the ping pong ball across the table and into one of the cups.',
		value: 'flick_beer_pong',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Is there a timer for the game',
					description: 'Sport flick - beer pong',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'flick_beer_pong_timer_enable',
					options: [
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					],
				
				},
				{
					label: 'How many shots does the user get?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_beer_pong_how_many_shots',
				},
				{
					label: 'Room',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_beer_pong_room',
				},
				{
					label: 'Cups',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_beer_pong_cups',
				},
				{
					label: 'Background',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'flick_beer_pong_background',
				},
			],
		],
	},
	{
		title: 'Survey',
		name: 'Survey',
		programType: 'QUOTE',
		description:
			'Answer the survey questions to provide opinions, information, and feedback.',
		value: 'survey',
		seq: 0,
		finished: 0,
		group: [
			{
				label: '',
				description: 'List each question for the survey',
				inputType: 'quiz',
				questionType: 'DETAILS',
				name: 'survey',
				default: [],
			},
		],
	},
	{
		title: 'Tap to reveal',
		name: 'Tap to reveal',
		programType: 'QUOTE',
		description:
			'Tap on the object to play an animation and reveal a reward or prize.',
		value: 'tap_to_reveal',
		seq: 0,
		finished: 0,
		group: [
			{
				label: 'Please select one of the following animation options',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'tap_to_reveal_animations',
				options: [
					{ label: 'Open the gift box', value: 'yes' },
					{ label: 'Open the shoe box', value: 'no' },
					{ label: 'Open the backpack', value: 'no' },
					{ label: 'Pop the ballon', value: 'no' },
					{ label: 'Open the book', value: 'no' },
					{ label: 'Open the fortune cookie', value: 'no' },
					{
						label: 'Other',
						value: 'other_animations',
						components: [
							{
								label: '',
								inputType: 'text',
								questionType: 'DETAILS',
								placeHolder: 'Specify',
								name: 'other_animations.other',
							},
						],
					},
				],
			},
		],
	},
];


export const PROGRAM_MODULES: TProgramSetup[] = [
	{
		title: 'Data capture',
		name: 'Data capture',
		programType: 'QUOTE',
		description:
			'Capture user data only or in addition to any of the other modules below.',
		value: 'data_capture',
		finished: 0,
		seq: 1,
		group: [
			{
				label: 'Please select all form fields for data collection?',
				description: 'Data capture',
				inputType: 'checkbox',
				questionType: 'DETAILS',
				name: 'data_capture_fields',
				options: [
					{ label: 'Email', value: 'email' },
					{ label: 'First name', value: 'first_name' },
					{ label: 'Last name', value: 'last_name' },
					{ label: 'Gender', value: 'gender' },
					{ label: 'Age (exact number)', value: 'age' },
					{
						label: 'Age range (Specify age ranges)',
						value: 'age_range',
						children: [
							{
								label: 'Provide each age range below',
								description: 'Age ranges',
								inputType: 'text',
								questionType: 'DETAILS',
								name: 'data_capture_fields_age_range',
							},
						],
					},
					{
						label:
							'Date of Birth (Note: legally you can only collect birth month and birth year)',
						value: 'date_birth',
					},
					{ label: 'Mobile number', value: 'mobile_number' },
					{ label: 'Street address', value: 'address' },
					{ label: 'Apt/suite #', value: 'apt_suite' },
					{ label: 'City', value: 'city' },
					{ label: 'Province/state', value: 'province' },
					{ label: 'Postal code/ZIP code', value: 'postal_code' },
					{ label: 'Country', value: 'Country' },
					{
						label: 'Other',
						value: 'other_field',
						children: [
							{
								label: 'Specify all other data you want your users to enter',
								description: 'Other data to capture',
								inputType: 'text',
								questionType: 'DETAILS',
								name: 'data_capture_fields_other_field',
							},
						],
					},
				],
				
				default: [],
			},
			{
				label:
					'Select copy for opt-in to receive marketing communication from company/brand',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'data_capture_optin_copy',
				options: [
					{
						label:
							'I would like to receive communications and offers from INSERT BRAND/COMPANY NAME. You can withdraw your consent at any time. Visit our Privacy policy for details.',
						value: 'optin_copy_one',
					},
					{
						label:
							'I would like to receive INSERT BRAND NAME information and offers from INSERT CONNTEST SPONSORS NAME offers. You can withdraw your consent at any time. Visit our privacy policy for details. If you have any questions about the processing of your personal details or about our privacy policy, you are welcome to contact us. The contact details are: INSERT PRIVACY POLICY INFORMMATION',
						value: 'optin_copy_two',
					},
					{
						label:
							'I would like to hear from INSERT BRAND NAME and its family of companies via email about products, services, and events. Including the latest solutions, tips, and exclusive offers. To withdraw consent or manage your contact preferences, visit the Promotional Communications Manager Privacy Statement. If you have any questions about the processing of your personal details or about our privacy policy, you are welcome to contact us. The contact details are: INSERT PRIVACY CONTACT INFO',
						value: 'optin_copy_three',
					},
					{ label: 'N/A', value: 'N/A' },
					{
						label: 'Other',
						value: 'other_optin_copy',
						children: [
							{
								label:
									'Provide the copy for the opt-in to receive marketing communications',
								description: 'Other opt-in copy',
								inputType: 'text',
								questionType: 'DETAILS',
								name: 'other_data_capture_optin_copy',
							},
						],
					},
				],
				status: 'PROGRAM',
			},
		],
	},
	{
		title: 'Grand prize contest/sweepstakes',
		name: 'Grand prize contest/sweepstakes',
		programType: 'QUOTE',
		description: 'User enters to win a grand prize',
		value: 'contest',
		finished: 0,
		seq: 2,
		group: [
			{
				label: 'Explain the mechanics of the grand prize contest/sweepstakes',
				description: 'Grand prize contest/sweepstakes',
				inputType: 'textarea',
				questionType: 'DETAILS',
				name: 'contest_contest_mechanic',
			},
			{
				label: 'Grand prize entry limitations',
				description:
					'How many times can a user enter the  grand prize contest/sweepstakes',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_entry_limitation',
				options: [
					{
						label: 'One entry per day per participant',
						value: 'participant_day',
					},
					{
						label: 'One entry per week per participant',
						value: 'participant_week',
					},
					{
						label: 'One entry per participant per period/event',
						value: 'participant_event',
					},
					{
						label: 'No entry limits (can enter as many times as they want)',
						value: 'No entry limits',
					},

					{
						label: 'Other',
						value: 'entry_limitation_other',
						components: [
							{
								label: '',
								inputType: 'text',
								questionType: 'DETAILS',
								placeHolder: 'Specify',
								name: 'entry_limitation_other',
							},
						],
					},
				],
			},
			{
				label: 'Grand prize bonus entries',
				description:
					'Can users earn bonus entries towards the grand prize contest/sweepstakes?',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_is_awarded',
				options: [
					{ label: 'No', value: 'No' },
					{ label: 'Yes', value: 'Yes' },
				],
			},
			{
				label: 'How are they awarded?',
				inputType: 'textarea',
				questionType: 'DETAILS',
				name: 'how_contest_is_awarded',
			},
			{
				label: 'How many bonus entries can a user receive',
				description: 'Grand prize bonus entry limitation',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_bonus_entry_limitation',
				options: [
					{
						label: 'One bonus entry per day per participant',
						value: 'per day',
					},
					{
						label: 'One bonus entry per week per participant',
						value: 'per week',
					},
					{
						label: 'One bonus entry per participant per period/event',
						value: 'per event',
					},
					{
						label:
							'No bonus entry limits (can enter as many times as they want)',
						value: 'No bonus',
					},
					{ label: 'Other', value: 'other_bonus_limitation' },
				],
			},
			{
				label: 'Please select all other qualifying elements.',
				description: 'Other qualifying elements',
				inputType: 'checkbox',
				questionType: 'DETAILS',
				name: 'contest_other_qualify_elements',
				options: [
					{
						label:
							'Participant must have a valid Facebook Account to participate',
						value: 'fb',
					},
					{
						label:
							'Participant must “LIKE” Company/Brand Facebook Page in order to participate',
						value: 'fb like',
					},
					{
						label:
							'Participant must have a valid Twitter Account to participate',
						value: 'twitter',
					},
					{
						label:
							'Participant must "FOLLOW" Company/Brand Twitter Page in order to participate',
						value: 'twitter follow',
					},
					{
						label: 'Participant must hold a valid driver’s license',
						value: 'driver license',
					},
					{
						label:
							'Participant must complete a free demonstration with a Company/Brand Representative in order to participate.',
						value: 'demonstration',
						components: [
							{
								label: 'Please explain in detail',
								description:
									'Participant must complete a free demonstration with a Company/Brand Representative in order to participate.',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'demonstration_detail',
							},
						],
					},
					{ label: 'Participant must upload a video', value: 'video' },
					{ label: 'Participant must upload an image', value: 'image' },
					{
						label: 'Other',
						value: 'Other',
						children: [
							{
								label: 'Please explain in detail',
								description:
									'Other qualifying elements for participating in the contest/sweepstakes',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'optin_copy_other',
							},
						],
					},
					{ label: 'N/A', value: 'N/A' },
				],
			
			},
			{
				label:
					'.Please select the message a user will see after entering the contest.',
				description: 'Thank you page messaging',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_thanks_page_message',
				options: [
					{
						label:
							'Thank you for participating! Thank you for entering the {Contest name}',
						value: 'default',
					},
					{
						label: 'Other',
						value: 'other_thanks_page_message',
						components: [
							{
								label: 'Please specify ',
								inputType: 'textarea',
								questionType: 'DETAILS',
								placeHolder: 'Specify',
								name: 'other_thanks_page_message',
							},
						],
					},
				],
			},
		],
	},
	{
		title: 'Instant win contest/sweepstakes',
		name: 'Instant win contest/sweepstakes',
		programType: 'QUOTE',
		description: 'User enters to win a instant win prize',
		value: 'contest_prize',
		seq: 2,
		finished: 0,
		group: [
			{
				label: 'Explain the mechanics of the instant win contest/sweepstakes',
				description: 'Instant win contest/sweepstakes',
				inputType: 'textarea',
				questionType: 'DETAILS',
				name: 'contest_prize_mechanic',
			},
			{
				label:
					'How many times can a user enter the  instant win contest/sweepstakes',
				description: 'Instant win entry limitations',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_prize_entry_limitation',
				options: [
					{
						label: 'One entry per day per participant',
						value: 'participant_day',
					},
					{
						label: 'One entry per week per participant',
						value: 'participant_week',
					},
					{ label: 'No entry limits', value: 'No entry limits' },

					{
						label: 'Other',
						value: 'entry_limitation_other',
						components: [
							{
								label: 'Please specify',
								inputType: 'text',
								questionType: 'DETAILS',
								placeHolder: 'Specify',
								name: 'contest_prize_entry_limitation_other',
							},
						],
					},
				],
			},
			{
				label:
					'Can users earn bonus entries towards the instant win contest/sweepstakes?',
				description: 'Instant win bonus entries',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_prize_is_awarded',
				options: [
					{ label: 'No', value: 'No' },
					{
						label: 'Yes',
						value: 'Yes',
						components: [
							{
								label: 'How are they awarded?',
								description: 'How are they awarded?',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'how_contest_prize_is_awarded',
							},
						],
					},
				],
			},
			{
				label: 'How many bonus entries can a user receive',
				description: 'Instant win bonus entry limitations',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_prize_bonus_entry_limitation',
				options: [
					{
						label: 'One bonus entry per day per participant',
						value: 'per day',
					},
					{
						label: 'One bonus entry per week per participant',
						value: 'per week',
					},
					{
						label: 'One bonus entry per participant per period/event',
						value: 'per event',
					},
					{
						label:
							'No bonus entry limits (can enter as many times as they want)',
						value: 'No bonus',
					},
					{ label: 'Other', value: 'other_bonus_limitation' },
				],
			},
			{
				label: 'Please select all other qualifying elements.',
				inputType: 'checkbox',
				questionType: 'DETAILS',
				name: 'contest_prize_other_qualify_elements',
				options: [
					{
						label:
							'Participant must have a valid Facebook Account to participate',
						value: 'fb',
					},
					{
						label:
							'Participant must “LIKE” Company/Brand Facebook Page in order to participate',
						value: 'fb like',
					},
					{
						label:
							'Participant must have a valid Twitter Account to participate',
						value: 'twitter',
					},
					{
						label:
							'Participant must "FOLLOW" Company/Brand Twitter Page in order to participate',
						value: 'twitter follow',
					},
					{
						label: 'Participant must hold a valid driver’s license',
						value: 'driver license',
					},
					{
						label:
							'Participant must complete a free demonstration with a Company/Brand Representative in order to participate.',
						value: 'demonstration',
						components: [
							{
								label: 'Please explain in detail',
								description:
									'Participant must complete a free demonstration with a Company/Brand Representative in order to participate.',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'contest_prize_demonstration_detail',
							},
						],
					},
					{ label: 'Participant must upload a video', value: 'video' },
					{ label: 'Participant must upload an image', value: 'image' },
					{
						label: 'Other',
						value: 'Other',
						components: [
							{
								label: 'Please explain in detail',
								description:
									'Other qualifying elements for participating in the contest/sweepstakes',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'contest_prize_other_qualify_details',
							},
						],
					},
					{ label: 'N/A', value: 'N/A' },
				],
		
			},
			{
				label: 'Loser journey message',
				inputType: 'radios',
				questionType: 'DETAILS',
				name: 'contest_prize_loser_page_message',
				options: [
					{
						label:
							'"Sorry, you did not win an instant win prize. Thank you for playing!"',
						value: 'default',
					},
					{
						label: 'Other',
						value: 'other_loser_page_message',
						components: [
							{
								label: 'Please specify',
								inputType: 'textarea',
								questionType: 'DETAILS',
								name: 'other_loser_page_message',
							},
						],
					},
				],
			},
		],
	},
	{
		title: 'Giveaway promotion',
		name: 'Giveaway promotion',
		programType: 'QUOTE',
		description: 'Everyone that enters the promotion receives something.',
		value: 'giveaway',
		seq: 3,
		finished: 0,
		group: [
			{
				label: 'How do users qualify for the giveaway?',
				description: 'Giveaway promotion',
				inputType: 'textarea',
				questionType: 'DETAILS',
				name: 'giveaway_qualify_users',
			},
			{
				label: 'What reward(s) can users receive?',
				inputType: 'textarea',
				questionType: 'DETAILS',
				name: 'giveaway_user_reward',
			},
		],
	},
	{
		title: 'Event registration / check-in',
		name: 'Event registration / check-in',
		programType: 'QUOTE',
		description: 'Capture user data for event registration and check-in.',
		value: 'event',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'Are users registering for an event?',
					description: 'Event registration/check-in',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'event_event_registration',
					options: [
						{ label: 'Yes', value: 'Yes' },
						{ label: 'No', value: 'No' },
					],
				
				},
				{
					label: 'Are users checking into an event?',
					inputType: 'radios',
					questionType: 'DETAILS',
					name: 'event_event_checkin',
					options: [
						{ label: 'Yes', value: 'Yes' },
						{ label: 'No', value: 'No' },
					],
					
				},
				{
					label: 'How many events are there?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'event_total_event',
				},
			],
		],
	},
];

export const PROOF_OF_PURCHASE: TProgramSetup[] = [
	{
		title: 'UPC codes',
		name: 'UPC codes',
		programType: 'QUOTE',
		description:
			'Users will have to provide UPC codes as part of the experience.',
		value: 'upc_code',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'What do users get for providing UPC codes?',
					description: 'UPC codes',
					inputType: 'textarea',
					questionType: 'DETAILS',
					name: 'upc_code_upc_get',
				},
				{
					label: 'How many UPC codes do users need to provide?',
					inputType: 'textarea',
					questionType: 'DETAILS',
					name: 'upc_code_how_many',
				},
			],
		],
	},
	{
		title: 'Receipts',
		name: 'Receipts',
		programType: 'QUOTE',
		description:
			'Users will have to upload receipts as part of the experience.',
		value: 'receipts',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'What do users get for uploading receipts?',
					description: 'Receipt upload',
					inputType: 'textarea',
					questionType: 'DETAILS',
					name: 'receipts_receipt_get',
				},
				{
					label:
						'What purchase(s) are mandatory for the receipt to be eligible?',
					inputType: 'textarea',
					questionType: 'DETAILS',
					name: 'receipts_purchase_mandatory',
				},
			],
		],
	},
	{
		title: 'Pin codes',
		name: 'Pin codes',
		programType: 'QUOTE',
		description:
			'Users will have to provide PIN codes as part of the experience.',
		value: 'pin_codes',
		seq: 0,
		finished: 0,
		group: [
			[
				{
					label: 'What do users get for submitting PIN codes?',
					description: 'PIN codes',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'pin_codes_pin_get',
					status: 'PROGRAM',
					
				},
				{
					label: 'How many PIN codes do users need to provide?',
					inputType: 'text',
					questionType: 'DETAILS',
					name: 'pin_codes_how_many',
					status: 'PROGRAM',
					
				},
			],
		],
	},
];

export const UPLOADS: TProgramSetup[] = [
	{
		title: 'Image upload',
		name: 'Image upload',
		programType: 'QUOTE',
		description: 'Users will upload images as part of the experience.',
		seq: 0,
		finished: 0,
		value: 'image',
		group: [],
	},
	{
		title: 'Video upload',
		name: 'Video upload',
		programType: 'QUOTE',
		description: 'Users will upload videos as part of the experience.',
		seq: 0,
		finished: 0,
		value: 'video',
		group: [],
	},
	{
		title: 'Branded image frame selection ',
		name: 'Branded image frame selection ',
		programType: 'QUOTE',
		description: 'Users will select a brand frame to apply to their image.',
		seq: 0,
		finished: 0,
		value: 'branded_frame',
		group: [],
	},
	{
		title: 'Users will select a brand frame to apply to their image.',
		name: 'Users will select a brand frame to apply to their image.',
		programType: 'QUOTE',
		description: 'Users can view images / videos in a consumer facing gallery.',
		seq: 0,
		finished: 0,
		value: 'user_select_frame',
		group: [],
	},
];
export const Quote_Program_modules: { [key: string]: DynamicFieldData } = {

	program_modules: {
		label: "Program Modules",
		inputType: "radios",
		name: "program_modules",
		options: PROGRAM_MODULES.map(m => { return { label: m.name, value: m.value } }),
		status: "ADDITIONAL",
		default: {}

	},
	modules_idea: {
		label: "Other details",
		inputType: "textarea",
		name: "modules_idea",
		status: "ADDITIONAL",
		default: "",
		optional: false


	},

	proof_of_purchase: {
		label: "Proof Of Purchase",
		inputType: "radios",
		name: "proof_of_purchase",
		options: PROOF_OF_PURCHASE.map(m => { return { label: m.name, value: m.value } }),
		status: "ADDITIONAL",
		default: {}

	},
	proof_idea: {
		label: "Other details",
		inputType: "textarea",
		name: "proof_idea",
		status: "ADDITIONAL",
		default: "",
		optional: false


	},
	mechanics: {
		label: "Engagement Mechanics",
		inputType: "radios",
		name: "mechanics",
		options: MECHANICS.map(e => { return { label: e.name, value: e.value } }),
		status: "ADDITIONAL",
		default: {}

	},
	mechanics_idea: {
		label: "Other details",
		inputType: "textarea",
		name: "mechanics_idea",
		status: "ADDITIONAL",
		default: "",
		optional: false
	},
	upload: {
		label: "Image/Video Upload",
		inputType: "radios",
		name: "upload",
		options: UPLOADS.map(e => { return { label: e.name, value: e.value } }),
		status: "ADDITIONAL",
		default: {}
	},
	upload_idea: {
		label: "Other details",
		inputType: "textarea",
		name: "upload_idea",
		status: "ADDITIONAL",
		default: "",
		optional: false


	},



}
export const PROGRAM_ADDITIONAL: { [key: string]: any } = {
	rules_responsible: {
		label: "Please indicate who is responsible for developing the Official Rules & Regulations and the Declaration & Release form(s) of the contest/sweepstakes?",
		inputType: "radios",
		name: "rules_responsible",
		options: [{ label: 'WILY Global', value: 'WILY' }, { label: 'Company/brand (Sponsor)', value: 'company' }, { label: 'Agency representing the Company/Brand', value: 'agency' }],
		status: "ADDITIONAL"
	},
	creative_assets: {
		label: "Who is developing the contest/sweepstakes creative assets and providing final creative files? Please Note: Final creative files must be provided in a PSD format, all logos, fonts and any other assets (i.e. product shots) must be provided.",
		inputType: "radios",
		name: "creative_assets",
		options: [{ label: 'WILY Global', value: 'WILY' }, { label: 'Company/brand (Sponsor)', value: 'company' }, { label: 'Agency representing the Company/Brand', value: 'agency' }],
		status: "ADDITIONAL"
	},
	quebec_responsible: {
		label: "Who is filing and registering for this contest with the Quebec Regie?",
		inputType: "radios",
		name: "quebec_responsible",
		options: [{ label: 'WILY Global', value: 'WILY' }, { label: 'Company/brand (Sponsor)', value: 'company' }, { label: 'Agency representing the Company/Brand', value: 'agency' }],
		status: "ADDITIONAL"
	},
	translate_copy: {
		label: "Who is responsible for providing language translations for the contest elements(rules & regulations, creative, contest copy, declaration & release form(s))? Please Note: All final translated elements must be provided to WILY no later than 4-5 business day PRIOR to the contest testing date(s).",
		inputType: "radios",
		name: "translate_copy",
		options: [{ label: 'WILY Global', value: 'WILY' }, { label: 'Company/brand (Sponsor)', value: 'company' }, { label: 'Agency representing the Company/Brand', value: 'agency' }],
		status: "ADDITIONAL"
	},
	pay_require: {
		label: "Is the microsite being activated within an event where paid admission is required?",
		inputType: "radios",
		name: "pay_require",
		options: [
			{ label: "Yes", value: "yes" },
			{ label: "No", value: "no" },
		],
		status: "PROGRAM"
	},
	internet_access: {
		label: "Do you have reliable internet access or your event activation days?",
		inputType: "radios",
		name: "internet_access",
		options: [
			{ label: "Yes", value: "yes" },
			{ label: "No, BUT: we will work in OFFLINE mode for data capture campaigns ONLY", value: "no" },
			{ label: "No, BUT: an APP is needed to award Instant Wins prizes during the event", value: "offline_instant_win" },
			{ label: "N/A", value: "N/A" },
		],
		status: "PROGRAM"
	},


	privacy_url: {
		label: "Company/brand privacy policy URL",
		inputType: "text",
		name: "privacy_url",
		status: "PROGRAM"
	},
	privacy_dept: {
		label: "Company/brand privacy policy department",
		inputType: "text",
		name: "privacy_dept",
		status: "PROGRAM"
	},
	mailing_address: {
		inputType: "custom_address",
		label: "Privacy policy mailing address",
		name: "mailing_address",
		status: "PROGRAM"
	},
	privacy_email: {
		label: "Privacy policy contact email address",
		inputType: "text",
		name: "privacy_email",
		status: "PROGRAM"
	},
	program_execute: {
		label: "How is the program being executed? (Select all that apply)",
		inputType: "checkbox",
		name: "program_execute",
		options: [
			{
				label: "Specific vanity URL", value: "vanity_URL",
				components: [
					{
						label: "",
						inputType: "text",
						placeHolder: "Microsite Unique URL",
						name: "program_execute.vanity_URL.url",
					},
					{
						label: "",
						inputType: "text",
						placeHolder: "Browser tab title",
						name: "program_execute.vanity_URL.title",
					}
				]
			},
			{ label: "Within an existing app", value: "existing_app" },
			{
				label: "On the company/brand website", value: "brand_website",
				components: [
					{
						label: "",
						inputType: "text",
						placeHolder: "Company/brand URL",
						name: "program_execute.brand_website.url",
					},
				]
			},
			{ label: "On company/brand Facebook page", value: "facebook" },
			{ label: "On company/brand Twitter page", value: "twitter" },
			// {label: "Other", value: "other", 
			// components:[
			//     {
			//         label: "",
			//         inputType: "text",
			//         placeHolder: "Specify",
			//         name: "program_execute.other.specify", 
			//     },               
			// ]
			// },
		],
		status: "PROGRAM"
	},
	microsite_url: {
		label: "Who is responsible for purchasing / providing the Microsite URL of choice?",
		inputType: 'radios',
		name: 'microsite_url',
		options: [
			{ label: "WILY Global", value: "wily" },
			{ label: "Company/brand", value: "company" },
			{ label: "Agency representing the company/brand", value: "agency" },
			{
				label: "Other", value: "other",
				components: [
					{
						label: "",
						inputType: "text",
						placeHolder: "Specify",
						name: "other_microsite_url",
					},
				]
			},
			{ label: "N/A", value: "N/A" },
		],
		status: "PROGRAM"
	},


	qa_date: {
		label: "Microsite testing date",
		inputType: "date",
		name: "qa_date",
		status: "PROGRAM"
	},
	training_date: {
		label: "Event/Staff Training Date, if applicable  ",
		inputType: "date",
		name: "training_date",
		status: "PROGRAM"
	},
}


