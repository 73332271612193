import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./index.css";
import { CallTypes } from "@sprycore/spry-api-client";
import { spryClient } from "../../../api";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { showToast } from "../../../Components/Toast/ToastManager";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CSVReader from "../../../Components/CSVReader";

type csvData = {
  brand: string;
  amount: string;
  code_1: string;
  code_2?: string;
  gift_card_link?: string;
  barcode_data?: string;
  expiry?: string;
};
type formData = {
  giftCardType: string;
  value: number;
  barcodeType: string;
  barcodeFormat: string;
};

function UploadGiftCard({ campaignKey }: { campaignKey: string }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({
    mode: "onTouched",
    defaultValues: {
      giftCardType: "",
      value: 0,
      barcodeType: "",
      barcodeFormat: "",
    },
  });
  const { giftCardTypeKey } = useParams() as { giftCardTypeKey: string };
  const [data, setData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showError, setshowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const barcodetypes = ["UPC", "Code_128", "PDF417", "QR"];
  const navigate = useNavigate();

  const sampleFile: csvData[] = [
    {
      amount: "$25",
      brand: "amazon",
      code_1: "12546632",
      code_2: "456632215",
      gift_card_link: "test@url.com",
      barcode_data: "XXXXXXXXX",
      expiry: "2022/04/01",
    },
  ];
  const headers = [
    { label: "Amount", key: "amount" },
    { label: "Brand", key: "brand" },
    { label: "Code 1", key: "code_1" },
    { label: "Code 2", key: "code_2" },
    { label: "Gift card link", key: "gift_card_link" },
    { label: "Barcode data", key: "barcode_data" },
    { label: "Expiry", key: "expiry" },
  ];

  const { isLoading: loadingGiftcardtype, data: giftCardType } = useQuery(
    "getGiftcardTypes",
    async () => {
      const {giftCardTypes:[giftcartType]} = await spryClient.getGiftCardTypes({
        campaignKey,
        giftCardTypeKey,
      });
      reset({giftCardType:giftcartType.giftCardTypeName})
      return giftcartType
    }
  );

  const handleupload = async (formdata: formData) => {
    setLoading(true);

    let error_records = new Map<number, string>();

    let csv_records = [...data];
    let keys =
      csv_records &&
      csv_records.shift().map((key: string) => {
        return key.toLowerCase().replace(/\W/g, "_");
      });
    if (keys.toString() !== Object.keys(sampleFile[0]).toString()) {
      setErrorMessage([
        "The file format/columns are different from the example file. Please reference the sample file and upload again.",
      ]);
      setLoading(false);
      setshowError(true);
      return;
    }
    csv_records =
      csv_records &&
      csv_records.map(function (row: any) {
        if (row[0]) {
          return keys?.reduce(function (obj: any, key: any, i: number) {
            obj[key] = row[i];
            return obj;
          }, {});
        }
        return undefined;
      });

    const create_allgiftcards =
      csv_records &&
      Promise.all(
        csv_records.map(async function (csvdata: csvData, index: number) {
          if (csvdata !== undefined) {
            if (formdata.value.toString() === csvdata.amount.slice(1)) {
              if (giftCardType?.giftCardTypeName.includes(csvdata.brand)) {
                const record: CallTypes.CreateGiftCardArgs = {
                  campaignKey,
                  giftCardTypeKey,
                  value: Number(csvdata.amount.slice(1)),
                  directLinkUrl: csvdata.gift_card_link,
                  expiryTime: csvdata.expiry,
                  primaryCode: csvdata.code_1!,
                  secondaryCode: csvdata.code_2,
                  barcodeFormat: formdata.barcodeFormat,
                  barcodeValue: csvdata.barcode_data,
                };
                await spryClient
                  .createGiftCard({ ...record })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    error_records.get(index + 2)
                      ? error_records.set(
                          index + 2,
                          error_records.get(index + 1) +
                            "," +
                            e.toString().substr(6)
                        )
                      : error_records.set(index + 2, e.toString().substr(6));
                  });
              } else {
                error_records.get(index + 2)
                  ? error_records.set(
                      index + 2,
                      error_records.get(index + 1) +
                        "The brand listed in the uploaded file does not match the gift card type selected."
                    )
                  : error_records.set(
                      index + 2,
                      "The brand listed in the uploaded file does not match the gift card type selected."
                    );
              }
            } else {
              error_records.get(index + 2)
                ? error_records.set(
                    index + 2,
                    error_records.get(index + 1) +
                      "The value listed in the uploaded file does not match the value provided."
                  )
                : error_records.set(
                    index + 2,
                    "The value listed in the uploaded file does not match the value provided."
                  );
            }
          }
        })
      );
    await create_allgiftcards;
    if (error_records.size > 0) {
      let error_msg: string[] = [];
      for (let entry of error_records.entries()) {
        error_msg.push(`Row no ${entry[0]}: ${entry[1]}`);
      }
      setErrorMessage(error_msg);
      setLoading(false);

      setshowError(true);
    } else {
      setLoading(false);

      showToast({
        content: "All the gift cards are added successfully",
        duration: 3000,
        error: false,
      });
      navigate(`/campaign/${campaignKey}/giftcards/${giftCardTypeKey}`);
    }
  };

  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/giftcards/${giftCardTypeKey}`);
  };

  return (
    <>
      {loadingGiftcardtype ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div className="dashboardContent campaignDetail tabsCont">
          <div id="tab09" className="tab-contents giftcards">
            <div className="head giftcard-details">
              <h3>
                <button className="btn-noborder" onClick={backHandler}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                Upload Gift Cards
              </h3>
            </div>
            <div className="main-content">
              <form onSubmit={handleSubmit(handleupload)}>
                <div className="formContent ">
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="giftCardType">Gift Card Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        {...register("giftCardType", {
                          required: {
                            value: true,
                            message: "Please enter a giftcard type",
                          },
                        })}
                        aria-describedby="emailHelp"
                        disabled
                      />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="form-group">
                        <label className="exampleFormControlInput1">
                          $ Value
                        </label>
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          id="exampleInputEmail1"
                          {...register("value", {
                            required: {
                              value: true,
                              message:
                                "Please enter the value of the gift card you are loading",
                            },
                          })}
                          aria-describedby="emailHelp"
                          placeholder="0.0"
                        />
                        {errors.value && (
                          <div className="error">
                            <i className="fas fa-exclamation-circle" />
                            {errors.value.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">
                          Barcode Type(optional)
                        </label>
                        <select
                          {...register("barcodeType", { required: false })}
                          className="form-control"
                        >
                          <option>Please select barcode type</option>
                          {barcodetypes.length > 0 &&
                            barcodetypes.map((code: string) => {
                              return (
                                <option value={code} key={code}>
                                  {code}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">
                          Barcode Format
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          {...register("barcodeFormat", { required: false })}
                          aria-describedby="emailHelp"
                        />
                        {errors.barcodeFormat && (
                          <div className="error">
                            <i className="fas fa-exclamation-circle" />
                            {errors.barcodeFormat.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="form-group">
                        <CSVReader loaddata={setData} />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <CSVLink
                          headers={headers}
                          data={sampleFile}
                          filename="sample.csv"
                          className="downloand-link"
                        >
                          Download CSV example file
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-2">
                      <div className="form-group">
                        {loading ? (
                          <button
                            type="submit"
                            disabled
                            className="form__btn btn btn--purple"
                          >
                            <i className="fas fa-circle-notch fa-spin"></i>
                          </button>
                        ) : (
                          <button
                            className="btn"
                            type="submit"
                            disabled={data && data.length > 0 ? false : true}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <Modal show={showError} onHide={() => {}} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setshowError(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Error adding gift cards</h3>
          <div className="form-group">
            {errorMessage.map((error) => (
              <li className="error-link"> {error} </li>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => setshowError(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadGiftCard;
