import { Row, TableProps } from "react-table";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import dayjs from "dayjs";

const dropdownToggleStyles = {
  background: "none",
  border: "none",
  boxShadow: "none",
};

export const TaskColumns = ({
  deleteHandler,
  markasCompleted,
  reset,
  setIsopen,
}: {
  reset: Function;
  setIsopen: Function;
  markasCompleted: Function;
  deleteHandler: Function;
}) => {
  const COLUMNS = [
    {
      Header: "No",
      width: "1%",
      accessor: "index",
      Cell: (tableInfo: any) => {
        return <p> {tableInfo.row.index + 1}</p>;
      },
    },
    {
      Header: "Created",
      accessor: "created_at",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (new Date(rowA.values[columnId]) > new Date(rowB.values[columnId]))
          return 1;
        if (new Date(rowB.values[columnId]) > new Date(rowA.values[columnId]))
          return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].created_at
          ? new Date(
            TableInfo.data[TableInfo.row.index].created_at
          ).toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "Updated",
      accessor: "updated_at",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (new Date(rowA.values[columnId]) > new Date(rowB.values[columnId]))
          return 1;
        if (new Date(rowB.values[columnId]) > new Date(rowA.values[columnId]))
          return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updated_at
          ? new Date(
            TableInfo.data[TableInfo.row.index].updated_at
          ).toLocaleString()
          : "";
        return dateString;
      },
    },

    {
      Header: "Task Name",
      accessor: "name",
    },

    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Due date",
      accessor: "dueDate",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (new Date(rowA.values[columnId]) > new Date(rowB.values[columnId]))
          return 1;
        if (new Date(rowB.values[columnId]) > new Date(rowA.values[columnId]))
          return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].dueDate
          ? new Date(
            TableInfo.data[TableInfo.row.index].dueDate
          ).toLocaleDateString()
          : "N/A";
        return dateString;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (TableInfo: TableProps) => {
        let a = TableInfo.data[TableInfo.row.index].completed;

        return <p>{a ? "completed" : "to-do"}</p>;
      },
    },
    {
      Header: "Completed on",
      accessor: "completedOn",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (new Date(rowA.values[columnId]) > new Date(rowB.values[columnId]))
          return 1;
        if (new Date(rowB.values[columnId]) > new Date(rowA.values[columnId]))
          return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].completedOn
          ? new Date(
            TableInfo.data[TableInfo.row.index].completedOn
          ).toLocaleString()
          : "N/A";
        return dateString;
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: (TableInfo: any) => {
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };
        let status = TableInfo.data[TableInfo.row.index].completed ? 1 : 0
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {status ? <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  reset({ ...TableInfo.data[TableInfo.row.index], completed: status });
                  setIsopen(true)
                }}
              >
                View
              </Dropdown.Item> :
                <>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={(e) => {
                      reset({ ...TableInfo.data[TableInfo.row.index], completed: status });
                      setIsopen(true)
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={(e) => {
                      markasCompleted(TableInfo.data[TableInfo.row.index].id);
                    }}
                  >
                    Mark as completed
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={(e) => {
                      deleteHandler(TableInfo.data[TableInfo.row.index].id);
                    }}
                  >
                    Delete
                  </Dropdown.Item></>
              }

            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return COLUMNS;
};
