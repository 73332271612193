import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useStores } from "../../stores";
import { Dropdown } from "react-bootstrap";
import CampaignDetailHead from "./CampaignDetailHead";
import { getPrograms, spryClient } from "../../api";
import "./styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { toJS } from "mobx";
import { TProgram } from "../../types/portalTypes";

const Header = ({ title }: { title?: string }) => {
  const navigate = useNavigate();
  const cognito = useAuthenticator();

  const location = useLocation();

  const match = !!location.pathname.match(
    /users|brands|campaigns|agencies|programs|createrepo/g
  );
  const { campaignStore, userStore, programStore } = useStores();

  const { campaignKey, programId } = useParams() as { campaignKey?: string, programId?: string };

  useQuery(
    ["getCampaign", campaignKey],
    async () => {
      if (!campaignKey) { return }
      const { campaigns: [campaign] } = await spryClient.getCampaigns({ campaignKey });
      if (!campaign) { return }
      const { brands: [brand] } = await spryClient.getBrands({ brandKey: campaign.brandKey })
      const agencies = !campaign.agencyKey ? undefined : await spryClient.getAgencies({ agencyKey: campaign.agencyKey })
      programStore.setProgram({companyName:"",programName:""})
      campaignStore.setCampaign({
        campaignName: campaign.campaignName,
        campaignKey: campaign.campaignKey,
        brand: {
          brandName: brand.brandName,
          brandKey: brand.brandKey,
        },
        agency: {
          agencyName: agencies?.agencies[0]?.agencyName || "",
          agencyKey: agencies?.agencies[0]?.agencyKey || "",
        },
      });
    },
    { enabled: !!campaignKey }
  );
  useQuery(
    ["getProgramQuotes", programId],
    async () => {
      const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["quotes", "company"] });
      if (!res.programs) {
        return
      }
      campaignStore.setCampaign({
        campaignName: '',
        campaignKey: '',
        brand: {
          brandName: '',
          brandKey: ''
        },
        agency: {
          agencyName: '',
          agencyKey: ''
        }
      })
      programStore.setProgram({ programName: res.programs.quotes[0].program_name || "", companyName: res.programs.company.name || "" })
    },
    { enabled: !!programId },
  );


  const signOut = async () => {
    cognito.signOut();
    navigate("/", { replace: true });
  };

  let shortName = userStore.user.name.charAt(0).toUpperCase();
  try {
    shortName += userStore.user.name.split(".")[1].charAt(0).toUpperCase();
  } catch (e) { }

  let userGroup = toJS(userStore.user.role);

  const getHeaderTitle = (path: string) => {
    const HEAD_TITLE = {
      users: "Users",
      agencies: "Agencies",
      brands: "Brands",
      campaigns: "Campaigns",
      dashboard: "Dashboard",
      createrepo: "Create Repos",
      programs: "Programs",
      cms: "CMS",
    };

    if (path.match(/users/)) {
      return HEAD_TITLE.users;
    }

    if (path.match(/campaign/)) {
      return HEAD_TITLE.campaigns;
    }

    if (path.match(/brands/)) {
      return HEAD_TITLE.brands;
    }

    if (userGroup === "Admin" && (path.match(/agencies/) || path === "/")) {
      return HEAD_TITLE.agencies;
    }

    if (userGroup === "Standard" && path === "/") {
      return HEAD_TITLE.campaigns;
    }

    if (path.match(/users/)) {
      return HEAD_TITLE.users;
    }

    if (path.match(/dashboard/)) {
      return HEAD_TITLE.dashboard;
    }

    if (path.match(/createrepo/)) {
      return HEAD_TITLE.createrepo;
    }
    if (path.match(/programs/)) {
      return HEAD_TITLE.programs;
    }
    if (path.match(/cms/)) {
      return HEAD_TITLE.cms;
    }
  };

  return (
    <div className="header" id="desktop">
      {!match && campaignStore.campaign.campaignName ? (
        <CampaignDetailHead
          campaignName={campaignStore.campaign.campaignName}
          brandName={campaignStore.campaign.brand.brandName}
          agencyName={campaignStore.campaign.agency.agencyName}
          brandKey={campaignStore.campaign.brand.brandKey}
          agencyKey={campaignStore.campaign.agency.agencyKey}
        />
      ) : !match && programStore.program.programName ? (
        <h2> {programStore.program.programName} </h2>
      ) : (
        <h2> {title ? title : getHeaderTitle(location.pathname)} </h2>
      )}

      <div className="userLogged">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {shortName}
          </Dropdown.Toggle>

          <Dropdown.Menu className="show">
            <Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
