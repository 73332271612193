import { Button, Spinner } from "react-bootstrap";
import { campaignSignup } from "../../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useStores } from "../../../stores";
import { ICampaignUser } from "../../../interfaces";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../../utils/helpers";
import { ValidKeywords } from "../../../utils/constants/cogntioAdditionalAttributes";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  addUsertoAdmin: Function;
  poolId: string;
  refetch: any;
  userEmail: string;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userCustomAttributeName: string;
  userCustomAttributeValue: string;
};

type AddFormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: string;
  poolId: string;
  customAttributeName: string;
  customAttributeValue: string;
};

const AddAdmin = ({
  showSidebar,
  closeHandler,
  poolId,
  refetch,
  userEmail,
  userRole,
  userFirstName,
  userLastName,
  userCustomAttributeValue,
}: AppProps) => {
  const { userStore } = useStores();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<AddFormData>();

  const queryClient = useQueryClient();

  const { mutate: addAdminUser, isLoading: isLoadingAdd } = useMutation(
    campaignSignup,
    {
      onSuccess: (data) => {
        refetch();
        closeHandler(false);
        showToast({
          content: "User has been added successfully.",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "User already exists.",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getUserList");
      },
    }
  );

  const handleAdd = (values: ICampaignUser, fns: any) => {
    reset();
    values.token = userStore.user.token;
    values.poolId = poolId;
    values.username = userEmail;
    // console.log(values);
    addAdminUser(values);
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Add User</h2>
        <span
          className="closeSideBar"
          onClick={(e) => {
            closeHandler(false);
          }}
        >
          <i className="fas fa-times"></i>
        </span>

        <form onSubmit={handleSubmit(handleAdd)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">First name</label>
              <input
                type="text"
                className="form-control"
                {...register("firstName")}
                defaultValue={userFirstName}
                placeholder="First name"
              />
              {errors.firstName && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  <>{errors.firstName}</>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Last name</label>
              <input
                type="text"
                className="form-control"
                {...register("lastName")}
                defaultValue={userLastName}
                placeholder="Last name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="agency">Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email.",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
              {errors.email && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />{" "}
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Role</label>
              <input
                type="text"
                className="form-control"
                {...register("role")}
                defaultValue={userRole}
                placeholder="Role"
              />
            </div>

            <div className="form-group">
              <label htmlFor="agency">Additional attribute name</label>
              <select
                className="form-control"
                {...register("customAttributeName")}
              >
                <option value="">Additional attribute name</option>
                {ValidKeywords().map((keyword) => (
                  <option key={keyword} value={keyword}>
                    {keyword.startsWith("custom:")
                      ? `${keyword.slice(7)}`
                      : keyword}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="agency">Additional attribute value</label>
              <input
                placeholder="Additional attribute value"
                type="text"
                className="form-control"
                defaultValue={userCustomAttributeValue}
                {...register("customAttributeValue")}
              />
            </div>

            <div className="actionBtns">
              {isLoadingAdd ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button
                  type="submit"
                  className="btn"
                  disabled={isDirty ? false : true}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default AddAdmin;
