export const SnapshotColumns = () => {
  const COLUMNS = [
    {
      Header: 'Prize Pool',
      accessor: 'prizePoolName',
      width: "20%",

    },
    {
      Header: 'Prize',
      accessor: 'prizeName',
      width: "30%",
    },
    {
      Header: 'Assigned',
      accessor: 'assigned',
      width: "10%",


    },
    {
      Header: 'Unassigned',
      accessor: 'unassigned',
      width: "10%"
    }
  ]

  return COLUMNS
}



