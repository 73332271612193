import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import icoCalendar from "../../../assets/images/ico-calendar.png";
import icoClock from "../../../assets/images/ico-clock.png";
import { showToast } from "../../../Components/Toast/ToastManager";
import { spryClient } from "../../../api";
import { Modal } from "../../../Components/Modal";
import {
  InstantPrizeTimestamp,
  Prize,
  PrizePool,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import DatePickerCalendar from "../DatePickerCalendar";

type IProps = {
  campaignKey: string;
  allPrizes: Prize[] | undefined;
  allPrizePools: PrizePool[] | undefined;
};
type EditTimestampsForm = {
  currentDate: string;
  currentTime: string;
  newDate: string;
  newTime: string;
};

function EditTimestamps(props: IProps) {
  const { instantPrizeTimestampKey } = useParams() as {
    instantPrizeTimestampKey: string;
  };
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [currentPrizeStamp, setCurrentTimestamp] =
    useState<InstantPrizeTimestamp>();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    currentDate:
      currentPrizeStamp?.winningTimestamp || new Date().toISOString(),
    currentTime:
      currentPrizeStamp?.winningTimestamp || new Date().toISOString(),
    newDate: new Date().toISOString(),
    newTime: new Date().toISOString(),
  };
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<EditTimestampsForm>();
  const newDateWatch = watch("newDate");
  const newTimeWatch = watch("newTime");
  const currentDateWatch = watch("currentDate");
  const currentTimeWatch = watch("currentTime");

  const getDateTime = (date: Date, time: Date) => {
    let tempDate = dayjs(date);
    let updatedDate_time = dayjs(tempDate)
      .hour(dayjs(time).hour())
      .minute(dayjs(time).minute())
      .second(dayjs(time).second());
    const finalDate = updatedDate_time.toISOString();
    return finalDate;
  };

  async function getTimestamps() {
    if (loading || !instantPrizeTimestampKey) {
      return;
    }
    setLoading(true);
    const res = await spryClient.getInstantPrizeTimestamps({
      campaignKey: props.campaignKey,
      instantPrizeTimestampKey,
    });
    setCurrentTimestamp(res.instantPrizeTimestamps[0]);
    setLoading(false);
  }

  const updateTimestamps = async (data: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const finalDate = getDateTime(data.newDate, data.newTime);
    let content = {
      instantPrizeTimestampKey,
      priority: currentPrizeStamp?.priority || 1,
      winningTimestamp: finalDate,
    };
    //  console.log(content);
    await spryClient
      .updateInstantPrizeTimestamp(content)
      .then(async (res) => {
        await getTimestamps();
        showToast({
          content: "Timestamp updated successfully!",
          duration: 3000,
          error: false,
        });
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          content: `e.toString()`,
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };
  const deleteTimestamp = async () => {
    setShowModal(false);
    if (loading) {
      return;
    }
    setLoading(true);
    await spryClient
      .deleteInstantPrizeTimestamp({ instantPrizeTimestampKey })
      .then(async (res) => {
        showToast({
          content: "timestamp has been deleted successfully",
          duration: 3000,
          error: false,
        });
        setLoading(false);
        backHandler();
      })
      .catch((e: any) => {
        showToast({
          content: `${e.toString()}`,
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };
  const showdeleteModal = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const backHandler = () => {
    navigate(`/campaign/${props.campaignKey}/timestamps`);
  };
  useEffect(() => {
    getTimestamps();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Modal
            calldelete={deleteTimestamp}
            handleClose={handleClose}
            notification={false}
            modalType="DeleteConfirmPopUp"
            message="Are you sure you want to delete this timestamp?"
            header="Delete Timestamp ?"
          />
        </div>
      )}
      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <div className="dashboardContent campaignDetail tabsCont">
            <div
              id="tab05"
              className="tab-contents timestamps"
              style={{ overflow: "visible" }}
            >
              <div className="head inner">
                <h3>
                  <button className="backBtn" onClick={backHandler}>
                    <i className="fas fa-arrow-left"></i>
                  </button>
                  Timestamp details
                </h3>
                <button className="btn addbtn" onClick={showdeleteModal}>
                  Delete
                </button>
                <button
                  className="btn addbtn"
                  type="submit"
                  form="editTimestampsForm"
                  style={{ marginRight: "20px" }}
                >
                  Update
                </button>
              </div>

              <div>
                <form
                  onSubmit={handleSubmit(updateTimestamps)}
                  id="editTimestampsForm"
                >
                  <div className="formContent">
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div className="date start disable">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Current date
                            </label>

                            <Controller
                              render={({ field }) => (
                                <DatePickerCalendar
                                  showTimePicker={false}
                                  value={
                                    currentDateWatch
                                      ? new Date(currentDateWatch)
                                      : new Date(initialValues.currentDate)
                                  }
                                  onChange={async (val: Date) => {
                                    val
                                      ? setValue(
                                          "currentDate",
                                          val.toISOString()
                                        )
                                      : setValue(
                                          "currentDate",
                                          new Date().toISOString()
                                        );
                                  }}
                                  readOnly={true}
                                />
                              )}
                              control={control}
                              name="currentDate"
                            />
                            <span className="calIco">
                              <img src={icoCalendar} alt="Select Date" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="clock start disable">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Current time
                            </label>
                            <Controller
                              render={({ field }) => (
                                <DatePickerCalendar
                                  showTimePicker={true}
                                  value={
                                    currentTimeWatch
                                      ? new Date(currentTimeWatch)
                                      : new Date(initialValues.currentTime)
                                  }
                                  onChange={async (val: Date) => {
                                    val
                                      ? setValue(
                                          "currentTime",
                                          val.toISOString()
                                        )
                                      : setValue(
                                          "currentTime",
                                          new Date().toISOString()
                                        );
                                  }}
                                  readOnly={true}
                                />
                              )}
                              control={control}
                              name="currentDate"
                            />
                            <span className="timeIco">
                              <img src={icoClock} alt="Select Time" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div className="date End date">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">New Date</label>
                            {/* <DatePickerCalendar
                              name="newDate"
                              value={values.newDate}
                              onChange={setFieldValue}
                              readOnly={false}
                              dateforTime={values.newDate}
                            /> */}
                            <Controller
                              render={({ field }) => (
                                <DatePickerCalendar
                                  showTimePicker={false}
                                  value={
                                    newDateWatch
                                      ? new Date(newDateWatch)
                                      : new Date(initialValues.newDate)
                                  }
                                  startDate={new Date()}
                                  onChange={async (val: Date) => {
                                    val
                                      ? setValue("newDate", val.toISOString())
                                      : setValue(
                                          "newDate",
                                          new Date().toISOString()
                                        );
                                  }}
                                  readOnly={false}
                                />
                              )}
                              control={control}
                              name="newDate"
                            />

                            <span className="calIco">
                              <img src={icoCalendar} alt="Calendar" />
                            </span>
                            {errors.newDate && (
                              <div className="error">
                                <i className="fas fa-exclamation-circle" />
                                <>{errors.newDate.message}</>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="clock start">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              New Time
                            </label>
                            {/* <DatePickerCalendar
                              showTimePicker
                              name="newTime"
                              value={values.newTime}
                              onChange={setFieldValue}
                              readOnly={false}
                              dateforTime={values.newDate}
                            /> */}
                             <Controller
                              render={({ field }) => (
                                <DatePickerCalendar
                                  showTimePicker={true}
                                  value={
                                    newTimeWatch
                                      ? new Date(newTimeWatch)
                                      : new Date(initialValues.newTime)
                                  }
                                  startDate={new Date()}
                                  onChange={async (val: Date) => {
                                    val
                                      ? setValue("newTime", val.toISOString())
                                      : setValue(
                                          "newTime",
                                          new Date().toISOString()
                                        );
                                  }}
                                  readOnly={false}
                                />
                              )}
                              control={control}
                              name="newTime"
                            />
                            <span className="timeIco">
                              <img src={icoClock} alt="Calendar" />
                            </span>
                            {errors.newTime && (
                              <div className="error">
                                <i className="fas fa-exclamation-circle" />
                                <>{errors.newTime.message}</>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EditTimestamps;
