import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import DatePickerCalendar from "../CampaignDetail/DatePickerCalendar";
import icoCal from "../../assets/images/ico-calendar.png";
import { createTask, deleteTask, getPrograms, markComplete, saveTask } from "../../api";
import { useStores } from "../../stores";
import { TProgram } from "../../types/portalTypes";
import { showToast } from "../../Components/Toast/ToastManager";
import { TaskColumns } from "./TasksCols";
import { Table } from "../../Components";
import { useQuery } from "react-query";

type TaskForm = {
  name: string;
  description: string;
  dueDate: string;
  completed?: number;
  id: string;
  programId: string;

};

const Tasks = ({ programId, active }: { programId: string, active: boolean }) => {
  const { userStore } = useStores();

  const [isOpen, setIsopen] = useState(false);

  const [loading, setLoading] = useState(false);

  const { data: program, isLoading: programLoading, refetch: refetchProgram, isRefetching } = useQuery(
    ["getProgramtasks", programId],
    async () => {
      const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId,include:["tasks"] });
      return res.programs
    },
    { enabled: !!programId && !!active }
  );
  const programName = program ? program.program_name : ""

  const default_values = {
    dueDate: new Date().toISOString(),
    description: "",
    name: "",
    completed: 0,
  };
  const sortedTasks = program?.tasks?.sort((a, b) => {
    if (new Date(a.dueDate) > new Date(b.dueDate)) return 1;
    if (new Date(a.dueDate) < new Date(b.dueDate)) return -1;
    return 0;
  });

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TaskForm>({
    mode: "onChange",
    defaultValues: {
      ...default_values,
    },
  });

  const formWatch = watch();
  const handleAdd = async (data: TaskForm) => {
    if (loading) {
      return
    }
    setLoading(true)

    if (data.id) {
      try {
        await saveTask({
          ...data,
          token: userStore.user.token,
          programId,
        });
        showToast({
          content: `You have successfully updated ${data.name}`,
          duration: 3000,
          error: false,
        });
        reset({ ...default_values })
        refetchProgram();
        setLoading(false)
        setIsopen(false)

      } catch (e) {
        showToast({
          content: `Error while updating task`,
          duration: 3000,
          error: true,
        });
      }

    }
    else {

      try {
        await createTask({
          ...data,
          token: userStore.user.token,
          programId: programId,
          completed: false,
        });
        showToast({
          content: `You have successfully added a task.`,
          duration: 3000,
          error: false,
        });
        reset({ ...default_values })
        refetchProgram();
        setLoading(false)
        setIsopen(false)

      } catch (e) {
        showToast({
          content: `Error while creating task`,
          duration: 3000,
          error: true,
        });
      }

    }

    setLoading(false);
  };
  const deleteHandler = async (id: string) => {

    if (loading) {
      return;
    }
    setLoading(true)
    try {
       await deleteTask({
        id,
        token: userStore.user.token,
      });
      showToast({
        content: `You have successfully deleted task`,
        duration: 3000,
        error: false,
      });
      refetchProgram()
    } catch (e) {
      showToast({
        content: `Error while deleting task`,
        duration: 3000,
        error: true,
      });
    }
    setLoading(false)

  };
  const markasCompleted = async (id: string) => {
    if (loading) { return }
    try {
      setLoading(true);
      await markComplete({ id, token: userStore.user.token });
      showToast({
        content: `You have successfully marked task as completed`,
        duration: 3000,
        error: false,
      });
      refetchProgram();
    } catch (e) {
      showToast({
        content: `error while marking task as completed`,
        duration: 3000,
        error: true,
      });
    }
    setLoading(false);
  };

  const functions = {
    deleteHandler,
    setIsopen,
    markasCompleted,
    reset,
  };
  const columns = TaskColumns(functions);

  return (
    <>
      <div className="taskHead">
        <button
          className="btn action-buttons-sm"
          onClick={() => setIsopen(true)}
        >
          Add Task
        </button>
      </div>
      <div className="tasksTable">
        {(programLoading || isRefetching) ? (
          <div className="spinner">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (

          <Table
            columns={columns}
            data={sortedTasks?.length ? sortedTasks : []}
            tablePageSize={15}
            tableTitle="Tasks"
            descending={false}
            sortbyid="index"
          />)
        }
      </div>
      <Modal show={isOpen} onHide={() => { }} centered>
        <form onSubmit={handleSubmit(handleAdd)} >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                reset({ ...default_values });
                setIsopen(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <h3>{formWatch.id ? "Update task" : formWatch.completed ? "View Task" : "Add a task"}</h3>
            <div className="form-group">
              <label htmlFor="name">Task name</label>
              <input
                className="form-control"
                aria-autocomplete="none"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Please enter a task name.",
                  },
                })}
              />
            </div>
            {errors.name && (
              <p className="error">
                <i className="fas fa-exclamation-circle" />
                {errors.name.message}
              </p>
            )}
            <div className="form-group">
              <label htmlFor="description">Task description</label>
              <textarea
                className="form-control"
                {...register("description", {
                  required: {
                    value: true,
                    message: "Please enter a task description.",
                  },
                })}
              />
            </div>
            {errors.description && (
              <p className="error">
                <i className="fas fa-exclamation-circle" />
                {errors.description.message}
              </p>
            )}
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="clock start">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Due date</label>
                    <DatePickerCalendar
                      value={new Date(formWatch.dueDate)}
                      onChange={(val: Date) =>
                        setValue("dueDate", val.toISOString())
                      }
                      readOnly={false}
                      dateforTime={new Date(formWatch.dueDate)}
                      timeInterval={30}
                    />
                    <span className="timeIco">
                      <img src={icoCal} alt="Select Time" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Status</label>
                  <select
                    className="form-control"
                    {...register("completed", {
                      required: false,
                    })}
                    disabled={formWatch.id ? true : false}
                  >
                    <option value={0}> To Do</option>
                    {formWatch.id && <option value={1}> completed</option>}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>

            <button
              type="button"
              className="btn btn-primary small"
              onClick={async () => {
                reset({ ...default_values });
                setIsopen(false);
              }}
            >
              Cancel
            </button>
            {(loading || isRefetching) ? <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...{" "}
            </Button> :
              <button type="submit" className="btn btn-secondary small outline" disabled={formWatch.completed ? true : false}>
                Submit
              </button>}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Tasks;
