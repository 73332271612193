import { useState, useEffect, useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Spinner } from "react-bootstrap";
import AddAdmin from "./AddAdmin";
import { Table } from "../../../Components";
import { AdminColumns } from "./AdminColumns";
import {
  getCognitoPools,
  spryClient,
  campaignGetUserList,
  campaignAddUsertoGroup,
  campaignRemoveFromGroup,
  campaignDeleteUser,
} from "../../../api";
import { useStores } from "../../../stores";
import { useBodyClass } from "../../../utils/hooks";
import { showToast } from "../../../Components/Toast/ToastManager";
import Popup from "../../../Components/Popup";
import { Modal } from "react-bootstrap";
import EditAdmin from "./EditAdmin";
import { convertcaps, moveHashHeaderLast } from "../../../utils/helpers";
import dayjs from "dayjs";

export type User = {
  role: string;
  username: string;
  name: string;
  email: string;
  updateDate: string;
  enabled: boolean;
  status: string;
  customerAttributeName: string;
  customerAttributeValue: string;
};

const CampaignAdmins = (props: { campaignKey: string }): JSX.Element => {
  
  useBodyClass("adminPg");

  const { userStore } = useStores();

  const [repositoryName, setRepositoryName] = useState("");
  const [poolId, setPoolId] = useState("");

  useEffect(() => {
    const getCognitoUserPools = async () => {
      let { data } = await getCognitoPools(userStore.user.token);
      const id = data.UserPools.filter((pool: any) => {
        return (
          repositoryName &&
          pool.Name.indexOf(repositoryName) !== -1
        );
      });
      if (id.length > 0) {
        setPoolId(id[0].Id);
      }
    };
    getCampaign(props.campaignKey);
    getCognitoUserPools();
  }, [repositoryName, props.campaignKey, userStore.user?.token]);

  const getCampaign = async (campaignKey: string) => {
    const {
      campaigns: [campaign],
    } = await spryClient.getCampaigns({ campaignKey });
    setRepositoryName(campaign.repositoryName ?? '');
  };

  const [show, setShow] = useState(false);
  const [showEditBar, setShowEditBar] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const queryClient = useQueryClient();
  const { mutate: addUsertoAdmin, isLoading: loadingAdd } = useMutation(
    campaignAddUsertoGroup,
    {
      onSuccess: () => {
        // queryClient.invalidateQueries("getUserList");
        refetch();
        showToast({
          content: `${selectedUser.username} has been assigned with the ${selectedRole} role.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: `Sorry, Error while assigning user with the ${selectedRole} role.`,
          duration: 3000,
          error: true,
        });
      },
    }
  );

  useMutation(campaignRemoveFromGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserList");
      showToast({
        content: `${selectedUser.username} has been removed from the ${selectedRole} role.`,
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      showToast({
        content: `Sorry, Error while removing ${selectedRole} role from user.`,
        duration: 3000,
        error: true,
      });
    },
  });

  const {
    data: usersData,
    isLoading: isLoadingState,
    refetch,
  } = useQuery(
    "campaignGetUserList",
    async () => {
      const res: any = await campaignGetUserList({
        poolId: poolId,
        token: userStore.user.token,
      });
      const users = res.data.users.map((user: any) => {
        const formattedDate = dayjs(user.updateDate).format("MM/DD/YYYY");
        return {
          ...user,
          ...user.addtionalAttributes,
          firstName: user.name
            ? user.name
                .split(" ")
                .slice(0, -1)
                .join(" ")
                .charAt(0)
                .toUpperCase() +
              user.name.split(" ").slice(0, -1).join(" ").slice(1)
            : "",
          lastName: user.name
            ? user.name.split(" ").slice(-1).join(" ").charAt(0).toUpperCase() +
              user.name.split(" ").slice(-1).join(" ").slice(1)
            : "",
          updateDate: formattedDate,
        };
      });
      return users;
    },
    { enabled: !!poolId }
  );

  const { mutate: deleteAdminUser, isLoading: Deleting } = useMutation(
    campaignDeleteUser,
    {
      onSuccess: () => {
        // queryClient.invalidateQueries("getUserList");
        refetch();
        showToast({
          content: `${selectedUser.username} has been deleted successfully.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: "Sorry, Deleting user failed.",
          duration: 3000,
          error: false,
        });
      },
    }
  );

  let columns = useMemo(
    () =>
      AdminColumns(
        {
          setShow,
          setShowEditBar,
          setShowModal,
          setSelectedUser,
          setisOpenAdd,
          setSelectedRole,
          refetch,
          userStore,
        },
        poolId
      ),
    // eslint-disable-next-line
    [
      poolId,
      setShow,
      setShowEditBar,
      setShowModal,
      setSelectedUser,
      setisOpenAdd,
      setSelectedRole,
      refetch,
      userStore,
    ]
  );

  const [newAdditonalAttributesData, setAdditonalAttributesData] =
    useState<any>();

  useEffect(() => {
    const destructureColumns = async () => {
      let addtionalAttributes =
        (await usersData) &&
        (await usersData.map((item: any) => {
          return item.addtionalAttributes;
        }));

      let addtionalAttributesUniquekeys = Array.from(
        new Set(addtionalAttributes?.flatMap((obj: any) => Object.keys(obj)))
      )?.filter(
        (key: any) =>
          key !== "sub" &&
          key !== "email_verified" &&
          key !== "given_name" &&
          key !== "family_name"
      );

      let newData = addtionalAttributesUniquekeys.sort().map((item: any) => {
        return {
          Header: convertcaps(
            `${item.startsWith("custom:") ? item!.slice(7) : item!}`
          ),
          accessor: `${item!}`,
          width: "10%",
        };
      });
      setAdditonalAttributesData([...columns, ...newData]);
    };
    usersData && destructureColumns();
  }, [usersData, columns]);

  const calldelete = () => {
    deleteAdminUser({
      userName: selectedUser.username,
      token: userStore.user.token,
      poolId: poolId,
    });
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Popup
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
            action1={calldelete}
            header="Delete User"
            message={`Are you sure you want to delete ${selectedUser.username}?`}
            yesMessage="Yes, Delete"
            cancelMessage="Cancel"
          />
        </div>
      )}
      <div className="container-fluid p-0">
        {!isLoadingState ? (
          <div className="dashboardContent campaignDetail">
            <div
              className="head"
              // style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {poolId !== "" ? (
                <span
                  className="btn addbtn"
                  onClick={(e) => {
                    refetch();
                    setSelectedUser("");
                    setShow(true);
                  }}
                >
                  Add user
                </span>
              ) : (
                <p>There are no Cognito pools set up for this campaign.</p>
              )}
            </div>
            <AddAdmin
              showSidebar={show}
              closeHandler={setShow}
              addUsertoAdmin={addUsertoAdmin}
              poolId={poolId}
              refetch={refetch}
              userEmail={selectedUser && selectedUser.email}
              userRole={selectedUser && selectedUser.role}
              userFirstName={selectedUser && selectedUser.firstName}
              userLastName={selectedUser && selectedUser.lastName}
              userCustomAttributeName={
                selectedUser && selectedUser.customAttributeName
              }
              userCustomAttributeValue={
                selectedUser && selectedUser.customAttributeValue
              }
            />
            {selectedUser && (
              <EditAdmin
                showSidebar={showEditBar}
                closeHandler={setShowEditBar}
                poolId={poolId}
                refetch={refetch}
                userEmail={selectedUser && selectedUser.email}
                userRole={selectedUser && selectedUser.role}
                userFirstName={selectedUser && selectedUser.firstName}
                userLastName={selectedUser && selectedUser.lastName}
                userCustomAttributeName={
                  selectedUser && selectedUser.customAttributeName
                }
                userCustomAttributeValue={
                  selectedUser && selectedUser.customAttributeValue
                }
              />
            )}
            {isLoadingState || loadingAdd || Deleting ? (
              <div className="spinner dfsdfsd">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              newAdditonalAttributesData &&
              poolId !== "" && (
                <>
                  <Table
                    tableTitle="Campaign Cognito Users"
                    columns={moveHashHeaderLast(newAdditonalAttributesData)}
                    data={usersData.length > 0 ? usersData : []}
                    tablePageSize={10}
                    sortbyid="username"
                    descending={false}
                  />
                </>
              )
            )}
          </div>
        ) : (
          <div className="dashboardContent">
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          </div>
        )}
      </div>
      <Modal show={isOpenAdd} onHide={() => {}} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Change Role</h3>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Role</label>
            <input
              type="text"
              className="form-control"
              value={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.currentTarget.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={async () => {
              setisOpenAdd(false);
              addUsertoAdmin({
                userName: selectedUser.username ? selectedUser.username : "",
                role: selectedRole,
                token: userStore.user.token,
                poolId: poolId,
              });
            }}
            disabled={selectedRole === selectedUser?.role ? true : false}
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignAdmins;
