export const PrizeInfoColumns = () => {
  const COLUMNS = [
    {
      Header: "# Of Participants Won",
      accessor: "value",
      width: "50%",
    },
    {
      Header: "Prize Name",
      accessor: "name",
      width: "50%",
    },
  ];
  return COLUMNS;
};
