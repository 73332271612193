import { Dropdown } from "react-bootstrap";

export const BrandColumns = (fns: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdate: (data: { brandKey: string; brandName: string }) => void;
  mutate: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStateKey: any;
}) => {
  const COLUMNS = [
    {
      Header: "Brand Name",
      accessor: "brandName",
      width: "86%",
    },
    {
      Header: "Updated",
      accessor: "updatedTime",
      width: "9%",
    },
    {
      Header: "",
      accessor: "brandKey",
      width: "5%",
      Cell: (TableInfo: {
        data: { [x: string]: { brandName: string; brandKey: string } };
        row: { index: string | number };
      }) => {
        const brandKey = TableInfo.data[TableInfo.row.index].brandKey;

        const brandName = TableInfo.data[TableInfo.row.index].brandName;

        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  fns.handleUpdate({ brandKey, brandName });
                  fns.setShow(true);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  fns.setStateKey(brandKey);
                  fns.mutate({ brandKey });
                  fns.mutate({ brandKey });
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return COLUMNS;
};
