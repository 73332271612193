import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { spryClient } from "../../../api";
import { Modal } from "../../../Components/Modal";
import { showToast } from "../../../Components/Toast/ToastManager";


type typeProp = {
    campaignKey: string
}

const UpdatePrize = ({ campaignKey }: typeProp) => {
    const { prizeKey } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [prizeName, setprizeName] = useState<string>('')
    const [value, setvalue] = useState<number>(1)
    const [description, setdescription] = useState<string | null>('')
    const [inventoryActive, setinventoryActive] = useState<boolean>(false)
    const [inventoryCount, setinventoryCount] = useState<number>(1)
    const [allowNegativeInventory, setallowNegativeInventory] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)


    const { isLoading: isLoadingState, data: dataPrize } = useQuery(
        ["getPrize", { campaignKey, prizeKey }],
        async () => {
            if (prizeKey) {
                const res = await spryClient.getPrizes({ campaignKey, prizeKey });
                setallowNegativeInventory(res.prizes[0].allowNegativeInventory)
                setprizeName(res.prizes[0].prizeName)
                setdescription(res.prizes[0].description)
                setinventoryActive(res.prizes[0].inventoryActive)
                setinventoryCount(res.prizes[0].inventoryCount)
                setvalue(res.prizes[0].value)
                return res.prizes;
            }
        },
        { enabled: !!prizeKey }
    );


    useMutation(spryClient.deletePrize, {
        onSuccess: data => {
            setError('')
            navigate(`/campaign/${campaignKey}/prizing`);
        }
        ,
        onError: (res) => {
            console.log('error')
            setError('Prize pool is already associated with an instant timestamp')
        },
        onSettled: () => {
            queryClient.invalidateQueries('getPrizes')
        }

    });

    const deletehandler = async () => {
        setShowModal(false);
        if (loading) { return }
        setLoading(true)
        prizeKey && await spryClient.deletePrize({ prizeKey }).then(res => {
            showToast({
                content: "Prize has been deleted successfully.",
                duration: 3000,
                error: false,
            });
            setLoading(false)

            navigate(`/campaign/${campaignKey}/prizing`);
        }).catch((e) => {
            showToast({
                content: `${e.toString()}`,
                duration: 3000,
                error: true,
            });
            setLoading(false)

        })

    }
    const showdeleteModal = () => {
        setShowModal(true);
    };



    const handleSubmit = async () => {
        if (loading) { return }
        setLoading(true)
        try {
            prizeKey && await spryClient.updatePrize({ prizeKey, prizeName, value, description })
        }
        catch (e: any) {
            showToast({ content: `${e.toString()}`, duration: 3000, error: true })
        }
        finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        setShowModal(false);
    };


    return (
        <>
            {showModal && (
                <div className="row no-gutters">
                    <Modal
                        notification={false}
                        calldelete={deletehandler}
                        handleClose={handleClose}
                        modalType="DeleteConfirmPopUp"
                        header="Delete ?"
                        message="Are you sure you want to delete this Prize?"
                    />
                </div>
            )}
            <div className="dashboardContent campaignDetail tabsCont addpg prizing">
                {loading || isLoadingState ? <div className="spinner"><Spinner animation="border" variant="secondary" /></div> :

                    <div id="tab04" className="tab-contents addprize">
                        <div className="head inner">
                            <h3><button className="backBtn"><i onClick={() => navigate(`/campaign/${campaignKey}/prizing`)} className="fas fa-arrow-left"></i></button>Update prize</h3>
                            <span onClick={handleSubmit} className="btn addbtn">Update prize</span>
                            <button className="btn addbtn" onClick={showdeleteModal}> Delete </button>
                            {error && <p className="error-message"> {error} </p>}

                        </div>
                        <form>
                            <div className="formContent">
                                <div className="form-group">
                                    <label>Prize name</label>
                                    <input type="text" className="form-control" value={prizeName} placeholder="Prize name" onChange={event => {
                                        setprizeName(event.target.value)
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <input type="text" className="form-control" value={description + ""} placeholder="Description" onChange={event => {
                                        setdescription(event.target.value)
                                    }} />
                                </div>
                                <div className="form-group shortInput">
                                    <label >$ Value</label>
                                    <input type="number" className="form-control" placeholder="$ Value" value={value} step={0.01} onChange={event => {
                                        let numEventvalue = event.target.value
                                        setvalue(+numEventvalue)
                                    }} />
                                </div>
                                <div className="form-group shortInput">
                                    <label >Current Inventory</label>
                                    <br />
                                    <input type="number" disabled className="form-control" placeholder="Current Inventory" value={dataPrize && dataPrize[0]?.inventoryCount} />
                                </div>
                                <div className="form-group shortInput">
                                    <label >Adjust Inventory To</label>
                                    <br />
                                    <input type="number" className="form-control" min={!allowNegativeInventory ? 0 : undefined} placeholder="Adjust Inventory" value={+inventoryCount} onChange={event => {
                                        let numEventinventoryCount = event.target.value
                                        setinventoryCount(+numEventinventoryCount)
                                    }} disabled={inventoryActive ? false : true} />
                                </div>
                                <div className="form-group toggleBtn">
                                    <p>Enable inventory for this prize</p>
                                    <div className="swtichCont">
                                        <label className="switch">
                                            <input type="checkbox" id="checkbox" onChange={event => {
                                                setinventoryActive(!inventoryActive)
                                                if (inventoryActive === true) {
                                                    setallowNegativeInventory(false)
                                                    setinventoryCount(0)
                                                }
                                            }} value={inventoryActive ? "yes" : "no"} checked={inventoryActive ? true : false} />
                                            <div className="slider round" ></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group toggleBtn">
                                    <p>Allow Negative inventory for this prize</p>
                                    <div className="swtichCont">
                                        <label className="switch" >
                                            <input type="checkbox" id="checkbox2" onChange={event => {
                                                setallowNegativeInventory(!allowNegativeInventory)
                                                if (!allowNegativeInventory) {
                                                    setinventoryCount(inventoryCount)
                                                }
                                                else {
                                                    setinventoryCount(0)

                                                }

                                            }} value={allowNegativeInventory ? "yes" : "no"} checked={allowNegativeInventory ? true : false} disabled={inventoryActive ? false : true} />
                                            <div className="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>}
            </div>



        </>
    )

}

export default UpdatePrize;

