import { useEffect } from "react";

export const useBodyClass = (className: string) => {
  useEffect(() => {
    document.body.className = className;
  }, [className]);

  return () => {
    document.body.className = '';
  }
};

export const disableBrowserHistory = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
};
