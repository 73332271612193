import { Link, useParams } from "react-router-dom";
import IcoRefresh from "../../assets/images/ico-refresh.png";
import { useStores } from "../../stores";

type Iprops = {
  setCollapse: (collapsed: boolean) => void;
};

export default function SubMenu(props: Iprops) {
  const { campaignStore, filtersStore } = useStores();
  const { campaignKey } = useParams() as { campaignKey?: string }

  const handleclick = () => {
    props.setCollapse(false);
  };

  if (!campaignKey) { return <></> }

  return (
    <div className="subMenuCont">
      {campaignStore.campaign.campaignName && <p>
        <Link
          to={`/campaign/${campaignKey}/overview`}
          onClick={() => {
            filtersStore.setbrandfilter("");
            filtersStore.setagencyFilter("");
          }}
          className="nameLink"
        >
          <span>{campaignStore.campaign.campaignName}</span>{" "}
        </Link>
        <Link
          to={`/campaign/${campaignKey}/overview`}
          className="icoLink"
          onClick={() => {
            filtersStore.setbrandfilter("");
            filtersStore.setagencyFilter("");
          }}
        >
          {" "}
          <span>
            <img src={IcoRefresh} alt="refresh" className="icoRefresh" />{" "}
          </span>{" "}
        </Link>
      </p>}
      <ul className="submenu">
        <li>
          <Link to={`/campaign/${campaignKey}/overview`} onClick={handleclick}>
            Overview
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/cognitoAccounts`} onClick={handleclick}>
            Campaign Users
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/participants`} onClick={handleclick}>
            Participants
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/setting`} onClick={handleclick}>
            Settings
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/manage`} onClick={handleclick}>
            Schedule
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/prizing`} onClick={handleclick}>
            Prizing
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/timestamps`} onClick={handleclick}>
            Timestamps
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/media`} onClick={handleclick}>
            Media
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/emailtemplates`} onClick={handleclick}>
            Emails
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/prizedraws`} onClick={handleclick}>
            Prize Draws
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/giftcards`} onClick={handleclick}>
            Gift Cards
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/keywords`} onClick={handleclick}>
            Keywords
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/email-batches`} onClick={handleclick}>
            Email Batches
          </Link>
        </li>
        <li>
          <Link to={`/campaign/${campaignKey}/participant-import`} onClick={handleclick}>
            Participant Import
          </Link>
        </li>
      </ul>
    </div>
  );
}
