import {Link, useParams} from "react-router-dom";
import {useStores} from "../../stores";
import {useQuery} from "react-query";
import {cmsListTypes} from "../../api";
import {Header, NavMenu} from "../../Components";
import React, {ReactNode, useState} from "react";
import {ManageCMSTypeDialog} from "../../Components/ManageCMSTypeDialog";

export const TypeSelectedLayout = ({children}:{children: ReactNode}) => {
    const {typeId} = useParams<{ typeId?: string }>()
    const {userStore} = useStores();
    const {data: types, isLoading} = useQuery("cmsListTypes", async () => {
        return cmsListTypes({token: userStore.user.token});
    });
    const [isCreating, setIsCreating] = useState(false)

    return <div className="container-fluid p-0">
        <ManageCMSTypeDialog open={isCreating} setOpen={setIsCreating} existingEntityId={'*'} />
        <div className="row no-gutters">
            <NavMenu />
            <div className="col-sm-12 col-md-10">
                <Header />
                <div className="dashboardContent">
                    <div className={'row min-h-[700px]'}>
                        <div className={'col-2 border-r'}>
                            <div className={'flex justify-content-between'}>
                                <p className={'mt-auto mb-auto font-bold'}>Content Type</p>
                                <div onClick={() => setIsCreating(true)} className={'mt-auto mb-auto'}>
                                    <button className={'mb-0 btn px-4 py-2 bg-transparent text-primary border-0'}>+</button>
                                </div>
                            </div>
                            {types?.[0]?.map((type) => (
                                <Link to={`/cms/${type.slug}`} key={type.slug} className={`flex justify-between p-2 w-full hover:bg-gray-100 rounded ${type.slug === typeId ? 'font-bold text-blue-500' : ''}`}>
                                    <p>{type.title}</p>
                                </Link>
                            ))}
                        </div>
                        <div className={'col-10'}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}