import { useMemo } from 'react'
import Table from '../../../Components/CheckBoxTable';
import { PrizeWinner } from '@sprycore/spry-api-client/dist/MainDbReturnTypes';
import { Dropdown } from "react-bootstrap";
import {
  GrandPrizeDrawRanking,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";


type IProps = {
  campaignKey: string,
  rejectedRankings: GrandPrizeDrawRanking[]
  setSelectedWinners: Function
  unforfeitPrizemultiple: Function
  prizeWinners: PrizeWinner[] | undefined
}
export const Rejected = (props: IProps) => {

  const makeRejectPending = async (selected: GrandPrizeDrawRanking[]) => {
    props.unforfeitPrizemultiple(selected)
  }

  const COLUMNS = [
    {
      Header: 'Rank',
      accessor: 'ranking',
      width: "3%",

    },
    {
      Header: 'First Name',
      accessor: 'participant.firstName',
      width: "8%",

    },
    {
      Header: 'Last Name',
      accessor: 'participant.lastName',
      width: "8%",

    },
    {
      Header: 'Email',
      accessor: 'participant.email',
      width: "8%",
    },
    {
      Header: 'Last Updated',
      accessor: 'creationTime',
      width: "18%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'rejectionTime',
      width: "18%",
      Cell: (tableInfo: any) => {
        return (
          <p>{tableInfo.data[tableInfo.row.index].rejectionTime ? "Rejected" : "Forfeited"}</p>
        );
      },
    },

    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (TableInfo: any) => {
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (

          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu >
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  props.setSelectedWinners([TableInfo.data[TableInfo.row.index]])
                  makeRejectPending([TableInfo.data[TableInfo.row.index]])
                }}
              >
                Make eligible
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    }
  ]
  // eslint-disable-next-line
  const columns = useMemo(() => COLUMNS, [])


  return (
    <>
      {
        props.rejectedRankings && (props.rejectedRankings.length > 0 ? <Table
          setSelectedRows={props.setSelectedWinners}
          sortbyid="ranking"
          columns={columns}
          data={props.rejectedRankings}
          tablePageSize={10} /> : <p className="table-nodata">There are no records to display.</p>)}
    </>
  )

}



