import { TProgram } from '../../types/portalTypes'
import { getAssets, getPrograms, submitEstimate } from '../../api';
import { useStores } from '../../stores';
import { useQuery } from 'react-query';
import QuoteForm from './QuoteForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Ballpark from './Ballpark';
import { Button, Modal, Spinner } from "react-bootstrap";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../Components/Toast/ToastManager';
import { Dropzone } from '../../Components/dropzone';
import { Table } from '../../Components';
import { Row, TableProps } from "react-table";


type estimateForm = {
    internalId: string
    estimate: string;
    estimationUrl: string;
}




const ProgramDetails = ({ programId, backHandler, status }: { programId?: string, backHandler: Function, status: string }) => {
    const { userStore } = useStores();
    const defaultValues: estimateForm = { estimate: "", estimationUrl: "", internalId: "" }
    const [uploadCompleted, setUploadCompleted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const { register, watch, formState: { errors }, handleSubmit } = useForm<estimateForm>({ mode: "onTouched", defaultValues: { ...defaultValues } })


    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();


    const { data: program, isLoading: programLoading, refetch: programFetch } = useQuery(
        ["getProgramdetails", programId, searchParams],
        async () => {
            const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["approvedQuote", "company"] });
            return res.programs
        },
        { enabled: !!programId }
    );

    const COLUMNS = [
        {
            Header: "No",
            accessor: "no",
            Cell: (TableInfo: any) => {

                const nmb = TableInfo.row.index + 1;

                return nmb;
            }

        },
        {
            Header: "File name",
            accessor: "key",
            Cell: (TableInfo: TableProps) => {
                const key = TableInfo.data[TableInfo.row.index].key

                return <a href={TableInfo.data[TableInfo.row.index].url}>{key.split('--')[1]}</a>;
            },

        },
        {
            Header: "Created",
            accessor: "lastModified",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                const dateString = new Date(TableInfo.data[TableInfo.row.index].lastModified).toDateString()

                return dateString;
            },
        },]

    const { data: estimations, isLoading: quoteAssetsLoading, refetch } = useQuery(["getAssets", program?.approvedQuote.id], async () => {
        const res = await getAssets({ token: userStore.user.token, id: program?.approvedQuote.id, entityType: "quote" });

        return res?.sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())
    }, { enabled: !!program?.approvedQuote.id });


    const formwatch = watch()

    const handleEstimate = async (data: estimateForm) => {
        if (loading || !program?.approvedQuote) {
            return
        }
        setLoading(true)
        try {
            const res = await submitEstimate({
                id: program?.approvedQuote.id,
                estimate: data.estimate,
                internalId: data.internalId,
                token: userStore.user.token,
            });

            if (res) {
                showToast({
                    content: "Estimation has been submitted successffully.",
                    duration: 3000,
                    error: false,
                });
                programFetch()
            }

        } catch (e) {
            showToast({
                content: "Error while submitting estimation.",
                duration: 3000,
                error: true,
            });

        }
        setIsOpen(false)
        setLoading(false)
    }

    const filter = searchParams.get("filter") || "basic_config"

    if (programLoading || quoteAssetsLoading) {

        return <div className="spinner">
            <Spinner animation="border" variant="secondary" />
        </div>

    }

    return (
        <>
            <div >
                <h3>
                    <button onClick={() => { searchParams.set("filter", "basic_config"); backHandler() }} className="backBtn" style={{ paddingRight: "15px" }}>
                        <i className="fas fa-arrow-left" />
                    </button>
                    Back to quotes
                </h3>
            </div>

            {(program && +status > 20) ?

                <div className='flex' style={{ justifyContent: "space-between" }}>

                    {filter === "ballpark" ? <Ballpark quoteId={program.approvedQuote.id} backHandler={() => { navigate(`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=basic_config`) }} company={program?.company.id} programId={program.id} status="23" /> :

                        filter === "estimate" ? <div>
                            <button className='btn action-buttons-sm no-add'onClick={() => setIsOpen(true)} disabled={+status == 22 || +status == 33}>Upload new estimation</button>
                            {(estimations?.length) ? <Table columns={COLUMNS} data={estimations} tablePageSize={10} sortbyid='no' descending={false} /> : <></>}

                        </div> :
                            <QuoteForm programId={program.id} quoteId={program.approvedQuote.id} type={"program"} filter={filter} />
                    }

                    <div className='flex flex-col' style={{ padding: "4rem" }}>
                        <ol style={{ listStyle: "outside", }} >
                            <li>
                            <Link to={`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=basic_config`} className={filter === "basic_config" ? "active-filterlink" : "filterlink"}>Program Overview</Link></li>
                            <li><Link to={`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=program_config`} className={filter === "program_config" ? "active-filterlink" : "filterlink"}>Program Modules</Link></li>
                            <li><Link to={`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=communication`} className={filter === "communication" ? "active-filterlink" : "filterlink"}>Communication & Reporting</Link></li>
                            <li><Link to={`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=ballpark`} className={filter === "ballpark" ? "active-filterlink" : "filterlink"}>Ballpark quote</Link></li>
                            <li><Link to={`/program/${program.id}?tab=quotes&qId=${program.approvedQuote.id}&filter=estimate`} className={filter === "estimate" ? "active-filterlink" : "filterlink"}> Estimations</Link></li>
                        </ol>

                    </div>
                </div > : <></>}


            {program &&
                <Modal show={!!isOpen} onHide={() => setIsOpen(false)} centered>
                    <form onSubmit={handleSubmit(handleEstimate)} id="estimate">

                        <Modal.Body>
                            <h3 style={{ fontWeight: "bold" }}>Upload Estimation</h3>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="program_name">Internal ID</label>
                                        <input
                                            className="form-control"
                                            aria-autocomplete='none'
                                            {...register("internalId", { required: { value: true, message: "Please add an internal id." } })}
                                        />

                                    </div>
                                    {errors.internalId &&
                                        <p className="error">
                                            <i className="fas fa-exclamation-circle" />
                                            {errors.internalId.message}
                                        </p>
                                    }
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="program_name">Price estimate</label>
                                        <input
                                            className="form-control"
                                            {...register("estimate", { required: { value: true, message: "Please enter a value for estimation." } })}
                                        />
                                    </div>
                                    {errors.estimate &&
                                        <p className="error">
                                            <i className="fas fa-exclamation-circle" />
                                            {errors.estimate.message}
                                        </p>
                                    }
                                </div>
                            </div>
                            {/* {formwatch.estimationUrl &&
                                    <div className='file-uploaded'>
                                        <a href={formwatch.estimationUrl} target="_blank" rel="noreferrer">{formwatch.estimationUrl.split('--')[1].split('?')[0]}</a>
                                    {estimationUrl.size && <span>{getBytes(+estimationUrl.size)}</span>} 
                                    </div>
                                } */}

                            {!formwatch.estimationUrl &&
                                <Dropzone fileTypes={[".pdf"]}
                                    companyId={program!.company.id} autoProceed={false} entityId={program!.approvedQuote.id} entityType={'quote'} onComplete={(resp: any) => {
                                        setUploadCompleted(true)
                                        showToast({
                                            content: "Estimation pdf has been uploaded successfully.",
                                            duration: 3000,
                                            error: false,
                                        });
                                        refetch()
                                    }} />
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {loading ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button> :
                                <button className='btn btn-sm' type="submit" disabled={!uploadCompleted ? true : false}>Submit Estimate</button>
                            }</Modal.Footer>
                    </form>

                </Modal>
            }



        </>
    )
}

export default ProgramDetails