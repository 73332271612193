import { useState, useMemo } from "react";
import { ReturnTypes } from "@sprycore/spry-api-client";
import axios from "axios";
import CampaignSnapshot from "./CampaignSnapshot";
import { Table } from "../../../Components";
import { PrizeWinnersColumns } from "./PrizeWinnersColumns";
import { Spinner } from "react-bootstrap";
import { showToast } from "../../../Components/Toast/ToastManager";
import { spryClient } from "../../../api";
import { useQuery } from "react-query";
import { GiftCard } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type CampaignSettings = {
  brand: ReturnTypes.Brand;
  agency: ReturnTypes.Agency | null;
  campaign: ReturnTypes.Campaign;
  emailTemplates: ReturnTypes.EmailTemplate[];
  giftCardTypes: ReturnTypes.GiftCardType[];
  giftCards: ReturnTypes.GiftCard[];
  prizes: ReturnTypes.Prize[];
  prizePools: ReturnTypes.PrizePool[];
  prizePoolPrizes: ReturnTypes.PrizePoolPrize[];
  publicHostingFiles: ReturnTypes.PublicHostingFile[];
  campaignStateSchedules: ReturnTypes.CampaignStateSchedule[];
  emailTemplateContents: ReturnTypes.EmailTemplateContents[];
  campaignLanguageSettings: ReturnTypes.CampaignLanguageSetting[];
};

export function CampaignOverview(props: {
  campaignKey: string;
  allPrizes: CampaignSettings["prizes"] | undefined;
  allPrizePools: CampaignSettings["prizePools"] | undefined;
}) {
  // window.scrollTo(0, 0);
  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importstates, setImportStates] = useState(false);

  const migrateJsonElement = document.getElementById("migrateCode") as any;

  async function getExportSettings(campaignKey: string) {
    const {
      campaigns: [campaign],
    } = await spryClient.getCampaigns({ campaignKey });
    if (!campaign) {
      return null;
    }

    const {
      brands: [brand],
    } = await spryClient.getBrands({ brandKey: campaign.brandKey });
    let agency: ReturnTypes.Agency | null = null;
    if (campaign.agencyKey) {
      ({
        agencies: [agency],
      } = await spryClient.getAgencies({ agencyKey: campaign.agencyKey }));
    }
    const { emailTemplates } = await spryClient.getEmailTemplates({
      campaignKey,
    });
    const { giftCardTypes } = await spryClient.getGiftCardTypes({
      campaignKey,
    });
    const { giftCards } = await spryClient.getGiftCards({ campaignKey });
    const { prizes } = await spryClient.getPrizes({ campaignKey });
    const { prizePools } = await spryClient.getPrizePools({ campaignKey });
    const { prizePoolPrizes } = await spryClient.getPrizePoolPrizes({
      campaignKey,
    });
    const { publicHostingFiles } = await spryClient.getPublicHostingFiles({
      campaignKey,
    });
    const { campaignStateSchedules } =
      await spryClient.getCampaignStateSchedules({ campaignKey });
    const { campaignLanguageSettings } =
      await spryClient.getCampaignLanguageSettings({ campaignKey });

    const emailTemplateContents: ReturnTypes.EmailTemplateContents[] = [];
    for (let emailTemplate of emailTemplates) {
      try {
        const contents = await spryClient.loadEmailTemplateContents(
          emailTemplate
        );
        emailTemplateContents.push(contents);
      } catch (e) { }
    }

    const settings: CampaignSettings = {
      campaign,
      agency,
      brand,
      emailTemplates,
      giftCardTypes,
      giftCards,
      prizes,
      prizePools,
      prizePoolPrizes,
      publicHostingFiles,
      campaignStateSchedules,
      emailTemplateContents,
      campaignLanguageSettings,
    };
    return settings;
  }

  async function handleExport() {
    if (loading) {
      return;
    }
    setLoading(true);
    // migrateJsonElement.value = `Exporting campaign ${props.campaignKey}...`
    migrateJsonElement.value = `Exporting campaign settings...`;

    try {
      const settings = await getExportSettings(props.campaignKey);
      setLoading(false);
      migrateJsonElement.value = JSON.stringify(settings, null, 2);
    } catch (e) {
      setLoading(false);
      showToast({
        content: "Oops,api error. ",
        duration: 3000,
        error: true,
      });
    }
  }
  async function importBrand(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    if (settings.brand.brandKey === existingSettings?.brand.brandKey) {
      return;
    }
    const { brands } = await spryClient.getBrands({});
    let [brand] = brands.filter((x) => x.brandKey === settings.brand.brandKey);
    if (brand) {
      return;
    }
    await spryClient.createBrand(settings.brand);
  }

  async function importAgency(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    if (!settings.agency) {
      return;
    }
    if (settings.agency.agencyKey === existingSettings?.agency?.agencyKey) {
      return;
    }
    const { agencies } = await spryClient.getAgencies({});
    let [agency] = agencies.filter(
      (x) => x.agencyKey === settings.agency?.agencyKey
    );
    if (agency) {
      return;
    }
    await spryClient.createAgency(settings.agency);
  }

  async function importCampaign(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    if (
      settings.campaign.campaignKey === existingSettings?.campaign.campaignKey
    ) {
      await spryClient.updateCampaign(settings.campaign);
    } else {
      await spryClient.createCampaign(settings.campaign);
    }
  }

  async function importCampaignStateSchedules(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let campaignStateSchedule of settings.campaignStateSchedules) {
      if (
        existingSettings?.campaignStateSchedules.filter(
          (x) =>
            x.campaignStateScheduleKey ===
            campaignStateSchedule.campaignStateScheduleKey
        ).length
      ) {
        continue;
      }
      await spryClient.createCampaignStateSchedule(campaignStateSchedule);
    }
  }

  async function importCampaignLanguageSettings(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let campaignLanguageSetting of settings.campaignLanguageSettings) {
      if (
        existingSettings?.campaignLanguageSettings.filter(
          (x) => x.language === campaignLanguageSetting.language
        ).length
      ) {
        await spryClient.updateCampaignLanguageSettings(
          campaignLanguageSetting
        );
      } else {
        await spryClient.createCampaignLanguageSettings(
          campaignLanguageSetting
        );
      }
    }
  }

  async function importPrizes(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let prize of settings.prizes) {
      if (
        existingSettings?.prizes.filter((x) => x.prizeKey === prize.prizeKey)
          .length
      ) {
        await spryClient.updatePrize(prize);
      } else {
        await spryClient.createPrize(prize);
      }
      await spryClient.adjustPrizeInventory({
        prizeKey: prize.prizeKey,
        active: prize.inventoryActive,
        allowNegative: prize.allowNegativeInventory,
        addInventoryCount: 0, // Does not adjust inventory counts
      });
    }
  }

  async function importPrizePools(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let prizePool of settings.prizePools) {
      if (
        existingSettings?.prizePools.filter(
          (x) => x.prizePoolKey === prizePool.prizePoolKey
        ).length
      ) {
        await spryClient.updatePrizePool(prizePool);
      } else {
        await spryClient.createPrizePool(prizePool);
      }
    }
  }

  async function importPrizePoolPrizes(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let prizePoolPrize of settings.prizePoolPrizes) {
      await spryClient.addPrizeToPool(prizePoolPrize);
    }
  }

  async function importGiftCardTypes(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let giftCardType of settings.giftCardTypes) {
      if (
        existingSettings?.giftCardTypes.filter(
          (x) => x.giftCardTypeKey === giftCardType.giftCardTypeKey
        ).length
      ) {
        await spryClient.updateGiftCardType(giftCardType);
      } else {
        await spryClient.createGiftCardType(giftCardType);
      }
    }
  }

  async function importEmailTemplates(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let emailTemplate of settings.emailTemplates) {
      if (
        existingSettings?.emailTemplates.filter(
          (x) => x.emailTemplateKey === emailTemplate.emailTemplateKey
        ).length
      ) {
        await spryClient.updateEmailTemplate(emailTemplate);
      } else {
        await spryClient.createEmailTemplate(emailTemplate);
      }
    }
  }

  async function importEmailTemplateContents(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let emailTemplateContents of settings.emailTemplateContents) {
      await spryClient.saveEmailTemplateContents(emailTemplateContents);
    }
  }

  async function importPublicHostingFiles(
    settings: CampaignSettings,
    existingSettings: CampaignSettings | null
  ) {
    for (let publicHostingFile of settings.publicHostingFiles) {
      const file = await axios.get(publicHostingFile.url, {
        responseType: "arraybuffer",
      });
      const { data } = file;
      publicHostingFile.contentType = file.headers["content-type"];
      const { uploadUrl } = await spryClient.getFileUploadUrlForPublicHosting(
        publicHostingFile
      );
      await axios.put(uploadUrl, data, {
        headers: { "Content-Type": publicHostingFile.contentType },
      });
    }
  }
  async function handleImport(includeCampaignStateSchedules: boolean) {
    if (importing || importstates) {
      return;
    }
    if (includeCampaignStateSchedules) {
      setImportStates(true);
    } else {
      setImporting(true);
    }
    const text = migrateJsonElement.value as string;
    if (!text) {
      includeCampaignStateSchedules
        ? setImportStates(false)
        : setImporting(false);
      showToast({
        content: "Export settings to import.",
        duration: 3000,
        error: true,
      });
      return;
    }
    if (!window.confirm(text)) {
      return;
    }

    const settings = JSON.parse(text) as CampaignSettings;
    if (!settings.campaign) {
      throw new Error("No campaign is specified");
    }
    const existingSettings = await getExportSettings(
      settings.campaign.campaignKey
    );
    if (!window.confirm("From..." + JSON.stringify(existingSettings))) {
      return;
    }

    migrateJsonElement.value = "Importing...";
    try {
      await importBrand(settings, existingSettings);
      await importAgency(settings, existingSettings);
      await importCampaign(settings, existingSettings);
      if (includeCampaignStateSchedules) {
        await importCampaignStateSchedules(settings, existingSettings);
      }
      await importCampaignLanguageSettings(settings, existingSettings);
      await importPrizes(settings, existingSettings);
      await importPrizePools(settings, existingSettings);
      await importPrizePoolPrizes(settings, existingSettings);
      await importGiftCardTypes(settings, existingSettings);
      //await importGiftCards(settings, existingSettings)
      await importEmailTemplates(settings, existingSettings);
      await importEmailTemplateContents(settings, existingSettings);
      await importPublicHostingFiles(settings, existingSettings);
      includeCampaignStateSchedules
        ? setImportStates(false)
        : setImporting(false);
      migrateJsonElement.value = "";
      showToast({
        content: "Campaign settings are imported Successfully.",
        duration: 3000,
        error: false,
      });
    } catch (e) {
      migrateJsonElement.value = "";

      includeCampaignStateSchedules
        ? setImportStates(false)
        : setImporting(false);
      showToast({
        content: "Error while importing settings.",
        duration: 3000,
        error: true,
      });
    }
  }

  const QueryKey_prizewinners = { campaignKey: props.campaignKey };
  const getstatus = (winner: ReturnTypes.PrizeWinner) => {
    let status = winner.forfeitTime
      ? "Expired"
      : winner.fulfillTime
        ? "Fully Claimed"
        : "Unclaimed";
    return status;
  };
  const findPrizeName = (
    prizeKey: string,
    prizes: ReturnTypes.Prize[] | undefined
  ) => {
    let prize =
      prizes &&
      prizes.filter((prize) => {
        return prize.prizeKey === prizeKey;
      });
    const prizename = prize ? prize[0].prizeName : " ";
    return prizename;
  };
  let updatedParticipants;
  const getCounts = (giftcards: GiftCard[]) => {
    let assigned = 0;
    let unassigned = 0;
    giftcards.forEach((card) => {
      card.prizeWinnerKey ? assigned++ : unassigned++;
    });
    return { assigned: assigned, unassigned: unassigned };
  };

  const {
    isLoading: loadingGiftCardTypes,
    data: giftCardTypes
  } = useQuery("getGiftcardTypes", async () => {
    const res = await spryClient.getGiftCardTypes({
      campaignKey: props.campaignKey,
    });
    return res.giftCardTypes;
  });
  const {
    isLoading: loadingGiftCards,
    data: giftCards
  } = useQuery(["getGiftCards", giftCardTypes], async () => {
    const giftcards =
      giftCardTypes &&
      Promise.all(
        giftCardTypes.map(async (giftCardtype) => {
          const res = await spryClient.getGiftCards({
            campaignKey: props.campaignKey,
            giftCardTypeKey: giftCardtype.giftCardTypeKey,
          });
          const counts = getCounts(res.giftCards);
          let data = {
            ...giftCardtype,
            total: res.giftCards.length,
            assigned: counts.assigned,
            unAssigned: counts.unassigned,
          };
          return data;
        })
      );
    return giftcards;
  });

  const { isLoading: loadingwinners, data: prizewinners } = useQuery(
    ["getPrizeWinners", QueryKey_prizewinners],
    async () => {
      const res = await spryClient.getPrizeWinners(QueryKey_prizewinners);

      return res.prizeWinners;
    }
  );

  const winnersColumns = useMemo(() => PrizeWinnersColumns(), []);

  const checkPrizes = (
    sessionKey: string | null,
    allPrizes: ReturnTypes.Prize[] | undefined,
    prizewinners: ReturnTypes.PrizeWinner[] | undefined
  ) => {
    let matchedPrizes;
    const participantPrizes =
      prizewinners &&
      prizewinners.filter((record) => {
        return record.participant.sessionKey === sessionKey;
      });

    if (participantPrizes?.length) {

      const prizeslist =
        participantPrizes &&
        participantPrizes.map((prize) => {
          let prizes = findPrizeName(prize.prizeKey, allPrizes);
          let prizestatus = getstatus(prize);
          return [prizes, prizestatus];
        });
      //console.log(prizeslist)
      matchedPrizes = prizeslist;
    }
    return matchedPrizes;
  };

  const { isLoading: isLoadingparticipants, data: participants } = useQuery(
    "getParticipants",
    async () => {
      const res = await spryClient.getParticipants({ campaignKey: props.campaignKey });
      //sort participants

      return res.participants;
    }
  );

  if (participants?.length && prizewinners) {
    updatedParticipants =
      participants &&
      participants.map((record: ReturnTypes.Participant) => {
        let firstname = record.firstName
          ? record.firstName.charAt(0).toUpperCase() +
          record.firstName.slice(1).toLowerCase()
          : " ";
        let lastname = record.lastName
          ? record.lastName.charAt(0).toUpperCase() +
          record.lastName.slice(1).toLowerCase()
          : " ";
        let fullName = firstname + " " + lastname;
        let entryDate = record.creationTime
        let entryTime = record.creationTime
        let prizedata =
          props.allPrizePools &&
          prizewinners &&
          checkPrizes(record.sessionKey, props.allPrizes, prizewinners);
        let prizing =
          prizedata && prizedata.length
            ? prizedata.map((record) => record[0]).join(",")
            : "None";
        let status =
          prizedata && prizedata.length
            ? prizedata.map((record) => record[1]).join(",")
            : "N/A";
        let updatedRecord = {
          ...record,
          firstname1: firstname,
          lastname1: lastname,
          fullName: fullName,
          status: status,
          prizing: prizing,
          prizedata: prizedata,
          entryDate: entryDate,
          entryTime: entryTime,
        };

        return updatedRecord;
      });
  }

  return (
    <div className="dashboardContent campaignDetail tabsCont overview">
      <div className="head">
        <h2>Overview</h2>
      </div>
      <div id="tab03" className="tab-contents">
        <div className="leftArea">
          <h3>Campaign Snapshot</h3>
          <CampaignSnapshot
            allPrizes={props.allPrizes}
            prizeWinners={prizewinners}
            totalEntries={updatedParticipants ? updatedParticipants.length : 0}
            giftCards={giftCards}
          />
        </div>

        <div className="rigthArea">
          <div className="head"></div>
          {loadingwinners ||
            isLoadingparticipants ||
            loadingGiftCardTypes ||
            loadingGiftCards ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            updatedParticipants &&
            (updatedParticipants.length > 0 ? (
              <Table
                columns={winnersColumns}
                data={
                  updatedParticipants && updatedParticipants.length > 0
                    ? updatedParticipants
                    : []
                }
                sortbyid="entryDate"
                descending={true}
                tableTitle="Latest Entries"
                tablePageSize={20}
              />
            ) : (
              <p> There are no participant entries to display.</p>
            ))
          )}
          <div>
            <textarea
              className="text-area"
              id="migrateCode"
              readOnly={importing || importstates || loading ? true : false}
            ></textarea>
            <div className="action-btns">
              <button
                className="overview-btn"
                onClick={() => handleImport(false)}
                disabled={importing || loading || importstates ? true : false}
              >
                {importing ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ color: "white" }}
                  />
                ) : (
                  "Import"
                )}
              </button>
              <button
                className="overview-btn"
                onClick={() => handleImport(true)}
                disabled={importing || loading || importstates ? true : false}
              >
                {importstates ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ color: "white" }}
                  />
                ) : (
                  "Import (including campaign state schedules)"
                )}
              </button>

              <button
                className="overview-btn"
                onClick={() => handleExport()}
                disabled={importing || loading || importstates ? true : false}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ color: "white" }}
                  />
                ) : (
                  "Export"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
