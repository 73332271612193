import { useMemo } from 'react'
import Table from "../../../Components/CheckBoxTable";
import { Dropdown } from "react-bootstrap";

import {
  GrandPrizeDrawRanking, Prize, EmailTemplate,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type IProps = {
  campaignKey: string,
  eligibleRankings: GrandPrizeDrawRanking[],
  activePrizes: Prize[],
  setSelectedWinners: Function
  emailTemplates: EmailTemplate[] | undefined
  rejectOpen: Function
  setisOpen: Function

}
export const Winners = (props: IProps) => {

  const COLUMNS = [
    {
      Header: 'Rank',
      accessor: 'ranking',
      width: "5%",

    },

    {
      Header: 'First Name',
      accessor: 'participant.firstName',
      width: "13%",

    },
    {
      Header: 'Last Name',
      accessor: 'participant.lastName',
      width: "13%",

    },
    {
      Header: 'Language',
      accessor: 'participant.preferredLanguage',
      width: "13%",
      Cell: (tableInfo: any) => {
        const language = tableInfo.data[tableInfo.row.index].participant.preferredLanguage || "en"
        return (
          <p>{language}</p>
        );
      },

    },
    {
      Header: 'Email',
      accessor: 'participant.email',
      width: "13%",
    },
    {
      Header: 'Last Updated',
      accessor: 'creationTime',
      width: "18%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (TableInfo: any) => {
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdown-item"
                onClick={() => {
                  props.setSelectedWinners([TableInfo.data[TableInfo.row.index]])
                  props.setisOpen(true)
                }}
              >
                Assign a prize
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  props.setSelectedWinners([TableInfo.data[TableInfo.row.index]])
                  props.rejectOpen(true)
                }}
              >
                Reject
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );

      },
    },


  ]

  // eslint-disable-next-line
  const columns = useMemo(() => COLUMNS, [])
  return (
    <>
      {props.eligibleRankings && props.eligibleRankings.length > 0 ? <Table
        columns={columns}
        data={props.eligibleRankings}
        tablePageSize={10}
        setSelectedRows={props.setSelectedWinners}
        sortbyid="ranking"

      /> : <p className="table-nodata">There are no records to display.</p>}

    </>
  )
}







