import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Modal, Spinner, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";

import { spryClient } from "../../../api";
import icoview from "../../../assets/images/ico-view.png";

import { useBodyClass } from "../../../utils/hooks";
import {
  emailRegex,
  getLanguageDescription,
  passwordRegex,
  repoNameRegex,
  urlRegex,
} from "../../../utils/helpers";
import idelete from "../../../assets/images/ico-trash.png";
import { toJS } from "mobx";
import { useStores } from "../../../stores";
import { LanugageDescription } from "../../../utils/constants";
import { CampaignLanguageSetting } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type SettingForm = {
  campaignName: string;
  programCode: string;
  brandKey: string;
  agencyKey: string;
  repositoryName: string;
  languageSetting: CampaignLanguageSetting[];
  campaignPassword: string;
};

type Props = { campaignKey: string };

function CampaignSetting({ campaignKey }: Props) {
  useBodyClass("adminPg");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toggleClass, setToggleClass] = useState<boolean[]>([]);

  const [modalShow, setModalShow] = useState({
    lng: false,
    del: false,
  });

  const [lng, setLng] = useState("en");

  const { userStore } = useStores();

  let userGroup = toJS(userStore.user.role);

  const Languageoptions = () => {
    let options = [];
    for (let lang in LanugageDescription) {
      options.push(
        <option value={lang} key={lang}>
          {LanugageDescription[`${lang}`]}
        </option>
      );
    }
    return options;
  };

  const {
    isLoading: campaignLoading,
    data: campaignData,
    refetch: fetchcampaignData,
  } = useQuery(["getCampaign1", campaignKey], async () => {
    const {
      campaigns: [campaign],
    } = await spryClient.getCampaigns({ campaignKey });

    return campaign;
  });

  const { isLoading: loadingAgencies, data: agencies } = useQuery("getAgencies", () =>
    spryClient.getAgencies({})
  );
  const { isLoading: loadingBrands, data: brands } = useQuery("getBrands", () =>
    spryClient.getBrands({})
  );
  const {
    isLoading: lngSettingLoading,
    data: lngSettingData,
    refetch,
  } = useQuery(["getCampaignLanguageSettings", campaignKey], async () => {
    const { campaignLanguageSettings } = await spryClient.getCampaignLanguageSettings({
      campaignKey,
    });
    let classnames = campaignLanguageSettings.map((s) => false);
    setToggleClass(classnames);
    return campaignLanguageSettings;
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SettingForm>({ mode: "onTouched" });
  const formwatch = watch();
  useEffect(() => {
    reset({
      campaignName: campaignData?.campaignName || "",
      agencyKey: campaignData?.agencyKey || "",
      brandKey: campaignData?.brandKey,
      campaignPassword: campaignData?.campaignPassword || "",
      programCode: campaignData?.programCode,
      repositoryName: campaignData?.repositoryName || "",
      languageSetting: lngSettingData,
    });
  }, [campaignData, reset, lngSettingData]);

  const hanldeViewPassword = () => {
    document.getElementById("campaignPassword")?.setAttribute("type", "text");
  };

  const hanldeHidePassword = () => {
    document.getElementById("campaignPassword")?.setAttribute("type", "password");
  };

  const submitSettings = async (values: SettingForm) => {
    try {
      setIsSubmitting(true);
      await spryClient.updateCampaign({ ...campaignData, ...values, campaignKey });
      for (let setting of values.languageSetting) {
        let senderemailConverted = setting.emailFromAddress?.toLowerCase();
        if (lngSettingData?.find((lang) => lang.language === setting.language)) {
          await spryClient.updateCampaignLanguageSettings({
            ...setting,
            emailFromAddress: senderemailConverted,
          });
        } else {
          await spryClient.createCampaignLanguageSettings({
            ...setting,
            emailFromAddress: senderemailConverted,
          });
        }
      }
      showToast({
        content: "Campaign settings saved successfully",
        duration: 3000,
        error: false,
      });
      fetchcampaignData();
      refetch();
    } catch (e: any) {
      console.log(e.rawError);
      showToast({ content: "Oops,api error", duration: 3000, error: true });
    } finally {
      setIsSubmitting(false);
      //  window.location.reload()
    }
  };

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont setting">
        {campaignLoading || lngSettingLoading || isSubmitting ? (
          <div className="spinner">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(submitSettings)}>
            <div className="head inner">
              <h2>Settings</h2>

              {!isSubmitting ? (
                <button
                  type="submit"
                  className="btn save"
                  disabled={userGroup === "Admin" ? false : true}>
                  Save changes
                </button>
              ) : (
                <Button variant="primary" disabled>
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                  Saving...{" "}
                </Button>
              )}
            </div>
            <div id="tab01" className="tab-contents">
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div className="formContent">
                    <div className="form-group">
                      <label htmlFor="campaignName">Campaign name</label>
                      <input
                        {...register("campaignName", {
                          required: {
                            value: true,
                            message: "Please enter a campaign name.",
                          },
                        })}
                        placeholder="Campaign Name"
                        className="form-control"
                      />
                      {errors.campaignName && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          <>{errors.campaignName?.message}</>
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="programCode">Program code</label>
                      <input
                        {...register("programCode", {
                          required: {
                            value: true,
                            message: "Please enter a program code.",
                          },
                        })}
                        placeholder="Program code"
                        className="form-control"
                      />

                      {errors.programCode && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          <>{errors.programCode.message}</>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Brand</label>
                      <select
                        className="form-control"
                        {...register("brandKey", {
                          required: {
                            value: true,
                            message: "Please select an brand.",
                          },
                        })}>
                        <option value="">Please select a brand</option>
                        {loadingBrands ? (
                          <option disabled>Loading...</option>
                        ) : (
                          brands?.brands.map((brand) => (
                            <option value={brand.brandKey} key={brand.brandKey}>
                              {brand.brandName}
                            </option>
                          ))
                        )}
                      </select>
                      {errors.brandKey && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          <>{errors.brandKey?.message}</>
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Agency</label>
                      <select
                        className="form-control"
                        {...register("agencyKey", {
                          required: {
                            value: true,
                            message: "Please select an agency.",
                          },
                        })}>
                        <option value="">Please select an agency</option>

                        {loadingAgencies ? (
                          <>
                            <option disabled>loading...</option>{" "}
                          </>
                        ) : (
                          agencies?.agencies.map((agency) => (
                            <option value={agency.agencyKey} key={agency.agencyKey}>
                              {agency.agencyName}
                            </option>
                          ))
                        )}
                      </select>
                      {errors.agencyKey && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          <>{errors.agencyKey?.message}</>
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="repositoryName">Repository name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="repositoryName"
                        aria-describedby="Repository name"
                        placeholder="Repository name"
                        {...register("repositoryName", {
                          required: false,
                          pattern: {
                            value: repoNameRegex,
                            message: "Invalid repository Name",
                          },
                          maxLength: {
                            value: 28,
                            message:
                              "Repo name is too long, please keep within 28 character limit.",
                          },
                        })}
                      />
                      <span
                        className="italic-p"
                        style={{
                          padding: "3px",
                          marginBottom: "5px",
                          display: "block",
                        }}>
                        Ex: test01-testcampaign-name
                      </span>
                    </div>

                    {errors.repositoryName && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.repositoryName.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Campaign password</label>
                      <div className="passwordControl">
                        <span
                          className="viewPass"
                          onMouseDown={hanldeViewPassword}
                          onMouseUp={hanldeHidePassword}>
                          <img src={icoview} alt="View Password" />
                        </span>
                        <input
                          type="password"
                          id="campaignPassword"
                          className="form-control"
                          {...register("campaignPassword", {
                            required: {
                              value: false,
                              message: "Please enter a password.",
                            },
                            pattern: {
                              value: passwordRegex,
                              message: "Please enter a valid password.",
                            },
                          })}
                          placeholder="Campaign password"
                        />
                      </div>
                    </div>

                    {/* <CampaignPassword /> */}
                  </div>
                </div>

                <div className="col-sm-12 col-md-5">
                  <Accordion>
                    {formwatch.languageSetting &&
                      formwatch.languageSetting.length > 0 &&
                      formwatch.languageSetting.map((setting, idx) => {
                        return (
                          <div className="card mb-4" key={idx}>
                            <Accordion.Item
                              eventKey={`${idx}`}
                              as={Card.Header}
                              className={
                                toggleClass[idx]
                                  ? "accordion-header accordion-toggle collapsed"
                                  : "accordion-header accordion-toggle"
                              }
                              data-toggle="collapse"
                              aria-expanded="false"
                              onClick={(e) => {
                                let classnames = toggleClass.map((t) => false);
                                classnames[idx] = !toggleClass[idx];
                                setToggleClass(classnames);
                              }}>
                              <Accordion.Header
                                className="card-title"
                                style={{ textDecoration: "none" }}>
                                {`${getLanguageDescription(setting.language)} language settings`}
                              </Accordion.Header>
                              <Accordion.Body className={`collapse${idx}`}>
                                <div className="langContent">
                                  {" "}
                                  <div className="form-group">
                                    <label htmlFor="metadata.campaignLiveUrl">
                                      Campaign live URL
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="metadata.campaignLiveUrl"
                                      aria-describedby="emailHelp"
                                      placeholder="Campaign live URL"
                                      {...register(`languageSetting.${idx}.url`, {
                                        required: false,
                                        pattern: {
                                          value: urlRegex,
                                          message: "Please enter a correct url.",
                                        },
                                      })}
                                    />
                                    <p style={{ fontSize: "12px" }}>
                                      (Ex: google.ca, www.something.com, etc)
                                    </p>
                                    {errors.languageSetting && errors.languageSetting[idx]?.url && (
                                      <div className="error">
                                        {errors.languageSetting[idx]?.url?.message}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email sender name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`languageSetting.${idx}.emailFromName`, {
                                        required: false,
                                      })}
                                    />
                                    {errors.languageSetting &&
                                      errors.languageSetting[idx]?.emailFromName && (
                                        <div className="error">
                                          {errors.languageSetting[idx]?.emailFromName?.message}
                                        </div>
                                      )}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Sender email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`languageSetting.${idx}.emailFromAddress`, {
                                        required: false,

                                        pattern: {
                                          value: emailRegex,
                                          message: "Please enter valid email from address",
                                        },
                                      })}
                                    />

                                    {errors.languageSetting &&
                                      errors.languageSetting[idx]?.emailFromAddress && (
                                        <div className="error">
                                          {errors.languageSetting[idx]?.emailFromAddress?.message}
                                        </div>
                                      )}
                                  </div>
                                  <div className="actionBtns">
                                    <button
                                      className="btn outline"
                                      type="button"
                                      disabled={userGroup === "Admin" ? false : true}
                                      onClick={() => {
                                        setLng(setting.language);
                                        setModalShow({
                                          ...modalShow,
                                          del: true,
                                        });
                                      }}>
                                      <span>
                                        <img src={idelete} alt="delete" className="trash-img"></img>
                                        Delete
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </div>
                        );
                      })}
                  </Accordion>
                  {errors.languageSetting && (
                    <div className="error"> Please check the fields for languages added </div>
                  )}
                  <div className="actionBtns addLang">
                    <button
                      className="btn outline add"
                      type="button"
                      onClick={() => {
                        setModalShow({ ...modalShow, lng: true });
                      }}
                      disabled={userGroup === "Admin" ? false : true}>
                      Add language
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <Modal show={modalShow.lng} onHide={() => {}} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setModalShow({ ...modalShow, lng: false })}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Add new language</h3>
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Select language</label>
              <select
                className="form-control"
                name="language"
                value={lng}
                onChange={(e) => {
                  setLng(e.target.value);
                }}>
                {Languageoptions()}
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={() => {
              const origSetting = formwatch.languageSetting || [];

              //console.log('add origSetting', origSetting);

              const existSetting = origSetting.filter((v) => v.language === lng);

              if (existSetting.length > 0) {
                return;
              }

              const setting = {
                campaignKey: campaignKey,
                language: lng,
                emailFromName: "",
                emailFromAddress: "",
                url: "",
                creationTime: new Date(),
                updateTime: new Date(),
              };

              const settings = [...origSetting, setting];

              setValue("languageSetting", settings);
              setModalShow({ ...modalShow, lng: false });
            }}>
            Add
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => setModalShow({ ...modalShow, lng: false })}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalShow.del} onHide={() => {}} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setModalShow({ ...modalShow, del: false })}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Delete?</h3>
          <p>Are you sure you want to delete this language?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={() => {
              const origSetting = formwatch.languageSetting ? formwatch.languageSetting : [];

              //console.log('origSetting', origSetting);

              const existSetting = origSetting.filter(
                (v: CampaignLanguageSetting) => v.language === lng
              );

              //console.log('existSetting', existSetting);

              if (existSetting.length > 0) {
                if (existSetting[0].creationTime) {
                  spryClient
                    .deleteCampaignLanguageSettings({
                      campaignKey: existSetting[0].campaignKey,
                      language: existSetting[0].language,
                    })
                    .then((res) => {
                      //console.log('delete', res);
                      refetch();
                      showToast({
                        content: "Language settings deleted successfully.",
                        duration: 3000,
                        error: false,
                      });
                    });
                }
                const leftSetting = origSetting.filter((v) => v.language !== lng);

                //console.log('leftSetting', leftSetting);

                const newSetting = [...leftSetting];

                setValue("languageSetting", newSetting);

                setModalShow({ ...modalShow, del: false });
                setLng("en");

                return;
              }
            }}>
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => setModalShow({ ...modalShow, del: false })}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CampaignSetting;
