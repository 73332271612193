import { Link } from 'react-router-dom';
import { useStores } from "../../stores";

type Props = {
  campaignName: string
  agencyName: string
  brandName: string
  brandKey: string
  agencyKey: string
};

function CampaignDetailHead(props: Props) {
  const { filtersStore } = useStores();

  const handleagency = () => {
    filtersStore.setagencyFilter(props.agencyKey);
    filtersStore.setbrandfilter('')
  }
  const handlebrand = () => {
    filtersStore.setbrandfilter(props.brandKey);
    filtersStore.setagencyFilter('')
  }

  const { campaignName, agencyName, brandName } = props;

  return (<nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item" onClick={handleagency}><Link to={`/campaigns`}>{agencyName}</Link></li>
      <li className="breadcrumb-item" onClick={handlebrand}><Link to={`/campaigns`}>{brandName}</Link></li>
      <li className="breadcrumb-item active" aria-current="page">{campaignName}</li>
    </ol>
  </nav>
  );
}

export default CampaignDetailHead;
