import { makeAutoObservable } from "mobx";
import { IAGENCY, IBRAND } from '../interfaces';

export type CampaignType = {
  campaignName: string,
  campaignKey: string,
  brand: IBRAND,
  agency: IAGENCY

}

export class CampaignStore {

  campaign: CampaignType = {
    campaignName: '',
    campaignKey: '',
    brand: {
      brandName: '',
      brandKey: ''
    },
    agency: {
      agencyName: '',
      agencyKey: ''
    }
  }

  constructor() {
    makeAutoObservable(this);
  }

  setCampaign(info: CampaignType) {
    this.campaign = info;
  }
}