import { useState, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { NavMenu, Header } from "../../Components";
import AddBrand from "./AddBrand";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { BrandColumns } from "./BrandColumns";
import { Table } from "../../Components";
import { spryClient } from "../../api";
import { useBodyClass } from "../../utils/hooks";
import { Modal } from "../../Components/Modal";
import { showToast } from "../../Components/Toast/ToastManager";
import dayjs from 'dayjs'

const Brands = () => {
  useBodyClass("adminPg");
  const [show, setShow] = useState(false);

  const queryClient = useQueryClient();

  const [passData, setPassData] = useState({});

  const { data, isLoading } = useQuery("getBrands", async () => {
    const res = await spryClient.getBrands({});
    const updatedBrands = res.brands.map((record: any) => {
      let formattedDate = dayjs(record.updateTime).format("MM/DD/YYYY");
      record["updatedTime"] = formattedDate;
      return record;
    });
    return updatedBrands;
  });

  const handleUpdate = (data: { brandName: string; brandKey: string }) => {
    setPassData(data);
  };

  const { mutate } = useMutation(spryClient.deleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("getBrands");
      showToast({
        content: "Deleted Brand",
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      setShowModal(true);
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [statekey, setStateKey] = useState("");

  const columns = useMemo(
    () => BrandColumns({
      setShow,
      handleUpdate,
      mutate,
      setShowModal,
      setStateKey,
    }),
    // eslint-disable-next-line
    [setShow, setShowModal, setStateKey, mutate]);

  const calldelete = () => {
    mutate({ brandKey: statekey });
    // setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Modal
            calldelete={calldelete}
            handleClose={handleClose}
            notification={true}
            modalType="DeleteErrorPopUp"
            header="Delete ?"
            message="Sorry, this Brand can't be deleted since it is already associated with a Campaign."
          />
        </div>
      )}
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <NavMenu />
          <div className="col-sm-12 col-md-10">
            <Header />


            <div className="dashboardContent">
              <div className="head">
                <span
                  className="btn addbtn"
                  onClick={(e) => {
                    setShow(true);
                  }}
                >
                  Add brand
                </span>
              </div>
              <AddBrand
                showSidebar={show}
                closeHandler={setShow}
                brand={passData}
              />
              {isLoading ? (
                <div className="spinner"><Spinner animation="border" variant="secondary" /></div>
              ) : (
                data && (

                  <Table
                    columns={columns}
                    data={data && data.length > 0 ? data : []}
                    tablePageSize={10}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
