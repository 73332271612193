import { SPRYClient, setSPRYClientAxios } from "@sprycore/spry-api-client";
import axios from "axios";
import { IUser, ICampaignUser } from "../interfaces";
import { EContentType, ICMSItem, TAsset, TQuote } from "../types/portalTypes";

export const endPoint = process.env.REACT_APP_API_ENDPOINT
// export const endPoint = "http://localhost:31337"
export const spryClient = new SPRYClient(endPoint!);

setSPRYClientAxios(axios);
spryClient.setFollowContinuationTokens(true);

export const setupApiClient = (token: string) => {
  spryClient.setAuthorization(token);
};

export const getPrograms = async ({ token, id, skip, count, include, filter_companyId }: {
  token: string;
  id?: string;
  skip?: number;
  count?: number;
  include?: string[];
  filter_companyId?: string
}) => {
  const res: any = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "GET",
      url: `admin/program/${id ? ("g/" + id) : "s"}`,
      payload: {},
      searchParams: { include, skip, count, filter_companyId }
    },
  }).then((res) => res.data);
  return id ? { programs: res, total: 0 } : { programs: res[0], total: res[1] };
};
export const getCompanies = async ({ token, skip, count, include, filter_name }: {
  token: string;
  skip?: number;
  count?: number;
  include?: string[];
  filter_name?: string
}) => {

  const res: any = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "GET",
      url: `admin/company/`,
      payload: {},
      searchParams: { include, skip, count, filter_name }
    },
  }).then((res) => res.data);
  return { companies: res[0], total: res[1] };

}


export const getQuote = async ({
  token,
  id,
}: {
  token: string;
  id?: string;
}) => {
  const res: TQuote | undefined = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "GET",
      url: `admin/quote/g/${id}`,
      payload: {},
    },
  }).then((res) => res.data);
  return res;
};

export const getAssets = async ({
  token,
  id,
  entityType
}: {
  token: string;
  id?: string;
  entityType: string
}) => {
  const res: TAsset[] = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "GET",
      url: `admin/${entityType}/${id}/assets`,
      payload: {}
    },
  }).then((res) => res.data);
  return res;
};
export const deleteAsset = async ({ token, entityType, fileName, key, id, companyId }: {
  token: string;
  id: string;
  key: string;
  fileName: string;
  entityType: "program" | "quote";
  companyId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "DELETE",
      url: `admin/asset/${entityType}/${id}`,
      payload: {},
      searchParams: { filename: fileName, companyId }
    },
  }).then((res) => res.data);
  return res;
};

export const deleteTask = async ({
  token,
  id,
}: {
  token: string;
  id: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "DELETE",
      url: `admin/task/${id}`,
      payload: {},
    },
  }).then((res) => res.data);
  return res;
};

export const submitEstimate = async (data: { token: string; id: string, estimate: string, internalId: string }) => {
  const { id, estimate, token, internalId } = data;
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "POST",
      url: `admin/quote/${id}/estimate`,
      payload: { estimate: +estimate, internalId },
    },
  }).then((res) => res.data);
  return res;
};

export const getTasks = async (data: { token: string; programId: string }) => {
  const { programId, token } = data;
  const res: { tasks: any[] } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      httpMethod: "GET",
      url: `admin/program/${programId}/task/s`,
      payload: {}
    },
  }).then((res) => res.data);
  return res;
};
export const createTask = async (data: {
  token: string;
  name: string;
  description: String;
  dueDate: string;
  completed?: boolean;
  programId: string;
}) => {
  const { description, dueDate, name, programId, token } = data;
  const res: { quotes: any } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/program/${programId}/task`,
      httpMethod: "POST",
      payload: {
        name,
        description,
        dueDate,
      },
    },
  }).then((res) => res.data);
  return res;
};
export const saveTask = async (data: {
  token: string;
  name: string;
  description: String;
  dueDate: string;
  programId: string;
  id: string;
}) => {
  const { description, dueDate, name, id, token } = data;
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/task/${id}/`,
      httpMethod: "PUT",
      payload: {
        name,
        description,
        dueDate,
      },
    },
  }).then((res) => res.data);
  return res;
};
export const markComplete = async (data: { token: string; id: string }) => {
  const { id, token } = data;
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/task/${id}/`,
      httpMethod: "POST",
      payload: {}
    },
  }).then((res) => res.data);
  return res;
};
export const addLinks = async (data: { token: string; id: string, name: string, url: string }) => {
  const { id, token, name, url } = data;
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/program/${id}/env`,
      payload: { name, url, type: "0" },
      token: token!,
      httpMethod: "POST",
    },
  }).then((res) => res.data);
  return res;
};
export const CreateComment = async (data: { token: string, entityType: 'quote' | 'program', entityId: string, content: string }) => {
  const { content, entityId, entityType, token } = data;
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/${entityType}/${entityId}/comment`,
      payload: { content: content },
      token: token!,
      httpMethod: "POST",
    },
  }).then((res) => res.data);
  return res;
};

export const submitChangeRequest = async (data: { id: string, title: string, jiraLink: string, description: string, estimate: string, companyId: string, internalId: string, token: string }) => {

  const { companyId, description, estimate, id, internalId, jiraLink, title, token } = data;

  const result1 = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/program/${id}/change?companyId=${companyId}`,
      payload: { request: JSON.stringify({ title, description, jiraLink, estimate }) },
      token: token!,
      httpMethod: "POST",
    },
  }).then((res) => res.data);
  if (result1.id) {

    await axios({
      method: "post",
      headers: { authorization: token! },
      url: `${endPoint}/callSpryPortal`,
      data: {
        url: `admin/quote/${result1.id}/estimate`,
        payload: { estimate: +estimate, internalId },
        token: token!,
        httpMethod: "POST",
      },
    }).then((res) => res.data);
    if (jiraLink) {
      await axios({
        method: "post",
        headers: { authorization: token! },
        url: `${endPoint}/callSpryPortal`,
        data: {
          url: `admin/quote/${result1.id}/approve`,
          payload: {},
          token: token!,
          httpMethod: "POST",
        },
      }).then((res) => res.data);
    }

  }
  return result1
}
export const signup = async (data: IUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/signup`,
    data,
  });
  return res;
};
export const editUser = async (data: IUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/editUser`,
    data,
  });
  return res;
};

export const campaignEditUser = async (data: ICampaignUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignEditUser`,
    data,
  });
  return res;
};

export const campaignSignup = async (data: ICampaignUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignSignup`,
    data,
  });
  return res;
};

export const campaignResendPassword = async (data: {
  userName: string;
  poolId: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignResendPassword`,
    data,
  });
  return res;
};

export const resendPassword = async (data: {
  userName: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/resendPassword`,
    data,
  });
  return res;
};

export const getUserList = async (token: string) => {
  const res = await axios({
    method: "post",
    headers: { authorization: token },
    url: `${endPoint}/getUserList`,
    data: {},
  });
  return res;
};

export const campaignGetUserList = async (data: {
  poolId: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignGetUserList`,
    data,
  });
  return res;
};

export const deleteUser = async (data: { userName: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/deleteUser`,
    data,
  });
  return res;
};

export const campaignDeleteUser = async (data: {
  userName: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignDeleteUser`,
    data,
  });
  return res;
};

export const addUsertoGroup = async (data: {
  userName: string;
  role: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/addUsertoGroup`,
    data,
  });
  return res;
};

export const campaignAddUsertoGroup = async (data: {
  userName: string;
  role: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignAddUsertoGroup`,
    data,
  });
  return res;
};

export const removeFromGroup = async (data: {
  userName: string;
  role: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/removeFromGroup`,
    data,
  });
  return res;
};

export const campaignRemoveFromGroup = async (data: {
  userName: string;
  role: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignRemoveFromGroup`,
    data,
  });
  return res;
};

export const createRepos = async (data: {
  repoName: string;
  createApi?: boolean;
  createAdmin?: boolean;
  token: string;
  projectName: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/createRepo`,
    data: { repoName: data.repoName, projectName: data.projectName },
  });

  return res;
};

export const getCognitoPools = async (token: string) => {
  const res = await axios({
    method: "post",
    headers: {
      authorization: token,
    },
    url: `${endPoint}/getCognitoPools`,
  });
  return res;
};
export const cmsListTypes = async ({ token }: { token: string }) => {
  const res: [{ slug: string; title?: string; enabled: boolean }[], number] =
    await axios({
      method: "post",
      headers: { authorization: token! },
      url: `${endPoint}/callSpryPortal`,
      data: {
        url: `admin/cms/type`,
        httpMethod: "GET",
        payload: {}
      },
    }).then((res) => res.data);
  return res;
};

export const cmsGetType = async ({ token, slug }: { token: string; slug: string }) => {
  const res: { items: ICMSItem[], title: string, slug: string, id: string, enabled: boolean, content_type: EContentType } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/type/${slug}`,
      httpMethod: "GET",
      payload: {}
    },
  }).then((res) => res.data);
  return res;
};

export const cmsGetItem = async ({
  token,
  id,
}: {
  token: string;
  id: string;
}) => {
  const res: ICMSItem = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/item/${id}`,
      httpMethod: "GET",
      payload: {}
    },
  }).then((res) => res.data);
  return res;
};

export const cmsUpdateType = async (data: { token: string, oldSlug: string, enabled?: boolean, title?: string, slug?: string, content_type?: EContentType }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/type/${data.oldSlug}`,
      httpMethod: "PUT",
      payload: {
        slug: data.slug,
        enabled: data.enabled,
        title: data.title,
        content_type: data.content_type
      }
    },
  });
  return res;
};

export const cmsUpdateItem = async (data: {
  token: string;
  id: string;
  subtitle?: string;
  enabled?: boolean;
  title?: string;
  slug?: string;
  children?: any[];
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/item/${data.id}`,
      httpMethod: "PUT",
      payload: {
        slug: data.slug,
        title: data.title,
        subtitle: data.subtitle,
        enabled: data.enabled,
        children: data.children
      }
    },
  });
  return res;
};

export const cmsCreateType = async (data: { token: string, slug: string, enabled: boolean, title?: string, content_type: EContentType }) => {
  const { content_type, enabled, slug, token, title } = data
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/type`,
      httpMethod: "POST",
      payload: {
        slug: slug,
        title: title,
        enabled: enabled,
        content_type: content_type
      }
    },
  });
  return res;
};

export const cmsCreateItem = async (data: {
  token: string;
  slug: string;
  enabled: boolean;
  title?: string;
  subtitle?: string;
  type: { slug: string };
}) => {
  const { enabled, slug, token, type, subtitle, title } = data
  const res = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: `admin/cms/item`,
      httpMethod: "POST",
      payload: {
        slug,
        title: title,
        subtitle: subtitle,
        enabled: enabled,
        type: {
          slug: type.slug
        }
      }
    }
  });
  return res;
};
export const passThroughFunction = async ({
  key,
  data,
}: {
  key: string;
  data: Record<any, any> & { token: string };
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/callSpryPortal`,
    data: {
      url: "admin/s3/sign",
      httpMethod: "POST",
      payload: { ...data },
    }
  });
  return res;
};
