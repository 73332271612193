import dayjs from "dayjs";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CreateComment, getPrograms } from "../../api";
import { useForm } from "react-hook-form";
import { useStores } from "../../stores";
import { Button, Spinner } from "react-bootstrap";
import { TProgram } from "../../types/portalTypes";
import { FileInput } from "@uppy/react";
import { createUppy } from "../../Components/dropzone";
import { showToast } from "../../Components/Toast/ToastManager";
import Comment from "../../Components/Comment";

const Activity = ({ programId, active }: { programId?: string, active: boolean }) => {
  const { userStore } = useStores();
  const [message, setMessage] = useState("")
  const queryClient = useQueryClient();
  const inputRef = useRef<HTMLDivElement>(null)
  const [uploading, setUploading] = useState(false)
  const user = userStore.user
  const [filesAdded, setFilesAdded] = useState(false)
  const [activeComments, setActiveCommeents] = React.useState<string[]>([]);



  const { data: program, isLoading: programLoading, refetch: refetchProgram, isRefetching } = useQuery(
    ["getProgramActivies", programId],
    async () => {
      const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["comments", "company"] });
      return res.programs
    },
    { enabled: !!programId && !!active }
  );


  const sortedComments = useMemo(() => {
    if (!program || !program.comments) return [];
    return program.comments.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }, [program]);

  const uppy = useMemo(() => {
    return createUppy(user.token, false, [".pdf", ".PDF", ".JPG", ".JPEG", ".png", ".PNG"],1)
  }, [user])


  const reloadState = () => {

    const uppyState = uppy.getState()
    const filesSelected = Object.keys(uppyState.files).length
    if (filesSelected) {
      setFilesAdded(true)
    }
    return uppy.setOptions({
      locale: {
        strings: {
          chooseFiles: filesSelected ? `Selected (${filesSelected})` : 'Choose files',
          allowMultipleUploads:"false"
        }
      }
    })
  }


  useEffect(() => {
    uppy.on('files-added', reloadState)
    uppy.on('file-added', reloadState)
    uppy.on('file-removed', reloadState)
  }, [uppy])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ content: string }>({
    defaultValues: {
      content: "",
    },
    mode: "onTouched",
  });

  const discardComment = () => {
    setFilesAdded(false)
    uppy.getFiles().forEach((file) => uppy.removeFile(file.id))
  }


  const { mutate: adminCreateComment, isLoading: isCreatingComment } = useMutation(
    CreateComment,
    {
      onSuccess: (data) => {
        if (uppy.getFiles().length) {
          setUploading(true)
          uppy.setMeta({
            entityId: data.id,
            entityType: 'comment',
            companyId: program?.company.id
          })
          uppy.upload().then((result) => {
          
            discardComment()
            reset({ content: "" })
            uppy.setMeta({
              entityId: '',
              entityType: '',
              companyId: ""
            })
            setUploading(false)
          });
        }
        setMessage("")
      },
      onError: () => {
        showToast({
          content: "Error while uploading files",
          duration: 3000,
          error: true,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries("getProgramActivies");
      },
    }
  );

  useEffect(() => {
    inputRef.current?.focus()
    inputRef.current?.scrollIntoView({ behavior: 'smooth' })

  }, [inputRef])


  const handleCreateComment = (data: { content: string }) => {
    return adminCreateComment({ token: userStore.user.token, content: data.content, entityType: 'program', entityId: programId! })
  }
  if (programLoading || isRefetching) {
    return <div className="spinner">
      <Spinner animation="border" variant="secondary" />
    </div>
  }

  return (
    < >

      {sortedComments && sortedComments.length ? (
        <div className={'activity-container'}  >
          {sortedComments.map((a, index) =>

            <Comment key={a.id} active={activeComments.find(c => c === a.id) ? true : false} token={user.token} comment={a} />

          )}
        </div>
      ) : (
        <p style={{ textAlign: "center" }} >Currently there are no activities to display.</p>
      )}
      <form onSubmit={handleSubmit(handleCreateComment)} className="footer-message container">
        <div className="panel-actions">
          <textarea
            {...register('content', { required: { value: true, message: "Please add a comment" } })}
            className="form-control col"
            value={message}
            placeholder="Write comment..."
            onChange={(e) => { setMessage(e.currentTarget.value) }} />
          <div className={'col-span-4 flex justify-end gap-2 items-center'}>
            <FileInput uppy={uppy} />
            {filesAdded ? <i className="fas fa-trash" onClick={() => discardComment()} style={{ cursor: "pointer" }} /> : ""
            }
          </div>



          {(isCreatingComment || isRefetching) ? <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button> :
            <button className='btn btn-sm action-buttons-sm' type={'submit'}>Post</button>}

        </div>
        <div className="col-md-12">
          {errors.content &&
            <p className="error-message">
              <i className="fas fa-exclamation-circle" />
              {errors.content.message}
            </p>
          }
        </div>

      </form>
    </>
  );
};

export default Activity;
