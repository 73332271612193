import { makeAutoObservable } from "mobx";

export type programType = {
    programName: string,
    companyName: string,
}

export class ProgramStore {

    program: programType = {
        companyName: '',
        programName: ''

    }

    constructor() {
        makeAutoObservable(this);
    }

    setProgram(info: programType) {
        this.program = info;
    }
}