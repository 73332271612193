type Imodal = {
  calldelete: () => void;
  handleClose: () => void;
  notification: boolean;
  message: string;
  modalType: string;
  header: any;
};

export const Modal = (props: Imodal) => {
  const styles = {
    modal: { display: "block", opacity: 1, backgroundColor: "rgba(0,0,0,0.5)" },
    border: { border: "1px solid black" },
  } as const;

  return (
    <div
      className="modal fade warning"
      id="Modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={styles.modal}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.handleClose}
            ></button>
            <h3>{props.header}</h3>
            <h5>{props.message}</h5>
          </div>
          <div className="modal-footer">
            {(() => {
              if (props.modalType === "DeleteErrorPopUp") {
                return (
                  <button
                    type="button"
                    className="btn btn-secondary small outline"
                    data-dismiss="modal"
                    style={{ fontSize: "17px" }}
                    onClick={props.handleClose}>
                    Okay
                  </button>
                );
              } else if (
                props.modalType === "DeleteConfirmPopUp" ||
                "DeleteAllConfirmPopUp"
              ) {
                return (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary small"
                      onClick={props.calldelete}>
                      {props.modalType === "DeleteAllConfirmPopUp"
                        ? "Delete"
                        : "Yes, Delete"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary small outline"
                      data-dismiss="modal"
                      onClick={props.calldelete}>
                      Cancel
                    </button>
                  </>
                );
              } else if (props.modalType === "ResendConfirmPopUp") {
                return (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary small"
                      onClick={props.calldelete}>
                      Resend
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary small outline"
                      data-dismiss="modal"
                      onClick={props.calldelete}>
                      Cancel
                    </button>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
