import idelete from '../../../assets/images/ico-trash.png';

export const PrizesColumns = (fns: {
  setshowdeletePrizeModal: React.Dispatch<React.SetStateAction<boolean>>,
  setdeletePrizeKey: React.Dispatch<any>
}) => {
  const styles = {
    button: {
      border: "none",
      backgroundColor: "transparent"
    },
  } as const;

  const COLUMNS = [
    {
      Header: 'Prize',
      accessor: 'prizeName',
      width: "30%",
    },
    {
      Header: '',
      accessor: 'prizeKey',
      width: "70%",

      Cell: (TableInfo: any) => {
        // const campaignStateScheduleKey = TableInfo.data[TableInfo.row.index].campaignStateScheduleKey;

        return <button type='button' className="delete-Button" style={styles.button} onClick={e => {
          fns.setdeletePrizeKey(TableInfo.data[TableInfo.row.index].prizeKey)
          fns.setshowdeletePrizeModal(true)
        }}
        ><div className="deleteimg"><img src={idelete} className="trash-img" alt="delete"></img></div> </button>
      }
    }

  ]
  return COLUMNS
}



