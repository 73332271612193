import React, { useEffect } from 'react'

const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage, currentPage, setCurrentPage }: { pageChangeHandler: Function, totalRows: number, rowsPerPage: number, currentPage: number, setCurrentPage: Function }) => {

    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    const pagesArr = [...new Array(noOfPages || 1)];

    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

    useEffect(() => {
        pageChangeHandler(currentPage);
    }, [currentPage,pageChangeHandler]);

    return (
        <>
            {noOfPages > 1 ? (
                <div className='paginationCont'>
                    <div className="pagination">
                        {/* <div className="page-item"> */}
                        <button
                            className="page-link"
                            onClick={onPrevPage}
                            disabled={currentPage === 1 ? true : false}
                        >
                            &#8249;
                        </button>
                        {pagesArr.map((num, index) => (
                            <button
                                key={index}
                                onClick={() => onPageSelect(index + 1)}
                                className={`page-link  ${index + 1 === currentPage ? "active" : ""
                                    }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="page-link"
                            onClick={onNextPage}
                            disabled={noOfPages === currentPage ? true : false}
                        >
                            &#8250;
                        </button>
                    </div>
                    {/* </div> */}
                </div>
            ) : null}
        </>
    )
}


export default Pagination