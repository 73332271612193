import React, { useMemo } from 'react'
import { FormBuilder } from './RenderForm/form_builder';
import { ADDITIONALFields, CommunicationFields, MECHANICS, PROGRAM_MODULES, PROOF_OF_PURCHASE, Quote_Program_modules, ReportingFields, UPLOADS, overviewFormFields } from './questions';
import * as z from 'zod';
import { DynamicFieldData, TProgram } from '../../types/portalTypes';
import { byKey } from '../../utils/helpers';
import ProgramModule from './ProgramModule';
import { useQuery } from 'react-query';
import { getPrograms, getQuote } from '../../api';
import { useStores } from '../../stores';
import { Spinner } from 'react-bootstrap';

const QuoteForm = ({ type, quoteId, filter, programId }: { quoteId: string | undefined, type?: string, filter?: string, programId: string }) => {

    const { userStore } = useStores();

    const { data: program, isLoading: programLoading } = useQuery(
        ["getProgramfeatures", programId],
        async () => {
            const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["answers", "features", "company"] });
            return res.programs
        },
        { enabled: !!programId }
    );
    const { data: quote, isLoading: quoteLoading } = useQuery(["getQuoteSelected", quoteId], async () => {
        const res = await getQuote({ token: userStore.user.token, id: quoteId });
        return res;
    }, { enabled: !!programId && !!quoteId });

    const featuresByType = useMemo(() => {
        if (quote) {
            return byKey(quote.features, (f) => {
                return f.type;
            })
        }
        else {
            return {}
        }
    }, [quote])

    const getPrivacyAddr = (answers: any[]) => {
        let addr = {
            line1: '',
            line2: '',
            city: '',
            country: '',
            state: '',
            postal_code: '',
        };
        const mailing = answers.find(
            (v: any) => v.questionLabel === 'mailing_address',
        )?.value



        if (mailing) {
            addr = JSON.parse(mailing);
        }
        return addr


    };

    // function dateFormat(date: { dateString?: string; dateFormat?: string }) {
    //     const { dateString, dateFormat } = date;

    //     let format = dateFormat ?? 'MMM D, YYYY - h:mm A';

    //     if (dateString && format) {
    //         return dayjs(dateString).format(format);
    //     }

    //     if (dateString) {
    //         return dayjs(dateString).format(format);
    //     }

    //     return dayjs().format(format);
    // }

    const getProgramExecute = (answers: any[]) => {

        const program_execute = answers.find(
            (v: any) => v.questionLabel === 'program_execute',
        )?.value;
        if (program_execute) {
            const executes = JSON.parse(program_execute);
            return executes;
        }
        return []
    };

    const getTimeFromString = (time: string | undefined) => {

        if (!time) {
            return ""
        }

        if (+time.split(":")[0] > 12) {
            return (+time.split(":")[0] - 12) + ":" + time.split(":")[1] + " pm"
        }
        else {
            return time + " am"
        }
    }
    // const getProgram_modules_others = (other: any, name: string) => {
    //     if (other) {
    //         const otherdetails = JSON.parse(other)
    //         return otherdetails[`${name}`]
    //     }
    //     else {
    //         return ""
    //     }
    // }
    const configure_Program_modules = useMemo(() => {

        if (program) {

            const program_modules = byKey(PROGRAM_MODULES, (k) => k.value);
            const proof_of_purchases = byKey(PROOF_OF_PURCHASE, (k) => k.value);
            const uploads = byKey(UPLOADS, (k) => k.value);
            const mechanics = byKey(MECHANICS, (k) => k.value);

            return program.features.map((f) => {

                let configure = null
                switch (f.type) {
                    case "0":
                        configure = program_modules[f.name]

                        break;
                    case "1":
                        configure = proof_of_purchases[f.name];
                        break;
                    case "2":
                        configure = mechanics[f.name]
                        break;
                    case "3":
                        configure = uploads[f.name];
                }
                return {
                    ...f,
                    configure: configure ? configure[0] : {},
                    answer: program.answers.find(a => a.questionLabel === f.name)?.value
                }
            })
        }
    }, [program])


    const render = (program_features: any[]) => {
        const modules = [], proof = [], mechanics = [], uploads = [];

        for (let i = 0; i < program_features.length; i++) {
            if (program_features[i].type === "0") {
                program_features[i].configure && modules.push(program_features[i])
            }
            if (program_features[i].type === "1") {
                program_features[i].configure && proof.push(program_features[i])
            }
            if (program_features[i].type === "2") {
                program_features[i].configure && mechanics.push(program_features[i])
            }
            if (program_features[i].type === "3") {
                program_features[i].configure && uploads.push(program_features[i])
            }
        }

        function generateSchema(configure: any) {

            let schema: { [keys: string]: DynamicFieldData } = {}


            if (configure?.group) {


                let group: any[] = []

                // CHECK IF GROUP HAS ARRAYS AND FLATTEN

                if (Array.isArray(configure.group[0])) {
                    configure.group[0].map(g => group.push(g))
                }
                else {
                    group = configure.group ?? []
                }

                for (let question of group) {
                    schema[question.name] = { ...question, status: "" }
                }

            }
            return schema

        }


        return < div>
            {modules.length > 0 && <>

                <h3>Program modules</h3>
                {
                    modules.filter(f => f.configure).map(f =>

                        <ProgramModule
                            title={f.configure.name}
                            description={f.description}
                            configure={f.configure}
                            defaultValues={defaultValues}
                            key={f.configure.name}
                            schema={generateSchema(f.configure)}

                        />
                    )
                }
            </>
            }
            {proof.length > 0 && <h3 >Proof of purchase</h3>}
            {
                proof.filter(f => f.configure).map(f => <ProgramModule
                    title={f.configure.name}
                    description={f.description}
                    configure={f.configure}
                    defaultValues={defaultValues}
                    schema={generateSchema(f.configure)}


                />)
            }
            {mechanics.length > 0 && <h3 >Engagement mechanics</h3>}
            {
                mechanics.filter(f => f.configure).map(f => <ProgramModule
                    title={f.configure.name}
                    description={f.description}
                    configure={f.configure}
                    defaultValues={defaultValues}
                    schema={generateSchema(f.configure)}
                />)
            }
            {uploads.length > 0 && <h3 >Image/video upload</h3>}
            {
                uploads.length ? uploads.filter(f => f.configure).map(f => <ProgramModule
                    title={f.configure.name}
                    description={f.description}
                    configure={f.configure}
                    defaultValues={defaultValues}
                    schema={generateSchema(f.configure)}
                />) : "N/A"
            }
        </div>
    }

    const getValueFromJsonString = (value: string | undefined) => {
        if (value) {
            return JSON.parse(value)
        }
        else {
            return {}
        }

    }

    const FormSchema = z.object({
        internalId: z.string().optional(),
        program_name: z.string(),
        data_capture: z.any(),
        event: z.any(),
        contest: z.any(),
        giveaway: z.any(),
        upc_code: z.any(),
        receipts: z.any(),
        pin_codes: z.any(),
        image: z.any(),
        video: z.any(),
        branded_frame: z.any(),
        user_select_frame: z.any(),
        card_matching: z.any(),
        plinko: z.any(),
        quiz_test: z.any(),
        quiz_trivia: z.any(),
        quiz_trivia_video: z.any(),
        survey: z.any(),
        scavenger_hunt: z.any(),
        scrambled_image_puzzle: z.any(),
        scratch: z.any(),
        search_image: z.any(),
        spin_wheel: z.any(),
        flick_hockey: z.any(),
        flick_basketball: z.any(),
        flick_soccer: z.any(),
        flick_football: z.any(),
        flick_golf: z.any(),
        flick_beer_pong: z.any(),
        tap_to_reveal: z.any(),

    });



    const formFields = (inputFields: { [key: string]: DynamicFieldData; }) => {

        let fields: { [key: string]: DynamicFieldData } = {}

        if (type === "program") {
            fields = inputFields
        }
        else {
            for (let a in inputFields) {
                if (inputFields[a].status === "QUOTE") {
                    fields[a] = inputFields[a]
                }
            }
        }
        return fields;
    }

    const defaultValues = useMemo(() => {
        if (!program || !quote) return {}

        const de: { [key: string]: any } = {
            program_name: quote ? quote.program_name : '',
            program_brand: quote && quote.program_brand ? quote.program_brand.name : overviewFormFields["program_brand"].default,
            current_address:
                quote && quote.current_address
                    ? quote.current_address
                    : {
                        line1: '',
                        line2: '',
                        city: '',
                        country: '',
                        state: '',
                        postal_code: '',
                    },
            mailing_address: quote
                ? getPrivacyAddr(quote.answers)
                : {
                    line1: '',
                    line2: '',
                    city: '',
                    country: '',
                    state: '',
                    postal_code: '',
                },
            description:
                quote && quote.program_description ? quote.program_description : '',
            privacy_dept: program
                ? program.answers.find((v) => v.questionLabel === 'privacy_dept')?.value
                : '',
            privacy_url: program
                ? program.answers.find((v) => v.questionLabel === 'privacy_url')?.value
                : '',
            privacy_email: program
                ? program.answers.find((v) => v.questionLabel === 'privacy_email')?.value
                : '',
            language: quote
                ? quote.answers.find((v) => v.questionLabel === 'language')?.value
                : '',
            other_language: quote.answers.find((v) => v.questionLabel === 'other_language')?.value,
            program_execute: program ? getProgramExecute(program.answers) : [],
            program_execute_vanity_url: program.answers.find(
                (v) => v.questionLabel === 'program_execute_vanity_url',
            )?.value || '',
            program_execute_vanity_url_title: program.answers.find(
                (v) => v.questionLabel === 'program_execute_vanity_url_title',
            )?.value || '',
            program_execute_brand_website_url: program.answers.find(
                (v) => v.questionLabel === 'program_execute_brand_website_url',
            )?.value || '',
            other_program_execute: program.answers.find(
                (v) => v.questionLabel === 'other_program_execute',
            )?.value || '',
            other_url_provider:
                program.answers.find(
                    (v) => v.questionLabel === 'other_url_provider',
                )?.value || '',

            microsite_url: program
                ? program.answers.find((v) => v.questionLabel === 'microsite_url')?.value
                : [],
            start_date: quote
                ? quote.answers.find((v) => v.questionLabel === 'start_date')?.value
                : '',
            start_time: quote
                ? getTimeFromString(quote.answers.find((v) => v.questionLabel === 'start_time')?.value)
                : '',
            end_time: program
                ? program.answers.find((v) => v.questionLabel === 'end_time')?.value
                : '',

            end_date: program
                ? program.answers.find((v) => v.questionLabel === 'end_date')?.value
                : undefined,
            qa_date: program
                ? program.answers.find((v) => v.questionLabel === 'qa_date')?.value
                : undefined,
            training_date: program
                ? program.answers.find((v) => v.questionLabel === 'training_date')?.value
                : undefined,
            device: quote
                ? (quote.answers.find((v) => v.questionLabel === 'device')?.value)
                : [],

            communicate: quote
                ? quote.answers.find((v) => v.questionLabel === 'communicate')?.value
                // ? getValueFromJsonString(quote.answers.find((v) => v.questionLabel === 'communicate')?.value??"")
                : [],
            access: quote
                ? quote.answers.find((v) => v.questionLabel === 'access')?.value
                : '',
            other_access: quote ? quote.answers.find((v) => v.questionLabel === 'other_access')?.value
                : '',
            promoted: quote
                ? quote.answers.find((v) => v.questionLabel === 'promoted')?.value
                : '',
            other_promoted: quote
                ? quote.answers.find((v) => v.questionLabel === 'other_promoted')?.value
                : '',
            deploy_region: quote
                ? quote.answers.find((v) => v.questionLabel === 'deploy_region')?.value
                : '',
            deploy_locations: quote
                ? quote.answers.find((v) => v.questionLabel === 'deploy_locations')?.value
                : '',
            numberoflocation: quote
                ? quote.answers.find((v) => v.questionLabel === 'numberoflocation')?.value
                : '',
            internet_type: quote
                ? quote.answers.find((v) => v.questionLabel === 'internet_type')?.value
                : '',
            anticipate_number: quote
                ? quote.answers.find((v) => v.questionLabel === 'anticipate_number')
                    ?.value
                : '',
            pay_require: program
                ? program.answers.find((v) => v.questionLabel === 'pay_require')?.value
                : '',
            internet_access: program
                ? program.answers.find((v) => v.questionLabel === 'internet_access')
                    ?.value
                : '',
            days_in_market: quote
                ? quote.answers.find((v) => v.questionLabel === 'days_in_market')?.value
                : '',
            program_date_type: quote
                ? quote.answers.find((v) => v.questionLabel === 'program_date_type')
                    ?.value
                : '',
            numberofemails: quote
                ? quote.answers.find((v) => v.questionLabel === 'numberofemails')
                    ?.value
                : '',
            numberofsms: quote
                ? quote.answers.find((v) => v.questionLabel === 'numberofsms')
                    ?.value
                : '',
            integration: quote
                ? quote.answers.find((v) => v.questionLabel === 'integration')
                    ?.value
                : '',
            integration_detail: quote
                ? quote.answers.find((v) => v.questionLabel === 'integration_detail')
                    ?.value
                : '',
            report_description: quote
                ? quote.answers.find((v) => v.questionLabel === 'report_description')
                    ?.value
                : '',
            google_analytics: quote
                ? quote.answers.find((v) => v.questionLabel === 'google_analytics')
                    ?.value
                : '',
            legal_service: quote
                ? quote.answers.find((v) => v.questionLabel === 'legal_service')
                    ?.value
                : '',
            design_service: quote
                ? quote.answers.find((v) => v.questionLabel === 'design_service')
                    ?.value
                : '',
            translate_service: quote
                ? quote.answers.find((v) => v.questionLabel === 'translate_service')
                    ?.value
                : '',
            program_modules: featuresByType["0"]?.map(v => v.name),
            modules_idea: quote.features.find(f => f.name === "custom_modules_idea")?.description,

            proof_of_purchase: featuresByType["1"]?.map(v => v.name),
            proof_idea: quote.features.find(f => f.name.includes("custom_proof_idea"))?.description,
            mechanics: featuresByType["2"]?.map(v => v.name),
            mechanics_idea: quote.features.find(f => f.name.includes("custom_mechanics_idea"))?.description,
            giveaway: getValueFromJsonString(program.answers.find(a => a.questionLabel === "giveaway")?.value),
            upload: featuresByType["3"]?.map(v => v.name),
            custom_upload_idea: quote.features.find(f => f.name.includes("custom_upload_idea"))?.description,
            data_capture: getValueFromJsonString(program.answers.find(a => a.questionLabel === "data_capture")?.value),
            event: getValueFromJsonString(program.answers.find(a => a.questionLabel === "event")?.value),
            // event_total_event: program.answers.find(a => a.questionLabel === "event_total_event")?.value,
            // event_event_checkin: program.answers.find(a => a.questionLabel === "event_event_checkin")?.value,
            // event_event_registration: program.answers.find(a => a.questionLabel === "event_event_registration")?.value,

            receipts: getValueFromJsonString(program.answers.find(a => a.questionLabel === "receipts")?.value),
            upc_code: getValueFromJsonString(program.answers.find(a => a.questionLabel === "upc_code")?.value),
            quiz_trivia: getValueFromJsonString(program.answers.find(a => a.questionLabel === "quiz_trivia")?.value),
            contest: getValueFromJsonString(program.answers.find(a => a.questionLabel === "contest")?.value),

            email_subject: program
                ? program.answers.find((v) => v.questionLabel === 'email_subject')
                    ?.value
                : '',
            email_from: program
                ? program.answers.find((v) => v.questionLabel === 'email_from')
                    ?.value
                : '',
            email_body: program
                ? program.answers.find((v) => v.questionLabel === 'email_body')
                    ?.value
                : '',
            email_addr_share: program ? program.answers.find((v) => v.questionLabel === 'email_addr_share')
                ?.value
                : '',
            email_offer: program ? program.answers.find((v) => v.questionLabel === 'email_offer')
                ?.value
                : '',
            translate_copy: program.answers.find(
                (q) => q.questionLabel === "translate_copy",
            )?.value,
            creative_assets: program.answers.find(
                (q) => q.questionLabel === "creative_assets"
            )?.value,
            quebec_responsible: program.answers.find(
                (q) => q.questionLabel === "quebec_responsible"
            )?.value,
            rules_responsible: program.answers.find(
                (q) => q.questionLabel === "rules_responsible"
            )?.value,
            pin_codes: getValueFromJsonString(program.answers.find(a => a.questionLabel === "pin_codes")?.value),
            // upc_code_upc_get: program.answers.find(a => a.questionLabel === "upc_code_upc_get")?.value,
            // upc_code_how_many: program.answers.find(a => a.questionLabel === "upc_code_how_many")?.value

        };
        for (let module of PROGRAM_MODULES) {

            for (let group of module.group) {
                let q = group as any
                de[q.name] = program.answers.find(a => a.questionLabel === q.name)?.value
            }
        }

        for (let module of PROOF_OF_PURCHASE) {

            if (module?.group) {
                let group: any[] = []

                if (Array.isArray(module.group[0])) {
                    module.group[0].map(g => group.push(g))
                }
                else {
                    group = module.group ?? []
                }

                for (let q of group) {
                    de[q.name] = program.answers.find(a => a.questionLabel === q.name)?.value
                }
            }
        }
        for (let module of MECHANICS) {

            for (let group of module.group) {
                let q = group as any
                de[q.name] = program.answers.find(a => a.questionLabel === q.name)?.value
            }
        }

        return de


    }, [program, quote, featuresByType])


    return (
        <>
            {(programLoading || quoteLoading) ? <div className="spinner">
                <Spinner animation="border" variant="secondary" />
            </div> : <>
                {(!filter || filter === "basic_config") && <div>
                    {type === "program" && <h3>Program Overview</h3>}

                    <FormBuilder
                        schema={formFields(overviewFormFields)}
                        validation={FormSchema}
                        defaultValues={{
                            ...defaultValues
                        }}
                        submitHandler={() => { }}
                    /> </div>}
                {!filter ? <div>
                    <h3>Program Configuration</h3>
                    <FormBuilder
                        schema={Quote_Program_modules}
                        validation={FormSchema}
                        defaultValues={{
                            ...defaultValues
                        }}
                        submitHandler={() => { }}
                    />
                </div> : ((filter === "program_config" && configure_Program_modules) && <>{render(configure_Program_modules)} </>)
                }

                {(!filter || filter === "communication") && <div>

                    <h3>Communication</h3>
                    <FormBuilder
                        schema={formFields(CommunicationFields)}
                        validation={FormSchema}
                        defaultValues={{
                            ...defaultValues
                        }}
                        submitHandler={() => { }}
                    />
                    <h3>Data & Reporting</h3>
                    <FormBuilder
                        schema={formFields(ReportingFields)}
                        validation={FormSchema}
                        defaultValues={{
                            ...defaultValues
                        }}
                        submitHandler={() => { }}
                    />
                    <h3>Additional Services</h3>
                    <FormBuilder
                        schema={formFields(ADDITIONALFields)}
                        validation={FormSchema}
                        defaultValues={{
                            ...defaultValues
                        }}
                        submitHandler={() => { }}
                    />




                </div>
                }
            </>
            }
        </>

    );
}

export default QuoteForm