import idelete from '../../../assets/images/ico-trash.png';
import { copyToClipboard } from '../../../utils/helpers';


export const StateColumns = (fns: {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setStateKey: any
}) => {
    const styles = {
        button: {
            border: "none",
            backgroundColor: "transparent"
        },
    } as const;

    const COLUMNS = [
        {
            Header: 'State',
            accessor: 'state',
            width: "8%",
        },
        {
            Header: 'Start Date',
            accessor: 'startDate',
            width: "10%",

        },
        {
            Header: 'Start Time',
            accessor: 'startTime',
            width: "10%",

        },
        {
            Header: 'Meta Data',
            accessor: 'metadata',
            width: "20%"
        },
        {
            Header: '',
            accessor: 'metadataCopy',
            width: "8%",
            Cell: (TableInfo: any) => {if(TableInfo.data[TableInfo.row.index].metadataCopy){        
                 return <span className="btn copy outline" onClick={e => {
                    const metaCopy = TableInfo.data[TableInfo.row.index].metadataCopy;
                    copyToClipboard(metaCopy);
                }}>Copy</span>} else <p></p>
            }

        },
        {
            Header: '',
            accessor: 'campaignStateScheduleKey',
            width: "5%",

            Cell: (TableInfo: any) => {
                const campaignStateScheduleKey = TableInfo.data[TableInfo.row.index].campaignStateScheduleKey;

                return <button type='button' className="delete-Button" style={styles.button} onClick={() => {
                    fns.setShowModal(true)
                    fns.setStateKey(campaignStateScheduleKey)
                }}
                ><span><img src={idelete} alt="delete" className="trash-img"></img></span> </button>
            }
        }

    ]
    return COLUMNS
}



