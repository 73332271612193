import { NavigateFunction } from "react-router-dom";

type Props = {
  selectedPage: number
  navigate: NavigateFunction
  campaignKey: string
}

export function ParticipantColumns({ selectedPage, navigate, campaignKey }: Props) {
  const styles = {
    button: {
      border: "none",
      backgroundColor: "transparent",
      color: "blue"
    },
  } as const;

  const COLUMNS = [
    {
      Header: "Last Action",
      accessor: "lastAction",
      width: "15%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: "Full Name",
      accessor: "fullName",
      width: "20%",
      Cell: (tableInfo: any) => {
        return (

          <button type='button' className="delete-Button" style={styles.button} onClick={e => {

            navigate(`/campaign/${campaignKey}/participants/${tableInfo.data[tableInfo.row.index].sessionKey}`, { state: { page: selectedPage } })
          }}

          > {tableInfo.data[tableInfo.row.index].fullName}</button>

        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      width: "25%",
      Cell: (tableInfo: any) => {
        return (
          <button type='button' className="delete-Button" style={styles.button} onClick={e => {

            navigate(`/campaign/${campaignKey}/participants/${tableInfo.data[tableInfo.row.index].sessionKey}`)
          }}
          > {tableInfo.data[tableInfo.row.index].email ? tableInfo.data[tableInfo.row.index].email : "N/A"}</button>

        );
      },
    },
    {
      Header: "Prizing",
      accessor: "prizing",
      width: "20%",
      Cell: (tableInfo: any) => {
        const prizing = tableInfo.data[tableInfo.row.index].prizing.split(',')
        const display = prizing.length > 0 && prizing.map((prize: string) => {
          return <p style={{ fontSize: "14px" }} key={prize}>{prize} </p>
        })
        return display
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "20%",

      Cell: (tableInfo: any) => {
        const status = tableInfo.data[tableInfo.row.index].status.split(',')
        const display = status.length > 0 && status.map((status:string ,index:number) => {
          return <p style={{ fontSize: "14px" }} key={index}>{status} </p>
        })
        return display
      },

    },
  ];
  return COLUMNS;
};
