import './Footer.css';

export const Footer = (): JSX.Element => {

    return <div className="footer">
        <FooterCopy />

    </div>

}

export const FooterCopy = (): JSX.Element => {

    return <p className="cpyTxt">&copy; 2023 Wily Global Inc.</p>;

}

