import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { Spinner } from "react-bootstrap";
import { Table, Modal } from "../../../Components";
import { EmailTemplateColumns } from "./EmailTemplateColumns";
import { spryClient } from "../../../api";

type Props = { campaignKey: string };

function CampaignEmail({ campaignKey }: Props) {
  const [showModal, setShowModal] = useState(false);

  const [emailTemplateKey, setEmailTemplateKey] = useState("");

  const queryClient = useQueryClient();

  const [status, setStatus] = useState({ error: "", success: "" });

  const { isLoading, data } = useQuery(
    ["getEmailTemplates", campaignKey],
    async () => {
      const resp = await spryClient.getEmailTemplates({ campaignKey });
      return resp.emailTemplates;
    }
  );

  const { mutate: mutateDelete } = useMutation(spryClient.deleteEmailTemplate, {
    onError: () => {
      setStatus({ ...status, error: "Error: please try again", success: "" });
    },
    onSettled: () => {
      queryClient.invalidateQueries("getEmailTemplates");
    },
  });

  const handleDelete = () => {
    mutateDelete({ emailTemplateKey });

    setShowModal(false);
  };

  const columns = useMemo(() => {
    const calldelete = () => {
      setShowModal(true);
    };
    return EmailTemplateColumns({ setEmailTemplateKey, calldelete });
  }, [setEmailTemplateKey]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont">
        <div id="tab07" className="tab-contents">
          <div className="head inner">
            <h3>Email Templates</h3>
            {status.error && <h1 className="error-message"> {status.error}</h1>}
            <Link to="../addemailtemplate" className="btn">
              Add Email Template
            </Link>
          </div>
          <div className="dataGrid mediaTab">
            {isLoading ? (
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <Table
                columns={columns}
                data={data ? data : []}
                tablePageSize={15}
                sortbyid="No"
                descending={false}
              />
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div className="row no-gutters">
          <Modal
            notification={false}
            calldelete={handleDelete}
            handleClose={handleClose}
            modalType="DeleteConfirmPopUp"
            header="Delete ?"
            message="Are you sure you want to delete this template?"
          />
        </div>
      )}
    </>
  );
}

export default CampaignEmail;
