import { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { spryClient } from "../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  agency?: any;
};

const AddAgency = ({ showSidebar, closeHandler, agency }: AppProps) => {
  const queryClient = useQueryClient();

  const [agencyName, setAgencyName] = useState("");

  const { mutate: updMutate, isLoading: isLoadingUpd } = useMutation(
    spryClient.updateAgency,
    {
      onSuccess: (data) => {
        setAgencyName("");
        closeHandler(false);
        showToast({
          content: "Updated Agency",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "Agency already exists",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getAgencies");
      },
    }
  );

  const { mutate, isLoading } = useMutation(spryClient.createAgency, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAgencies");
      closeHandler(false);
      setAgencyName("");
      showToast({
        content: "Added Agency",
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      showToast({
        content: "Agency already exists",
        duration: 3000,
        error: true,
      });
    },
  });

  useEffect(() => {
    if (agency && agency.agencyName) {
      setAgencyName(agency.agencyName);
    }
  }, [agency]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAgencyName(value)
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        {agency && agencyName && agency.agencyKey ? (
          <h2>Edit agency</h2>
        ) : (
          <h2>Add agency</h2>
        )}
        <span
          className="closeSideBar"
          onClick={(e) => {
            setAgencyName("");
            closeHandler(false);
          }}
        >
          <i className="fas fa-times"></i>
        </span>
        <form>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">Agency name</label>
              <input
                type="text"
                className="form-control"
                name="agency"
                id="agency"
                aria-describedby="Agency Name"
                placeholder="Agency name"
                value={agencyName}
                onChange={(e) => handleInput(e)}
              />
            </div>
            <div className="actionBtns">
              {isLoading || isLoadingUpd ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {/* <span className="visually-hidden">Loading...</span> */}
                </Button>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    if (agency && agency.agencyKey) {
                      updMutate({
                        agencyKey: agency.agencyKey,
                        agencyName: agencyName,
                      });
                      agency.agencyKey = undefined;
                    } else {
                      mutate({ agencyName });
                    }
                  }}
                >
                  {agency && agency.agencyKey ? "Update" : "Add"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default AddAgency;
