import { Button, Spinner } from "react-bootstrap";
import { editUser } from "../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  addUsertoAdmin: Function;
  refetch: any;
  userEmail: string;
  userRole: string;
  userFirstName: string;
  userLastName: string;
};

type EditFormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: string;
};

const EditAdmin = ({
  showSidebar,
  closeHandler,
  refetch,
  userEmail,
  userRole,
  userFirstName,
  userLastName,
}: AppProps) => {
  const { userStore } = useStores();
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
    getValues,
    setValue,
    resetField,
  } = useForm<EditFormData>({ mode: "all" });
  const queryClient = useQueryClient();
  const { mutate: editAdminUser, isLoading: isLoadingEdit } = useMutation(
    editUser,
    {
      onSuccess: (data) => {
        refetch();
        closeHandler(false);
        showToast({
          content: "User has been updated successfully.",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "User already exists.",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getUserList");
      },
    }
  );

  useEffect(() => {
    setValue("firstName", userFirstName);
    setValue("lastName", userLastName);
    setValue("role", userRole);
  }, [userFirstName, userLastName, userRole, setValue]);

  const handleEdit = (values: any) => {
    values.token = userStore.user.token;
    values.email = userEmail;
    values.firstName = dirtyFields.firstName
      ? getValues("firstName")
      : userFirstName!;
    values.lastName = dirtyFields.lastName
      ? getValues("lastName")
      : userLastName;
    values.role = dirtyFields.role ? getValues("role") : userRole;
    editAdminUser(values);
    resetField("firstName");
    resetField("lastName");
    resetField("role");
    setValue("firstName", userFirstName);
    setValue("lastName", userLastName);
    setValue("role", userRole);
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Edit User</h2>
        <span
          className="closeSideBar"
          onClick={() => {
            closeHandler(false);
            setValue("firstName", userFirstName);
            setValue("lastName", userLastName);
            setValue("role", userRole);
          }}
        >
          <i className="fas fa-times"></i>
        </span>
        <form onSubmit={handleSubmit(handleEdit)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">First name *</label>
              <input
                type="text"
                className="form-control"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "Please enter a first name.",
                  },
                })}
                defaultValue={userFirstName}
                placeholder="First name"
              />
              {errors.firstName && !getValues("firstName") && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  {errors.firstName.message}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Last name *</label>
              <input
                type="text"
                className="form-control"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Please enter a last name.",
                  },
                })}
                defaultValue={userLastName}
                placeholder="Last name"
              />
              {errors.lastName && !getValues("lastName") && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  {errors.lastName.message}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Email *</label>
              <input
                type="text"
                className="form-control"
                {...register("email")}
                value={userEmail}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="agency">Role *</label>
              <select
                className="form-control"
                {...register("role", {
                  required: {
                    value: true,
                    message: "Please select a role.",
                  },
                })}
              >
                <option value="">Select a role</option>
                <option value="Admin">Admin</option>
                <option value="Standard">Standard</option>
              </select>

              {errors.role && !getValues("role") && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />{" "}
                  {errors.role.message}
                </p>
              )}
            </div>

            <div className="actionBtns">
              {isLoadingEdit ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button
                  type="submit"
                  className="btn"
                  disabled={Object.keys(dirtyFields).length > 0 ? false : true}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default EditAdmin;
