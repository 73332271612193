import {useStores} from "../../stores";
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {cmsGetItem, cmsGetType, cmsListTypes, cmsUpdateItem} from "../../api";
import _ from 'lodash'
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import {showToast} from "../../Components/Toast/ToastManager";
import {Link} from "react-router-dom";
import {Dropdown, Modal} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import {EContentType, ICMSItem} from "../../types/portalTypes";
import {CreateItemDialog} from "./Items";
import {DraftEditorCommand, EditorState, Editor, RichUtils, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const getDraftFromHTML = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState)
}

const ParsedContentInput = ({type, defaultValue}: {type: EContentType | undefined, defaultValue?:string}) => {
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );
    const { control, register, setValue } = useFormContext();

    useEffect(() => {
        if (editorState) {
            if (editorState.getLastChangeType()) {
                const rawContentState = convertToRaw(editorState.getCurrentContent());
                const markup = draftToHtml(rawContentState);
                setValue('subtitle', markup)
            }
        }
    },[editorState])

    useEffect(() => {
        if (defaultValue) {
            const state = getDraftFromHTML(defaultValue)
            setEditorState(state)
        }
    },[defaultValue])

    const onStyleClick = (style: string) => () => setEditorState(RichUtils.toggleInlineStyle(editorState, style));

    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    switch (type) {
        case EContentType.markdown:
            return <div className={'border rounded p-2 mt-2'}>
                <div className={'grid grid-cols-6 gap-2 mb-2'}>
                    <button className={'border rounded font-bold'} onClick={onStyleClick('BOLD')}>Bold</button>
                    <button className={'border rounded italic'} onClick={onStyleClick('ITALIC')}>Italic</button>
                    <button className={'border rounded underline'} onClick={onStyleClick('UNDERLINE')}>Underline</button>
                </div>
                <Controller
                    name={'subtitle'}
                    control={control}
                    render={() => (
                        <Editor handleKeyCommand={handleKeyCommand} editorState={editorState} onChange={setEditorState} />
                    )}
                />
            </div>
        case EContentType.video:
            return <>
                <p>Vimeo Video ID</p>
                <input
                    {...register("subtitle")}
                    placeholder="Subtitle"
                    className="form-control"
                />
            </>
        case EContentType.document:
            return <>
                <p>Document URL</p>
                <input
                    {...register("subtitle")}
                    placeholder="Subtitle"
                    className="form-control"
                />
            </>
        case EContentType.image:
            return <>
                <p>Image URL</p>
                <input
                    {...register("subtitle")}
                    placeholder="Subtitle"
                    className="form-control"
                />
            </>
        case EContentType.text:
            return <>
                <p>Short Text</p>
                <input
                    {...register("subtitle")}
                    placeholder="Subtitle"
                    className="form-control"
                />
            </>
        default:
        case EContentType.other:
            return <textarea
                {...register("subtitle")}
                placeholder="Content (Other)"
                className="form-control"
            />
    }
}

type FormType = {
    title: string;
    subtitle: string;
    slug: string;
    enabled: boolean;
};


const DynamicField = ({type, item}:{type: any, item: any}) => {
    return <div className="row mt-2">
        <div className="col-sm-6 col-md-12">
            <div className="form-group">
                <ParsedContentInput defaultValue={item?.subtitle} type={type?.content_type || item?.type?.content_type} />
            </div>
        </div>
    </div>
}

export const ItemForm = ({className, typeId, itemId}:{className?: string, typeId?:string, itemId?: string}) => {
    const {userStore} = useStores();
    const { data: type } = useQuery(`cmsGetType-${typeId}`, async () => {
        if (!typeId) return
        return cmsGetType({token: userStore.user.token, slug: typeId || ''});
    });
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        if (!itemId) return
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
    } = useFormContext();

    useEffect(() => {
        if (item?.slug) {
            setValue('slug', item.slug)
            setValue('title', item.title)
            setValue('subtitle', item.subtitle)
            setValue('enabled', item.enabled)
        }
    }, [setValue, item])

    const genSlug = () => {
        setValue('slug', uuidv4())
    }

    return <form className={className} onSubmit={handleSubmit(() => {})}>
        <div className="mt-2">
            <div className="row">
                <div className="col-sm-6 col-md-12">
                    <div className="form-group">
                        <div className={'flex justify-content-between'}>
                            <label htmlFor="">
                                Slug
                            </label>
                            <Link onClick={genSlug} to={'#'}>Generate</Link>
                        </div>
                        <input
                            {...register("slug", {
                                required: {
                                    value: true,
                                    message: "Please Enter a slug",
                                },
                            })}
                            placeholder="Slug (unique)"
                            className="form-control"
                        />

                        {/*{errors.slug ? <div className="error">*/}
                        {/*    <i className="fas fa-exclamation-circle"/>*/}
                        {/*    {errors.slug.message}*/}
                        {/*</div> : null}*/}
                    </div>
                    <div className={'text-muted'}>Used by the API to identify different
                        items, required and unique.
                    </div>
                    <div className="italic-p">Example: blog-001</div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">
                            Title
                        </label>
                        <input
                            {...register("title")}
                            placeholder={"Title"}
                            className="form-control"
                        />

                        {/*{errors.title ? <div className="error">*/}
                        {/*    <i className="fas fa-exclamation-circle"/>*/}
                        {/*    {errors.title.message || 'Error'}*/}
                        {/*</div> : null}*/}
                    </div>
                    <div className={'text-muted'}>Title for the item, used by the frontend
                    </div>
                    <div className="italic-p">Example: "WILY Blog Post 2023"</div>
                </div>
            </div>
            <DynamicField type={type} item={item} />

            <div className="form-group toggleBtn">
                <p>Enabled</p>
                <div onClick={() => setValue('enabled', !getValues('enabled'))}
                     className="swtichCont">
                    <label className="switch" htmlFor="checkbox1">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            {...register("enabled", {required: false})}
                        />

                        <div className="slider round"></div>
                    </label>
                </div>
            </div>
        </div>
    </form>
}

const AddConnectionDialog = ({open, setOpen, onSelect}:{open: boolean, setOpen: any, onSelect:any}) => {
    const {userStore} = useStores();
    const { data: types } = useQuery("cmsListTypes", async () => {
        return cmsListTypes({token: userStore.user.token});
    });
    const {itemId} = useParams<{ itemId: string }>();
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const handleAddType = (slug: string) => () => {
        onSelect(slug)
        setOpen(false)
    }
    return <Modal show={open} onHide={()=>setOpen(false)}>
        <Modal.Header>
            <Modal.Title>Add Connection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{maxHeight: 300, overflow:'auto'}}>
                <p className={'text-muted mb-4'}>Select a content type to connect to</p>
                {useMemo(()=>!types ? [] : types[0].filter(e=>item?.type.slug !== e.slug),[]).map(e=>{
                    return <div className={'cursor-pointer text-lg mb-2 w-full border-black border border-dashed p-2 rounded'} onClick={handleAddType(e.slug)}>{e.title ?? e.slug}</div>
                })}
            </div>
        </Modal.Body>
    </Modal>
}

const TypeDropdown = ({slug, selectedItems, onSelect, onAddNew,onDelete}:{slug: string, onDelete: any, selectedItems: Partial<ICMSItem>[], onSelect: any, onAddNew:any}) => {
    const { userStore } = useStores();
    const { data: type } = useQuery(`cmsGetType-${slug}`, async () => {
        return cmsGetType({token: userStore.user.token, slug});
    });
    if (!type) return null
    return <div>
        <p className={'text-lg font-bold mb-2'}>{type.title}</p>
        <div className={'border-l border-l-slate-300 pl-8'}>
            <Dropdown>
                <Dropdown.Toggle className={'text-gray-400 w-full'}>
                    Select
                </Dropdown.Toggle>
                <Dropdown.Menu className={'w-full'}>
                    {type.items.map((item) => <Dropdown.Item className={'mb-2'} onClick={() => onSelect(item)}>
                        <i className={selectedItems.map(e=>e.id).includes(item.id) ? "fas fa-check": "fas fa-circle"}/> {item.title}
                    </Dropdown.Item>)}
                    <Dropdown.Item onClick={() => onAddNew(type)}><i className="fas fa-plus-circle"/> Add new {type.title}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className={'grid grid-cols-3 mb-2 gap-2'}>
                {selectedItems.map((item) => <div onClick={() => onDelete(item)} className={'cursor-pointer rounded-full col-span-1 px-4 py-2 bg-slate-300 text-black flex'}>{item.title}</div>)}
            </div>
        </div>
    </div>
}

const ItemContainer = () => {
    const {userStore} = useStores();
    const queryClient = useQueryClient();
    const {itemId} = useParams<{ itemId: string }>();
    const [isLinking, setIsLinking] = useState(false)
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const {mutate: updateItem} = useMutation(
        cmsUpdateItem,
        {
            onSuccess: () => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(`cmsGetItem-${itemId}`);
            },
        }
    );
    const [types, setTypes] = useState<string[]>([])
    const [items, setItems] = useState<ICMSItem[]>([])
    const [creating, setCreating] = useState<string | undefined>()

    const childrenSlugs = useMemo(() => {
        return items.map(e => ({slug: e.slug}))
    }, [items])

    const form = useForm<any>({
        defaultValues: {
            title: "",
            subtitle: "",
            slug: "",
            enabled: false,
        },
        mode: "onTouched",
    });

    const onSubmit = useCallback((data: FormType) => {
        if (!itemId) return
        // if (itemId === '*') return createType({token: userStore.user.token, title: data.title, enabled: data.enabled, slug: data.slug})
        return updateItem({
            token: userStore.user.token,
            id: itemId,
            enabled: data.enabled,
            slug: data.slug,
            title: data.title,
            subtitle: data.subtitle,
            children: childrenSlugs
        })
    }, [updateItem, userStore.user.token, itemId, childrenSlugs])

    useEffect(() => {
        if (item) {
            setTypes(_.uniq(item.children.map(e=>e.type.slug)))
            setItems(item.children)
        }
    },[item])

    const handleAddConnectionType = (slug: string) => {
        if (types.includes(slug)) return handleDelConnectionType(slug)
        return setTypes((t)=>[...t, slug])
    }

    const handleAddConnectionItem = useCallback((item: ICMSItem) => {
        if (items.map(e=>e.id).includes(item.id)) return handleDelConnectionItem(item)
        return setItems((t)=>[...t, item])
    },[items])

    const handleDelConnectionType = (slug: string) => {
        if (types.includes(slug)) return setTypes((t)=> t.filter(e=> e !== slug))
    }
    const handleDelConnectionItem = useCallback((item: ICMSItem) => {
        if (items.map(e=>e.id).includes(item.id)) return setItems((t)=> t.filter(e=> e.id !== item.id))
    },[items])

    if (!itemId || !item) return <div/>

    const editing = (itemId !== '*' && !!item)
    return <>
        <CreateItemDialog _typeId={creating} open={!!creating} setOpen={setCreating}/>
        <AddConnectionDialog setOpen={setIsLinking} open={isLinking} onSelect={handleAddConnectionType}/>
        <div className={'grid grid-cols-12 border-b pb-2 pt-2'}>
            <Link className='col-span-12' to={`/cms/${item.type.slug}`}><i className="fas fa-arrow-left"></i>  Back to {item?.type?.title ?? item?.slug}</Link>
            <div className={'col-span-3 flex items-center'}>
                <p className={'font-bold'}>Item: {item.title}</p>
            </div>
            <div className={'col-span-7'}></div>
            <div className={'col-span-2 flex justify-end'}>
                <button className={'btn py-2 px-4 mb-0'} onClick={form.handleSubmit(onSubmit)}>Save</button>
            </div>
        </div>
        {editing ? <div className={'container flex justify-center'}>
            <div>
                <FormProvider {...form}>
                    <ItemForm itemId={itemId} />
                </FormProvider>
                {types.map((type) => (
                    <TypeDropdown onAddNew={(t:{slug: string}) => setCreating(t.slug)} onDelete={handleDelConnectionItem} onSelect={handleAddConnectionItem} selectedItems={items.filter(e=>e.type.slug === type)} slug={type}/>
                ))}
                <button onClick={() => setIsLinking(true)} className={'p-2 bg-white border text-lg w-full rounded'}>Add connection</button>
            </div>
        </div> : null}
    </>
}

export default ItemContainer