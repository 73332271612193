import { useFieldArray } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from "./RenderForm/formelements";


function PrizeDetails({
	name,
	grandPrize,
	label,
}: {
	name: string;
	label: string;
	grandPrize?: boolean;
}) {
	const { fields, append, remove } = useFieldArray({
		name: `${name}`,
	});

	return (
		<div className="">
			<FormLabel className='font-bold'>{label}</FormLabel>
			<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
				{fields.map((item, index) => {
					return (
						<>
							<div className='row' key={item.id}>
								<FormField
									name={`${name}.${index}.prize_name`}
									render={({ field }) => {
										return (
											<FormItem>
												<FormLabel className="font-semibold">Prize {index + 1}</FormLabel>
												<FormControl>
													<Input {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
								<FormField
									name={`${name}.${index}.quantity`}
									render={({ field }) => {
										return (
											<FormItem className='w-2/12'>
												<FormLabel className="font-semibold">Quantity</FormLabel>
												<FormControl>
													<Input {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										);
									}}
								/>
								<FormField
									name={`${name}.${index}.retail_value`}
									render={({ field }) => {
										return (
											<FormItem className='w-3/12'>
												<FormLabel className="font-semibold">Retail value</FormLabel>
												<FormControl>
													<div className='flex'>
														<Input {...field} />


													</div>
												</FormControl>

												<FormMessage />
											</FormItem>
										);
									}}
								/>
							</div>
							{grandPrize && (
								<FormItem className='grid grid-cols-12 gap-2'>
									<div className='flex flex-col space-y-2 basis-4/6 col-span-6 lg:col-span-9'>
										<FormField
											name={`${name}.${index}.winner_process`}
											render={({ field }) => {
												return (
													<>
														<FormLabel className="font-bold">Winner selection process</FormLabel>

														<Input {...field} />

													</>
												);
											}}
										/>
									</div>
								</FormItem>
							)}

							<FormItem className='grid grid-cols-12 gap-2'>
								<div className='flex flex-col space-y-2 basis-4/6 col-span-6 lg:col-span-9'>
									<FormField
										name={`${name}.${index}.grand_total`}
										render={({ field }) => {
											return (
												<>
													<FormLabel className='font-bold'>
														Total retail value of all prizes combined
													</FormLabel>
													<Input
														placeholder={''}
														{...field}
													//disabled={disabled || false}
													//value={field.value === undefined ? '' : field.value}
													/>
												</>
											);
										}}
									/>
								</div>
							</FormItem>
						</>
					);
				})}
			</div>
		</div>
	);
}

export { PrizeDetails };
