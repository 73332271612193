import Ballpark from './Ballpark';
import ProgramDetails from './ProgramDetails';

const Quote = ({ id, programStatus, quoteStatus, programId, companyId, backHandler }: { companyId: string; id: string; programId: string; programStatus: string | number; quoteStatus: string, backHandler: Function }) => {


    if (!id || !programStatus || !quoteStatus) {
        return <div>No details available to display</div>
    }

    const status = `${programStatus}${quoteStatus}`

    return (<>

        {(+status > 20 && +status !== 23) ? <ProgramDetails programId={programId} backHandler={() => backHandler()} status={`${programStatus}${quoteStatus}`} /> : <Ballpark company={companyId} programId={programId}
            quoteId={id} backHandler={() => backHandler()} status={`${programStatus}${quoteStatus}`}
        />}

    </>

    )
}

export default Quote