import { useQuery, useQueryClient } from "react-query"
import { spryClient } from "../../../api"
import { Table } from "../../../Components"
import { Spinner } from "react-bootstrap"
import { useState } from "react"
import { generateKey } from "@sprycore/spry-api-client"
import { EmailBatch } from "@sprycore/spry-api-client/dist/ReturnTypes"
import { useNavigate } from "react-router-dom"
import { showToast } from "../../../Components/Toast/ToastManager"
import { AddBulkParticipantDataArgs } from "@sprycore/spry-api-client/dist/MainDbCallTypes"

type CampaignEmailBatchesProps = {
    campaignKey: string
}

const DefaultScheduledTime = new Date(new Date().getFullYear() + 20, 0, 1)

export default function CampaignEmailBatches({ campaignKey }: CampaignEmailBatchesProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [newName, setNewName] = useState("")
    const [newTemplate, setNewTemplate] = useState("")
    const [transactional, setTransactional] = useState(true)
    const [addError, setAddError] = useState("")
    const [adding, setAdding] = useState(false)

    const emailBatchesQuery = useQuery(
        ["getEmailBatches", campaignKey],
        async () => {
            const { emailBatches } = await spryClient.getEmailBatches({ campaignKey })
            return emailBatches
        })
    const emailTemplatesQuery = useQuery(
        ["getEmailTemplates", campaignKey],
        async () => {
            const { emailTemplates } = await spryClient.getEmailTemplates({ campaignKey })
            return emailTemplates
        })

    async function addEmailBatch() {
        if (!newName || !newTemplate || adding) { return }
        setAddError("")
        setAdding(true)
        try {
            const emailBatchKey = generateKey()
            await spryClient.createEmailBatch({ emailTemplateKey: newTemplate, name: newName, transactional, emailBatchKey, scheduledTime: DefaultScheduledTime })
            navigate(`/campaign/${campaignKey}/email-batches/${emailBatchKey}`)
            setNewName("")
        }
        catch {
            setAddError("Error adding new emailBatch")
        }
        finally {
            setAdding(false)
            queryClient.invalidateQueries("getEmailBatches")
        }
    }
    async function cancelEmailBatch(emailBatchKey: string) {
        if (!emailBatchKey) { return }
        if (!window.confirm("Cancel?")) { return }
        try {
            await spryClient.cancelEmailBatch({ emailBatchKey })
        }
        catch {
            showToast({ content: "Error cancelling batch", duration: 3000, error: true })
        }
        finally {
            queryClient.invalidateQueries("getEmailBatches")
        }
    }

    const loading = emailBatchesQuery.isLoading || emailTemplatesQuery.isLoading

    const columns = [
        {
            Header: "Name",
            Cell: (table: any) => {
                const row = table.row.original as EmailBatch
                return <div>{row.name}</div>
            }
        },
        {
            Header: "Template",
            Cell: (table: any) => {
                const row = table.row.original as EmailBatch
                const template = emailTemplatesQuery.data?.find(x => x.emailTemplateKey === row.emailTemplateKey)
                return <div>[{template?.language}] {template?.emailTemplateName}</div>
            }
        },
        {
            Header: "Scheduled",
            Cell: (table: any) => {
                const row = table.row.original as EmailBatch
                return <div>{row.scheduledTime.toLocaleString()}</div>
            }
        },
        {
            Header: "Status",
            Cell: (table: any) => {
                const row = table.row.original as EmailBatch
                let statuses = []
                if (row.cancelled) { statuses.push("Cancelled") }
                if (row.triggered) { statuses.push("Triggered") }
                if (!statuses.length) { statuses.push("Scheduled") }
                return <div>{statuses.join(", ")}</div>
            }
        },
        {
            Header: "Options",
            Cell: (table: any) => {
                const row = table.row.original as EmailBatch
                return <div>
                    <button className="btn action-buttons-sm mx-1" onClick={() => navigate(`/campaign/${row.campaignKey}/email-batches/${row.emailBatchKey}`)}>Edit</button>
                    {!row.cancelled && !row.triggered && <button className="btn action-buttons-sm mx-1" onClick={() => navigate(`/campaign/${row.campaignKey}/email-batches/${row.emailBatchKey}/targets`)}>Targets</button>}
                    {row.triggered && <button className="btn action-buttons-sm mx-1" onClick={() => navigate(`/campaign/${row.campaignKey}/email-batches/${row.emailBatchKey}/report`)}>Send Report</button>}
                    {!row.cancelled && <button className="btn action-buttons-sm mx-1" onClick={() => cancelEmailBatch(row.emailBatchKey)}>Cancel</button>}
                </div>
            }
        }
    ]

    async function addTestParticipants() {
        const participants: AddBulkParticipantDataArgs["participants"] = []
        let count = 0
        for (let x = 0; x < 3; ++x) {
            participants.push({ sessionKey: generateKey(), firstName: `first-${++count}`, email: `ooto+${x}@simulator.amazonses.com` })
        }
        for (let x = 0; x < 17; ++x) {
            participants.push({ sessionKey: generateKey(), firstName: `first-${++count}`, email: `suppressionlist+${x}@simulator.amazonses.com` })
        }
        for (let x = 0; x < 20; ++x) {
            participants.push({ sessionKey: generateKey(), firstName: `first-${++count}`, email: `complaint+${x}@simulator.amazonses.com` })
        }
        for (let x = 0; x < 560; ++x) {
            participants.push({ sessionKey: generateKey(), firstName: `first-${++count}`, email: `bounce+${x}@simulator.amazonses.com` })
        }
        for (let x = 0; x < 9400; ++x) {
            participants.push({ sessionKey: generateKey(), firstName: `first-${++count}`, email: `success+${x}@simulator.amazonses.com` })
        }
        await spryClient.addBulkParticipantData({
            campaignKey,
            tags: ["test-email-batches"],
            participants
        })
    }

    const [showAddTestParticipants] = useState(false)

    return <>
        <div className="dashboardContent campaignDetail tabsCont emailBatches">
            <div className="head inner">
                <h2>Email Batches</h2>
            </div>
            {showAddTestParticipants && <div>
                <button className="btn" onClick={addTestParticipants}>Add Test Participants</button>
            </div>}
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {!loading && <>
                    <Table columns={columns} data={emailBatchesQuery.data} tablePageSize={15} sortbyid="no" />
                </>}
            </div>
            {!loading && !!emailTemplatesQuery.data?.length && <>
                <div className="tab-contents">
                    <input className="form-control col-4" maxLength={50} value={newName} onChange={e => setNewName(e.target.value)} placeholder="New Email Batch Name" /><br />
                    <select className="form-control col-4"  value={newTemplate} onChange={e => setNewTemplate(e.target.value)}>
                        <option value="">[Select an email template]</option>
                        {emailTemplatesQuery.data.map(x => <option key={x.emailTemplateKey} value={x.emailTemplateKey}>[{x.language}] {x.emailTemplateName}</option>)}
                    </select>
                    <div className="form-row">
                    <div className="col-auto my-3 ml-1">
                    <input className="form-check-input" type="checkbox" checked={transactional} onChange={e => setTransactional(!!e.target.checked)} id="transactional" />
                    </div>  
                    <div className="col-auto my-3">
                    <label htmlFor="transactional" className="form-check-label ml-1">Transactional</label>
                    </div>
                    </div>
                    
                    <button className="btn" onClick={addEmailBatch} disabled={!newName || !newTemplate || adding}>Add New Email Batch</button>
                    <span>{addError}</span>
                </div>
            </>}
        </div>
    </>
}