import React, { useEffect } from 'react';
import { useTable, usePagination, useRowSelect, useGlobalFilter, useSortBy } from 'react-table';
import GlobalFilter from '../GlobalFilter';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
type Props = {
  columns: any;
  data: any;
  disabledSort?: boolean;
  tablePageSize: number;
  setSelectedRows: Function;
  sortbyid?: string;
  tableTitle?: string;

  //pageCount: any
};
const Table = ({
  columns,
  data,
  disabledSort,
  tablePageSize,
  setSelectedRows,
  sortbyid,
  tableTitle

}: Props) => {
  const maxPageShow = 6;

  const tableInstance = useTable(
    {
      columns,
      data,
      //initialState: { pageIndex: 0 }
      disableSortBy: disabledSort,

      initialState: {
        sortBy: [
          {
            id: sortbyid ? sortbyid : "",
            desc: false
          }
        ],
        pageSize: tablePageSize,


        // pageIndex: window.sessionStorage.getItem("page")?Number(window.sessionStorage.getItem("page")):0,
        hiddenColumns: ["instantPrizeTimestampKey"]
      }
    },
    useGlobalFilter,
    useSortBy,

    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          disableSortBy: true,
          canSort: false,
          width: "3%",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns

      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // // which has only the rows for the active page

    // // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { pageIndex, globalFilter },
    setGlobalFilter
  } = tableInstance;

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map(
      d => d.original
    ))
  }, [selectedFlatRows, setSelectedRows])




  const caculateShowPage = (pgcnt: any, pgidx: any) => {
    const totalPages = [...Array(pgcnt)].map((_, i) => i);

    if (pgidx + maxPageShow > totalPages.length) {
      return totalPages.slice(maxPageShow * -1);
    } else {
      return totalPages.slice(pgidx, pgidx + maxPageShow);
    }
  };

  return (
    <>
      {
        (((tableTitle === "Recent Participants") || (tableTitle === "Latest entries") || (tableTitle === "Winning timestamps")) || (tableTitle === "Subscribers")) &&

        <div className="searchCont">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} tableheader="" />
        </div>}

      <div className="dataGrid">
        <table className="table" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      // Apply the header cell props

                      return (
                        <th
                          scope="col"
                          {...column.getHeaderProps(column.getSortByToggleProps({
                            title: undefined,

                            style: {
                              width: column.width,
                            },
                          }))}

                        >
                          {
                            // Render the header
                            column.render("Header")
                          }
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <i className="fas fa-angle-down" style={{ paddingLeft: "5px" }}></i>
                                : <i className="fas fa-angle-up" style={{ paddingLeft: "5px" }}></i>
                              : ''}
                          </span>
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props

                        return (
                          <td {...cell.getCellProps()} className={data[cell.row.index].expired ? 'text-danger' : ''}>
                            {// Render the cell contents
                              cell.render('Cell')}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
          </tbody>

        </table>
        {data.length > tablePageSize && (
          <div className="paginationCont">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="Previous"
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    <span aria-hidden="true">&lt;</span>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                {pageIndex > 0 &&
                  pageIndex + maxPageShow <= pageOptions.length ? (
                  <>
                    <li className="page-item ">
                      <span
                        className="page-link"
                        onClick={() => gotoPage(0)}
                      >
                        1
                      </span>
                    </li>
                    <li className="page-item ">
                      <span className="page-link">...</span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {caculateShowPage(pageCount, pageIndex).map(function (page) {
                  return (
                    <li
                      className={
                        page === pageIndex ? "page-item active" : "page-item"
                      }
                      key={page}
                      onClick={() => gotoPage(page)}
                    >
                      <span className="page-link">
                        {page + 1}
                      </span>
                    </li>
                  );
                })}
                {pageIndex < pageCount - maxPageShow ? (
                  <>
                    <li className="page-item ">
                      <span className="page-link">...</span>
                    </li>
                    <li className="page-item ">
                      <span
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                      >
                        {pageCount}
                      </span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={nextPage}
                    aria-label="Next"
                    disabled={!canNextPage}
                  >
                    <span aria-hidden="true">&gt;</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>

  )


}

export default Table;