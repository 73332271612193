import React, { useState } from 'react'
import { TProgram } from '../../types/portalTypes'
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { addLinks, getPrograms } from '../../api';
import { useStores } from '../../stores';
import dayjs from 'dayjs';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { showToast } from '../../Components/Toast/ToastManager';

type Linkform = {
    url: string
    name: string
}

const Overview = ({ programId, active }: { programId: string, active: boolean }) => {

    const { userStore } = useStores();
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm<Linkform>({ mode: "onChange", defaultValues: { url: "", name: "" } })

    const { data: program, isLoading: programLoading, refetch, isRefetching } = useQuery(
        ["getOverview", programId],
        async () => {
            const res: { programs: TProgram } = await getPrograms({
                token: userStore.user.token, id: programId,
                include: ["approvedQuote", "environments"]
            });
            if (+res.programs.status >= 1)
                return res.programs
        },
        { enabled: !!programId && !!active }
    )

    const addLink = async (data: Linkform) => {

        if (loading) {
            return
        }
        setLoading(true)
        try {
            const res = await addLinks({ name: data.name, url: data.url, token: userStore.user.token, id: programId })

            if (res.id) {
                setIsOpen(false)
                reset({ name: "", url: "" })
                showToast({
                    content: `${data.name} has been added`,
                    duration: 3000,
                    error: false,
                });
                refetch()

            }
        }
        catch (e) {
            showToast({
                content: `Error while adding the url`,
                duration: 3000,
                error: true,
            });
        }
        setLoading(false)
    }
    if (programLoading || isRefetching) {
        return <div className="spinner">
            <Spinner animation="border" variant="secondary" />
        </div>
    }

    return (<>

        <div id="tab11" className="tab-contents">
            {(program && +program.status === 3) ? <>
                < div className='flex' style={{ flexWrap: "wrap" }}>
                    {/* <div className='col-md-4'>
                        <div className='form-group'>

                            <label style={{ width: "100%" }}>Brand</label>
                            <p>{program?.approvedQuote.program_name}</p>
                        </div>
                    </div> */}


                    <div className='col-sm-12 col-md-6'>
                        <div className='form-group'>

                            <label style={{ width: "100%" }}>Launch Date</label>
                            <p>{dayjs(program.start_date).format('YYYY/MM/DD')}</p>
                        </div>

                    </div>

                    <div className='col-sm-12 col-md-6'>
                        <div className='form-group'>

                            <label style={{ width: "100%" }}>Close Date</label>
                            <p>{dayjs(program.end_date).format('YYYY/MM/DD')}</p>

                        </div>

                    </div>

                    {program.environments?.map(l =>
                        <div className=' col-sm-12 col-md-6' key={l.id}>
                            <div className='form-group'>

                                <label style={{ width: "100%" }}>{l.name}</label>
                                <a href={l.url} target="_blank" rel="noreferrer" className='linkButton'>{l.url}</a>

                            </div>

                        </div>)}


                </div>
                <div>
                    {+program.status === 3 &&
                        <button className='btn add' onClick={() => setIsOpen(true)}>Add a link</button>}


                </div>

            </> : <p style={{ textAlign: "center" }}>
                There are no details available to display.</p>}

        </div>
        <Modal show={!!isOpen} onHide={() => setIsOpen(false)} centered>
            <form onSubmit={handleSubmit(addLink)} id="estimate">

                <Modal.Body>
                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">

                                <>
                                    <label style={{ width: "100%" }} htmlFor="name">Name</label>

                                    <input
                                        className="form-control"
                                        {...register("name", { required: { value: true, message: "Please add a name for url." } })}
                                    />
                                </>
                            </div>
                            {errors.name &&
                                <p className="error">
                                    <i className="fas fa-exclamation-circle" />
                                    {errors.name.message}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                                <label style={{ width: "100%" }} htmlFor="program_name">Url</label>

                                <input
                                    className="form-control"
                                    {...register("url", { required: { value: true, message: "Please add a url." } })}
                                />
                            </div>
                            {errors.url &&
                                <p className="error">
                                    <i className="fas fa-exclamation-circle" />
                                    {errors.url.message}
                                </p>
                            }
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {loading ?
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button> :
                        <button className='btn btn-sm' type="submit" >Add link</button>
                    }</Modal.Footer>
            </form>

        </Modal>

    </>
    )
}

export default Overview