import {useStores} from "../../stores";
import { useQuery,} from "react-query";
import {cmsGetType,} from "../../api";
import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {ManageCMSTypeDialog} from "../../Components/ManageCMSTypeDialog";
import {TypeSelectedLayout} from "./TypeSelectedLayout";
import {CreateItemDialog} from "./Items";
import {dateFormatHelper} from "../../utils/helpers";

export const CMSTypeItemsTable = () => {
    const {typeId} = useParams<{typeId?: string}>()
    const { userStore } = useStores();
    const { data: type } = useQuery(`cmsGetType-${typeId}`, async () => {
        if (!typeId) return
        if (typeId === '*') return
        return cmsGetType({token: userStore.user.token, slug: typeId || ''});
    });
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)
    return type ? <div>
        <CreateItemDialog open={creating} setOpen={setCreating}/>
        <ManageCMSTypeDialog existingEntityId={typeId} open={editing} setOpen={setEditing} />
        <div className={'grid grid-cols-12 border-b pb-2 pt-2'}>
            <div className={'col-span-3 flex items-center'}>
                <p className={'font-bold'}>{type.title}</p>
            </div>
            <div className={'col-span-7'}></div>
            <div className={'col-span-2 flex justify-end gap-2'}>
                <button onClick={() => setEditing(true)} className={'btn py-2 px-2 mb-0'}>Edit</button>
                <button onClick={() => setCreating(true)} className={'btn py-2 px-2 mb-0'}>Add Item</button>
            </div>
        </div>
        <div className={'flex justify-between border-b pb-2 pt-2'}>
            <div className={'col-span-4 flex items-center'}>
                <p className={'font-extralight'}>0 entries selected</p>
            </div>
            <div className={'col-span-3 flex gap-1'}>
                <button className={'py-1 px-2 mb-0 bg-green-500 rounded'}>Enable</button>
                <button className={'py-1 px-2 mb-0 bg-yellow-500 rounded'}>Disable</button>
                <button className={'py-1 px-2 mb-0 bg-red-500 rounded'}>Delete</button>
            </div>
        </div>
        <div className={'grid grid-cols-12 gap-2'}>
            <div className={'col-span-1 border-b p-3'}></div>
            <div className={'col-span-4 border-b p-3'}>Title</div>
            <div className={'col-span-1 border-b p-3'}>Status</div>
            <div className={'col-span-2 border-b p-3'}>Created</div>
            <div className={'col-span-2 border-b p-3'}>Last Updated</div>
            <div className={'col-span-2 border-b p-3'}>Slug</div>
        </div>
        {type.items.map((item) => {
            return <div key={item.id} className={'grid grid-cols-12 gap-2'}>
                <div className={'col-span-1 border-b p-3 text-center text-lg'}><Link to={`/cms/${typeId}/${item.id}`}><i className={'fas fa-pen'}/></Link></div>
                <div className={'col-span-4 border-b p-3 text-lg flex items-center'}>{item.title}</div>
                <div className={'col-span-1 border-b p-3 text-lg flex items-center'}>{item.enabled ? 'Enabled' : 'Disabled'}</div>
                <div className={'col-span-2 border-b p-3 text-lg flex items-center'}>{dateFormatHelper(item.created_at,'YYYY-MM-DD HH:mm a')}</div>
                <div className={'col-span-2 border-b p-3 text-lg flex items-center'}>{dateFormatHelper(item.updated_at,'YYYY-MM-DD HH:mm a')}</div>
                <div className={'col-span-2 border-b p-3 text-lg flex items-center'}>{item.slug}</div>
            </div>
        })}
    </div> : null
}

const Types = () => {
    return <TypeSelectedLayout>
        <CMSTypeItemsTable />
    </TypeSelectedLayout>
}

export default Types