import {useStores} from "../../stores";
import {useNavigate, useParams} from "react-router-dom";
import {Header, NavMenu} from "../../Components";
import React, {useCallback, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {cmsCreateItem, cmsGetType} from "../../api";
import { useForm, FormProvider } from "react-hook-form";
import {showToast} from "../../Components/Toast/ToastManager";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {ItemForm} from "./Item";


type CreateItemFormType = {
    title: string;
    subtitle: string;
    slug: string;
    enabled: boolean;
};

export const CreateItemDialog = ({open, setOpen, _typeId}:{open:boolean, setOpen: any, _typeId?:string}) => {
    const params = useParams<{ typeId: string }>();
    const queryClient = useQueryClient();
    const { userStore } = useStores();
    const navigate = useNavigate()
    const typeId = _typeId || params.typeId
    const { data: type } = useQuery(`cmsGetType-${typeId}`, async () => {
        return cmsGetType({token: userStore.user.token, slug: typeId || ''});
    });
    const {mutate: createItem} = useMutation(
        cmsCreateItem,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Item has been created successfully.",
                    duration: 3000,
                    error: false,
                });
                if (!_typeId) navigate(`/cms/${typeId}/${data.data.id}`)
            },
            onError: () => {
                showToast({
                    content: "Type creation failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: (data) => {
                if (!data?.data) return
                return queryClient.invalidateQueries(`cmsGetType-${typeId}`).then(()=>setOpen(false))
            },
        }
    );

    const submitForm = useCallback((data: CreateItemFormType) => {
        if (!typeId) return
        return createItem({
            type: {
                slug: typeId
            },
            title: data.title,
            subtitle: data.subtitle ?? undefined,
            enabled: data.enabled ?? false,
            slug: data.slug ?? undefined,
            token: userStore.user.token
        })
    }, [userStore.user.token, createItem, typeId])

    const form = useForm<any>({
        defaultValues: {
            title: "",
            subtitle: "",
            slug: "",
            enabled: false,
        },
        mode: "onTouched",
    });

    return <Modal show={open} onHide={()=>setOpen(false)}>
        <Modal.Header>
            <Modal.Title>New {type ? type.title : 'Item'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormProvider {...form}>
                <ItemForm typeId={type?.slug} />
            </FormProvider>
            <button className={'btn form'} type={'submit'} onClick={form.handleSubmit(submitForm)}>Create</button>
        </Modal.Body>
    </Modal>
}

const Items = () => {
    const [isCreating, setIsCreating] = useState(false)
    return <>
        <div className="container-fluid p-0">
            <CreateItemDialog open={isCreating} setOpen={setIsCreating}/>
            <div className="row no-gutters">
                <NavMenu />
                <div className="col-sm-12 col-md-10">
                    <Header />
                    <div className="dashboardContent">
                        <div className="head">
                            <div className={'flex justify-content-between'}>
                                <Link to={'/cms'}><i className="fas fa-arrow-left"></i>Back to types</Link>
                                <Link onClick={()=>setIsCreating(true)} to={`#`}>Create Item</Link>
                            </div>
                        </div>
                        <p>Item Management used to be here</p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Items