import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

type AppProps = {
    showTimePicker?: boolean
    stateDate: Date,
    handler: Function,
    disabled?: boolean | false

}


function Calendar({ showTimePicker, stateDate, handler, disabled }: AppProps) {

    const dateFormat = showTimePicker ? 'h:mm aa' : 'MMMM d, yyyy';

    return (

        <DatePicker selected={stateDate || (new Date())}
            onChange={date => {console.log(date) }}
            showTimeSelect={showTimePicker}
            showTimeSelectOnly={showTimePicker}
            dateFormat={dateFormat}
            className="form-control"
            disabled={disabled}

        />
    )



}

export default Calendar;
