
import { FormDescription, FormField, FormItem, Input } from './formelements';
import {  FormLabel } from 'react-bootstrap';

type Props = {
    Field: { name: string; label: string };
    disabled: boolean;
};

function AddressForm(props: Props) {
    const { disabled } = props;
    const { name, label } = props.Field;

    // const { watch } = useFormContext();
    return (
        <>

                <FormField
                name={`${name}`}
                disabled={disabled}
                //control={control}
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="font-extrabold">{label}</FormLabel>
                        <div className="form-group">

                       <p>{field.value.line1} {field.value.line2}, {field.value.city}, {field.value.state},{field.value.country} - {field.value.postal_code}
                       </p> </div>

                    </FormItem>
                )}
            />
{/* 
            <FormField
                name={`${name}.country`}
                disabled={disabled}
                //control={control}
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="font-extrabold">{label}</FormLabel>
                        <div className="form-group">

                        <FormDescription >Country/Region</FormDescription>
                        <Input
                            placeholder={""}
                            {...field}
                            disabled={disabled || false}
                            value={field.value? field.value:""}
                        />
                        </div>


                    </FormItem>
                )}
            />
            <div className='grid grid-cols-2 gap-4'>
                <FormField
                    name={`${name}.line1`}
                    disabled={disabled}
                    //control={form.control}
                    render={({ field }) => {
                        return (
                            <FormItem >
                                <FormLabel className="font-extrabold">Address 1</FormLabel>
                                <div className="form-group">

                                <Input
                                    placeholder={""}
                                    {...field}
                                    disabled={disabled || false}
                                    value={field.value ? field.value:""}
                                />
                                </div>

                            </FormItem>)
                    }
                    }
                />
                <FormField
                    disabled={disabled}
                    // control={form.control}
                    name={`${name}.line2`}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="font-extrabold">Address 2</FormLabel>
                            <div className="form-group">

                            <Input
                                placeholder={""}
                                {...field}
                                disabled={disabled || false}
                                value={field.value ? field.value:""}
                                />
                            </div>
                        </FormItem>
                    )}
                />
            </div>
            <div className='grid gap-3 grid-cols-3'>
                <FormField
                    disabled={disabled}
                    name={`${name}.city`}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="font-extrabold">City</FormLabel>
                            <div className="form-group">

                            <Input
                                placeholder={""}
                                {...field}
                                disabled={disabled || false}
                                value={field.value ? field.value:""}
                               
                            />
                            </div>
                        </FormItem>
                    )}
                />
                <FormField
                    name={`${name}.state`}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="font-extrabold">Province/state</FormLabel>
                            <div className="form-group">
                            <Input
                                placeholder={""}
                                {...field}
                                disabled={disabled || false}
                                value={field.value ? field.value:""}
                                />
                            </div>
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={disabled}
                    name={`${name}.postal_code`}
                    //control={form.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={'truncate font-extrabold'}>Postal code/ZIP</FormLabel>
                            <div className="form-group">

                            <Input
                                placeholder={""}
                                {...field}
                                disabled={disabled || false}
                                value={field.value ? field.value:""}
                                />
                            </div>
                        </FormItem>
                    )}
                />
            </div> */}
        </>
    );
}

export { AddressForm };
