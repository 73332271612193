import { useState, useMemo, useEffect } from 'react'
import Table from '../../../Components/CheckBoxTable';

import { Dropdown } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import { EmailTemplate } from '@sprycore/spry-api-client/dist/MainDbReturnTypes';
import {
  GrandPrizeDrawRanking, PrizeWinner,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type IProps = {
  campaignKey: string,
  pendingRankings: GrandPrizeDrawRanking[]
  setSelectedWinners: Function
  setForfeitopen: Function
  prizeWinners: PrizeWinner[] | undefined
  resendEmail: Function
  emailTemplates: EmailTemplate[] | undefined
}
export const Pending = (props: IProps) => {
  const [openEmailmodal, setopenEmailModal] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState<string>("")

  useEffect(() => {
    window.sessionStorage.setItem("page", "0")

    return () => {
      window.sessionStorage.setItem("page", "0")
    }
  }, [])

  const resendClaimEmail = async () => {
    setopenEmailModal(false)
    props.resendEmail(selectedEmail)
  }

  const COLUMNS = [
    {
      Header: 'Rank',
      accessor: 'ranking',
      width: "3%",

    },

    {
      Header: 'First Name',
      accessor: 'participant.firstName',
      width: "10%",

    },
    {
      Header: 'Last Name',
      accessor: 'participant.lastName',
      width: "10%",

    },
    {
      Header: 'Language',
      accessor: 'participant.preferredLanguage',
      width: "13%",
      Cell: (tableInfo: any) => {
        const language = tableInfo.data[tableInfo.row.index].participant.preferredLanguage || "en"
        return (
          <p>{language}</p>
        );
      },

    },
    {
      Header: 'Email',
      accessor: 'participant.email',
      width: "13%",
    },
    {
      Header: 'Last Updated',
      accessor: 'creationTime',
      width: "13%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (tableInfo: any) => {
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="pending-menu">
              <Dropdown.Item
                className="dropdown-item"
                onClick={() => {
                  props.setSelectedWinners([tableInfo.data[tableInfo.row.index]])
                  setopenEmailModal(true)
                }}
              >
                Resend Claim email
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  props.setSelectedWinners([tableInfo.data[tableInfo.row.index]])
                  props.setForfeitopen(true)
                }}
              >
                Forfeit
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },

  ]
  // eslint-disable-next-line
  const columns = useMemo(() => COLUMNS, [])
  return (
    <>
      {/* {loading || loading ? <div className="spinner"><Spinner animation="border" variant="secondary" /></div> : */}
      {props.pendingRankings && props.pendingRankings.length > 0 ? <Table
        setSelectedRows={props.setSelectedWinners}
        sortbyid="ranking"
        columns={columns}
        data={props.pendingRankings}
        tablePageSize={10} /> :
        <p className="table-nodata">There are no records to display.</p>}

      <Modal show={openEmailmodal} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => { setopenEmailModal(false) }}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          <h3>Resend prize claim email?</h3>
          <p>Are you sure you want to resend the prize claim email to the user?</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"> Prize claim email</label>
            <select className="form-control" value={selectedEmail} onChange={(e) => {
              setSelectedEmail(e.currentTarget.value)
            }} >
              <option value="" key="">Select an option</option>
              {props.emailTemplates && props.emailTemplates.map(template => <option value={template.emailTemplateKey} key={template.emailTemplateKey}>{template.emailTemplateName} </option>)}
            </select>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-primary small'
            onClick={resendClaimEmail}
          >
            Resend
          </button>
          <button
            type='button'
            className='btn btn-secondary small outline'
            onClick={() => {
              setopenEmailModal(false)
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}








