import { useNavigate, useParams } from "react-router-dom"
import { spryClient } from "../../../api"
import { useQuery, useQueryClient } from "react-query"
import { Spinner } from "react-bootstrap"
import { useState } from "react"
import { useForm } from "react-hook-form"

type CampaignEditEmailBatchProps = {
    campaignKey: string
}

type BatchForm = {
    scheduledTime: string
    name: string
    transactional: boolean
    defaultParameters: string
    participantFields: string
}

const supportedFields = ["firstInitial", "lastInitial", "firstName", "lastName", "email", "phone", "address", "city", "postal", "province", "country", "address2", "sessionKey"]

export default function CampaignEditEmailBatch({ campaignKey }: CampaignEditEmailBatchProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { emailBatchKey } = useParams() as { emailBatchKey: string }
    const [updating, setUpdating] = useState(false)
    const [updateStatus, setUpdateStatus] = useState("")
    const [localScheduled, setLocalScheduled] = useState("")

    const batchForm = useForm<BatchForm>()

    const emailBatchQuery = useQuery(
        ["getEmailBatches", campaignKey, emailBatchKey],
        async () => {
            const { emailBatches: [emailBatch] } = await spryClient.getEmailBatches({ campaignKey, emailBatchKey })
            batchForm.reset({
                scheduledTime: emailBatch.scheduledTime.toISOString(),
                name: emailBatch.name,
                transactional: emailBatch.transactional,
                defaultParameters: JSON.stringify(emailBatch.defaultParameters, undefined, 2),
                participantFields: emailBatch.participantFields.join(", ")
            })
            setLocalScheduled(getLocalScheduled(emailBatch.scheduledTime.toISOString()))
            return emailBatch
        })

    const loading = emailBatchQuery.isLoading
    const emailBatch = loading ? undefined : emailBatchQuery.data

    const emailTemplatesQuery = useQuery(
        ["getEmailTemplates", campaignKey, emailBatch?.emailTemplateKey],
        async () => {
            if (!emailBatch) { return }
            const { emailTemplates } = await spryClient.getEmailTemplates({ campaignKey, emailTemplateKey: emailBatch.emailTemplateKey })
            return emailTemplates[0]
        })

    const emailTemplate = emailTemplatesQuery.isLoading ? undefined : emailTemplatesQuery.data

    async function submitEmailBatch(values: BatchForm) {
        if (updating || !emailBatch) { return }
        let defaultParameters: Record<string, any>
        let participantFields: string[] = [...new Set(values.participantFields.split(",").map(x => x.trim()).filter(x => x))]
        try {
            defaultParameters = JSON.parse(values.defaultParameters)
        }
        catch {
            setUpdateStatus("Invalid JSON in default parameters")
            return
        }
        for (const field of participantFields) {
            if (supportedFields.indexOf(field) === -1 && !field.startsWith("metadata.")) {
                setUpdateStatus(`Unknown field '${field}' in participant fields. Supported: ${supportedFields.join(", ")}`)
                return
            }
        }
        if (!isoDateRegex.test(values.scheduledTime)) { return }
        setUpdating(true)
        setUpdateStatus("")
        try {
            await spryClient.updateEmailBatch({
                emailBatchKey: emailBatch.emailBatchKey,
                name: values.name,
                scheduledTime: new Date(values.scheduledTime),
                transactional: values.transactional,
                defaultParameters,
                participantFields
            })
            setUpdateStatus("Saved")
        }
        catch {
            setUpdateStatus("Error updating")
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getEmailBatches")
        }
    }

    async function cancelEmailBatch() {
        if (!emailBatch) { return }
        if (!window.confirm("Cancel?")) { return }
        await spryClient.cancelEmailBatch({ emailBatchKey: emailBatch.emailBatchKey })
    }

    const isoDateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.*[0-9]*Z$/

    function getLocalScheduled(str: string) {
        if (!isoDateRegex.test(str)) { return "invalid iso date format" }
        return `Local time: ${new Date(str).toLocaleString()}`
    }

    return <>
        <div className="dashboardContent campaignDetail tabsCont emailBatches">           
            <div className="head inner">
                <h2><button className="backBtn" 
            onClick={() => navigate(`/campaign/${campaignKey}/email-batches`)}><i className="fas fa-arrow-left" aria-hidden="true"></i></button>Edit Email Batch {emailBatch && ` [${emailBatch.name}]`}</h2>
            </div>
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {emailBatch && <>

                    <p>Email Template: [{emailTemplate?.language}] {emailTemplate?.emailTemplateName}</p>
                    {!emailBatch.cancelled && <p><button className="btn" onClick={cancelEmailBatch}>Cancel Batch</button></p>}
                    {emailBatch.cancelled && <p>Batch has been cancelled</p>}
                    {emailBatch.triggered && <p>Batch has been triggered</p>}

                    <form onSubmit={batchForm.handleSubmit(submitEmailBatch)}>

                        <label htmlFor="name">Name</label>
                        <input {...batchForm.register("name")} className="form-control" />
                        {batchForm.formState.errors.name?.message}

                        <label htmlFor="scheduledTime">Scheduled Time</label>
                        <input {...batchForm.register("scheduledTime", { pattern: isoDateRegex })} className="form-control" onChange={e => setLocalScheduled(getLocalScheduled(e.target.value))} readOnly={emailBatch.cancelled || emailBatch.triggered} />
                        <span>{localScheduled}<br /></span>
                        {batchForm.formState.errors.scheduledTime?.message}

                        <div className="form-row align-items-center">
                        <div className="col-auto my-2">
                            <div className="form-check">
                            <input {...batchForm.register("transactional")} id="transactional" type="checkbox" className="form-check-input" readOnly={emailBatch.cancelled || emailBatch.triggered} />
                                <label htmlFor="transactional" className="form-check-label mt-0">Transactional</label>
                            </div>
                            {batchForm.formState.errors.transactional?.message}
                        </div>
                        </div>

                        <label htmlFor="participantFields">Participant Fields (example: &quot;firstInitial, lastName, phone, sessionKey, metadata.studentId, metadata.something.else, ...&quot;)</label>
                        <input {...batchForm.register("participantFields")} className="form-control" readOnly={emailBatch.cancelled || emailBatch.triggered} maxLength={250} />
                        {batchForm.formState.errors.participantFields?.message}

                        <label htmlFor="defaultParameters" style={{ float: "inherit", display: "block" }}>Default email template replacement parameters</label>
                        <textarea {...batchForm.register("defaultParameters")}
                            style={{ width: "80%", minHeight: "10em" }}></textarea><br />

                        <button className="btn mt-2" type="submit" disabled={updating}>Submit</button>
                        <span>{updateStatus}</span>

                    </form>
                </>}
            </div>
        </div>
    </>
}