import React, { useEffect, useState } from 'react'
import { TAsset, TComment } from '../../types/portalTypes'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer';
import { getAssets } from '../../api';

const Comment = ({ active, comment: a, token }: { active: boolean, token: string, comment: TComment }) => {

  const [asset, setAsset] = useState<TAsset>()

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: .8,
  });

  const download = (url: string, filename: string) => {
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };


  const callAssets = async (id: string) => {
    const res: any = await getAssets({ token: token, id: a.id, entityType: "comment" });
    if (res && res[0]) {
      setAsset(res[0])
    }
    return res;
  }

  useEffect(() => {
    if (inView) {
      callAssets(a.id)
    }
  }, [a.id, inView])


  return (
    <div
      ref={ref}
      style={{
        justifyContent: a.creator?.email ? "left" : "right"
      }}
      className={`activity a-${a.id} ${active}`} key={a.id}
    >
      <div
        className={"shortName"}
        style={{
          color: a.creator?.email ? "black" : 'white',
          background: a.creator?.email ? "#84ffa0" : 'black',
        }}>
        {a.creator?.email?.charAt(0).toUpperCase() || 'WILY'}
        {(a.creator?.email?.split(".")[1] ?? " ")?.charAt(0)?.toUpperCase()}
      </div>
      <div className="panel">
        <div className="panel-heading">
          <p style={{ fontWeight: "bold" }}> {a.creator ? a.creator.email.split('@')[0] : "WILY"}</p>
          <p className="date" style={{}}>{dayjs(a.created_at).format("MMM DD, hh:mm a ")}</p>
        </div>
        <div className="panel-body">
          {/* <p>{a.content.includes('{\"') ? getChangeRequestComment(a.content).title : a.content} </p> */}
          <p>{a.content}</p>
          {asset && <>
            <button onClick={(e) => download(asset.url, asset.key.split('--')[1])} style={{ textDecoration: "underline" }}>
              <i className="fas fa-paperclip" style={{ marginRight: "5px" }}></i>

              {asset.key.split('--')[1]}</button>
          </>}

        </div>

      </div>
    </div>
  )
}

export default Comment