import { useMemo, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Spinner } from "react-bootstrap";
import { NavMenu, Header } from "../../Components";
import AddAgency from "./AddAgency";
import { Table } from "../../Components";
import { COLUMNS } from "./Columns";
import { spryClient } from "../../api";
import { useBodyClass } from "../../utils/hooks";
import { Modal } from "../../Components/Modal";
import { showToast } from "../../Components/Toast/ToastManager";
import dayjs from 'dayjs'

const Agencies = (): JSX.Element => {
  useBodyClass("adminPg");

  const [show, setShow] = useState(false);

  const [passData, setPassData] = useState({});



  const queryClient = useQueryClient();

  const { mutate } = useMutation(spryClient.deleteAgency, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAgencies");
      showToast({
        content: "Deleted Agency",
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      setShowModal(true);
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [statekey, setStateKey] = useState("");

  const columns = useMemo(
    () => {
      const handleUpdate = (data: { agencyKey: string; agencyName: string }) => {
        setPassData(data);
      };
      return COLUMNS({
        setShow,
        handleUpdate,
        mutate,
        setShowModal,
        setStateKey
      })
    },
    [setShow, mutate, setShowModal, setStateKey]);

  // const { data, isLoading, error } = useQuery("getAgencies", getAgencies);

  const { data, isLoading, error } = useQuery("getAgencies", async () => {
    const res = await spryClient.getAgencies({});
    const updatedAgencies = res.agencies.map((record: any) => {
      let formattedDate = dayjs(record.updateTime).format("MM/DD/YYYY");
      record["updatedTime"] = formattedDate;
      return record;
    });
    return updatedAgencies;
  });

  const calldelete = () => {
    mutate({ agencyKey: statekey });
    // setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Modal
            calldelete={calldelete}
            handleClose={handleClose}
            notification={true}
            modalType="DeleteErrorPopUp"
            header="Delete ?"
            message="Sorry, this Agency can't be deleted since it is already associated with a Campaign."
          />
        </div>
      )}
      <div className="container-fluid p-0">
        {/* <button
          onClick={() =>
            showToast({
              title: "Toast title",
              content: "Toast body",
              duration: 3000,
            })
          }
        >ClickMe
        </button> */}
        <div className="row no-gutters">
          <NavMenu />
          <div className="col-sm-12 col-md-10">
            <Header />
            {!error ? (
              <div className="dashboardContent">
                <div className="head">
                  <span
                    className="btn addbtn"
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    Add agency
                  </span>
                </div>
                <AddAgency
                  showSidebar={show}
                  closeHandler={setShow}
                  agency={passData}
                />
                {isLoading ? (
                  <div className="spinner"><Spinner animation="border" variant="secondary" /></div>
                  // <Spinner animation="border" variant="secondary" />
                ) : (
                  data && (
                    <Table
                      columns={columns}
                      data={data.length > 0 ? data : []}
                      tablePageSize={10}
                    />
                  )
                )}
              </div>
            ) : (
              <div className="dashboardContent">
                <h1> API Error </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Agencies;
