import { useNavigate, useParams } from "react-router-dom"
import { spryClient } from "../../../api"
import { useQuery, useQueryClient } from "react-query"
import { Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"
import { EmailBatchTarget, Keyword, KeywordLocation, Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes"
import { showToast } from "../../../Components/Toast/ToastManager"
import React from "react"

type CampaignEditEmailBatchTargetsProps = {
    campaignKey: string
}

export default function CampaignEditEmailBatchTargets({ campaignKey }: CampaignEditEmailBatchTargetsProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { emailBatchKey } = useParams() as { emailBatchKey: string }
    const [showExisting, setShowExisting] = useState(false)
    const [showNew, setShowNew] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [parametersJson, setParametersJson] = useState("{}")
    const [rawParametersJson, setRawParametersJson] = useState("{}")
    const [filterTags, setFilterTags] = useState("")
    
    const emailBatchTargetsQuery = useQuery(
        ["getEmailBatchTargets", campaignKey, emailBatchKey],
        async () => {
            const { emailBatchTargets } = await spryClient.getEmailBatchTargets({ campaignKey, emailBatchKey })
            return emailBatchTargets
        })
    const participantsQuery = useQuery(
        ["participants", campaignKey],
        async () => {
            const { participants } = await spryClient.getParticipants({ campaignKey })
            return participants
        })
    const emailBatchQuery = useQuery(
        ["getEmailBatches", campaignKey, emailBatchKey],
        async () => {
            const { emailBatches: [emailBatch] } = await spryClient.getEmailBatches({ campaignKey, emailBatchKey })
            return emailBatch
        })
    const keywordsQuery = useQuery(
        ["getKeywords", campaignKey],
        async () => {
            const { keywords } = await spryClient.getKeywords({ campaignKey })
            return keywords
        })
    const keywordLocationsQuery = useQuery(
        ["getKeywordLocations", campaignKey],
        async () => {
            const { keywordLocations } = await spryClient.getKeywordLocations({ campaignKey })
            return keywordLocations
        })

    const loading = emailBatchQuery.isLoading || emailBatchTargetsQuery.isLoading || participantsQuery.isLoading || keywordsQuery.isLoading || keywordLocationsQuery.isLoading
    const emailBatch = loading ? undefined : emailBatchQuery.data
    const emailBatchTargets = loading ? undefined : emailBatchTargetsQuery.data
    const participants = loading ? undefined : participantsQuery.data
    const keywords = loading ? undefined : keywordsQuery.data
    const keywordLocations = loading ? undefined : keywordLocationsQuery.data
    const participantsBySessionKey: Record<string, Participant> = {}
    const keywordsByKey: Record<string, Keyword> = {}
    const keywordLocationsByKey: Record<string, KeywordLocation> = {}
    const targetsBySessionKey: Record<string, EmailBatchTarget> = {}
    participants?.forEach(x => participantsBySessionKey[x.sessionKey] = x)
    keywords?.forEach(x => keywordsByKey[x.keywordKey] = x)
    keywordLocations?.forEach(x => keywordLocationsByKey[x.keywordLocationKey] = x)
    emailBatchTargets?.forEach(x => targetsBySessionKey[x.sessionKey] = x)
    const untargetedParticipants = participants?.filter(x => !targetsBySessionKey[x.sessionKey]) || []

    useEffect(() => {
        if (emailBatch?.cancelled || emailBatch?.triggered) {
            navigate(`/campaign/${campaignKey}/email-batches`)
        }
    }, [emailBatch?.cancelled, emailBatch?.triggered, navigate, campaignKey])

    async function removeAllTargets() {
        if (!emailBatch || !emailBatchTargets?.length || updating) { return }
        if (!window.confirm("Remove all targets?")) { return }
        setUpdating(true)
        try {
            await spryClient.deleteEmailBatchTargets({ emailBatchKey, sessionKeys: emailBatchTargets.map(x => x.sessionKey) })
        }
        catch {
            showToast({ content: "Error removing targets", duration: 3000, error: true })
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getEmailBatchTargets")
        }
    }
    async function removeSelectedTargets() {
        if (updating) { return }
        const sessionKeys = [...document.querySelectorAll("table.existing-targets input[data-sessionkey]:checked")]
            .map(x => x.getAttribute("data-sessionkey") as string)
            .filter(x => x)
        if (!sessionKeys.length) { return }
        setUpdating(true)
        try {
            await spryClient.deleteEmailBatchTargets({ emailBatchKey, sessionKeys })
        }
        catch {
            showToast({ content: "Error removing targets", duration: 3000, error: true })
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getEmailBatchTargets")
        }
    }
    async function addTargets(toAdd: Participant[]) {
        if (updating) { return }
        let parameters: Record<string, string>
        let rawParameters: Record<string, string>
        try {
            parameters = JSON.parse(parametersJson || "{}")
            rawParameters = JSON.parse(rawParametersJson || "{}")
        }
        catch {
            showToast({ content: "Invalid JSON in replacement parameters", duration: 3000, error: true })
            return
        }
        setUpdating(true)
        try {
            toAdd = toAdd.slice()
            while (toAdd.length) {
                await spryClient.addEmailBatchTargets({
                    campaignKey,
                    emailBatchKey,
                    targets: toAdd.splice(0, 1000).map(x => ({
                        sessionKey: x.sessionKey,
                        parameters,
                        rawParameters
                    }))
                })
            }
        }
        catch {
            showToast({ content: "Error adding targets", duration: 3000, error: true })
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getEmailBatchTargets")
        }
    }
    async function addAllTargets() {
        await addTargets(untargetedParticipants)
    } 
    async function addFilteredTargets() {
        await addTargets(filteredParticipants)
    }
    async function addSelectedTargets() {
        const sessionKeys = [...document.querySelectorAll("table.new-targets input[data-sessionkey]:checked")]
            .map(x => x.getAttribute("data-sessionkey") as string)
            .filter(x => x)
        if (!sessionKeys.length) { return }
        const toAdd = sessionKeys.map(x => participantsBySessionKey[x])
        await addTargets(toAdd)
    }

    function getTagString(tags: string[]) {
        return tags.map(x => {
            if (!x.indexOf("keyword:")) {
                const keyword = keywordsByKey[x.substring(8)]
                return keyword ? `[Keyword: ${keyword.word}]` : x
            }
            else if (!x.indexOf("location:")) {
                const location = keywordLocationsByKey[x.substring(9)]
                return location ? `[Location: ${location.name}]` : x
            }
            return x
        }).join(", ")
    }

    const filterTagsArray = filterTags.split(",").map(x => x.trim().toLowerCase()).filter(x => x)
    const filteredParticipants = filterTagsArray.length ? untargetedParticipants.filter(x => filterTagsArray.every(f => x.tags.indexOf(f) !== -1)) : untargetedParticipants

    return <>
        <div className="dashboardContent campaignDetail tabsCont emailBatchTarget">
        <div className="head inner">
            <h2><button className="backBtn" 
                    onClick={() => navigate(`/campaign/${campaignKey}/email-batches`)}><i className="fas fa-arrow-left" aria-hidden="true"></i></button>Edit Email Batch {emailBatch && ` [${emailBatch.name}]`}</h2>
            </div>           
            <div className="head inner">
                <h2>Edit Targets for Email Batch {emailBatch && ` [${emailBatch.name}]`}</h2>
            </div>
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {emailBatch && emailBatchTargets && participants && <>
                    <div>
                        <h3>Existing Targets</h3>
                        <p>There are {emailBatchTargets.length} targets in the email batch</p>
                        {emailBatchTargets.length > 0 && <p><button className="btn" onClick={removeAllTargets} disabled={updating}>Remove all targets</button></p>}
                        {!showExisting && emailBatchTargets.length > 0 && <p><button className="btn" onClick={() => setShowExisting(true)}>Show</button></p>}
                        {showExisting && <>
                            <p><button className="btn" onClick={() => setShowExisting(false)}>Hide</button></p>
                            <table className="existing-targets">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Area</th>
                                        <th>Tags</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailBatchTargets.map(x => {
                                        const participant = participantsBySessionKey[x.sessionKey]
                                        if (!participant) { return <React.Fragment key={x.sessionKey}></React.Fragment> }
                                        return <tr key={participant.sessionKey}>
                                            <td>{participant.firstName} {participant.lastName}</td>
                                            <td>{participant.email}</td>
                                            <td>{participant.phone}</td>
                                            <td>{[participant.city, participant.province, participant.country].filter(x => x).join(", ")}</td>
                                            <td>{getTagString(participant.tags)}</td>
                                            <td><input type="checkbox" data-sessionkey={participant.sessionKey} /></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <p><button className="btn" onClick={removeSelectedTargets} disabled={updating}>Remove Selected Targets</button></p>
                        </>}
                    </div>
                    <div>
                        <h3>Add New Targets</h3>
                        <label>New targets will be added with these custom template replacement parameters</label>
                        <textarea value={parametersJson} onChange={e => setParametersJson(e.target.value)} style={{ width: "80%", minHeight: "10em" }}></textarea><br />
                        <label>New targets will be added with these custom template RAW (not html-escaped, objects/arrays allowed) replacement parameters</label>
                        <textarea value={rawParametersJson} onChange={e => setRawParametersJson(e.target.value)} style={{ width: "80%", minHeight: "10em" }}></textarea><br />
                        {untargetedParticipants.length > 0 && <p><button className="btn" onClick={addAllTargets} disabled={updating}>Add all targets</button></p>}
                        <p>There are {untargetedParticipants.length} participants who are not yet targets</p>
                        {!showNew && untargetedParticipants.length > 0 && <p><button className="btn" onClick={() => setShowNew(true)}>Show</button></p>}
                        {showNew && <div>
                            <p><button className="btn" onClick={() => setShowNew(false)}>Hide</button></p>
                            <label style={{ float: "none", display: "block" }}>Filter by participants matching all these tags (example:  tag1, tag2, someTag3)</label>
                            <input value={filterTags} onChange={e => setFilterTags(e.target.value)} /><br />
                            <p>{filteredParticipants.length} participants match the current filters</p>
                            {filteredParticipants.length > 0 && <p><button className="btn" onClick={addFilteredTargets} disabled={updating}>Add filtered targets</button></p>}
                            <table className="new-targets">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Area</th>
                                        <th>Tags</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredParticipants.map(participant => {
                                        return <tr key={participant.sessionKey}>
                                            <td>{participant.firstName} {participant.lastName}</td>
                                            <td>{participant.email}</td>
                                            <td>{participant.phone}</td>
                                            <td>{[participant.city, participant.province, participant.country].filter(x => x).join(", ")}</td>
                                            <td>{getTagString(participant.tags)}</td>
                                            <td><input type="checkbox" data-sessionkey={participant.sessionKey} /></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <p><button className="btn" onClick={addSelectedTargets} disabled={updating}>Add Selected Targets</button></p>
                        </div>}
                    </div>
                </>}
            </div>
        </div>
    </>
}