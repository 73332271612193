import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { spryClient } from "../../../api";
import { Spinner } from "react-bootstrap";
import {
  Prize,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import {
  dateFormatHelper,
  emailRegex,
  looseNorthAmericanPhoneRegex,
} from "../../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ReturnTypes } from "@sprycore/spry-api-client";
import { useForm } from "react-hook-form";

type ClaimForm = {
  prize: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  date: string;
  stQAnswer: string | number;
  signature: string;
};
function ClaimDetails({
  campaignKey,
  allPrizes,
}: {
  campaignKey: string;
  allPrizes: Prize[] | undefined;
}) {
  const { prizeDrawRankingKey, grandPrizeDrawKey } = useParams() as {
    grandPrizeDrawKey: string;
    prizeDrawRankingKey: string;
  };
  const QueryKey = { campaignKey, grandPrizeDrawKey };
  const navigate = useNavigate();
  const [signatureUrl, setSignatureUrl] = useState<any>();
  const {
    register,
    reset,
    formState: { errors },
  } = useForm<ClaimForm>({
    mode: "onTouched",
  });

  const { isLoading: loadingWinner, data: prizeWinner } = useQuery(
    ["getPrizeWinners", QueryKey],
    async () => {
      const res: ReturnTypes.PrizeWinners = await spryClient.getPrizeWinners({
        ...QueryKey,
      });
      const filteredWinner =
        res.prizeWinners &&
        res.prizeWinners.filter(
          (winner) => winner.grandPrizeDrawRankingKey === prizeDrawRankingKey
        );
      if (filteredWinner[0]) {
        if (
          filteredWinner[0].declarationAndRelease &&
          filteredWinner[0].declarationAndRelease.digitalSignatureUrl
        ) {
          let url = await getParticipantFiles(
            filteredWinner[0].declarationAndRelease?.digitalSignatureUrl
          );
          const link =
            url.downloadUrls[
              filteredWinner[0].declarationAndRelease.digitalSignatureUrl
            ];
          let publicUrl = await fetch(link).then((res) => res.text());
          setSignatureUrl(publicUrl);
        }
      }
      return filteredWinner[0];
    }
  );
  const prizeName = useMemo(() => {
    if(prizeWinner && allPrizes){
      const matchedPrize = allPrizes.filter((prize: any) => {
        return prize.prizeKey === prizeWinner.prizeKey;
      });
      return matchedPrize[0].prizeName;
    }
   
  },[prizeWinner,allPrizes])

  
  useEffect(() => {
    if (prizeWinner) {
      reset({
        prize: prizeName || "",
        firstName: prizeWinner.declarationAndRelease?.firstName || "",
        lastName: prizeWinner.declarationAndRelease?.lastName || "",
        email: prizeWinner.declarationAndRelease?.email || "",
        phoneNumber: prizeWinner.declarationAndRelease?.phone || "",
        address1: prizeWinner.declarationAndRelease?.address || "",
        address2: prizeWinner.declarationAndRelease?.address2 || "",
        city: prizeWinner.declarationAndRelease?.city || "",
        province: prizeWinner.declarationAndRelease?.province || "",
        country: prizeWinner.declarationAndRelease?.country || "",
        postalCode: prizeWinner.declarationAndRelease?.postal || "",
        date: prizeWinner.declarationAndRelease?.creationTime
          ? dateFormatHelper(
              dayjs(
                prizeWinner.declarationAndRelease?.creationTime
              ).toISOString(),
              "MM/DD/YYYY hh:mm:ss A"
            )
          : "",
        stQAnswer: prizeWinner.declarationAndRelease?.skillTestingAnswer || "",
        signature: prizeWinner.declarationAndRelease?.digitalSignatureUrl || "",
      });
    }
  }, [prizeWinner, reset, prizeName]);

 
  
  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/prizedraws/${grandPrizeDrawKey}`);
  };

  async function getParticipantFiles(key: string) {
    const urls = await spryClient.downloadUserFile({
      campaignKey,
      key: key,
      expirySeconds: 604800,
    });
    return urls;
  }
  return (
    <>
      {loadingWinner || !prizeName ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : prizeWinner ? (
        <div className="dashboardContent campaignDetail tabsCont addPg">
          <div id="tab08" className="tab-contents prizedraws">
            <div className="head inner">
              <h3>
                <button className="backBtn" onClick={backHandler}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                Prize Claim details
              </h3>
              <button
                type="submit"
                form="conductPrizeDrawForm"
                className="btn"
                disabled
              >
                Save changes
              </button>
            </div>

            <form>
              <div className="">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">Prize</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("prize")}
                        aria-describedby="prize Name"
                        placeholder="Prize name"
                        id="exampleFormControlInput1"
                        disabled
                      />
                      {errors.prize && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.prize.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">First name</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("firstName")}
                        aria-describedby="First Name"
                        placeholder="First Name"
                        id="exampleFormControlInput1"
                      />
                      {errors.firstName && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.firstName.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("lastName")}
                        placeholder="Last Name"
                        id="exampleFormControlInput1"
                      />
                      {errors.lastName && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.lastName.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("email", {
                          pattern: {
                            value: emailRegex,
                            message: "invalid email format",
                          },
                        })}
                        aria-describedby="email"
                        placeholder="Email"
                        id="exampleFormControlInput1"
                      />
                      {errors.email && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Phone number</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("phoneNumber", {
                          required: false,
                          pattern: {
                            value: looseNorthAmericanPhoneRegex,
                            message: "Please enter valid phone number",
                          },
                        })}
                        aria-describedby="phoneNumber"
                        placeholder="Phone Number"
                        id="exampleFormControlInput1"
                      />
                      {errors.phoneNumber && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.phoneNumber.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address 1</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("address1")}
                        aria-describedby="address1"
                        placeholder="Address 1"
                        id="exampleFormControlInput1"
                      />
                      {errors.address1 && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.address1.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address 2</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("address2")}
                        aria-describedby="address2"
                        placeholder="Address 2"
                        id="exampleFormControlInput1"
                      />
                      {errors.address2 && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.address2.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("city")}
                        aria-describedby="city"
                        placeholder="City"
                        id="exampleFormControlInput1"
                      />
                      {errors.city && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.city.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Province</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("province")}
                        aria-describedby="Province"
                        placeholder="Province"
                        id="exampleFormControlInput1"
                      />
                      {errors.province && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.province.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("country")}
                        aria-describedby="Country"
                        placeholder="Country"
                        id="exampleFormControlInput1"
                      />
                      {errors.country && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.country.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Postal code</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("postalCode")}
                        aria-describedby="postalCode"
                        placeholder="Postal Code"
                        id="exampleFormControlInput1"
                      />
                      {errors.postalCode && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.postalCode.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Date</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("date")}
                        aria-describedby="Date"
                        placeholder="Date"
                        id="exampleFormControlInput1"
                        disabled
                      />
                      {errors.date && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.date.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Skill-testing question answer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("stQAnswer")}
                        aria-describedby="stQAnswer"
                        placeholder="stQAnswer"
                        id="exampleFormControlInput1"
                        disabled
                      />
                      {errors.stQAnswer && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.stQAnswer.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="flex-column">
                      <label htmlFor="exampleInputEmail1">
                        Winner signature
                      </label>
                      <div
                        className="form-control signature"
                        style={{
                          height: "150px",
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        {prizeWinner.declarationAndRelease
                          ?.digitalSignatureUrl ? (
                          <img
                            src={signatureUrl}
                            style={{ height: "149px" }}
                            alt="signature"
                          />
                        ) : (
                          <p> Signature not available </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <p> No prize winner details found</p>
      )}
    </>
  );
}

export default ClaimDetails;
