import { useNavigate } from "react-router-dom";

export const CampaignPrizingColumns = () => {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: "No",
      width: "5%",
      accessor: "no",
      Cell: (row: any) => {
        return <div>{Number(row.row.id) + 1}</div>;
      },
    },
    {
      Header: "Name",
      accessor: "prizeName",
      width: "15%",
      Cell: (TableInfo: any) => {
        const prizeKey = TableInfo.data[TableInfo.row.index].prizeKey;
        const prizeName = TableInfo.data[TableInfo.row.index].prizeName;
        const campaignKey = TableInfo.data[TableInfo.row.index].campaignKey;
        return (
          <div>
            <button
              className="linkButton"
              onClick={(e) => {
                navigate(`/campaign/${campaignKey}/prizing/updatePrize/${prizeKey}`);
              }}
            >
              {prizeName}
            </button>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      width: "30%",
    },
    {
      Header: "$ Value",
      accessor: "value",
      width: "10%",
    },
    {
      Header: " ",
      accessor: "fsdfsd ",
      width: "40%",
    },
  ];

  return COLUMNS;
};
