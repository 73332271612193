import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { spryClient } from '../../../api';
import { PrizeDrawColumns } from './PrizeDrawColumns'
import { Table } from '../../../Components';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { IDraws } from '../../../interfaces';
import './index.css'
import Popup from '../../../Components/Popup';
import { GrandPrizeDraw, GrandPrizeDrawRanking, PrizeWinner } from '@sprycore/spry-api-client/dist/MainDbReturnTypes';
import dayjs from 'dayjs'

type typeProp = {
  campaignKey: string
  allPrizePools: any[] | undefined
}
type Counts = {
  eligible: number
  pending: number
  rejected: number
  forfeited: number
  claimed: number
  fulfilled: number
}

function CampaignPrizeDraws({ campaignKey, allPrizePools }: typeProp) {


  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [grandprizedraws, setgrandprizedraws] = useState<IDraws[]>()
  const [draws, setDraws] = useState<GrandPrizeDraw[]>()
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const { isLoading: loadingDraws } = useQuery('getGrandPrizeDraws', async () => {
    const res = await spryClient.getGrandPrizeDraws({ campaignKey });
    setDraws(res.grandPrizeDraws)
    return res.grandPrizeDraws
  })
  const keywordsQuery = useQuery(
    ["getKeywords", campaignKey],
    async () => {
      const { keywords } = await spryClient.getKeywords({ campaignKey })
      return keywords
    })
  const keywordLocationsQuery = useQuery(
    ["getKeywordLocations", campaignKey],
    async () => {
      const { keywordLocations } = await spryClient.getKeywordLocations({ campaignKey })
      return keywordLocations
    })

  const findPrizePoolName = (prizePoolKey: String, allPrizePools: any) => {
    const matchedPrizePool = allPrizePools.filter((prizePool: any) => {
      return prizePool.prizePoolKey === prizePoolKey;
    });
    return matchedPrizePool[0]?.prizePoolName;
  };

  const handlePrizeDraw = () => {
    navigate(`/campaign/${campaignKey}/prizedraws/conduct`)
  }

  async function getdrawresults(grandPrizeDrawRankings: GrandPrizeDrawRanking[], prizeWinners: PrizeWinner[]) {

    let counts = {
      eligible: 0,
      pending: 0,
      rejected: 0,
      forfeited: 0,
      claimed: 0,
      fulfilled: 0
    }
    grandPrizeDrawRankings.forEach(ranking => {

      let prizeWinner = prizeWinners.filter(winner => winner.grandPrizeDrawRankingKey === ranking.grandPrizeDrawRankingKey)
      if (prizeWinner[0]) {
        if (prizeWinner[0].fulfillTime) {
          counts.fulfilled = counts.fulfilled + 1
        }
        else if (prizeWinner[0].forfeitTime) {
          counts.rejected = counts.rejected + 1

        }
        else if (prizeWinner[0].declarationAndRelease) {
          counts.claimed = counts.claimed + 1
        }
        else {
          counts.pending = counts.pending + 1
        }
      }
      else {
        if (ranking.rejectionTime) {
          counts.rejected = counts.rejected + 1
        }
        else {
          counts.eligible = counts.eligible + 1
        }
      }
      return counts
    }
    )
    return counts
  }
  async function getUpdatedRecords() {
    if (loading) { return }
    if (keywordsQuery.isLoading || keywordLocationsQuery.isLoading) { return }
    setLoading(true)

    let updatedGrandprizeDraws: any = []
    let getcounts: Counts
    if (draws) {
      const promises = draws.map(async (draw: GrandPrizeDraw, index: number) => {
        try {
          const rankings = await spryClient.getGrandPrizeDrawRankings({ campaignKey, grandPrizeDrawKey: draw.grandPrizeDrawKey })
          const winners = await spryClient.getPrizeWinners({ campaignKey, grandPrizeDrawKey: draw.grandPrizeDrawKey })
          getcounts = await getdrawresults(rankings?.grandPrizeDrawRankings, winners?.prizeWinners)
        }
        catch (e) {
          console.log(e)
        }
        const drawDate = dayjs(draw.creationTime, timezone).format('MM/DD/YYYY - hh:mm A')
        const prizePoolName = allPrizePools && findPrizePoolName(draw.prizePoolKey, allPrizePools)
        const entryStart = dayjs(draw.parameters.startTime, timezone).format('MM/DD/YYYY - hh:mm A')
        const entryEnd = dayjs(draw.parameters.finishTime, timezone).format('MM/DD/YYYY - hh:mm A')
        const keywordKey = draw.parameters.allTags?.find(x => /^keyword:[0-9a-f]{32}$/.test(x))?.split(':')[1]
        const keywordLocationKey = draw.parameters.allTags?.find(x => /^location:[0-9a-f]{32}$/.test(x))?.split(':')[1]
        const keyword = keywordsQuery.data?.find(x => x.keywordKey === keywordKey)
        const keywordLocation = keywordLocationsQuery.data?.find(x => x.keywordKey === keywordKey && x.keywordLocationKey === keywordLocationKey)
        let updatedRecord = { ...draw, drawDate, prizePoolName, entryStart, entryEnd, getcounts, keyword, keywordLocation }
        updatedGrandprizeDraws.push(updatedRecord)
        return updatedRecord

      })
      await Promise.all(promises).then(res =>
        setgrandprizedraws(updatedGrandprizeDraws)
      )

    }
    setLoading(false)
  }
  useEffect(() => {
    getUpdatedRecords()
    // eslint-disable-next-line
  }, [draws, keywordsQuery.isLoading, keywordLocationsQuery.isLoading])

  const columns = PrizeDrawColumns({
    navigate
  })

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont">
        <div id="tab08" className="tab-contents prizedraws">
          <div>
            <div className="head inner">
              <h3>Prize Draws</h3>
              <button className="btn" onClick={handlePrizeDraw} disabled={loading ? true : false} >Conduct Prize Draw</button>
            </div>
            {loadingDraws || loading ? <div className="spinner"><Spinner animation="border" variant="secondary" /></div> :
              grandprizedraws && (grandprizedraws.length > 0 ? <Table columns={columns} data={grandprizedraws} tablePageSize={12} sortbyid="drawDate" /> : <p>There are no prize draws conducted.</p>)}
          </div>
        </div>
      </div>
      <Popup
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        header=""
        message="There are no grand prize entries for this campaign"
        action1={() => setIsOpen(false)}
        yesMessage="Okay"
      ></Popup>
    </>
  )
}

export default CampaignPrizeDraws;
