import { useState, useMemo, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { spryClient } from "../../../api";
import { Table } from "../../../Components";
import { PrizesColumns } from "./PrizesColumns";
import { Modal } from "../../../Components/Modal";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type typeProp = {
  campaignKey: string;
};
type PrizePoolForm = {
  prizePoolName: string;
};
type AddPrizeForm = {
  prizeKey: string;
};

const UpdatePrize = ({ campaignKey }: typeProp) => {
  const { prizePoolKey } = useParams();
  const { data: allPrizes, isLoading: loadingPrizes } = useQuery(
    ["getPrizes", campaignKey],
    async () => {
      const res = await spryClient.getPrizes({ campaignKey });
      return res.prizes;
    }
  );
  const { data: prizePoolPrizes, isLoading: loadingPrizePoolPrizes } = useQuery(
    ["getPrizePoolPrizes", campaignKey, allPrizes],
    async () => {
      const res = await spryClient.getPrizePoolPrizes({ campaignKey, prizePoolKey });
      const updatePrizes = res.prizePoolPrizes
        .filter((m) => m.active)
        .map((p) => {
          let prize = allPrizes?.find(
            (q) => q.prizeKey === p.prizeKey
          )?.prizeName;
          return { ...p, prizeName: prize };
        });
      return updatePrizes;
    },
    { enabled: !!allPrizes }
  );
  const { data: prizePool, isLoading: loadingPrizePools } = useQuery(
    ["getPrizePools1", prizePoolKey],
    async () => {
      const res = await spryClient.getPrizePools({ campaignKey });

      return res.prizePools.find((p) => p.prizePoolKey === prizePoolKey);
    }
  );

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PrizePoolForm>({
    mode: "onTouched",
    defaultValues: {
      prizePoolName: "",
    },
  });
  const {
    register: prizeFormRegister,
    handleSubmit: prizeFormSubmit,
    formState: { errors: prizeFormErrors },
  } = useForm<AddPrizeForm>({
    mode: "onTouched",
    defaultValues: {
      prizeKey: "",
    },
  });

  useEffect(() => {
    reset({ prizePoolName: prizePool?.prizePoolName });
  }, [prizePool, reset]);

  const [deletePrizeKey, setdeletePrizeKey] = useState("");
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [showdeletePrizeModal, setshowdeletePrizeModal] = useState(false);

  const { mutate: deletepool } = useMutation(spryClient.deletePrizePool, {
    onSuccess: (data) => {
      showToast({
        content: "Prize Pool has been deleted successfully",
        duration: 3000,
        error: false,
      });
      setLoading(false);
    },
    onError: (res: any) => {
      showToast({
        content: "Prize pool is already associated with an instant timestamp ",
        duration: 3000,
        error: true,
      });
      setLoading(false);
    },
  });
  const { mutate: mutateUpdate, isLoading: updateLoading } = useMutation(
    spryClient.updatePrizePool,
    {
      onSuccess: (data) => {
        showToast({
          content: "Prize Pool has been updated successfully",
          duration: 3000,
          error: false,
        });
      },
      onError: (response) => {
        showToast({
          content: "Error while updating prizePool",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getPrizePools");
      },
    }
  );

  const { mutate, isLoading } = useMutation(spryClient.addPrizeToPool, {
    onSuccess: (data) => {
      showToast({
        content: "Prize has been added successfully",
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      showToast({
        content: "Error while adding prize to the prize pool",
        duration: 3000,
        error: true,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("getPrizePoolPrizes");
    },
  });
  const { mutate: removePrizePoolPrize, isLoading: isremoveloading } =
    useMutation(spryClient.removePrizeFromPool, {
      onError: () => {
        showToast({
          content: "Error while removing prize from the prize pool",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getPrizePoolPrizes");
      },
    });
  const handleUpdate = async ({ prizeKey }: AddPrizeForm) => {
    mutate({ prizeKey, prizePoolKey: prizePoolKey! });
  };

  const prizescolumns = useMemo(
    () => PrizesColumns({ setshowdeletePrizeModal, setdeletePrizeKey }),
    [setshowdeletePrizeModal, setdeletePrizeKey]
  );
  const deletePoolHanlder = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (prizePoolPrizes && prizePoolPrizes.length > 0) {
      setShowModal(false);
      showToast({
        content: "Prize pool is already associated with a prize",
        duration: 3000,
        error: true,
      });
      setLoading(false);
    } else {
      prizePoolKey && deletepool({ prizePoolKey });
      setShowModal(false);
    }
  };
  const showdeleteModal = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const updatePoolName = ({ prizePoolName }: PrizePoolForm) => {
    prizePoolKey && mutateUpdate({ prizePoolKey, prizePoolName });
  };
  const removeprizehandler = () => {
    setshowdeletePrizeModal(false);
    removePrizePoolPrize({
      prizePoolKey: prizePoolKey!,
      prizeKey: deletePrizeKey,
    });
  };

  if (loadingPrizePools || loadingPrizes || loadingPrizePoolPrizes) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }
  return (
    <>
      {showdeletePrizeModal && (
        <div className="row no-gutters">
          <Modal
            notification={false}
            calldelete={removeprizehandler}
            handleClose={handleClose}
            modalType="DeleteConfirmPopUp"
            header="Delete ?"
            message="Are you sure you want to remove the prize?"
          />
        </div>
      )}
      {showModal && (
        <div className="row no-gutters">
          <Modal
            notification={false}
            calldelete={deletePoolHanlder}
            handleClose={handleClose}
            modalType="DeleteConfirmPopUp"
            header="Delete ?"
            message="Are you sure you want to delete this PrizePool?"
          />
        </div>
      )}

      <div className="dashboardContent campaignDetail tabsCont addpg prizing">
        <div id="tab04" className="tab-contents addprizepool">
          <div className="head inner">
            <h3>
              <button className="backBtn">
                <i
                  className="fas fa-arrow-left"
                  onClick={() => {
                    navigate(`/campaign/${campaignKey}/prizing`);
                  }}
                ></i>
              </button>
              Prize Pool Details
            </h3>
            <button className="btn addbtn" onClick={showdeleteModal}>
              {" "}
              Delete{" "}
            </button>
          </div>

          <form onSubmit={handleSubmit(updatePoolName)}>
            <div className="formContent">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="form-group">
                    <label>Prize Pool Name</label>
                    {updateLoading || loading ? (
                      <div className="spinner">
                        <Spinner animation="border" variant="secondary" />
                      </div>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        {...register("prizePoolName", {
                          required: {
                            value: true,
                            message: "Please enter a prize pool name.",
                          },
                        })}
                        aria-describedby="prizePoolName"
                        placeholder="prizePoolName"
                        id="exampleFormControlInput1"
                      />
                    )}
                  </div>
                  {errors.prizePoolName && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      {errors.prizePoolName.message}
                    </div>
                  )}
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <button className=" update-btn" type="submit">
                      {" "}
                      Update{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {isLoading || isremoveloading || loading ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            prizePoolPrizes &&
            (prizePoolPrizes.length > 0 ? (
              <Table
                columns={prizescolumns}
                data={prizePoolPrizes}
                tablePageSize={10}
              />
            ) : (
              <p> There are no prizes added. </p>
            ))
          )}

          <form onSubmit={prizeFormSubmit(handleUpdate)}>
            <div className="addbtnPrizePool">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Prize</label>
                    <select
                      {...prizeFormRegister("prizeKey", {
                        required: {
                          value: true,
                          message: "Please select a prize.",
                        },
                      })}
                      className="form-control"
                      id="exampleFormControlSelect2"
                    >
                      <option value="">Please select a Prize</option>
                      {allPrizes?.map((arr: any) => {
                        return (
                          <option
                            value={arr.prizeKey}
                            key={arr.prizeKey}
                            disabled={
                              prizePoolPrizes?.find(
                                (p) => p.prizeKey === arr.prizeKey
                              )
                                ? true
                                : false
                            }
                          >
                            {arr.prizeName}
                          </option>
                        );
                      })}
                    </select>
                    {prizeFormErrors.prizeKey && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {prizeFormErrors.prizeKey.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="actionBtns">
                    <button className="btn outline add" type="submit">
                      {" "}
                      Add Prize
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdatePrize;
