export enum EQuoteStatuses {
    "draft" = "0",
    "estimationReady" = "1",
    "decisionReady" = "2",
    "approved" = "3",
    "rejected" = "4",
    "changesRequested" = "5",
    "onHold" = "6",
    "archived" = "7",
}
export const EProgramQuoteStatus: { [key: string]: string } = {
    '00': 'Draft',
    '01': 'Esitmation Pending',
    '02': 'Decision Pending',
    "07": "Rejected",
    '23': 'Approved',
    '21': 'Estimation pending',
    '22': 'Decision Pending',
    '33': 'Approved',
    "27": "Rejected",
}

export const EProgramStatus: { [key: string]: string } = {
    '0': 'Quoting',
    '1': 'Approved',
    '2': 'ProgramDetails',
    '3': 'Development',
    '4': 'Live',
    '5': 'Closed',
}

