import { Button, Spinner } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import icoview from "../../assets/images/ico-view.png";
import { spryClient } from "../../api";
import { showToast } from "../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import { passwordRegex } from "../../utils/helpers";
import {
  Agency,
  Brand,
} from "@sprycore/spry-api-client/dist/ReturnTypes";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  brands: Brand[];
  agencies: Agency[];
};
type AddCampaignForm = {
  campaignName: string;
  agencyKey: string;
  brandKey: string;
  programCode: string;
  timeZone: string;
  metadata: {
    password: string;
  };
};

const AddCampaign = ({
  showSidebar,
  closeHandler,
  brands,
  agencies,
}: AppProps) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddCampaignForm>({
    defaultValues: {
      campaignName: "",
      agencyKey: "",
      brandKey: "",
      programCode: "",
      timeZone: "",
      metadata: {
        password: "",
      },
    },
    mode: "onTouched",
  });

  const { mutate, isLoading } = useMutation(spryClient.createCampaign, {
    onSuccess: (data) => {
      closeHandler(false);
      showToast({
        content: "Campaign has been created!",
        duration: 3000,
        error: false,
      });
      reset();
    },
    onError: (e) => {
      showToast({
        content: `${e}`,
        duration: 3000,
        error: true,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("getCampaigns");
    },
  });

  const hanldeViewPassword = () => {
    document.getElementById("campaignPassword")?.setAttribute("type", "text");
  };

  const hanldeHidePassword = () => {
    document
      .getElementById("campaignPassword")
      ?.setAttribute("type", "password");
  };

  const handleAddCampaign = async (data: AddCampaignForm) => {
    await mutate(data);
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Add campaign</h2>
        <span
          className="closeSideBar"
          onClick={(e) => {
            closeHandler(false);
          }}
          style={{cursor:"pointer"}}
        >
          <i className="fas fa-times"></i>
        </span>

        <form onSubmit={handleSubmit(handleAddCampaign)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="campaignName">Campaign name</label>
              <input
                {...register("campaignName", {
                  required: {
                    value: true,
                    message: "Please enter a campaign name.",
                  },
                })}
                placeholder="Campaign Name"
                id="campaignName"
                className="form-control"
              />
              {errors.campaignName && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  <>{errors.campaignName?.message}</>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Agency</label>
              <select
                className="form-control"
                {...register("agencyKey", {
                  required: {
                    value: true,
                    message: "Please select an agency.",
                  },
                })}
              >
                <option value="">Please select an agency</option>
                {agencies?.map((agency) => (
                  <option value={agency.agencyKey} key={agency.agencyKey}>
                    {agency.agencyName}
                  </option>
                ))}
              </select>
              {errors.agencyKey && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  <>{errors.agencyKey?.message}</>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Brand</label>
              <select
                className="form-control"
                {...register("brandKey", {
                  required: { value: true, message: "Please select an brand." },
                })}
              >
                <option value="">Please select a brand</option>
                {brands?.map((brand) => (
                  <option value={brand.brandKey} key={brand.brandKey}>
                    {brand.brandName}
                  </option>
                ))}
              </select>
              {errors.brandKey && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  <>{errors.brandKey?.message}</>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Campaign timezone</label>
              <select
                className="form-control"
                {...register("timeZone", {
                  required: {
                    value: false,
                    message: "Please select a timezone.",
                  },
                })}
                id="exampleFormControlSelect1"
              >
                <option>Select a timezone</option>
                <option>Eastern Standard Time, GMT-5</option>
                <option>Newfoundland Standard Time, GMT-3:30</option>
                <option>Atlantic Standard Time, GMT-4</option>
                <option>Central Standard Time, GMT-6</option>
                <option>Mountain Standard Time, GMT-7</option>
                <option>Pacific Standard Time, GMT-8</option>
              </select>
            </div>
            <div className="form-group">
              <div className="passwordControl">
                <label htmlFor="exampleInputEmail1">Campaign password</label>
                <span
                  className="viewPass"
                  onMouseDown={hanldeViewPassword}
                  onMouseUp={hanldeHidePassword}
                >
                  <img src={icoview} alt="View Password" />
                </span>
                <input
                  type="password"
                  id="campaignPassword"
                  className="form-control"
                  {...register("metadata.password", {
                    required: {
                      value: false,
                      message: "Please enter a password.",
                    },
                    pattern: {
                      value: passwordRegex,
                      message: "Please enter a valid password.",
                    },
                  })}
                  placeholder="Campaign password"
                />
                {errors.metadata?.password && (
                  <div className="error">
                    <i className="fas fa-exclamation-circle" />
                    <>{errors.metadata.password.message}</>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Program code</label>
              <input
                {...register("programCode", {
                  required: {
                    value: true,
                    message: "Please enter a program code.",
                  },
                })}
                placeholder="Program code"
                id="programCode"
                className="form-control"
              />

              {errors.programCode && (
                <div className="error">
                  <i className="fas fa-exclamation-circle" />
                  <>{errors.programCode.message}</>
                </div>
              )}
            </div>
            <div className="actionBtns">
              {isLoading ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button type="submit" className="btn">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default AddCampaign;
