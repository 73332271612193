import { useNavigate, useParams } from "react-router-dom"
import { spryClient } from "../../../api"
import { useQuery, useQueryClient } from "react-query"
import { Spinner } from "react-bootstrap"
import { useState } from "react"

type CampaignEditKeywordProps = {
    campaignKey: string
}

export default function CampaignEditKeywordLocation({ campaignKey }: CampaignEditKeywordProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [updating, setUpdating] = useState(false)
    const [updateDynamicSettingsStatus, setUpdateDynamicSettingsStatus] = useState("")
    const [dynamicSettings, setDynamicSettings] = useState("{}")
    const [newName, setNewName] = useState("")

    const { keywordKey, keywordLocationKey } = useParams() as { keywordKey: string, keywordLocationKey: string }

    const keywordLocationQuery = useQuery(
        ["getKeywordLocations", campaignKey, keywordKey, keywordLocationKey],
        async () => {
            const { keywordLocations: [keywordLocation] } = await spryClient.getKeywordLocations({ campaignKey, keywordKey, keywordLocationKey })
            setNewName(keywordLocation.name)
            setDynamicSettings(JSON.stringify(keywordLocation.dynamicSettings, undefined, 2))
            return keywordLocation
        })

    function tryParseDynamicSettings() {
        try { return JSON.parse(dynamicSettings) } catch { }
    }

    const loading = keywordLocationQuery.isLoading
    const keywordLocation = loading ? undefined : keywordLocationQuery.data

    async function updateDynamicSettings() {
        if (updating) { return }
        const settings = tryParseDynamicSettings()
        if (!settings) {
            setUpdateDynamicSettingsStatus("Invalid JSON")
            return
        }
        setUpdating(true)
        try {
            await spryClient.updateKeywordLocationDynamicSettings({ campaignKey, keywordLocationKey, settings })
            setUpdateDynamicSettingsStatus("Saved")
        }
        catch {
            setUpdateDynamicSettingsStatus("Error saving dynamic settings")
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getKeywordLocations")
        }
    }

    async function updateName() {
        if (updating || !keywordLocation || !newName.trim()) { return }
        setUpdating(true)
        try {
            await spryClient.updateKeywordLocation({ campaignKey, keywordLocationKey, active: keywordLocation.active, name: newName.trim() })
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getKeywordLocations")
        }
    }
    async function toggleActive() {
        if (updating || !keywordLocation) { return }
        setUpdating(true)
        try {
            await spryClient.updateKeywordLocation({ campaignKey, keywordLocationKey, active: !keywordLocation.active, name: keywordLocation.name })
        }
        finally {
            setUpdating(false)
            queryClient.invalidateQueries("getKeywordLocations")
        }
    }
    const canUpdateDynamicSettings = !updating && tryParseDynamicSettings()

    return <>
        <div className="dashboardContent campaignDetail tabsCont">
            <button className="btn btn-primary" onClick={() => navigate(`/campaign/${campaignKey}/keywords/${keywordKey}`)}>Back</button>
            <div className="head inner">
                <h2>Edit Keyword Location {keywordLocation && ` [${keywordLocation.name}]`}</h2>
            </div>
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {keywordLocation && <>

                    <h3>Name</h3>
                    <input value={newName} onChange={e => setNewName(e.target.value)} disabled={updating} />
                    <button onClick={updateName} disabled={updating}>Update Name</button>

                    <h3>Active</h3>
                    <p>Status: {keywordLocation.active ? "active" : "inactive"}</p>
                    <button onClick={toggleActive} disabled={updating}>Toggle</button>

                    <h3>Dynamic Settings (JSON)</h3>
                    <textarea value={dynamicSettings} onChange={e => setDynamicSettings(e.target.value)} style={{ width: "80%", minHeight: "10em" }} disabled={updating}></textarea><br />
                    <button onClick={updateDynamicSettings} disabled={!canUpdateDynamicSettings}>Save</button>
                    <span>{updateDynamicSettingsStatus}</span>
                </>}
            </div>
        </div>
    </>
}