import { makeAutoObservable } from "mobx";

class filtersStore {
  filters = {
    agency: '',
    brand: ''
  }

  constructor() {
    makeAutoObservable(this);
  }

  setagencyFilter(agency: string) {
    this.filters.agency = agency
  }
  setbrandfilter(brand: string){
    this.filters.brand = brand
  }
}

export default filtersStore;