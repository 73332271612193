import { useNavigate, useParams } from "react-router-dom"
import { spryClient } from "../../../api"
import { useQuery, useQueryClient } from "react-query"
import { Spinner } from "react-bootstrap"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { uniqueStringArray } from "../../../utils/helpers"
import { Table } from "../../../Components"
import { Keyword, KeywordLocation } from "@sprycore/spry-api-client/dist/ReturnTypes"
import { generateKey } from "@sprycore/spry-api-client"

type CampaignEditKeywordProps = {
    campaignKey: string
}

type BasicSettingsForm = {
    contestType: string
    backgroundColor: string
    textColor: string
    buttonBackgroundColor: string
    buttonTextColor: string
    webHeaderImageUrl: string
    mobileHeaderImageUrl: string
    emailHeaderImageUrl: string
    webFontFamily: string
    emailFontFamily: string
    primaryPrizePoolKey: string
    provinces: string
    needDeclarationAndRelease: boolean
    ageGate: boolean
}


export default function CampaignEditKeyword({ campaignKey }: CampaignEditKeywordProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { keywordKey } = useParams() as { keywordKey: string }
    const [newStartTime, setNewStartTime] = useState("")
    const [newEndTime, setNewEndTime] = useState("")
    const [updatingTimes, setUpdatingTimes] = useState(false)
    const [updateTimesStatus, setUpdateTimesStatus] = useState("")
    const [updatingBasicSettings, setUpdatingBasicSettings] = useState(false)
    const [updateBasicSettingsStatus, setUpdateBasicSettingsStatus] = useState("")
    const [updatingDynamicSettings, setUpdatingDynamicSettings] = useState(false)
    const [updateDynamicSettingsStatus, setUpdateDynamicSettingsStatus] = useState("")
    const [dynamicSettings, setDynamicSettings] = useState("{}")
    const [newLocation, setNewLocation] = useState("")
    const [addingNewLocation, setAddingNewLocation] = useState(false)
    const [addNewLocationStatus, setAddNewLocationStatus] = useState("")
    const [importExportJson, setImportExportJson] = useState("")
    const [importJsonSummary, setImportJsonSummary] = useState<string[]>([])

    const basicSettingsForm = useForm<BasicSettingsForm>()

    async function addNewLocation() {
        if (!newLocation || addingNewLocation) { return }
        setAddingNewLocation(true)
        setAddNewLocationStatus("")
        try {
            const keywordLocationKey = generateKey()
            await spryClient.createKeywordLocation({ campaignKey, keywordKey, keywordLocationKey, active: true, name: newLocation })
            setNewLocation("")
        }
        catch {
            setAddNewLocationStatus("Error adding new location")
        }
        finally {
            setAddingNewLocation(false)
            queryClient.invalidateQueries("getKeywordLocations")
        }
    }

    const keywordQuery = useQuery(
        ["getKeywords", campaignKey, keywordKey],
        async () => {
            const { keywords: [keyword] } = await spryClient.getKeywords({ campaignKey, keywordKey })
            setNewStartTime(keyword.startTime.toISOString())
            setNewEndTime(keyword.endTime.toISOString())
            basicSettingsForm.reset({
                contestType: keyword.basicSettings.contestType || "",
                backgroundColor: keyword.basicSettings.backgroundColor || "",
                textColor: keyword.basicSettings.textColor || "",
                buttonBackgroundColor: keyword.basicSettings.buttonBackgroundColor || "",
                buttonTextColor: keyword.basicSettings.buttonTextColor || "",
                webHeaderImageUrl: keyword.basicSettings.webHeaderImageUrl || "",
                mobileHeaderImageUrl: keyword.basicSettings.mobileHeaderImageUrl || "",
                emailHeaderImageUrl: keyword.basicSettings.emailHeaderImageUrl || "",
                webFontFamily: keyword.basicSettings.webFontFamily || "",
                emailFontFamily: keyword.basicSettings.emailFontFamily || "",
                provinces: keyword.basicSettings.provinces?.join() || "",
                primaryPrizePoolKey: keyword.basicSettings.primaryPrizePoolKey || "",
                needDeclarationAndRelease: keyword.basicSettings.needDeclarationAndRelease,
                ageGate: keyword.basicSettings.ageGate
            })
            setDynamicSettings(JSON.stringify(keyword.dynamicSettings, undefined, 2))
            return keyword
        })
    const prizePoolsQuery = useQuery(
        ["getPrizePools", campaignKey],
        async () => {
            const { prizePools } = await spryClient.getPrizePools({ campaignKey })
            return prizePools
        })
    const keywordLocationsQuery = useQuery(
        ["getKeywordLocations", campaignKey, keywordKey],
        async () => {
            const { keywordLocations } = await spryClient.getKeywordLocations({ campaignKey, keywordKey })
            return keywordLocations
        })

    function tryParseDynamicSettings() {
        try { return JSON.parse(dynamicSettings) } catch { }
    }

    const loading = keywordQuery.isLoading || prizePoolsQuery.isLoading || keywordLocationsQuery.isLoading
    const keyword = loading ? undefined : keywordQuery.data
    const keywordLocations = loading ? undefined : keywordLocationsQuery.data
    const prizePools = loading ? undefined : prizePoolsQuery.data

    const canUpdateTimes = !updatingTimes && keyword && !!newStartTime && !!newEndTime && !isNaN(+new Date(newStartTime)) && !isNaN(+new Date(newEndTime)) && new Date(newStartTime) <= new Date(newEndTime)
    const canUpdateBasicSettings = !updatingBasicSettings && keyword && prizePools
    const canUpdateDynamicSettings = !updatingDynamicSettings && keyword && tryParseDynamicSettings()

    async function updateTimes() {
        if (updatingTimes) { return }
        setUpdateTimesStatus("")
        const startTime = new Date(newStartTime)
        const endTime = new Date(newEndTime)
        if (isNaN(+startTime) || isNaN(+endTime) || startTime > endTime) { return }
        setUpdatingTimes(true)
        try {
            await spryClient.updateKeyword({ campaignKey, keywordKey, startTime, endTime })
            setUpdateTimesStatus("Saved")
        }
        catch {
            setUpdateTimesStatus("Error updating times")
        }
        finally {
            setUpdatingTimes(false)
            queryClient.invalidateQueries("getKeywords")
        }
    }

    function stringToProvinces(s: string) { return uniqueStringArray(s.split(',').map(x => x.trim().toUpperCase()).filter(x => x)).sort() }

    async function submitBasicSettings(values: BasicSettingsForm) {
        if (updatingBasicSettings) { return }
        const provinces = stringToProvinces(values.provinces)
        if (provinces.some(x => x.length !== 2)) {
            setUpdateBasicSettingsStatus("Each province should be a 2 character short form")
            return
        }
        setUpdateBasicSettingsStatus("")
        setUpdatingBasicSettings(true)
        try {
            await spryClient.updateKeywordBasicSettings({
                campaignKey,
                keywordKey,
                contestType: values.contestType.trim(),
                backgroundColor: values.backgroundColor.trim(),
                textColor: values.textColor.trim(),
                buttonBackgroundColor: values.buttonBackgroundColor.trim(),
                buttonTextColor: values.buttonTextColor.trim(),
                emailFontFamily: values.emailFontFamily.trim(),
                webFontFamily: values.webFontFamily.trim(),
                mobileHeaderImageUrl: values.mobileHeaderImageUrl.trim(),
                emailHeaderImageUrl: values.emailHeaderImageUrl.trim(),
                webHeaderImageUrl: values.webHeaderImageUrl.trim(),
                provinces: provinces.length ? provinces : undefined,
                primaryPrizePoolKey: values.primaryPrizePoolKey.trim(),
                needDeclarationAndRelease: values.needDeclarationAndRelease,
                ageGate: values.ageGate
            })
            setUpdateBasicSettingsStatus("Saved")
        }
        catch {
            setUpdateBasicSettingsStatus("Error updating basic settings")
        }
        finally {
            setUpdatingBasicSettings(false)
            queryClient.invalidateQueries("getKeywords")
        }
    }

    function insertProvinces(newProvinces: string[]) {
        const provinces = stringToProvinces(basicSettingsForm.getValues("provinces"))
        provinces.push(...newProvinces)
        basicSettingsForm.setValue("provinces", stringToProvinces(provinces.join()).join())
    }

    function insertProvincesCA() {
        insertProvinces(["AB", "BC", "MB", "SK", "ON", "QC", "PE", "NL", "NS", "NB"])
    }
    function insertTerritoriesCA() {
        insertProvinces(["YT", "NT", "NU"])
    }
    function insertStatesUS() {
        insertProvinces(["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"])
    }
    function insertTerritoriesUS() {
        insertProvinces(["GU", "AS", "MP", "PR", "TT", "VI"])
    }

    async function updateDynamicSettings() {
        if (updatingDynamicSettings) { return }
        const settings = tryParseDynamicSettings()
        if (!settings) {
            setUpdateDynamicSettingsStatus("Invalid JSON")
            return
        }
        setUpdatingDynamicSettings(true)
        try {
            await spryClient.updateKeywordDynamicSettings({ campaignKey, keywordKey, settings })
            setUpdateDynamicSettingsStatus("Saved")
        }
        catch {
            setUpdateDynamicSettingsStatus("Error saving dynamic settings")
        }
        finally {
            setUpdatingDynamicSettings(false)
            queryClient.invalidateQueries("getKeywords")
        }
    }

    const locationsColumns = [
        {
            Header: "Location",
            Cell: (table: any) => {
                const row = table.row.original as KeywordLocation
                return <div>{row.name}</div>
            }
        },
        {
            Header: "Active",
            Cell: (table: any) => {
                const row = table.row.original as KeywordLocation
                return <div>{row.active.toString()}</div>
            }
        },
        {
            Header: "Dynamic Settings",
            Cell: (table: any) => {
                const row = table.row.original as KeywordLocation
                return <div>
                    {JSON.stringify(row.dynamicSettings)}
                </div>
            }
        },
        {
            Header: "Options",
            Cell: (table: any) => {
                const row = table.row.original as KeywordLocation
                return <div className="keywordRow"><button className="btn btn-sm outline" onClick={() => navigate(`/campaign/${campaignKey}/keywords/${row.keywordKey}/location/${row.keywordLocationKey}`)}>Edit</button></div>
            }
        }
    ]

    function exportJson() {
        updateJson(JSON.stringify({ keyword, keywordLocations }, null, 2))
    }

    async function importJson() {
        if (!keyword || !keywordLocations) { return }
        let data: { keyword: Keyword, keywordLocations: KeywordLocation[] }
        const summary: string[] = []
        try {
            data = JSON.parse(importExportJson)
            if (!data.keyword || !data.keywordLocations?.length) { return }
        }
        catch (e) {
            console.error(e)
            return
        }
        if (!window.confirm("Import data into this keyword?")) { return }
        try {
            await spryClient.updateKeyword({
                campaignKey: keyword.campaignKey,
                keywordKey: keyword.keywordKey,
                startTime: data.keyword.startTime,
                endTime: data.keyword.endTime
            })
            summary.push("Start/end time updated")
            await spryClient.updateKeywordBasicSettings(Object.assign({}, data.keyword.basicSettings, {
                campaignKey: keyword.campaignKey,
                keywordKey: keyword.keywordKey
            }))
            summary.push("Basic settings updated")
            if (JSON.stringify(keyword.dynamicSettings) !== JSON.stringify(data.keyword.dynamicSettings)) {
                await spryClient.updateKeywordDynamicSettings({
                    campaignKey: keyword.campaignKey,
                    keywordKey: keyword.keywordKey,
                    settings: data.keyword.dynamicSettings
                })
                summary.push("Dynamic settings updated")
            }
            for (const location of data.keywordLocations) {
                let match: KeywordLocation = keywordLocations.find(x => x.name === location.name)!
                if (match) {
                    if (match.active !== location.active) {
                        await spryClient.updateKeywordLocation({
                            campaignKey: keyword.campaignKey,
                            keywordLocationKey: match.keywordLocationKey,
                            name: match.name,
                            active: location.active
                        })
                        summary.push(`Location ${match.name} updated`)
                    }
                }
                else {
                    match = await spryClient.createKeywordLocation({
                        campaignKey: keyword.campaignKey,
                        keywordKey: keyword.keywordKey,
                        keywordLocationKey: location.keywordLocationKey,
                        name: location.name,
                        active: location.active
                    })
                    summary.push(`Location ${match.name} added`)
                }
                if (JSON.stringify(match.dynamicSettings) !== JSON.stringify(location.dynamicSettings)) {
                    await spryClient.updateKeywordLocationDynamicSettings({
                        campaignKey: keyword.campaignKey,
                        keywordLocationKey: match.keywordLocationKey,
                        settings: location.dynamicSettings
                    })
                    summary.push(`Location ${match.name} dynamic settings updated`)
                }
            }
        }
        catch (e: any) {
            console.error(e)
            summary.push(e.toString())
        }
        finally {
            setImportJsonSummary(summary)
            queryClient.invalidateQueries("getKeywords")
            queryClient.invalidateQueries("getKeywordLocations")
        }
    }

    function updateJson(json: string) {
        if (!keyword || !keywordLocations) { return }
        setImportExportJson(json)
        let data: { keyword: Keyword, keywordLocations: KeywordLocation[] }
        const summary: string[] = []
        try {
            data = JSON.parse(json)
            if (data.keyword.campaignKey !== keyword.campaignKey) {
                summary.push(`Copying data from a DIFFERENT CAMPAIGN!`)
            }
            if (data.keyword.word !== keyword.word) {
                summary.push(`Copying data from a DIFFERENT KEYWORD: ${data.keyword.word}`)
            }
            if (data.keyword.startTime as any !== keyword.startTime.toISOString()) {
                summary.push(`Start time ${keyword.startTime.toISOString()} -> ${data.keyword.startTime}`)
            }
            if (data.keyword.endTime as any !== keyword.endTime.toISOString()) {
                summary.push(`End time ${keyword.endTime.toISOString()} -> ${data.keyword.endTime}`)
            }
            for (const key of Object.keys(keyword.basicSettings)) {
                if (["creationTime", "updateTime"].indexOf(key) !== -1) { continue }
                let current = (keyword.basicSettings as any)[key]
                let updated = (data.keyword.basicSettings as any)[key]
                if (Array.isArray(current)) {
                    current = current.join()
                    updated = updated.join()
                }
                if (current !== updated) {
                    summary.push(`basicSettings.${key} ${current} -> ${updated}`)
                }
            }
            if (JSON.stringify(keyword.dynamicSettings) !== JSON.stringify(data.keyword.dynamicSettings)) {
                summary.push(`dynamic settings will be updated`)
            }
            for (const location of data.keywordLocations) {
                const match = keywordLocations.find(x => x.name === location.name)
                if (!match) {
                    summary.push(`Location ${location.name} will be added`)
                }
                else {
                    if (match.active !== location.active) {
                        summary.push(`Location ${location.name} active ${match.active} -> ${location.active}`)
                    }
                    if (JSON.stringify(match.dynamicSettings) !== JSON.stringify(location.dynamicSettings)) {
                        summary.push(`Location ${location.name} dynamic settings will be updated`)
                    }
                }
            }
        }
        catch (e) {
            console.error(e)
            summary.push("Invalid JSON")
            return
        }
        finally {
            setImportJsonSummary(summary)
        }
    }

    return <>
        <div className="dashboardContent campaignDetail tabsCont editKeyword">

            <div className="head inner">
                <h2><button className="backBtn"
                    onClick={() => navigate(`/campaign/${campaignKey}/keywords`)}><i className="fas fa-arrow-left" aria-hidden="true"></i></button>
                    Edit Keyword {keyword && ` [${keyword.word}]`}</h2>
            </div>
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {prizePools && keyword && <>
                    <h3>Dates</h3>

                    <label htmlFor="startTime">Start Time</label>
                    <input name="startTime" className="form-control" value={newStartTime} onChange={e => setNewStartTime(e.target.value)} />
                    <label htmlFor="endTime">End Time</label>
                    <input name="endTime" className="form-control" value={newEndTime} onChange={e => setNewEndTime(e.target.value)} />

                    <button className="btn mt-2 px-4" onClick={updateTimes} disabled={!canUpdateTimes}>Update</button>
                    <span>{updateTimesStatus}</span>

                    <form onSubmit={basicSettingsForm.handleSubmit(submitBasicSettings)}>
                        <h3>Basic Settings</h3>

                        <label htmlFor="contestType">Contest Type</label>
                        <select {...basicSettingsForm.register("contestType")} className="form-control">
                            <option value="">[None]</option>
                            <option value="ITEM">Item</option>
                            <option value="TICKET">Ticket</option>
                            <option value="TRIP">Trip</option>
                        </select>
                        {basicSettingsForm.formState.errors.contestType?.message}

                        <label htmlFor="backgroundColor">Background Color</label>
                        <input {...basicSettingsForm.register("backgroundColor")} className="form-control" />
                        {basicSettingsForm.formState.errors.backgroundColor?.message}

                        <label htmlFor="textColor">Text Color</label>
                        <input {...basicSettingsForm.register("textColor")} className="form-control" />
                        {basicSettingsForm.formState.errors.textColor?.message}

                        <label htmlFor="buttonBackgroundColor">Button Background Color</label>
                        <input {...basicSettingsForm.register("buttonBackgroundColor")} className="form-control" />
                        {basicSettingsForm.formState.errors.buttonBackgroundColor?.message}

                        <label htmlFor="buttonTextColor">Button Text Color</label>
                        <input {...basicSettingsForm.register("buttonTextColor")} className="form-control" />
                        {basicSettingsForm.formState.errors.buttonTextColor?.message}

                        <label htmlFor="webHeaderImageUrl">Web Header Image Url</label>
                        <input {...basicSettingsForm.register("webHeaderImageUrl")} className="form-control" />
                        {basicSettingsForm.formState.errors.webHeaderImageUrl?.message}

                        <label htmlFor="mobileHeaderImageUrl">Mobile Header Image Url</label>
                        <input {...basicSettingsForm.register("mobileHeaderImageUrl")} className="form-control" />
                        {basicSettingsForm.formState.errors.mobileHeaderImageUrl?.message}

                        <label htmlFor="emailHeaderImageUrl">Email Header Image Url</label>
                        <input {...basicSettingsForm.register("emailHeaderImageUrl")} className="form-control" />
                        {basicSettingsForm.formState.errors.emailHeaderImageUrl?.message}

                        <label htmlFor="webFontFamily">Web Font Family</label>
                        <input {...basicSettingsForm.register("webFontFamily")} className="form-control" />
                        {basicSettingsForm.formState.errors.webFontFamily?.message}

                        <label htmlFor="emailFontFamily">Email Font Family</label>
                        <input {...basicSettingsForm.register("emailFontFamily")} className="form-control" />
                        {basicSettingsForm.formState.errors.emailFontFamily?.message}

                        <label htmlFor="provinces">Provinces (example: AB,BC,MB,SK,ON)</label>
                        <input {...basicSettingsForm.register("provinces")} className="form-control" />
                        {basicSettingsForm.formState.errors.provinces?.message}
                        <button type="button" className="btn action-buttons-sm mr-2 my-2" onClick={insertProvincesCA}>Insert Canadian Provinces</button>
                        <button type="button" className="btn action-buttons-sm m-2" onClick={insertTerritoriesCA}>Insert Canadian Territories</button>
                        <button type="button" className="btn action-buttons-sm m-2" onClick={insertStatesUS}>Insert US States</button>
                        <button type="button" className="btn action-buttons-sm m-2" onClick={insertTerritoriesUS}>Insert US Territories</button>
                        <br />



                        <label htmlFor="primaryPrizePoolKey">Primary Prize Pool</label>
                        <select {...basicSettingsForm.register("primaryPrizePoolKey")} className="form-control">
                            <option value="">[None]</option>
                            {prizePools.map(x => <option key={x.prizePoolKey} value={x.prizePoolKey}>{x.prizePoolName}</option>)}
                        </select>

                        <div className="form-row align-items-center">
                            <div className="col-auto my-3">
                                <div className="form-check">
                                    <input {...basicSettingsForm.register("needDeclarationAndRelease")} type="checkbox" className="form-check-input" id="needDeclarationAndRelease" />
                                    <label htmlFor="needDeclarationAndRelease" className="form-check-label mt-0">Need Declaration &amp; Release</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-row align-items-center">
                            <div className="col-auto my-2">
                                <div className="form-check">
                                    <input {...basicSettingsForm.register("ageGate")} type="checkbox" className="form-check-input" id="ageGate" />
                                    <label htmlFor="ageGate" className="form-check-label mt-0">Age Gate</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row align-items-center">
                            <div className="col-auto my-1">
                                <button className="btn btn-sm mt-3" type="submit" disabled={!canUpdateBasicSettings}>Save</button>
                                <span>{updateBasicSettingsStatus}</span>
                            </div>
                        </div>

                    </form>

                    <h3>Dynamic Settings (JSON)</h3>
                    <textarea value={dynamicSettings} onChange={e => setDynamicSettings(e.target.value)} style={{ width: "100%", minHeight: "20em" }}></textarea><br />
                    <button className="btn btn-sm mt-3" onClick={updateDynamicSettings} disabled={!canUpdateDynamicSettings}>Save</button>
                    <span>{updateDynamicSettingsStatus}</span>

                    <h3>Locations</h3>
                    <Table columns={locationsColumns} data={keywordLocations} tablePageSize={15} sortbyid="no" />
                    <div className="form-row align-items-center">
                        <div className="col-auto my-2">
                            <input className="form-control" maxLength={256} value={newLocation} onChange={e => setNewLocation(e.target.value)} placeholder="New Location" />
                        </div>
                        <div className="col-auto my-2">
                            <button className="btn mt-2 px-4" onClick={addNewLocation} disabled={!newLocation || addingNewLocation}>Add New Location</button>
                            <span>{addNewLocationStatus}</span>
                        </div>
                    </div>

                    <h3>Export</h3>
                    <div className="form-row align-items-center">
                        <div className="col-auto my-2">
                            <textarea style={{ height: "100px", minWidth: "30vw" }} value={importExportJson} onChange={e => updateJson(e.target.value)} />
                        </div>
                        <div className="col-auto my-2">
                            {importJsonSummary.map(x => <React.Fragment key={x}>{x}<br /></React.Fragment>)}
                        </div>
                    </div>
                    <div className="form-row align-items-center">
                        <div className="col-auto my-2">
                            <button className="btn mt-2 px-4" onClick={exportJson}>Export</button>
                            <button className="btn mt-2 px-4" onClick={importJson}>Import</button>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </>
}