import React, { useState } from "react";
import { TProgram } from "../../types/portalTypes";
import { Modal } from "react-bootstrap";
import { Spinner, Tabs, Tab } from "react-bootstrap";
import { TableProps } from "react-table";
import { Dropzone } from "../../Components/dropzone";
import { useQuery } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { getBytes } from "../../utils/helpers";
import { deleteAsset, getAssets, getPrograms } from "../../api";
import { useStores } from "../../stores";
import { Table } from "../../Components";

// type UrlForm = {
//   urls: { value: string }[];
// };
const Assets = ({ programId, active }: { programId: string, active: boolean }) => {
  const { userStore } = useStores();

  const [isOpen, setIsopen] = useState(false);
  const [key, setKey] = useState("upload");
  const [loading, setLoading] = useState(false);

  const { data: program, isLoading: programLoading, } = useQuery(
    ["getassets", programId],
    async () => {
      const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["company"] });
      return res.programs
    },
    { enabled: !!programId && !!active }
  )

  const { data: assets, isLoading: assetsLoading, refetch, isRefetching } = useQuery(["getprogramAssets",], async () => {
    const res: any = await getAssets({ token: userStore.user.token, id: programId, entityType: "program" });
    return res;
  }, { enabled: !!programId && active });


  const handleKeySelection = (key: string | null) => {
    if (key) {
      setKey(key);
      window.sessionStorage.setItem("tabSelection", key);
    }
  };

  const deleteHandler = async (key: string, fileName: string) => {
    if (loading) {
      return;
    }
    setLoading(true)
    try {
      await deleteAsset({
        id: programId,
        key: key.split('/')[key.split('/').length - 1],
        fileName,
        entityType: "program",
        token: userStore.user.token,
        companyId: program!.company.id
      });
      refetch()
      setLoading(false)

    } catch (e) { }
  };
  // const {
  //   // register,
  //   // watch,
  //   // setValue,
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  // } = useForm<UrlForm>({
  //   mode: "onTouched",
  //   criteriaMode: "all",
  //   defaultValues: {
  //     urls: [{ value: "" }],
  //   },
  // });

  // const {
  //   fields: urls,
  // } = useFieldArray<UrlForm>({
  //   control,
  //   name: "urls",
  // });

  const COLUMNS = [
    {
      Header: "No",
      width: "1%",
      accessor: "index",
      Cell: (tableInfo: any) => {
        return <p> {tableInfo.row.index + 1}</p>;
      },
    },
    {
      Header: "Name",
      accessor: "url",
      Cell: (TableInfo: TableProps) => {
        const url = TableInfo.data[TableInfo.row.index].url

        const key = TableInfo.data[TableInfo.row.index].key
        const name = key.toString().split('--')[1]
        return <a href={url} target="_blank" rel="noreferrer">{name}</a>;
      },
    },

    {
      Header: "File Size",
      accessor: "size",
      Cell: (TableInfo: TableProps) => {

        const bytes = getBytes(TableInfo.data[TableInfo.row.index].size)

        return bytes
      },

    },
    {
      Header: "File Type",
      accessor: "type",
      Cell: (TableInfo: TableProps) => {

        const type = TableInfo.data[TableInfo.row.index].key.split(".")[TableInfo.data[TableInfo.row.index].key.split(".").length - 1]

        return type
      },

    },
    {
      Header: "Actions",
      accessor: "key",
      Cell: (TableInfo: TableProps) => {

        const key = TableInfo.data[TableInfo.row.index].key
        const fileName = key.toString()

        return <i
          style={{ cursor: "pointer" }}
          className="fa fa-trash"
          onClick={async () => await deleteHandler(key, fileName)}
        ></i>
      },

    }
  ];

  return (
    <><div>

      {loading || assetsLoading || programLoading || isRefetching ? <div className="spinner">
        <Spinner animation="border" variant="secondary" />
      </div> : <>

        <div className="taskHead">
          <button
            className="btn action-buttons-sm"
            onClick={() => setIsopen(true)}
          >
            Add Asset
          </button>
        </div>

        {program && assets && assets.length ? (


          <Table
            columns={COLUMNS}
            data={assets.length ? assets : []}
            tablePageSize={15}
            tableTitle="Assets"
            descending={false}
            sortbyid="index"
          />
        ) : (
          <p style={{ textAlign: "center" }}>
            {" "}
            Currently there are no assets uploaded.
          </p>
        )}</>}
    </div>
      {program &&
        <Modal show={isOpen} onHide={() => { }} centered>
          <Modal.Body>
            <div className="tabs tab10">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsopen(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <Tabs
                activeKey={key}
                onSelect={(key) => handleKeySelection(key)}
                className="nav nav-tabs"
                style={{ width: "100%", marginBottom: "25px" }}
              >
                <Tab eventKey="upload" title="Upload">
                  <Dropzone
                    companyId={program!.company.id}
                    autoProceed={false}
                    entityId={program!.id}
                    entityType={"program"}
                    fileTypes={[".png", ".jpg", ".jpeg", ".pdf"]}
                    onComplete={() => {
                      showToast({
                        content: "You've successfully added your assets.",
                        duration: 3000,
                        error: false,
                      });
                      setIsopen(false);
                      refetch();

                    }}
                  />
                </Tab>
                {/* <Tab eventKey="urls" title="From URL">
                  <form onSubmit={handleSubmit((data) => console.log(data))}>
                    <ul className="url-list">
                      {urls.map((item, index) => (
                        <>
                          <li key={item.id}>
                            <Controller
                              render={({ field }) => (
                                <div className="url-form">
                                  <div className="col-sm-10 col-md-10">
                                    <div className="form-group url-input ">
                                      <input
                                        {...field}
                                        placeholder="Link to asset"
                                        className="form-control"
                                      />
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                        className="url-btn"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  </div>
                                  {urls.length - 1 === index && (
                                    <button
                                      type="button"
                                      onClick={() => append({ value: "" })}
                                      className="btn add addbtn"
                                    ></button>
                                  )}
                                </div>
                              )}
                              name={`urls.${index}.value`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: `Please enter url.`,
                                },
                              }}
                            />
                          </li>
                          {errors.urls && errors.urls[index]?.value && (
                            <p className="error">
                              <i className="fas fa-exclamation-circle" />
                              {errors.urls[index]?.value?.message}
                            </p>
                          )}
                        </>
                      ))}
                    </ul>
                    <div className="actionBtns modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary small outline"
                        onClick={() => {
                          setIsopen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary small"
                        onClick={async () => { }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </Tab> */}
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      }
    </>
  );
};

export default Assets;
