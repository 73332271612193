import { makeAutoObservable } from "mobx";

class userStore {
  user = {
    name: "",
    token: "",
    role: ""
  }

  constructor() {
    makeAutoObservable(this);
  }

  setUserName = (name: string, token: string, role: string) => {
    this.user.name = name
    this.user.token = token
    this.user.role = role
  };
}

export default userStore
