import { useState } from "react";
import {
  Routes,
  Route,
  useParams
} from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { useQuery } from "react-query";
import { NavMenu, Header } from "../../Components";
import CampaignSetting from "./CampaignSetting/index";
import CampaignManage from "./CampaignManage/CampaignManage";
import CampaignPrize from "./CampaignPrizing";
import CampaignMedia from "./CampaignMedia";
import CampaignEmail from "./CampaignEmail";
import CampaignParticipants from "./CampaignParticipants";
import AddEmailTemplate from "./CampaignEmail/AddEmailTemplate";
import { CampaignOverview } from "./CampaignOverview";
import CampaignAdmins from "./CampaignAdmins";
import CampaignPrizeDraws from "./CampaignPrizeDraws/CampaignPrizeDraws";
import ConductPrizeDraw from "./CampaignPrizeDraws/ConductPrizeDraw";
import { spryClient } from "../../api";
import "./CampaignDetail.css";
import UpdatePrizePool from "./CampaignPrizing/UpdatePrizePool";
import WinningTimestamps from "./CampaignTimeStamps/WinningTimestamps";
import AddTimestamps from "./CampaignTimeStamps/AddTimestamps";
import ParticipantDetails from "./CampaignParticipants/ParticipantDetails";
import CampaignPrizeDrawDetails from "./CampaignPrizeDraws/CampaignPrizeDrawDetails";
import ClaimDetails from "./CampaignPrizeDraws/ClaimDetails";
import CampaignGiftCards from "./CampaignGiftCards";
import GiftcardDetails from "./CampaignGiftCards/GiftcardDetails";
import Giftcard from "./CampaignGiftCards/Giftcard";
import UploadGiftCard from "./CampaignGiftCards/UploadGiftCard";
import EditTimestamps from "./CampaignTimeStamps/EditTimestamps";
import UpdatePrize from "./CampaignPrizing/UpdatePrize";
import AddPrizePool from "./CampaignPrizing/addPrizePool";
import AddPrize from "./CampaignPrizing/addPrize";
import CampaignKeywords from "./CampaignKeywords";
import CampaignEditKeyword from "./CampaignKeywords/CampaignEditKeyword";
import CampaignEmailBatches from "./CampaignEmailBatches";
import CampaignEditEmailBatch from "./CampaignEmailBatches/CampaignEditEmailBatch";
import CampaignEditEmailBatchTargets from "./CampaignEmailBatches/CampaignEditEmailBatchTargets";
import CampaignEmailBatchReport from "./CampaignEmailBatches/CampaignEmailBatchReport";
import CampaignParticipantImport from "./CampaignParticipantImport";
import CampaignEditKeywordLocation from "./CampaignKeywords/CampaignEditKeywordLocation";

function CampaignDetail() {
  const [isAddtimestamps, setIsAddtimestamps] = useState<Boolean>(false);

  const { campaignKey } = useParams() as { campaignKey: string };

  const {
    isLoading: loadPrizes,
    data: allPrizes,
  } = useQuery(["getPrizes", campaignKey], async () => {
    if (!campaignKey) { return }
    const res = await spryClient.getPrizes({ campaignKey });
    return res.prizes;
  });
  const {
    isLoading: loadPrizePools,
    data: allPrizePools,
  } = useQuery(["getPrizePools", campaignKey], async () => {
    if (!campaignKey) { return }
    const res = await spryClient.getPrizePools({ campaignKey });
    return res.prizePools;
  });

  const addTimestamps = () => {
    setIsAddtimestamps(!isAddtimestamps);
  };

  if (loadPrizes || loadPrizePools || !campaignKey) {
    return <div className="spinner">
      <Spinner animation="border" variant="secondary" />
    </div>
  }

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <NavMenu />

          <div className="col-sm-12 col-md-10">
            <Header />
            <Routes>
              <Route path="overview" element={<CampaignOverview campaignKey={campaignKey} allPrizes={allPrizes} allPrizePools={allPrizePools} />} />
              <Route path="cognitoAccounts" element={<CampaignAdmins campaignKey={campaignKey} />} />
              <Route path="setting" element={<CampaignSetting campaignKey={campaignKey} />} />
              <Route path="participants" element={<CampaignParticipants campaignKey={campaignKey} allPrizes={allPrizes} allPrizePools={allPrizePools} />} />
              <Route path="participants/:sessionKey" element={<ParticipantDetails campaignKey={campaignKey} allPrizes={allPrizes} allPrizePools={allPrizePools} />} />
              <Route path="manage" element={<CampaignManage campaignKey={campaignKey} />} />
              <Route path="prizing" element={<CampaignPrize campaignKey={campaignKey} />} />
              <Route path="prizing/addPrize" element={<AddPrize campaignKey={campaignKey} />} />
              <Route path="prizing/addPrizePool" element={<AddPrizePool campaignKey={campaignKey} />} />
              <Route path="prizing/updatePrize/:prizeKey" element={<UpdatePrize campaignKey={campaignKey} />} />
              <Route path="prizing/updatePrizePool/:prizePoolKey" element={<UpdatePrizePool campaignKey={campaignKey} />} />
              <Route path="timestamps" element={<WinningTimestamps campaignKey={campaignKey} allPrizes={allPrizes} allPrizePools={allPrizePools} />} />
              <Route path="timestamps/add" element={<AddTimestamps campaignKey={campaignKey} addTimestamps={addTimestamps} allPrizes={allPrizes} />} />
              <Route path="timestamps/:instantPrizeTimestampKey" element={<EditTimestamps campaignKey={campaignKey} allPrizes={allPrizes} allPrizePools={allPrizePools} />} />
              <Route path="media" element={<CampaignMedia campaignKey={campaignKey} />} />
              <Route path="emailtemplates" element={<CampaignEmail campaignKey={campaignKey} />} />
              <Route path="addemailtemplate/:emailTemplateKey?" element={<AddEmailTemplate campaignKey={campaignKey} />} />
              <Route path="prizedraws" element={<CampaignPrizeDraws campaignKey={campaignKey} allPrizePools={allPrizePools} />} />
              <Route path="prizedraws/conduct" element={<ConductPrizeDraw campaignKey={campaignKey} />} />
              <Route path="prizedraws/:grandPrizeDrawKey" element={<CampaignPrizeDrawDetails campaignKey={campaignKey} allPrizes={allPrizes} />} />
              <Route path="prizedraws/:grandPrizeDrawKey/claimed/:prizeDrawRankingKey" element={<ClaimDetails campaignKey={campaignKey} allPrizes={allPrizes} />} />
              <Route path="giftcards" element={<CampaignGiftCards campaignKey={campaignKey} />} />
              <Route path="giftcards/:giftCardTypeKey" element={<GiftcardDetails campaignKey={campaignKey} />} />
              <Route path="giftcards/:giftCardTypeKey/upload" element={<UploadGiftCard campaignKey={campaignKey} />} />
              <Route path="giftcards/:giftCardTypeKey/giftcard/:giftCardKey" element={<Giftcard campaignKey={campaignKey} />} />
              <Route path="keywords" element={<CampaignKeywords campaignKey={campaignKey} />} />
              <Route path="keywords/:keywordKey" element={<CampaignEditKeyword campaignKey={campaignKey} />} />
              <Route path="keywords/:keywordKey/location/:keywordLocationKey" element={<CampaignEditKeywordLocation campaignKey={campaignKey} />} />
              <Route path="email-batches" element={<CampaignEmailBatches campaignKey={campaignKey} />} />
              <Route path="email-batches/:emailBatchKey" element={<CampaignEditEmailBatch campaignKey={campaignKey} />} />
              <Route path="email-batches/:emailBatchKey/targets" element={<CampaignEditEmailBatchTargets campaignKey={campaignKey} />} />
              <Route path="email-batches/:emailBatchKey/report" element={<CampaignEmailBatchReport campaignKey={campaignKey} />} />
              <Route path="participant-import" element={<CampaignParticipantImport campaignKey={campaignKey} />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignDetail;
