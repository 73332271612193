import { NavigateFunction } from "react-router-dom";

export const PrizeDrawColumns = (fns: { navigate: NavigateFunction }) => {

  const COLUMNS = [
    {
      Header: 'Draw Name',
      accessor: 'grandPrizeDrawName',
      width: "12%",
      Cell: (tableInfo: any) => <button value={tableInfo.data[tableInfo.row.index].grandPrizeDrawName} onClick={() => {
        const campaignkey: string = tableInfo.data[tableInfo.row.index].campaignKey
        const drawkey: string = tableInfo.data[tableInfo.row.index].grandPrizeDrawKey
        fns.navigate(`/campaign/${campaignkey}/prizedraws/${drawkey}`, { state: tableInfo.data[tableInfo.row.index] })
      }} className="linkButton"> {tableInfo.data[tableInfo.row.index].grandPrizeDrawName}</button>
    },
    {
      Header: 'Entry Start',
      accessor: 'parameters.startTime',
      width: "12%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].parameters.startTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: 'Entry End',
      accessor: 'parameters.finishTime',
      width: "12%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].parameters.finishTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: 'Draw date / time',
      accessor: 'creationTime',
      width: "12%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },

    },
    {
      Header: 'Potential Winners',
      accessor: 'parameters.maxRankingCount'
    },
    {
      Header: 'Eligible',
      accessor: 'getcounts.eligible'
    },
    {
      Header: 'Pending',
      accessor: 'getcounts.pending'

    },
    {
      Header: 'Rejected',
      accessor: 'getcounts.rejected'

    },
    {
      Header: 'Claimed',
      accessor: 'getcounts.claimed'

    },
    {
      Header: 'Fulfilled',
      accessor: 'getcounts.fulfilled'
    },
    {
      Header: 'Prizepool',
      accessor: 'prizePoolName'
    },
    {
      Header: 'Keyword',
      Cell: (table: any) => {
        const row = table.data[table.row.index]
        return <div>
          {row.keyword && <span>Keyword: {row.keyword.word}<br /></span>}
          {row.keywordLocation && <span>Location: {row.keywordLocation.name}<br /></span>}
        </div>
      }
    }

  ]
  return COLUMNS
}



