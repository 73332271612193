import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import icoCalendar from "../../../assets/images/ico-calendar.png";
import icoClock from "../../../assets/images/ico-clock.png";
import dayjs from "dayjs";
import { spryClient } from "../../../api";
import DatePickerCalendar from "../DatePickerCalendar";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useNavigate } from "react-router-dom";
import Popup from "../../../Components/Popup";
import { getDaysBetween2Dates } from "../../../utils/helpers";
import { Prize } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";

type ITimestamp = {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  numberOfTimestamps: number;
  prize: string;
  prizePool: string;
  priority: string;
  dayList: {
    day: string;
    startTime: string;
    endTime: string;
    numberOfTimestamps: number;
  }[];
  selectbydate: boolean;
};
type typeProp = {
  campaignKey: string;
  addTimestamps: Function;
  allPrizes: Prize[] | undefined;
};
type AddTimestampsContent = {
  prizePoolKey: string;
  prizeKey: string;
  startTime: string;
  endTime: string;
  prizeCount: number;
  priority: number;
};

function AddTimestamps({ campaignKey, addTimestamps, allPrizes }: typeProp) {
  const initialValues_daylist = {
    day: dayjs().toLocaleString(),
    endTime: dayjs().endOf("day").toISOString(),
    startTime: dayjs().startOf("day").toISOString(),
    numberOfTimestamps: 0,
  };
  const defaultValues = {
    startDate: new Date().toISOString(),
    startTime: dayjs().startOf("day").toISOString(),
    endDate: new Date().toISOString(),
    endTime: dayjs().endOf("day").toISOString(),
    selectbydate: false,
    numberOfTimestamps: 1,
    prize: "",
    prizePool: "",
    priority: "",
    dayList: getDaysBetween2Dates(
      dayjs().startOf("day").toISOString(),
      dayjs().endOf("day").toISOString()
    ).map((day) => {
      return {
        ...initialValues_daylist,
        day: day.toLocaleString(),
      };
    }),
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ITimestamp>({ defaultValues, mode: "onTouched" });
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formWatch = watch();

  React.useEffect(() => {
    if (formWatch.selectbydate) {
      const days = getDaysBetween2Dates(formWatch.startDate, formWatch.endDate);
      if (days.length < formWatch.numberOfTimestamps) {
        const remaining = formWatch.numberOfTimestamps % days.length;
        const timestampsPerday =
          (formWatch.numberOfTimestamps - remaining) / days.length;
        const initiate = days.map((day, index) => {
          if (index === days.length - 1) {
            return {
              endTime: dayjs().endOf("day").toISOString(),
              startTime: dayjs().startOf("day").toISOString(),
              day: day,
              numberOfTimestamps: timestampsPerday + remaining,
            };
          } else {
            return {
              endTime: dayjs().endOf("day").toISOString(),
              startTime: dayjs().startOf("day").toISOString(),
              day: day,
              numberOfTimestamps: timestampsPerday,
            };
          }
        });
        setValue("dayList", initiate);
      } else {
        const initiate = days.map((day, index) => {
          if (index < formWatch.numberOfTimestamps) {
            return {
              endTime: dayjs().endOf("day").toISOString(),
              startTime: dayjs().startOf("day").toISOString(),
              day: day,
              numberOfTimestamps: 1,
            };
          } else {
            return {
              endTime: dayjs().endOf("day").toISOString(),
              startTime: dayjs().startOf("day").toISOString(),
              day: day,
              numberOfTimestamps: 0,
            };
          }
        });
        setValue("dayList", initiate);
      }
    }
  }, [
    formWatch.selectbydate,
    setValue,
    formWatch.endDate,
    formWatch.startDate,
    formWatch.numberOfTimestamps,
  ]);

  useEffect(() => {
    setValue("endDate", formWatch.startDate);
  }, [formWatch.startDate, setValue]);

  const { isLoading: loadingPrizePools, data: prizePools } = useQuery(
    ["getPrizePools", campaignKey],
    async () => {
      const res = await spryClient.getPrizePools({ campaignKey });
      return res.prizePools;
    }
  );
  const { data: prizePoolPrizes, isLoading: loadingPrizes } = useQuery(
    ["getPrizePoolPrize", { campaignKey, prizePool: formWatch.prizePool }],
    async () => {
      const res = await spryClient.getPrizePoolPrizes({
        campaignKey,
        prizePoolKey: formWatch.prizePool,
      });
      const updatedPrizes = res.prizePoolPrizes.map((prize) => {
        let prizeName = allPrizes
          ? allPrizes.find((p) => p.prizeKey === prize.prizeKey)?.prizeName
          : "";
        return { ...prize, prizeName };
      });
      let activePrizes = updatedPrizes.filter((record: any) => {
        return record.active === true;
      });
      return activePrizes;
    }
  );

  const getDateTime = (date: string, time: string) => {
    let tempDate = dayjs(date);
    let updatedDate_time = dayjs(tempDate)
      .hour(dayjs(time).hour())
      .minute(dayjs(time).minute())
      .second(dayjs(time).second());
    const finalDate = updatedDate_time.toISOString();
    return finalDate;
  };

  const AddTimestamps = async (content: AddTimestampsContent) => {
    const res = await spryClient.createInstantPrizeTimestamps(content);
    return res;
  };

  const submitTimestamps = async ({
    startDate,
    startTime,
    endDate,
    endTime,
    numberOfTimestamps,
    prize,
    prizePool,
    priority,
    dayList,
    selectbydate,
  }: ITimestamp) => {
    if (loading) {
      return;
    }
    setLoading(true);

    if (!selectbydate) {
      const content = {
        prizePoolKey: prizePool,
        prizeKey: prize,
        startTime: getDateTime(startDate, startTime),
        endTime: getDateTime(endDate, endTime),
        prizeCount: Number(numberOfTimestamps),
        priority: Number(priority),
      };

      try {
        const result = await AddTimestamps(content);
        if (result.instantPrizeTimestamps) {
          setLoading(false);
          showToast({
            content: "Timestamps has been added successfully.",
            duration: 3000,
            error: false,
          });
          navigate(`/campaign/${campaignKey}/timestamps`);
        }
      } catch (e) {
        setLoading(false);
        showToast({
          content: "Oops, api error ",
          duration: 3000,
          error: true,
        });
      }
    } else {
      let distributedTimestamps = dayList
        .map((day) => day.numberOfTimestamps)
        .reduce((a, b) => +a + +b);
      if (+numberOfTimestamps === +distributedTimestamps) {
        const filteredTimestamps = dayList.filter(
          (day) => day.numberOfTimestamps > 0
        );
        const addAllTimestamps = Promise.all(
          filteredTimestamps.map(async (day) => {
            const content = {
              prizePoolKey: prizePool,
              prizeKey: prize,
              startTime: getDateTime(day.day, day.startTime),
              endTime: getDateTime(day.day, day.endTime),
              prizeCount: day.numberOfTimestamps,
              priority: Number(priority),
            };
            await AddTimestamps(content);
          })
        );
        try {
          await addAllTimestamps.then(async (res) => {
            setLoading(false);
            showToast({
              content: "Timestamps has been added successfully.",
              duration: 3000,
              error: false,
            });
            navigate(`/campaign/${campaignKey}/timestamps`);
          });
        } catch (e) {
          setLoading(false);
          showToast({
            content: "Oops, api error ",
            duration: 3000,
            error: true,
          });
        }
      } else {
        setErrorMessage(
          `The prize distribution by day (${distributedTimestamps}) does not match the total entered (${numberOfTimestamps}). Please adjust and try again.`
        );
        setLoading(false);

        setShowError(true);
      }
    }
  };
  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/timestamps`);
  };

  return (
    <>
      {loadingPrizePools || loadingPrizes || loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        allPrizes && (
          <div className="dashboardContent campaignDetail tabsCont addPg">
            <div id="tab05" className="tab-contents timestamps">
              <div className="head inner">
                <h3>
                  <button className="backBtn" onClick={backHandler}>
                    <i className="fas fa-arrow-left"></i>
                  </button>
                  Add timestamps
                </h3>

                <button
                  type="submit"
                  form="timestampsForm"
                  className="btn addbtn"
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div>
                <form
                  onSubmit={handleSubmit(submitTimestamps)}
                  id="timestampsForm"
                >
                  <div className="formContent">
                    <div
                      className="col-sm-12 col-md-7"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">
                          Prize Pool
                        </label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect2"
                          {...register("prizePool", {
                            required: {
                              value: true,
                              message: "please select a prize pool",
                            },
                          })}
                        >
                          <option value="">Please select a prize pool</option>
                          {prizePools?.map((prizePool: any) => {
                            return (
                              <option
                                value={prizePool.prizePoolKey}
                                key={prizePool.prizePoolKey}
                              >
                                {prizePool.prizePoolName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.prizePool && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.prizePool.message}
                        </div>
                      )}
                    </div>

                    <div
                      className="col-sm-12 col-md-7"
                      style={{ paddingLeft: "0px" }}
                    >
                      {" "}
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Prize</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect2"
                          {...register("prize", {
                            required: {
                              value: true,
                              message: "please select a prize",
                            },
                          })}
                        >
                          <option value="">Please select a prize</option>
                          {prizePoolPrizes?.map((p) => {
                            return (
                              <option value={p.prizeKey} key={p.prizeKey}>
                                {p.prizeName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.prize && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.prize.message}
                        </div>
                      )}
                    </div>
                    <div
                      className="col-sm-12 col-md-7"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="form-group flex-column">
                        <label htmlFor="exampleInputEmail1">Priority</label>
                        <select
                          className="form-control selectBoxShort"
                          {...register("priority", {
                            required: {
                              value: true,
                              message: "Please select priority",
                            },
                          })}
                          id="exampleFormControlSelect1"
                        >
                          <option value="">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      {errors.priority && (
                        <div className="error">
                          <i className="fas fa-exclamation-circle" />
                          {errors.priority.message}
                        </div>
                      )}
                    </div>
                    <div
                      className="col-sm-12 col-md-7"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Number of timestamps
                        </label>
                        <input
                          type="number"
                          className="form-control noofTimeStamps"
                          id="exampleInputEmail1"
                          min={1}
                          {...register("numberOfTimestamps", {
                            required: {
                              value: true,
                              message: "Please enter number of timestamps.",
                            },
                            min: { value: 1, message: "min value is 1" },
                          })}
                          placeholder=""
                          disabled={getValues("selectbydate") ? true : false}
                        />
                        {errors.numberOfTimestamps && (
                          <div className="error">
                            <i className="fas fa-exclamation-circle" />
                            {errors.numberOfTimestamps.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="col-sm-12 col-md-7"
                      style={{ display: "flex", padding: "20px" }}
                    >
                      <div
                        className="form-group "
                        style={{ justifyContent: "center" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="selectbydate"
                          {...register("selectbydate", { required: false })}
                          aria-describedby="selectbydate"
                          disabled={
                            dayjs(formWatch.endDate).diff(
                              formWatch.startDate,
                              "days"
                            ) > 60 || formWatch.numberOfTimestamps < 1
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="selectbydate"
                        >
                          Set Timestamp Distribution By Day
                        </label>
                      </div>
                    </div>

                    <h4>Date Range</h4>
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="date start">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Start Date
                            </label>
                            <DatePickerCalendar
                              name="startDate"
                              value={new Date(formWatch.startDate)}
                              onChange={async (val: Date) => {
                                setValue("startDate", val.toISOString());
                              }}
                              readOnly={false}
                              dateforTime={new Date(formWatch.startDate)}
                              timeInterval={30}
                            />
                            <span className="calIco">
                              <img src={icoCalendar} alt="Select Date" />
                            </span>

                            {errors.startDate && (
                              <div className="error">
                                <i className="fas fa-exclamation-circle" />
                                <>{errors.startDate.message}</>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="date End date">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">End Date</label>
                            <DatePickerCalendar
                              name="endDate"
                              value={new Date(formWatch.endDate)}
                              startDate={
                                new Date(
                                  dayjs(formWatch.startDate).toISOString()
                                )
                              }
                              endDate={
                                new Date(
                                  dayjs(formWatch.startDate)
                                    .add(60, "days")
                                    .toISOString()
                                )
                              }
                              onChange={async (val: Date) => {
                                setValue("endDate", val.toISOString());
                              }}
                              readOnly={false}
                              dateforTime={new Date(formWatch.endDate)}
                              timeInterval={30}
                            />
                            <span className="calIco">
                              <img src={icoCalendar} alt="Calendar" />
                            </span>
                          </div>
                          {errors.endDate && (
                            <div className="error">
                              <i className="fas fa-exclamation-circle" />
                              <>{errors.endDate}</>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {formWatch.selectbydate && !errors.endDate ? (
                      <div>
                        <h4 className="timing">Timing Per Day</h4>

                        {new Date(formWatch.startDate) <=
                          new Date(formWatch.endDate) &&
                          formWatch.dayList.map((day, index: number) => (
                            <div className="row" key={index}>
                              <div
                                className="col-sm-12 col-md-2"
                                style={{ marginTop: "25px" }}
                              >
                                <p>{dayjs(day.day).format("ddd MMM DD")}</p>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <div className="clock start">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Start Time
                                    </label>
                                    <DatePickerCalendar
                                      showTimePicker
                                      value={
                                        new Date(
                                          formWatch.dayList[index]?.startTime
                                        )
                                      }
                                      onChange={(val: Date) => {
                                        setValue(
                                          `dayList.${index}.startTime`,
                                          val.toISOString()
                                        );
                                      }}
                                      readOnly={false}
                                      dateforTime={
                                        new Date(
                                          formWatch.dayList[index]?.startTime
                                        )
                                      }
                                      timeInterval={30}
                                    />
                                    <span className="timeIco">
                                      <img src={icoClock} alt="Select Time" />
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-3">
                                <div className="clock End">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      End Time
                                    </label>
                                    <DatePickerCalendar
                                      showTimePicker
                                      name={`dayList[${index}].endTime`}
                                      value={
                                        new Date(
                                          formWatch.dayList[index]?.endTime
                                        )
                                      }
                                      onChange={(val: Date) =>
                                        setValue(
                                          `dayList.${index}.endTime`,
                                          val.toISOString()
                                        )
                                      }
                                      readOnly={false}
                                      dateforTime={
                                        new Date(
                                          formWatch.dayList[index]?.endTime
                                        )
                                      }
                                      timeInterval={30}
                                    />
                                    <span className="timeIco">
                                      <img src={icoClock} alt="Calendar" />
                                    </span>
                                  </div>
                                </div>
                                {errors.dayList &&
                                  errors.dayList[index]?.endTime && (
                                    <div className="error">
                                      <i className="fas fa-exclamation-circle" />
                                      {errors.dayList[index]?.endTime?.message}
                                    </div>
                                  )}
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <div className="form-group flex-column">
                                  <label htmlFor="exampleInputEmail1">
                                    Number Of Timestamps
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control noofTimeStamps"
                                    id="exampleInputEmail1"
                                    min={0}
                                    {...register(
                                      `dayList.${index}.numberOfTimestamps`,
                                      {
                                        required: {
                                          value: true,
                                          message:
                                            "Please enter number of timestamps.",
                                        },
                                        min: {
                                          value: 0,
                                          message: "min value is 0",
                                        },
                                      }
                                    )}
                                    placeholder=""
                                  />

                                  {errors.dayList &&
                                    errors.dayList[index]
                                      ?.numberOfTimestamps && (
                                      <div className="error">
                                        <i className="fas fa-exclamation-circle" />
                                        {
                                          errors.dayList[index]
                                            ?.numberOfTimestamps?.message
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <>
                        <h4 className="timing">Timing Per Day</h4>

                        <div className="row">
                          <div className="col-sm-12 col-md-4">
                            <div className="clock start">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Start Time
                                </label>
                                <DatePickerCalendar
                                  showTimePicker
                                  name="startTime"
                                  value={new Date(formWatch.startTime)}
                                  onChange={(val: Date) =>
                                    setValue("startTime", val.toISOString())
                                  }
                                  readOnly={false}
                                  dateforTime={new Date(formWatch.startDate)}
                                  timeInterval={30}
                                />
                                <span className="timeIco">
                                  <img src={icoClock} alt="Select Time" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="clock End">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  End Time
                                </label>
                                <DatePickerCalendar
                                  showTimePicker
                                  name="endTime"
                                  value={new Date(formWatch.endTime)}
                                  onChange={(val: Date) =>
                                    setValue("endTime", val.toISOString())
                                  }
                                  readOnly={false}
                                  dateforTime={new Date(formWatch.endDate)}
                                  timeInterval={30}
                                />
                                <span className="timeIco">
                                  <img src={icoClock} alt="Calendar" />
                                </span>
                                {errors.endTime && (
                                  <div className="error">
                                    <i className="fas fa-exclamation-circle" />
                                    <>{errors.endTime}</>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      )}
      <Popup
        isOpen={showError}
        closeModal={() => setShowError(false)}
        header="Prize Totals Don’t Match"
        message={errorMessage}
        cancelMessage="Okay"
        action2={() => setShowError(false)}
      />
    </>
  );
}

export default AddTimestamps;
