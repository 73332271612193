import { ReturnTypes } from "@sprycore/spry-api-client"

type GiftCard = {
    total: number;
    assigned: number;
    unAssigned: number;
    campaignKey: string;
    giftCardTypeName: string;
    giftCardTypeKey: string;
    creationTime: Date;
    updateTime: Date;
}[] | undefined


function CampaignSnapshot({ allPrizes, prizeWinners, totalEntries, giftCards }: { allPrizes: ReturnTypes.Prize[] | undefined, prizeWinners: ReturnTypes.PrizeWinner[] | undefined, totalEntries: number, giftCards: GiftCard }) {


    const prizeOverview = allPrizes && allPrizes.map((prize: ReturnTypes.Prize | undefined) => {

        let giftcardCount = giftCards && giftCards.filter(card => card.giftCardTypeName === prize?.prizeName)
        const totalnumberOfPrizes = giftcardCount ? giftcardCount[0]?.total : 0

        let prize_assigned = prizeWinners && prizeWinners.filter((winner: ReturnTypes.PrizeWinner | undefined) => {
            return winner?.prizeKey === prize?.prizeKey
        })

        let fullfilled_count = 0
        let forfeit_count = 0
        let pending_count = 0
        let claimed_count = 0
        let totalwincount = 0
        prize_assigned && prize_assigned.forEach(winner => {

            if (winner.fulfillTime) {
                fullfilled_count = fullfilled_count + 1
                totalwincount = totalwincount + 1
            }
            else if (winner.forfeitTime) {
                forfeit_count = forfeit_count + 1
            }
            else if (winner.declarationAndRelease) {
                claimed_count = claimed_count + 1
                totalwincount = totalwincount + 1

            }
            else {
                pending_count = pending_count + 1
                totalwincount = totalwincount + 1
            }
        })

        let overview = {
            prizeName: prize?.prizeName,
            noOfPrizes: totalnumberOfPrizes,
            noOfprizesWon: totalwincount,
            prizesRemaining: (totalnumberOfPrizes - fullfilled_count),
            fullfilled_count: fullfilled_count,
            pending_count: pending_count,
            forfeit_count: forfeit_count,
            claimed_count: claimed_count
        }
        return overview
    })

    const maybePluralize = (count: number, noun: string, suffix = 's') =>
        `${count} ${noun}${count !== 1 ? suffix : ''}`;


    return (
        <>
            <div className="campSnapShot">
                <div className="listItem totalentries">
                    <h4>Total Entries<span>{totalEntries}</span></h4>
                </div>
                {prizeOverview && prizeOverview.map(prize => {
                    return (
                        <div className="listItem" key={prize.prizeName}>
                            <h4>Total Number of {prize.prizeName} <span>{prize.noOfPrizes}</span></h4>
                            {/* { prize.noOfPrizes > 0 &&                        
                        <p>{prize.prizesRemaining} prizes remaining</p> } */}
                            <p>{prize.noOfprizesWon} prizes won</p>
                            <p>{maybePluralize(prize.fullfilled_count, "prize") + " fulfilled"}</p>
                            <p>{maybePluralize(prize.claimed_count, "prize") + " claimed"}</p>
                            <p>{maybePluralize(prize.pending_count, "prize") + " unclaimed"}</p>
                            <p>{maybePluralize(prize.forfeit_count, "prize") + " forfeited"}</p>
                        </div>)
                })}

            </div>

        </>
    )
}

export default CampaignSnapshot;
