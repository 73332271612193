import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Dropzone } from '../../Components/dropzone';
import { Button, Modal, Spinner } from "react-bootstrap";
import { useQuery } from 'react-query';
import { getAssets, submitEstimate } from '../../api';
import { useStores } from '../../stores';
import { showToast } from '../../Components/Toast/ToastManager';
import QuoteForm from './QuoteForm';


type estimateForm = {
    internalId: string
    estimate: string;
    estimationUrl: string;
}
const Ballpark = ({ quoteId, backHandler, company, programId, status }: { status: string; quoteId: string, backHandler: Function, company: string, programId: string }) => {

    const { userStore } = useStores();
    const downloadRef = useRef<HTMLAnchorElement | null>(null)

    const [loading, setLoading] = useState(false)
    const defaultValues: estimateForm = { estimate: "", estimationUrl: "", internalId: "" }
    const [uploadCompleted, setUploadCompleted] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const { register, watch, formState: { errors }, handleSubmit } = useForm<estimateForm>({ mode: "onTouched", defaultValues: { ...defaultValues } })



    const { data: latestEstimation, isLoading: quoteAssetsLoading, refetch } = useQuery(["getAssets", quoteId], async () => {
        const res = await getAssets({ token: userStore.user.token, id: quoteId, entityType: "quote" });

        return res?.sort((a, b) => +new Date(b.lastModified) - +new Date(a.lastModified))
    }, { enabled: !!quoteId });

    const formwatch = watch()
    const handleEstimate = async (data: estimateForm) => {
        if (loading) {
            return
        }
        setLoading(true)
        try {
            const res = await submitEstimate({
                id: quoteId,
                estimate: data.estimate,
                internalId: data.internalId,
                token: userStore.user.token,
            });

            if (res) {
                showToast({
                    content: "Estimation has been submitted successfully.",
                    duration: 3000,
                    error: false,
                });
                backHandler()
            }
            setIsOpen(false)

        } catch (e) {
            setIsOpen(false)
            showToast({
                content: "Error while submitting estimation.",
                duration: 3000,
                error: true,
            });

        }
        setLoading(false)
    }



    return (
        <>
            {quoteAssetsLoading ? (
                <div className="spinner">
                    <Spinner animation="border" variant="secondary" />
                </div>
            ) :
                <div className='quote'>


                    <div className='head inner'>
                        <h3>
                            {(+status < 20) &&

                                <button onClick={() => { backHandler() }} className="backBtn">
                                    <i className="fas fa-arrow-left" />
                                </button>}
                            Ballpark details
                            {latestEstimation &&
                                <a ref={downloadRef} href={latestEstimation[0]?.url} target='_blank' rel="noreferrer" style={{ padding: 0 }}
                                > <i className="fas fa-angle-down download-btn" title="download estimate" /></a>
                            }
                        </h3>
                        {
                            (+status === 1) &&
                            <button className='btn btn-sm no-add' onClick={() => { setIsOpen(true) }}>Upload new estimation</button>
                        }


                    </div>

                    <QuoteForm quoteId={quoteId} type="quote" programId={programId} />

                    <Modal show={!!isOpen} onHide={() => setIsOpen(false)} centered>
                        <form onSubmit={handleSubmit(handleEstimate)} id="estimate">

                            <Modal.Body>
                                <h3 style={{ fontWeight: "bold" }}>Upload Estimation</h3>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="program_name">Internal ID</label>
                                            <input
                                                className="form-control"
                                                {...register("internalId", { required: { value: true, message: "Please add an internal id." } })}
                                            />

                                        </div>
                                        {errors.internalId &&
                                            <p className="error">
                                                <i className="fas fa-exclamation-circle" />
                                                {errors.internalId.message}
                                            </p>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="program_name">Price estimate</label>
                                            <input
                                                className="form-control"
                                                {...register("estimate", { required: { value: true, message: "Please enter a value for estimation." } })}
                                            />
                                        </div>
                                        {errors.estimate &&
                                            <p className="error">
                                                <i className="fas fa-exclamation-circle" />
                                                {errors.estimate.message}
                                            </p>
                                        }
                                    </div>
                                </div>
                                {/* {formwatch.estimationUrl &&
                                    <div className='file-uploaded'>
                                        <a href={formwatch.estimationUrl} target="_blank" rel="noreferrer">{formwatch.estimationUrl.split('--')[1].split('?')[0]}</a>
                                    {estimationUrl.size && <span>{getBytes(+estimationUrl.size)}</span>} 
                                    </div>
                                } */}

                                {!formwatch.estimationUrl &&
                                    <Dropzone fileTypes={[".pdf"]}
                                        companyId={company} autoProceed={false} entityId={quoteId} entityType={'quote'} onComplete={(resp: any) => {
                                            setUploadCompleted(true)
                                            showToast({
                                                content: "Estimation pdf has been uploaded successfully.",
                                                duration: 3000,
                                                error: false,
                                            });
                                            refetch()
                                        }} />
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                {loading ?
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button> :
                                    <button className='btn btn-sm' type="submit" disabled={!uploadCompleted ? true : false}>Submit Estimate</button>
                                }</Modal.Footer>
                        </form>

                    </Modal>

                </div>}
        </>
    )
}

export default Ballpark