import React, { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getAssets, getPrograms } from "../../api";
import { Table } from "../../Components";
import { TAsset, TProgram } from "../../types/portalTypes";
import { QuoteColumns } from "./QuoteCols";
import { useStores } from "../../stores";
import { EProgramQuoteStatus, EQuoteStatuses } from "../../utils/constants/quotes";
import Quote from "./Quote";
import { useSearchParams } from "react-router-dom";

const Quotes = ({ programId, active }: { programId: string, active: boolean }) => {

  let [searchParams, setSearchParams] = useSearchParams();
  const quoteId = searchParams.get("qId")

  const columns = QuoteColumns(setSearchParams);
  const { userStore } = useStores();

  const { data: program, isLoading: programLoading, refetch: refetchProgram, isRefetching } = useQuery(
    ["getProgramQuotes", programId, active],
    async () => {
      const res: { programs: TProgram } = await getPrograms({ token: userStore.user.token, id: programId, include: ["quotes", "company"] });
      return res.programs
    },
    { enabled: !!programId && !!active },
  );

  const { data: quotePdfs, isLoading: quotePdfsLoading } = useQuery(["getAssets", program], async () => {

    const submittedQuotes = program?.quotes.filter(q => +q.status >= 1 && +q.type === 0)
    let quoteassets: { [key: string]: any } = {}
    if (submittedQuotes?.length) {
      for (let q of submittedQuotes) {
        let res: any = await getAssets({ token: userStore.user.token, id: q.id, entityType: "quote" })
        let assets = res?.sort((a: TAsset, b: TAsset) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())
        quoteassets[q.id] = assets[0] ? assets[0].url : ""
      }
    }
    return quoteassets
  }, { enabled: !!program?.quotes.find(q => +q.status >= 1) });

  const backHandler = async () => {
    setSearchParams({ tab: "quotes" })
    refetchProgram()
  }


  const data = useMemo(() => {
    if (program?.quotes && quotePdfs) {
      const quotes = program.quotes.filter(q => +q.type === 0)
      return quotes.map((quote) => {

        let status = ""

        if (quote.status === EQuoteStatuses["archived"]) {
          status = "Archived"
        }
        else {
          let newstatus = `${program.status}${quote.status}`
          status = EProgramQuoteStatus[newstatus]

        }
        return {
          id: quote.id,
          quoteStatus: quote.status ?? 0,
          programStatus: program.status,
          quote_programStatus: status,
          creationTime: new Date(quote.created_at),
          updateTime: new Date(quote.updated_at),
          company: program.company,
          companyName: program.company.name,
          domain: program.company.domain,
          programBrand: quote?.program_brand ?? "N/A",
          programName: quote.program_name,
          programId: program.id,
          brandId: quote?.program_brand?.id,
          internalId: quote?.internalId || "N/A",
          estimation: quote.internalId ? quote.status_reason : "",
          estimationUrl: quotePdfs[quote.id] ? quotePdfs[quote.id] : "",
          filename: quotePdfs[quote.id] ? quotePdfs[quote.id].split("--")[1].split('?')[0] : "",
          type: +`${program.status}${quote.status}` > 20 ? "Detailed Quote" : "Ballpark Quote"
        };
      });
    } else {
      return [];
    }
  }, [program, quotePdfs]);

  return (
    <>
      {quoteId && program ? <Quote id={quoteId} programStatus={program?.status || 0} quoteStatus={program.quotes?.find(q => q.id == quoteId)?.status || "0"} companyId={program!.company.id} programId={program!.id} backHandler={backHandler} />
        :
        <div className="dashboardContent quotes">
          {(quotePdfsLoading || programLoading || isRefetching) ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              {data.length ?
                <Table
                  columns={columns}
                  data={data}
                  tablePageSize={15}
                  tableTitle="Quotes"
                  sortbyid="updateTime"
                  descending={true}
                /> :
                <p style={{ textAlign: "center" }}>
                  {" "}
                  Currently there are no quotes submitted.
                </p>
              }

            </>
          )}

        </div>}
    </>
  );
};

export default Quotes;
