import { Dropdown } from "react-bootstrap";
import { Link, NavigateFunction } from "react-router-dom";
import { dateFormatHelper } from "../../utils/helpers";
import { CampaignStore } from "../../stores";
import { CampaignType } from "../../stores/CampaignStore";
import "./styles.css";
import { spryClient } from "../../api";
import { useQuery } from "react-query";

export const CampaignColumns = (fns: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  campaignStore: CampaignStore;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCampaign: Function;
  navigate: NavigateFunction;
}) => {
  const COLUMNS = [
    {
      Header: "Campaign Name",
      accessor: "campaignName",
      width: "30%",
      Cell: (tableInfo: any) => {
        const key: string = tableInfo.data[tableInfo.row.index].campaignKey;
        const name: string = tableInfo.data[tableInfo.row.index].campaignName;
        const brandName: string =
          tableInfo.data[tableInfo.row.index].brand.brandName;
        const brandKey: string =
          tableInfo.data[tableInfo.row.index].brand.brandKey;
        const agencyName: string =
          tableInfo.data[tableInfo.row.index].agency.agencyName;
        const agencyKey: string =
          tableInfo.data[tableInfo.row.index].agency.agencyKey;
        const campaignInfor: CampaignType = {
          campaignName: name,
          campaignKey: key,
          brand: {
            brandKey,
            brandName,
          },
          agency: {
            agencyKey,
            agencyName,
          },
        };
        return (
          <>
            <p>
              <span
                className="textlink"
                onClick={(e) => {
                  fns.setSelectedCampaign(tableInfo.data[tableInfo.row.index]);
                  fns.campaignStore.setCampaign(campaignInfor);
                  fns.navigate(`/campaign/${key}/overview`);
                }}
              >
                {name}
              </span>
            </p>
          </>
        );
      },
    },
    {
      Header: "Brand",
      accessor: "brand.brandName",
    },
    {
      Header: "Agency",
      accessor: "agency.agencyName",
    },
    {
      Header: "State",
      Cell: (TableInfo: any) => {
        let states: any = [];
        const key: string = TableInfo.data[TableInfo.row.index].campaignKey;
        const {
          isLoading: isLoadingState,
          data: dataStates,
          refetch,
        } = useQuery(["getCampaignStateSchedules"], async () => {
          const { campaignStateSchedules } = await spryClient.getCampaignStateSchedules({});
          const statesWithoutfutureDates = campaignStateSchedules.filter((state) => state.startTime < new Date());
          const sortedStates = statesWithoutfutureDates.sort((x, y) => x.startTime > y.startTime ? 1 : -1);
          sortedStates.forEach((x) => { states[x.campaignKey] = x.state; });
          setTimeout(() => refetch(), 1.08e7); // refetched current campaign status after 3Hrs.
          return states;
        });
        return isLoadingState
          ? ""
          : !dataStates[key]
            ? "none"
            : dataStates[key];
      },
    },
    {
      Header: "Updated",
      accessor: "updateTime",
      width: "11%",
      Cell: (TableInfo: any) => {
        const updateTime = TableInfo.data[TableInfo.row.index].updateTime;
        return dateFormatHelper(updateTime, "MM/DD/YYYY");
      },
    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (TableInfo: any) => {
        const campaignKey = TableInfo.data[TableInfo.row.index].campaignKey;
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link
                className="dropdown-item"
                to={`/campaign/${campaignKey}/overview`}
              >
                View Details
              </Link>
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  fns.setSelectedCampaign(TableInfo.data[TableInfo.row.index]);
                  fns.setShowModal(true);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return COLUMNS;
};
