import { useMemo } from 'react'
import { Table } from '../../../Components';
import { useQuery } from 'react-query';
import { spryClient } from '../../../api';
import { Spinner } from 'react-bootstrap';
import { PrizeWinner, GrandPrizeDrawRanking } from '@sprycore/spry-api-client/dist/MainDbReturnTypes';
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom"


type IProps = {
  campaignKey: string,
  claimedRankings: GrandPrizeDrawRanking[]
  grandPrizeDrawKey: string
}
type queryProps = {
  campaignKey: string,
  grandPrizeDrawKey: string
}
export const Claimed = ({ campaignKey, claimedRankings, grandPrizeDrawKey }: IProps) => {
  const navigate = useNavigate()

  const QueryKey_prizewinners: queryProps = { campaignKey, grandPrizeDrawKey }


  const { isLoading } = useQuery(['getPrizeWinners', QueryKey_prizewinners], async () => {
    const res = await spryClient.getPrizeWinners({ ...QueryKey_prizewinners });
    const claimedWinners = res.prizeWinners.filter((winner: PrizeWinner) => {
      return !winner.fulfillTime ? winner : undefined
    })
    return claimedWinners
  })

  const { isLoading: rankingsLoading } = useQuery(['getGrandPrizeDrawRankings', QueryKey_prizewinners], async () => {
    const res = await spryClient.getGrandPrizeDrawRankings(QueryKey_prizewinners)
    return res.grandPrizeDrawRankings
  })

  const COLUMNS = [
    {
      Header: 'Rank',
      accessor: 'ranking',
      width: "3%",

    }, {
      Header: 'First Name',
      accessor: 'participant.firstName',
      width: "8%",

    },
    {
      Header: 'Last Name',
      accessor: 'participant.lastName',
      width: "8%",

    },
    {
      Header: 'Email',
      accessor: 'participant.email',
      width: "8%",
    },
    {
      Header: 'Last Updated',
      accessor: 'creationTime',
      width: "18%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (tableInfo: any) => {
        const grandPrizeDrawRankingKey = tableInfo.data[tableInfo.row.index].grandPrizeDrawRankingKey
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  navigate(`/campaign/${campaignKey}/prizedraws/${grandPrizeDrawKey}/claimed/${grandPrizeDrawRankingKey}`)
                }}
              >
                View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },


  ]
  // eslint-disable-next-line
  const columns = useMemo(() => COLUMNS, [])


  return (
    <>
      {

        isLoading || rankingsLoading ? <div className="spinner"><Spinner animation="border" variant="secondary" /></div> :
          claimedRankings && (claimedRankings.length > 0 ? <Table columns={columns} data={claimedRankings} tablePageSize={10} /> : <p className="table-nodata"> There are no records to display.</p>)}
    </>
  )

}



