import { useState } from "react";
import { Spinner } from "react-bootstrap";
import icoCalendar from "../../../assets/images/ico-calendar.png";
import icoClock from "../../../assets/images/ico-clock.png";
import { spryClient } from "../../../api";
import DatePickerCalendar from "../DatePickerCalendar";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../Components/Toast/ToastManager";
import { CreateGrandPrizeDrawArgs } from "@sprycore/spry-api-client/dist/MainDbCallTypes";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { MultiSelect } from "react-multi-select-component";

type IPrizeDraw = {
  drawName: string;
  prizePool: string;
  entryStartDate: Date;
  entryStartTime: Date;
  entryEndDate: Date;
  entryEndTime: Date;
  numberOfWinners: number;
  numberOfAlternates?: number;
  filterDuplicateParticipants: boolean;
  filterDuplicateEmails: boolean;
  excludePreviousWinners: boolean;
  maxRankingCount: number | null;
  keywordKey: string;
  keywordLocationKey: { label: string; value: string }[];
  perlocation: boolean;
  total: boolean;
  anyTags: string;
  allTags: string;
};
type typeProp = {
  campaignKey: string;
};

function ConductPrizeDraw({ campaignKey }: typeProp) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    drawName: "",
    prizePool: "",
    entryStartDate: new Date(),
    entryStartTime: new Date(),
    entryEndDate: new Date(),
    entryEndTime: new Date(),
    numberOfWinners: 0,
    numberOfAlternates: 0,
    filterDuplicateParticipants: false,
    filterDuplicateEmails: false,
    excludePreviousWinners: false,
    maxRankingCount: 0,
    keywordLocationKey: [],
    perlocation: false,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IPrizeDraw>({
    mode: "onTouched",
    defaultValues: {
      ...initialValues,
    },
  });
  const formWatch = watch();
  const { isLoading: loadingPrizePools, data: prizePools } = useQuery(
    ["getPrizePools", campaignKey],
    async () => {
      const res = await spryClient.getPrizePools({ campaignKey });
      return res.prizePools;
    }
  );
  const { isLoading: loadingKeywords, data: keywords } = useQuery(
    ["getKeywords", campaignKey],
    async () => {
      const { keywords } = await spryClient.getKeywords({ campaignKey });
      return keywords;
    },
    { enabled: !!campaignKey }
  );

  const { isLoading: loadinglocations, data: locations } = useQuery(
    ["getKeywordLocations", formWatch.keywordKey],
    async () => {
      const { keywordLocations } = await spryClient.getKeywordLocations({
        campaignKey,
      });
      return keywordLocations
        .filter((x) => x.keywordKey === formWatch.keywordKey)
        ?.map((x) => {
          return { label: x.name, value: x.keywordLocationKey };
        });
    },
    { enabled: !!formWatch.keywordKey }
  );

  const handlecreatedraw = async (data: CreateGrandPrizeDrawArgs) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await spryClient.createGrandPrizeDraw(data);
      if (res.grandPrizeDrawName === data.grandPrizeDrawName) {
        showToast({
          content: `${data.grandPrizeDrawName} created successfully. `,
          duration: 3000,
          error: false,
        });
        navigate(`/campaign/${campaignKey}/prizedraws`);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      showToast({
        content: "Oops! Error while creating the draw. ",
        duration: 3000,
        error: true,
      });
    }
  };
  const getDateTime = (date: Date, time: Date) => {
    let tempDate = dayjs(date);
    let updatedDate_time = dayjs(tempDate)
      .hour(dayjs(time).hour())
      .minute(dayjs(time).minute())
      .second(dayjs(time).second());
    const finalDate = updatedDate_time.toISOString();
    return finalDate;
  };

  const submitPrizeDraw = async (data: IPrizeDraw) => {
    if (data.keywordLocationKey.length > 1) {
      // per location - winners
      if (+data.perlocation) {
        Promise.all(
          data.keywordLocationKey.map(async (k) => {
            const anyTags: Record<string, boolean> = {};
            const allTags: Record<string, boolean> = {};
            allTags[`keyword:${data.keywordKey}`] = true;
            allTags[`location:${k.value}`] = true;
            getTags(data.anyTags, anyTags);
            getTags(data.allTags, allTags);
            await createPrizeDrawfn(
              {
                ...data,
                drawName: `${data.drawName}-${k.label
                  .toLowerCase()
                  .trim()
                  .replace(" ", "_")}`,
              },
              anyTags,
              allTags
            );
          })
        );
      } else {
        const anyTags: Record<string, boolean> = {};
        const allTags: Record<string, boolean> = {};
        allTags[`keyword:${data.keywordKey}`] = true;
        data.keywordLocationKey.map(async (k) => {
          anyTags[`location:${k.value}`] = true;
          getTags(data.anyTags, anyTags);
          getTags(data.allTags, allTags);
        });
        await createPrizeDrawfn(data, anyTags, allTags);
      }
    } else {
      if (data.keywordLocationKey.length) {
        const anyTags: Record<string, boolean> = {};
        const allTags: Record<string, boolean> = {};
        allTags[`keyword:${data.keywordKey}`] = true;
        allTags[`location:${data.keywordLocationKey[0].value}`] = true;
        getTags(data.anyTags, anyTags);
        getTags(data.allTags, allTags);
        await createPrizeDrawfn(data, anyTags, allTags);
      } else if (data.keywordKey) {
        const anyTags: Record<string, boolean> = {};
        const allTags: Record<string, boolean> = {};
        allTags[`keyword:${data.keywordKey}`] = true;
        await createPrizeDrawfn(data, anyTags, allTags);
      } else {
        await createPrizeDrawfn(data, {}, {});
      }
    }
  };
  function getTags(str: string, tags: Record<string, boolean>) {
    str.split(",").forEach((x) => {
      const tag = x.trim().toLowerCase();
      if (tag) {
        tags[tag] = true;
      }
    });
  }
  const createPrizeDrawfn = async (
    data: IPrizeDraw,
    anyTags: Record<string, boolean>,
    allTags: Record<string, boolean>
  ) => {
    const drawContent = {
      campaignKey: campaignKey,
      prizePoolKey: data.prizePool,
      grandPrizeDrawName: data.drawName,
      entryStartTime: getDateTime(data.entryStartDate, data.entryStartTime),
      entryFinishTime: getDateTime(data.entryEndDate, data.entryEndTime),
      filterDuplicateParticipants: data.filterDuplicateParticipants,
      filterDuplicateEmails: data.filterDuplicateEmails,
      excludePreviousNonForfeitedWinners: data.excludePreviousWinners,
      maxRankingCount: data.numberOfWinners || null,
      anyTags: Object.keys(anyTags),
      allTags: Object.keys(allTags),
    };
    handlecreatedraw(drawContent);
  };
  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/prizedraws`);
  };

  const keywordKey = getValues("keywordKey");

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont addPg">
        <div id="tab08" className="tab-contents prizedraws">
          {loading ||
          loadinglocations ||
          loadingPrizePools ||
          loadingKeywords ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <div className="head inner">
                <h3>
                  <button className="backBtn" onClick={backHandler}>
                    <i className="fas fa-arrow-left"></i>
                  </button>
                  Conduct a prize draw
                </h3>
                <button
                  type="submit"
                  form="conductPrizeDrawForm"
                  className="btn"
                >
                  Submit
                </button>
              </div>

              <form
                onSubmit={handleSubmit(submitPrizeDraw)}
                id="conductPrizeDrawForm"
              >
                <div className="formContent ">
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">
                          Draw name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("drawName", {
                            required: {
                              value: true,
                              message: "Please enter a draw name.",
                            },
                          })}
                          aria-describedby="Draw Name"
                          placeholder="Draw Name"
                          id="exampleFormControlInput1"
                        />
                        {errors.drawName && (
                          <div className="error">
                            <i className="fas fa-exclamation-circle" />
                            {errors.drawName.message}
                          </div>
                        )}
                      </div>
                      <div
                        className="col-sm-12 col-md-7"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Prize Pool
                          </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect2"
                            {...register("prizePool", {
                              required: {
                                value: true,
                                message: "please select a prize pool",
                              },
                            })}
                          >
                            <option value="">Please select a prize pool</option>

                            {prizePools?.map((prizePool: any) => (
                              <option
                                value={prizePool.prizePoolKey}
                                key={prizePool.prizePoolKey}
                              >
                                {prizePool.prizePoolName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.prizePool && (
                          <div className="error">
                            <i className="fas fa-exclamation-circle" />
                            {errors.prizePool.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-7">
                      <div className="date start">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Entry start date
                          </label>
                          <DatePickerCalendar
                            name="entryStartDate"
                            value={formWatch.entryStartDate}
                            onChange={(val: Date) => {
                              setValue("entryStartDate", val);
                              setValue("entryEndDate", val);
                            }}
                            readOnly={false}
                            dateforTime={formWatch.entryStartDate}
                          />
                          <span className="calIco">
                            <img src={icoCalendar} alt="Select Date" />
                          </span>
                          {errors.entryStartDate && (
                            <div className="error">
                              <i className="fas fa-exclamation-circle" />
                              <>{errors.entryStartDate.message}</>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="date End date">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Entry end date
                          </label>
                          <DatePickerCalendar
                            name="entryEndDate"
                            value={formWatch.entryEndDate}
                            onChange={(val: Date) =>
                              setValue("entryEndDate", val)
                            }
                            startDate={formWatch.entryStartDate}
                            readOnly={false}
                            dateforTime={formWatch.entryEndDate}
                          />
                          <span className="calIco">
                            <img src={icoCalendar} alt="Calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5">
                      <div className="clock start">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Entry start Time
                          </label>
                          <DatePickerCalendar
                            showTimePicker
                            name="entryStartTime"
                            value={formWatch.entryStartTime}
                            onChange={(val: Date) =>
                              setValue("entryStartTime", val)
                            }
                            readOnly={false}
                            dateforTime={formWatch.entryStartDate}
                          />
                          <span className="timeIco">
                            <img src={icoClock} alt="Select Time" />
                          </span>
                        </div>
                      </div>
                      <div className="clock End">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Entry end Time
                          </label>
                          <DatePickerCalendar
                            showTimePicker
                            name="entryEndTime"
                            value={formWatch.entryEndTime}
                            onChange={(val: Date) =>
                              setValue("entryEndTime", val)
                            }
                            readOnly={false}
                            dateforTime={formWatch.entryEndDate}
                          />
                          <span className="timeIco">
                            <img src={icoClock} alt="Calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group flex-column">
                    <label htmlFor="exampleInputEmail1">
                      Number of Winners
                    </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control noofTimeStamps"
                      id="exampleInputEmail1"
                      {...register("numberOfWinners", {
                        required: {
                          value: true,
                          message: "Please enter no of winners.",
                        },
                        min: {
                          value: 1,
                          message: "Please enter a valid number",
                        },
                      })}
                      aria-describedby="emailHelp"
                      placeholder="Number of Potential Winners"
                    />
                    {errors.numberOfWinners && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.numberOfWinners.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group flex-column">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        {...register("filterDuplicateParticipants", {
                          required: false,
                        })}
                        className="form-check-input"
                        id="defaultCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Filter duplicate participants
                      </label>
                    </div>
                  </div>

                  <div className="form-group flex-column">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        {...register("filterDuplicateEmails", {
                          required: false,
                        })}
                        className="form-check-input"
                        id="defaultCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        Filter duplicate emails
                      </label>
                    </div>
                  </div>
                  <div className="form-group flex-column">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        {...register("excludePreviousWinners", {
                          required: false,
                        })}
                        className="form-check-input"
                        id="defaultCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Exclude Previous Winners(for the selected prize pool)
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-sm-12 col-md-7"
                    style={{ paddingLeft: "0px" }}
                  >
                    <div className="form-group">
                      <label>Filter by Keyword</label>
                      <select
                        className="form-control"
                        {...register("keywordKey", { required: false })}
                      >
                        <option value="">[None]</option>
                        {keywords?.map((x) => (
                          <option key={x.keywordKey} value={x.keywordKey}>
                            {x.word} ({x.startTime.toISOString()} to{" "}
                            {x.endTime.toISOString()})
                          </option>
                        ))}
                      </select>
                      {!!keywordKey && (
                        <span>
                          Participant must have tag keyword:{keywordKey}
                          <br />
                        </span>
                      )}
                    </div>
                  </div>
                  {keywordKey && locations?.length && (
                    <div
                      className="col-sm-12 col-md-7"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="form-group tag-list">
                        <label>Filter by Keyword Location</label>
                        {formWatch.keywordLocationKey.length ? (
                          <ol>
                            {formWatch.keywordLocationKey.map((k, i) => (
                              <li className="location-tabs" key={k.value}>
                                {i + 1}. {k.label}
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <></>
                        )}
                        <MultiSelect
                          options={locations}
                          value={formWatch.keywordLocationKey}
                          onChange={(v: any) =>
                            setValue("keywordLocationKey", v)
                          }
                          labelledBy="Select search"
                        />
                      </div>
                    </div>
                  )}

                  {formWatch.keywordLocationKey.length > 1 ? (
                    <>
                      <div className="form-group">
                        {" "}
                        <div className="form-check">
                          <input
                            type="radio"
                            {...register("perlocation", {
                              required: false,
                            })}
                            className="form-check-input"
                            value={1}
                            id="perlocation-1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="perlocation-1"
                          >
                            Per location
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        {" "}
                        <div className="form-check">
                          <input
                            type="radio"
                            {...register("perlocation", {
                              required: false,
                            })}
                            value={0}
                            className="form-check-input"
                            id="perlocation-2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="perlocation-2"
                          >
                            Total
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="form-group">
                    <label>
                      Participants must have at least one of these tags
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("anyTags", { required: false })}
                    />
                    <span>
                      Example: tag1, tag2, tag3
                      <br />
                    </span>
                  </div>

                  <div className="form-group">
                    <label>Participants must have all of these tags</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("allTags", { required: false })}
                    />
                    <span>
                      Example: tag1, tag2, tag3
                      <br />
                    </span>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ConductPrizeDraw;
