
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input, RadioInput, Textarea } from './formelements';
import { AddressForm } from './AddressForm';
import icoCal from "../../../assets/images/ico-calendar.png";
import icoTime from "../../../assets/images/ico-clock.png";
import dayjs from 'dayjs';
import { PrizeDetails } from '../PrizeDetails';




export type ControlType =
    | 'text'
    | 'select'
    | 'number'
    | 'checkbox'
    | 'radios'
    | 'textarea'
    | 'date'
    | 'custom_brand'
    | 'custom_address'
    | 'time'
    | 'data_capture'
    | 'sweepstake'
    | 'giveaway'
    | 'quiz'
    | 'checkbox_group'
    | 'title'
    | 'program_module';

export type DynamicFieldData = {
    label: string;
    description?: string
    inputType: ControlType;
    name: string;
    value?: string
    validation?: any;
    options?: SelectOption[];
    status: string;
    disabled?: boolean;
};

export interface SelectOption {
    label: string;
    value: string;
    name?: string;
    id?: string;
    components?: any[];
}

const getFormLabel = (label: string) => {
    return <label className="font-extrabold">{label}</label>;
};

const RenderControl = ({ Field, disabled, control }: any) => {

    // const { watch } = useFormContext();

    switch (Field.inputType) {
        case 'text':
            return (
                <FormField
                    name={Field.name}
                    render={({ field }) => {
                        return (
                            <FormItem>
                                {getFormLabel(Field.label)}
                                <FormControl>
                                    <div className="">
                                        {/* {field.value} */}
                                        <p> {field.value || "N/A"}</p>

                                        {/* <Input
                                            placeholder={Field.placeHolder || ''}
                                            {...field}
                                            disabled={disabled || false}
                                            value={field.value === undefined ? '' : field.value}
                                        /> */}
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />
            );
        case 'textarea':
            return (
                <FormField
                    name={Field.name}
                    //control={form.control}
                    render={({ field }) => {
                        return (

                            <FormItem>
                                {getFormLabel(Field.label)}
                                <FormControl>
                                <p> {field.value || "N/A"}</p>

                                    {/* <Textarea
                                        placeholder=''
                                        {...field}
                                        disabled={disabled || false}
                                    /> */}
                                </FormControl>
                                <FormMessage />
                            </FormItem>

                        )
                    }
                    }
                />
            );
        case 'radios':
            return (
                <FormField
                    name={Field.name}
                    render={({ field }) => {
                        return (<>
                            <FormItem >
                                {getFormLabel(Field.label)}
                                {Field.options?.filter((p: any) => p?.value && field.value?.includes(p?.value)).length ?

                                    <FormControl >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {Field.options.filter((p: any) => field.value?.includes(p.value)).map((o: { label: string, value: string, components?: any }) =>
                                                <>
                                                    <RadioInput
                                                        type='checkbox'
                                                        defaultValue={field.value}
                                                        className="form-check-input"
                                                        value={o.label}
                                                        id={o.value}
                                                        key={o.value}
                                                        checked={(field.value?.includes(o.value)) ? true : false}
                                                    />
                                                    {/* {field.value?.includes(o.value) &&
                                                        o.components &&
                                                        o.components.map((com: any) => (
                                                            <RenderControl
                                                                key={com.name}
                                                                Field={com}
                                                                disabled={disabled || false}
                                                            />
                                                        ))} */}
                                                </>
                                            )}


                                            {/* {field.value} */}
                                        </div>



                                    </FormControl> : <div className='form-check'> <li style={{ fontSize: "14px" }}>N/A</li></div>}
                                <FormMessage />
                            </FormItem >

                        </>
                        );
                    }}
                />
            );
        case 'checkbox': {
            return (
                <FormField
                    name={Field.name}
                    render={({ field }) => {
                        return (<>
                            <FormItem >
                                {getFormLabel(Field.label)}
                                {Field.options?.filter((p: any) => field?.value?.includes(p.value)).length ?

                                    <FormControl>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {Field.options.filter((p: any) => field.value?.includes(p.value)).map((o: { label: string, value: string, components?: any }) =>
                                                <>
                                                    <li style={{ fontSize: "14px" }}> {o.label}</li>

                                                    {field.value?.includes(o.value) &&
                                                        o.components &&
                                                        o.components.map((com: any) => (
                                                            <RenderControl
                                                                key={com.name}
                                                                Field={com}
                                                                disabled={disabled || false}
                                                            />
                                                        ))}
                                                </>
                                            )}


                                            {/* {field.value} */}
                                        </div>



                                    </FormControl> : <div className='form-check'> <li style={{ fontSize: "14px" }}>N/A</li></div>}
                                <FormMessage />
                            </FormItem >

                        </>
                        );
                    }}
                />

            );
        }
        case 'custom_address':
            return <AddressForm Field={Field} disabled={disabled || false} />;
        // case 'custom_brand':
        //     return <CompanyBrand />;
        case 'date':
            return (
                <FormField
                    name={`${Field.name}`}
                    render={({ field }) => (
                        <FormItem className='flex flex-col clock'>
                            <FormLabel className="font-extrabold">{Field.label}</FormLabel>
                            <p>{field.value}</p>
                            {/* <input
                                className="form-control"
                                value={dayjs(field.value).format("YYYY-MM-DD")}
                                readOnly
                            />
                            <span className="timeIco" style={{ padding: "12px" }}>
                                <img src={icoCal} alt="Select Date" />
                            </span> */}

                            <FormMessage />
                        </FormItem>
                    )}
                />
            );
        case 'time':
            return (
                <FormField
                    name={`${Field.name}`}
                    render={({ field }) => (
                        <FormItem className='flex flex-col clock'>
                            <FormLabel className="font-extrabold">{Field.label}</FormLabel>
                            <input
                                className="form-control"
                                value={field.value}
                                readOnly
                            />
                            <span className="timeIco" style={{ padding: "12px" }}>
                                <img src={icoTime} alt="Select Date" />
                            </span>

                            <FormMessage />
                        </FormItem>
                    )}
                />
            );
        case 'prizes':
            return <PrizeDetails {...Field} />;
        case 'grand_prizes':
            return <PrizeDetails {...Field} grandPrize={true} />;
        case 'grand_prizes':
            return <PrizeDetails {...Field} grandPrize={true} />;
        case 'quiz':
            return <></>
        case 'quiz_result':
            return <></>
        default:
            return <div></div>
    }
}

export { RenderControl };
