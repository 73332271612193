import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import { CampaignPrizingColumns } from "./PrizeColumns";
import { CampaignPoolPrizeColumns } from "./PoolPrizeColumns";
import { Table } from "../../../Components";
import { spryClient } from "../../../api";
import {
  PrizePool,
  PrizePoolPrize,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { useNavigate } from "react-router-dom";

type Props = { campaignKey: string };

function CampaignPrize({ campaignKey }: Props) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let finalPrizePools;

  const { data: allPrizes, isLoading: loadingPrizes } = useQuery(
    ["getPrizes", campaignKey],
    async () => {
      const res = await spryClient.getPrizes({ campaignKey });
      return res.prizes;
    }
  );
  const { data: allPrizePools, isLoading: loadingPrizePools } = useQuery(
    ["getPrizePools", campaignKey],
    async () => {
      const res = await spryClient.getPrizePools({ campaignKey });
      return res.prizePools;
    }
  );
  const findPrizeName = (prizeKey: String, allPrizes: any) => {
    const matchedPrize = allPrizes.filter((prize: any) => {
      return prize.prizeKey === prizeKey;
    });
    return matchedPrize[0].prizeName || "";
  };
  const findPrizePoolName = (prizePoolKey: String, prizePools: PrizePool[]) => {
    const matchedPrizePool = prizePools.filter((prizePool: PrizePool) => {
      return prizePool.prizePoolKey === prizePoolKey;
    });
    return matchedPrizePool[0].prizePoolName || "";
  };

  const { data: allprizePoolPrizes, isLoading: poolPrizesLoading } = useQuery(
    ["getPrizePoolPrizes", campaignKey, allPrizes, allPrizePools],
    async () => {
      const res = await spryClient.getPrizePoolPrizes({ campaignKey });
      let updatedPrizePoolPrizes = res.prizePoolPrizes.map(
        (record: PrizePoolPrize) => {
          let newrecord = {
            ...record,
            prizeNames: allPrizes && findPrizeName(record.prizeKey, allPrizes),
            prizePoolName:
              allPrizePools &&
              findPrizePoolName(record.prizePoolKey, allPrizePools),
          };
          return newrecord;
        }
      );
      let activePrizes = updatedPrizePoolPrizes.filter((record: any) => {
        return record.active === true;
      });
      return activePrizes;
    }
  );

  const [updatedPrizePools, setupdatedPrizePools] = useState<any[]>();

  const [error, setError] = useState("");
  const { isLoading: isLoadingDel } = useMutation(spryClient.deletePrize, {
    onSuccess: (data) => {
      setError("");
      // console.log('Prize deleted successfully');
    },
    onError: () => {
      // console.log('error')
      setError("Prize is already associated with an instant prize timestamp");
    },
    onSettled: () => {
      queryClient.invalidateQueries("getPrizes");
    },
  });

  const { isLoading: isLoadingDelPool } = useMutation(spryClient.deletePrizePool, {
    onSuccess: (data) => {
      // console.log('Prize Pool deleted successfully')
    },
    onError: () => {
      alert("there was an error");
    },
    onSettled: () => {
      queryClient.invalidateQueries("getPrizePools");
    },
  });

  // eslint-disable-next-line
  const columns = CampaignPrizingColumns();
  // eslint-disable-next-line
  const columnsPrizePool = CampaignPoolPrizeColumns();
  const getfinalprizepools = () => {
    if (allPrizePools && allprizePoolPrizes) {
      finalPrizePools = allPrizePools.map((record: any) => {
        const prizerecords = allprizePoolPrizes.filter((prizes: any) => {
          return record.prizePoolKey === prizes.prizePoolKey;
        });
        record["prizes"] =
          prizerecords &&
          prizerecords
            .map((prize: any) => {
              return prize.prizeNames;
            })
            .toString();

        return record;
      });
      setupdatedPrizePools(finalPrizePools);
    }
  };
  useEffect(() => {
    getfinalprizepools();
    // eslint-disable-next-line
  }, [allPrizePools, allprizePoolPrizes]);

  return (
    <div className="dashboardContent campaignDetail tabsCont addpg prizing">
      <div>
        <div className="head inner">
          <h2>Prizes</h2>
          <span
            className="btn addbtn"
            id="addprizebtn"
            onClick={() =>
              navigate(`/campaign/${campaignKey}/prizing/addPrize`)
            }
          >
            Add prize
          </span>
          {error && <p className="error-message"> {error}</p>}
        </div>
        <div id="tab04" className="tab-contents">
          {poolPrizesLoading ||
            isLoadingDel ||
            isLoadingDelPool ||
            loadingPrizes ? (
            <div className="spinner">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Table
              columns={columns}
              data={allPrizes && allPrizes.length > 0 ? allPrizes : []}
              tablePageSize={15}
              sortbyid={"no"}
            />
          )}

          <div className="head inner">
            <h3>Prize pools</h3>
            <span
              className="btn "
              onClick={() => {
                navigate(`/campaign/${campaignKey}/prizing/addPrizePool`);
              }}
            >
              Add Prize Pool
            </span>
          </div>
          <div className="dataGrid">
            {poolPrizesLoading ||
              isLoadingDelPool ||
              loadingPrizePools ||
              loadingPrizes ? (
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <Table
                columns={columnsPrizePool}
                data={
                  updatedPrizePools && updatedPrizePools.length > 0
                    ? updatedPrizePools
                    : []
                }
                tablePageSize={15}
                sortbyid={"no"}
              />
            )}
          </div>
        </div>
      </div>
      )
    </div>
  );
}

export default CampaignPrize;
