import { Dropdown } from "react-bootstrap";
import { NavigateFunction } from "react-router-dom";


export const GiftCardTypesColumns = (fns: {
  setIsOpen: Function,
  setSelectedType: Function
  setUpdatedName: Function
  deleteGiftCardType: Function
  setIsOpenDelete: Function
  navigate: NavigateFunction
}) => {

  const COLUMNS = [
    {
      Header: "No",
      width: "1%",
      accessor: "index",
      Cell: (tableInfo: any) => {
        return (
          <p> {tableInfo.row.index + 1}</p>
        )
      },
    },
    {
      Header: 'Gift Card Type',
      accessor: 'giftCardTypeName',
      width: "5%",
      Cell: (tableInfo: any) => <button value={tableInfo.data[tableInfo.row.index].grandPrizeDrawName} onClick={e => {
        const campaignkey: string = tableInfo.data[tableInfo.row.index].campaignKey
        const typekey: string = tableInfo.data[tableInfo.row.index].giftCardTypeKey
        // console.log(cell.data[cell.row.index].grandPrizeDrawKey)
        fns.navigate(`/campaign/${campaignkey}/giftcards/${typekey}`,
          { state: tableInfo.data[tableInfo.row.index] })
      }} className="linkButton"> {tableInfo.data[tableInfo.row.index].giftCardTypeName}</button>
    },
    {
      Header: 'Created',
      accessor: 'creationTime',
      width: "3%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },
    },
    {
      Header: "Total",
      width: "1%",
      accessor: "total",

    },
    {
      Header: "Assigned",
      width: "1%",
      accessor: "assigned",

    },
    {
      Header: "Unassigned",
      width: "1%",
      accessor: "unAssigned",

    },

    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (tableInfo: any) => {
        const campaignKey = tableInfo.data[tableInfo.row.index].campaignKey;
        const typekey: string = tableInfo.data[tableInfo.row.index].giftCardTypeKey;

        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (

          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu >
              <Dropdown.Item className="dropdown-item"
                onClick={(e) => {
                  fns.navigate(`/campaign/${campaignKey}/giftcards/${typekey}`,
                    { state: tableInfo.data[tableInfo.row.index] })
                }}
              >
                View
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-item"
                onClick={async (e) => {
                  fns.setSelectedType(tableInfo.data[tableInfo.row.index])
                  fns.setUpdatedName(tableInfo.data[tableInfo.row.index].giftCardTypeName)
                  fns.setIsOpen(true)
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-item"
                onClick={(e) => {
                  fns.setSelectedType(tableInfo.data[tableInfo.row.index])
                  fns.setUpdatedName(tableInfo.data[tableInfo.row.index].giftCardTypeName)
                  fns.setIsOpenDelete(true)
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },



  ]
  return COLUMNS
}



