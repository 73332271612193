import { useNavigate } from "react-router-dom";

export const CampaignPoolPrizeColumns = () => {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: 'No',
      accessor: "no",
      width: "5%",
      Cell: (row: any) => {
        return <div>{Number(row.row.id) + 1}</div>;
      },
    },
    {
      Header: 'Prize Pool Name',
      accessor: 'prizePoolName',
      width: "25%",

      Cell: (TableInfo: any) => {
        const prizePoolKey = TableInfo.data[TableInfo.row.index].prizePoolKey;
        const prizePoolName = TableInfo.data[TableInfo.row.index].prizePoolName;
        const campaignKey = TableInfo.data[TableInfo.row.index].campaignKey;

        return <div><button className="linkButton" onClick={e => {
          navigate(`/campaign/${campaignKey}/prizing/updatePrizePool/${prizePoolKey}`);

        }}>{prizePoolName}</button></div>
      }
    },
    {
      Header: 'Prizes',
      accessor: 'prizes',
    }
  ];

  return COLUMNS;
}