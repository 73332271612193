import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import CRDetails from './CRDetails'
import { Table } from '../../Components'
import { Row, TableProps } from "react-table";
import { useStores } from '../../stores'
import { useQuery } from 'react-query'
import { getAssets, getPrograms } from '../../api'
import { Link } from 'react-router-dom'
import { TQuote } from '../../types/portalTypes';

const ChangeRequests = ({ programId, active }: { programId: string, active: boolean }) => {

    const { data: program, isLoading: programLoading, refetch: refetchProgram, isRefetching } = useQuery(
        ["getChangeRequests", programId],
        async () => {
            const res = await getPrograms({ token: userStore.user.token, id: programId, include: ["quotes", "approvedQuote", "company"] });
            return res.programs
        },
        { enabled: !!programId && !!active }
    )
    const changeRequests = program?.quotes.filter((q: TQuote) => +q.type === 1) || []
    const [selected, setSelected] = useState<string | undefined>(undefined)
    const { userStore } = useStores();

    const { data: quotePdfs, isLoading: quotePdfsLoading } = useQuery(["getAssets", changeRequests], async () => {


        let quoteassets: { [key: string]: any } = {}
        if (changeRequests?.length) {
            for (let q of changeRequests) {
                let res: any = await getAssets({ token: userStore.user.token, id: q.id, entityType: "quote" })
                quoteassets[q.id] = res[0] ? res[0].url : ""

            }

        }


        return quoteassets;
    }, { enabled: !!changeRequests.length });

    const COLUMNS = [
        {
            Header: "No",
            width: "1%",
            accessor: "index",
            Cell: (tableInfo: any) => {
                return <p> {tableInfo.row.index + 1}</p>;
            },
        },
        {
            Header: "Title",
            accessor: "title",
            Cell: (TableInfo: TableProps) => {
                const { id } = TableInfo.data[TableInfo.row.index]

                return <button className='linkButton' onClick={() => { setSelected(id) }}>{TableInfo.data[TableInfo.row.index].title}</button>;
            },

        },

        // {
        //     Header: "Company name",
        //     accessor: "companyName"

        // },
        {
            Header: "Jira ticket",
            accessor: "jiraLink",
        },
        {
            Header: "Estimation",
            accessor: "estimate",
        },
        {
            Header: "Estimation pdf",
            accessor: "estimationUrl",
            Cell: (TableInfo: TableProps) => {
                const { id } = TableInfo.data[TableInfo.row.index]

                const pdf = quotePdfs ? quotePdfs[id] : ""

                return <Link className='linkButton' to={pdf} target='_blank'>{pdf.split('?')[0].split('--')[1]}</Link>;
            },
        },
        // {
        //     Header: "Status",
        //     accessor: "status",
        //     Cell: (TableInfo: TableProps) => {
        //         const status = EQuoteStatuses[TableInfo.data[TableInfo.row.index].status]

        //         return status
        //     },

        // },
        {
            Header: "Created",
            accessor: "creationTime",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].creationTime
                    ? TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
                    : "";
                return dateString;
            },
        },
        {
            Header: "Updated",
            accessor: "updationTime",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].updationTime
                    ? TableInfo.data[TableInfo.row.index].updationTime.toLocaleString()
                    : "";
                return dateString;
            },
        },
        {
            Header: "Actions",
            accessor: "id",

            Cell: (TableInfo: TableProps) => {

                return <button onClick={() => { }} className='linkButton'><i className="fas fa-trash"></i></button>;
            },
        }
    ];

    return (
        <>
            {selected && quotePdfs ? (selected === "new" ?
                <CRDetails newCR={true} program={program!} backHandler={() => setSelected(undefined)} refetchProgram={refetchProgram} quotePdf= {null} />
                :
                <CRDetails selected={selected} program={program!} backHandler={() => setSelected(undefined)} refetchProgram={refetchProgram} quotePdf={quotePdfs[selected]||null}/>) :
                <div className="dashboardContent quotes">
                    {(quotePdfsLoading || programLoading || isRefetching) ? (
                        <div className="spinner">
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    ) : (
                        <>
                            <div className="taskHead">
                                {(program && program.approvedQuote) &&
                                    <button
                                        className="btn action-buttons-sm"
                                        onClick={() => { setSelected("new") }}
                                    >
                                        New change request
                                    </button>}
                            </div>
                            {changeRequests.length ?
                                <Table
                                    columns={COLUMNS}
                                    data={changeRequests.map((c: TQuote) => { return { ...c, ...JSON.parse(c.additional_fields), companyName: "", creationTime: new Date(c.created_at), updationTime: new Date(c.updated_at) } })}
                                    tablePageSize={15}
                                    tableTitle="CRs"
                                    sortbyid="updateTime"
                                    descending={false}
                                /> :
                                <p style={{ textAlign: 'center' }}> no change requests to display</p>}

                        </>)}
                    {/* )} */}

                </div>
            }

        </>

    )
}

export default ChangeRequests