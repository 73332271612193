type IProps = {
  filter: string;
  setFilter: Function;
  tableheader: string;
  setselectedPage?: Function;
};
function GlobalFilter({
  filter,
  setFilter,
  tableheader,
  setselectedPage,
}: IProps) {
  return (
    <>
      <div
        className="head"
        style={
          tableheader === "Campaign Cognito Users"
            ? { display: "flex", flexDirection: "column" }
            : {}
        }
      >
      { tableheader!=="Campaigns" && tableheader!=="Quotes" && <h3>{ tableheader}</h3>}
        <div className="searchCont">
          <div className="searchArea">
            <input
              type="text"
              name="search"
              id="q"
              value={filter ?? ""}
              className="form-control search"
              placeholder="Search"
              onChange={(e) => {
                setselectedPage && setselectedPage(0);
                setFilter(e.target.value);
              }}
            />
          </div>{" "}
        </div>
      </div>
      {/* /.search */}
    </>
  );
}

export default GlobalFilter;
