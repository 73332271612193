import { NavigateFunction } from "react-router-dom"

type Props = {
  campaignKey: string
  navigate: NavigateFunction
}
export const WinningTimestampsColumns = ({ campaignKey, navigate }: Props) => {

  const COLUMNS = [
    {
      Header: 'Prize',
      accessor: 'prizeName',
      width: "20%",
    },
    {
      Header: 'Prize Pool',
      accessor: 'prizePoolName',
      width: "20%",

    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      width: "20%",
      Cell: (tableInfo: any) => <button value={tableInfo.data[tableInfo.row.index].timestamp} onClick={() => {
        if (tableInfo.data[tableInfo.row.index].timeStampKey === "N/A") {
          window.open(`/campaign/${campaignKey}/participants/${tableInfo.data[tableInfo.row.index].sessionKey}`, "_blank")
        }
        else {
          navigate(`/campaign/${campaignKey}/timestamps/${tableInfo.data[tableInfo.row.index].timeStampKey}`)
        }
      }} className="linkButton"> {tableInfo.data[tableInfo.row.index].timestamp}</button>


    },
    {
      Header: 'Priority',
      accessor: 'priority',
      width: "10%",
    },
    {
      Header: "Status",
      accessor: 'status',
      width: "10%"
    },
    {
      Header: "Assigned",
      accessor: 'assigned',
      width: "40%",
    }


  ]

  return COLUMNS
}



