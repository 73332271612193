import React from "react";
import { Dropdown } from "react-bootstrap";
import { UserStore } from "../../stores";
import { showToast } from "../../Components/Toast/ToastManager";
import { resendPassword } from "../../api";

export const AdminColumns = (fns: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  //   handleUpdate: (data: { agencyKey: string; agencyName: string }) => void;
  //   mutate: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>;
  setisOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
  setShowEditBar: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
  userStore: UserStore
}) => {
  const Columns = [
    {
      Header: "User Name",
      accessor: "username",
      width: "15%",
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "15%",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "15%",
    },
    {
      Header: "Email",
      accessor: "email",
      width: "15%",
    },
    {
      Header: "Role",
      accessor: "role",
      width: "15%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "9%",
    },
    {
      Header: "Updated",
      accessor: "updateDate",
      width: "9%",
    },
    {
      Header: "#",
      accessor: "enabled",
      width: "5%",
      Cell: (TableInfo: any) => {

        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (
          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ fontSize: "15px" }}>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={async () => {
                  const userStatus = TableInfo.data[TableInfo.row.index].status;
                  if (userStatus === "FORCE_CHANGE_PASSWORD") {
                    try {
                      const res: any = await resendPassword({
                        userName: TableInfo.data[TableInfo.row.index].email,
                        token: fns.userStore.user.token,
                      });
                      if (res) {
                        showToast({
                          content: `Temporary password for ${TableInfo.data[TableInfo.row.index].email
                            } has been resent.`,
                          duration: 3000,
                          error: false,
                        });
                      }
                    } catch (e) {
                      showToast({
                        content: `Temporary password for ${TableInfo.data[TableInfo.row.index].email
                          } has failed to resent since user status is Confirmed.`,
                        duration: 3000,
                        error: true,
                      });
                    }
                  }
                }}
              >
                {TableInfo.data[TableInfo.row.index].status ===
                  "FORCE_CHANGE_PASSWORD"
                  ? "Resend Temp Password"
                  : ""}
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  fns.setSelectedUser(TableInfo.data[TableInfo.row.index]);
                  fns.setShowModal(true);
                }}
              >
                Delete User
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item"
                href="#"
                onClick={() => {
                  fns.setSelectedUser(TableInfo.data[TableInfo.row.index]);
                  fns.setSelectedRole(TableInfo.data[TableInfo.row.index].role);
                  fns.setShowEditBar(true);
                }}
              >
                Edit User
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  return Columns;
};
