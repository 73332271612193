import { useEffect } from "react";
import closeImg from "../../assets/images/ico-toast-close.png";
import "./Toast.css";

export interface ToastProps {
  id: string
  destroy: () => void
  content: string
  duration?: number
  error: boolean
}

export default function Toast({ destroy, duration, error, content }: ToastProps) {
  useEffect(() => {
    if (!duration) { return }
    const timer = setTimeout(() => {
      destroy()
    }, duration)
    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <div className={error ? "wily-toast-header-error" : "wily-toast-header"}>
      <i className={error ? "fas fa-times-circle" : "fas fa-check-circle"} aria-hidden="true"></i>
      <p className="wily-toast-content">{content}</p>
      <button onClick={destroy} className="wily-toast-button">
        <span>
          <img src={closeImg} alt="Close" className="wily-toast-close" />
        </span>
      </button>
    </div>
  );
}
