import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getCompanies, getPrograms } from "../../api";
import { Header, NavMenu, Table } from "../../Components";
import { useStores } from "../../stores";
import { EProgramStatus } from "../../utils/constants/quotes";
import { ProgramColumns } from "./ProgramCols";
import { Spinner } from "react-bootstrap";
import { TCompany, TProgram } from "../../types/portalTypes";
import './index.css'
import Pagination from "./Pagination";

const Programs = () => {
  const { userStore, programStore } = useStores();
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("")
  const token = userStore.user.token

  const { data: programs, isLoading: programsLoading } = useQuery(["programs", skip, token, filter], async () => {
    const res: { programs: TProgram[], total: number | undefined } = await getPrograms({ token: token!, count: 15, skip: +skip, filter_companyId: filter });
    return res
  });
  const { data: companies, isLoading: companiesLoading } = useQuery(["companies", skip, token], async () => {
    let companies: TCompany[] = []
    let total = 0
    let skip_companies = 0


    const res: { companies: TCompany[], total: number | undefined } = await getCompanies({ token: token! });
    companies = [...res.companies]
    total = res.total!
    skip_companies = companies.length

    while (total - companies.length !== 0) {
      const res: { companies: TCompany[], total: number | undefined } = await getCompanies({ token: token!, skip: skip_companies });
      companies = [...companies, ...res.companies]
      skip_companies = skip_companies + res.companies.length
    }

    return companies.sort((a, b) => a.name.localeCompare(b.name))
  });
  const pageChangeHandler = async (currentPage: number) => {
    setSkip((+currentPage - 1) * 15)
  }

  useEffect(() => {
    programStore.setProgram({ programName: "", companyName: "" })

  }, [programStore])



  const data = useMemo(() => {
    if (programs && programs.programs.length) {


      return programs.programs.map((p) => {
        let quotes = p.quotes.filter(q => +q.type === 0)

        return {
          id: p.id,
          status: EProgramStatus[p.status ?? 0],
          creationTime: new Date(p.created_at),
          updateTime: new Date(p.updated_at),
          company: p.company?.name || "N/A",
          programBrand: quotes.length ? quotes[0].program_brand?.id : "N/A", // need it to be get from api
          programName: quotes.length ? quotes[0].program_name : "N/A",// need it to be get from api
          brandId: quotes.length ? quotes[0].program_brand?.id : "N/A",// need it to be get from api
        };
      });
    } else {
      return [];
    }
  }, [programs]);


  const columns = ProgramColumns();

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <NavMenu />
          <div className="col-sm-12 col-md-10">
            <Header />

            <div className="dashboardContent quotes">
              <div className="head">

                <div className="searchCont">
                  <div className="searchArea">
                    <select value={filter} onChange={(e) => setFilter(e.currentTarget.value)} className="form-control">
                      <option value="">All Companies</option>
                      {companies?.map(company => <option value={company.id} key={company.id}>{company.name}</option>)}
                    </select>

                  </div>{" "}
                </div>
              </div>

              {(programsLoading || companiesLoading) ? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (<>
                <Table
                  columns={columns}
                  data={data}
                  tablePageSize={15}
                  tableTitle="Programs"
                  sortbyid="updateTime"
                  descending={true}

                />
                <Pagination pageChangeHandler={pageChangeHandler} totalRows={programs?.total!} rowsPerPage={15} currentPage={currentPage} setCurrentPage={setCurrentPage} />

              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
