import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { spryClient } from "../../../api";
import { Table } from "../../../Components";
import { GiftCardTypesColumns } from "./GiftCardTypesColumns";
import { Modal, Spinner } from "react-bootstrap";
import {
  GiftCard,
  GiftCardType,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { showToast } from "../../../Components/Toast/ToastManager";
import Popup from "../../../Components/Popup";
import "./index.css";
import { CSVLink } from "react-csv";
import { useStores } from "../../../stores";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";

function CampaignGiftCards({ campaignKey }: { campaignKey: string }) {
  const [isLoading, setIsloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<GiftCardType>();
  const [updatedName, setUpdatedName] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [newGiftType, setNewGiftType] = useState("");
  const { userStore } = useStores();
  const navigate = useNavigate()

  const {
    isLoading: loadingGiftCardTypes,
    data: giftCardTypes,
    refetch,
  } = useQuery("getGiftcardTypes", async () => {
    const res = await spryClient.getGiftCardTypes({ campaignKey });
    return res.giftCardTypes;
  });
  const getCounts = (giftcards: GiftCard[]) => {
    let assigned = 0;
    let unassigned = 0;
    giftcards.forEach((card) => {
      card.prizeWinnerKey ? assigned++ : unassigned++;
    });
    return { assigned: assigned, unassigned: unassigned };
  };
  const {
    isLoading: loadingGiftCards,
    data: giftCards
  } = useQuery(["getGiftCards", giftCardTypes], async () => {
    const giftcards =
      giftCardTypes &&
      Promise.all(
        giftCardTypes.map(async (giftCardtype) => {
          const res = await spryClient.getGiftCards({
            campaignKey,
            giftCardTypeKey: giftCardtype.giftCardTypeKey,
          });
          const counts = getCounts(res.giftCards);
          let data = {
            ...giftCardtype,
            giftCards: res.giftCards,
            total: res.giftCards.length,
            assigned: counts.assigned,
            unAssigned: counts.unassigned,
          };
          return data;
        })
      );
    return giftcards;
  });

  const addNewGiftCardType = async () => {
    setIsloading(true);
    try {
      const res = await spryClient.createGiftCardType({
        campaignKey,
        giftCardTypeName: newGiftType,
      });
      setisOpenAdd(false);
      setIsloading(false);
      refetch();
      if (res) {
        showToast({
          content: "Gift card type added successfully.",
          duration: 3000,
          error: false,
        });
        setNewGiftType("");
      }
    } catch (e) {
      setIsOpen(false);
      setIsloading(false);
      showToast({
        content: "Oops,api error",
        duration: 3000,
        error: true,
      });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const saveEditedName = async () => {
    if (selectedType) {
      setIsloading(true);
      try {
        const res = await spryClient.updateGiftCardType({
          giftCardTypeKey: selectedType?.giftCardTypeKey,
          giftCardTypeName: updatedName,
        });
        setIsOpen(false);
        setIsloading(false);
        refetch();
        if (res) {
          showToast({
            content: "Gift card type updated successfully.",
            duration: 3000,
            error: false,
          });
        }
      } catch (e) {
        setIsOpen(false);
        setIsloading(false);
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
      }
    } else {
      setIsOpen(false);
      setIsloading(false);
      showToast({
        content: "Something, went wrong",
        duration: 3000,
        error: true,
      });
    }
  };

  const deleteGiftCardType = async () => {
    if (selectedType) {
      setIsloading(true);
      try {
        await spryClient.deleteGiftCardType({
          giftCardTypeKey: selectedType.giftCardTypeKey,
        });
        setIsOpenDelete(false);
        setIsloading(false);
        refetch();
        showToast({
          content: "Gift card type deleted successfully.",
          duration: 3000,
          error: false,
        });
      } catch (e) {
        setIsOpenDelete(false);
        setIsloading(false);
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
      }
    } else {
      setIsOpenDelete(false);
      setIsloading(false);
      showToast({
        content: "Something, went wrong",
        duration: 3000,
        error: true,
      });
    }
  };

  const reportData = useMemo(() => {
    if (giftCards) {
      const data = giftCards?.map((rp) => {
        let unassigned = rp.giftCards ? rp.giftCards.filter((card) => !card.prizeWinnerKey) : []

        return unassigned.map((card) => {
          return {
            "Creation Date/Time": card.creationTime.toLocaleString(),
            "Last Updated Date/Time": card.updateTime.toLocaleString(),
            "Gift Card Type": rp.giftCardTypeName,
            Value: card.value,
            "Primary Code": card.primaryCode || "N/A",
            "Secondary Code": card.secondaryCode || "N/A",
            "Gift Card Link": card.directLinkUrl || "N/A",
            "PDF Url": card.pdfUrl || "N/A",
            "Image Url": card.imageUrl || "N/A",
            "Expiry Date/Time": card.expiryTime || "N/A",
            "Bar code Format": card.barcodeFormat || "N/a",
            "Bar Code Data": card.barcodeValue || "N/A",
          
          };
        });
      });
      let flattened = data.flat();
      return flattened;
    }
  }, [giftCards]);

  const columns = GiftCardTypesColumns({
    setIsOpen,
    setSelectedType,
    setUpdatedName,
    deleteGiftCardType,
    setIsOpenDelete,
    navigate
  });

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont giftcards">
        <div id="tab09" className="tab-contents ">
          <div className="head inner">
            <h3>Gift Card Type</h3>
            <button
              className="btn"
              onClick={() => setisOpenAdd(true)}
              style={{ marginLeft: "20px" }}
            >
              Add Gift Card Type
            </button>
            {toJS(userStore.user.role === "Admin") &&

              <CSVLink

                filename={`Unassigned-Giftcards-Report-${new Date().toLocaleDateString()}`}
                className={(reportData && reportData?.length > 0) ? "btn btn--medium btn--mobile-small" : "btn btn--medium btn--mobile-small disabled"}
                data={reportData ? reportData : []}
                asyncOnClick={true}
                target="_blank"
              >
                Export Unassigned
              </CSVLink>
            }
          </div>
          <div className="main-content">
            {isLoading || loadingGiftCardTypes || loadingGiftCards ? (
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              giftCardTypes &&
              (giftCardTypes.length > 0 ? (
                <Table
                  columns={columns}
                  data={giftCards}
                  tablePageSize={12}
                  sortbyid={"index"}
                />
              ) : (
                <p> There are no gift card types to display.</p>
              ))
            )}
          </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Edit gift card type</h3>
          <p>Update the gift card type details below.</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">
              {" "}
              Gift card type name
            </label>
            <input
              type="text"
              className="form-control"
              name="giftcardtype"
              value={updatedName}
              placeholder={selectedType?.giftCardTypeName}
              onChange={(e) => {
                setUpdatedName(e.currentTarget.value);
              }}
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={saveEditedName}
            disabled={updatedName.length ? false : true}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={closeModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={isOpenAdd} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setisOpenAdd(false);
              setNewGiftType("");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Add gift card type</h3>
          <p>Add the new gift card type details below.</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">
              {" "}
              Gift card type name
            </label>
            <input
              type="text"
              className="form-control"
              name="giftcardtype"
              value={newGiftType}
              placeholder="Gift card type name"
              onChange={(e) => {
                setNewGiftType(e.currentTarget.value);
              }}
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={addNewGiftCardType}
            disabled={newGiftType.length ? false : true}
          >
            Add
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setisOpenAdd(false);
              setNewGiftType("");
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Popup
        isOpen={isOpenDelete}
        closeModal={() => setIsOpenDelete(false)}
        header="Delete gift card type"
        message={`Are you sure you want to delete ${selectedType?.giftCardTypeName}?`}
        action1={deleteGiftCardType}
        yesMessage="Yes, Delete"
        cancelMessage="Cancel"
      ></Popup>
    </>
  );
}

export default CampaignGiftCards;
