import { Button, Spinner } from "react-bootstrap";
import { signup } from "../../api";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
import { IUser } from "../../interfaces";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils/helpers";

type AppProps = {
  showSidebar: boolean;
  closeHandler: (val: boolean) => void;
  refetch: any;
  userEmail: string;
};

type AddFormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: string;
};

const AddAdmin = ({
  showSidebar,
  closeHandler,
  refetch,
  userEmail
}: AppProps) => {
  const { userStore } = useStores();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset
  } = useForm<AddFormData>({ mode: "onTouched" });

  const queryClient = useQueryClient();

  const { mutate: addAdminUser, isLoading: isLoadingAdd } = useMutation(
    signup,
    {
      onSuccess: () => {
        refetch();
        closeHandler(false);
        showToast({
          content: "User has been added successfully.",
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        showToast({
          content: "User already exists.",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getUserList");
      },
    }
  );

  const handleAdd = (values: IUser, fns: any) => {
    reset();
    values.token = userStore.user.token;
    values.username = userEmail;
    // console.log(values);
    addAdminUser(values);
  };

  return (
    <nav id="nav" className={showSidebar ? "show" : ""}>
      <div className="sidebarContent">
        <h2>Add User</h2>
        <span
          className="closeSideBar"
          onClick={() => closeHandler(false)}
        >
          <i className="fas fa-times"></i>
        </span>

        <form onSubmit={handleSubmit(handleAdd)}>
          <div className="formContent">
            <div className="form-group">
              <label htmlFor="agency">First name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "Please enter a first name.",
                  },
                })}
              // defaultValue={userFirstName}
              />
              {errors.firstName && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Last name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Please enter a last name.",
                  },
                })}
              // defaultValue={userLastName}
              />
              {errors.lastName && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />
                  {errors.lastName.message}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="agency">Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email.",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
              {errors.email && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />{" "}
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="agency">Role *</label>
              <select
                className="form-control"
                {...register("role", {
                  required: {
                    value: true,
                    message: "Please select a role.",
                  },
                })}
              >
                <option value="">Select a role</option>
                <option value="Admin">Admin</option>
                <option value="Standard">Standard</option>
              </select>

              {errors.role && (
                <p className="error">
                  <i className="fas fa-exclamation-circle" />{" "}
                  {errors.role.message}
                </p>
              )}
            </div>

            <div className="actionBtns">
              {isLoadingAdd ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <button
                  type="submit"
                  className="btn"
                  disabled={isDirty ? false : true}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </nav>
  );
};

export default AddAdmin;
