import { useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { spryClient } from "../../../api";
import { replaceTemplateS3Link, TemplateNameRegex } from "../../../utils/helpers";
import { showToast } from "../../../Components/Toast/ToastManager";
import { LanugageDescription } from "../../../utils/constants";
import { useForm } from "react-hook-form";

type Props = { campaignKey: string };
type EmailTemplateForm = {
  emailTemplateName: string;
  language: string;
  campaignKey: string;
  subject: string;
  htmlBody: string;
  textBody: string;
  preview: boolean;
};

function AddEmailTemplate({ campaignKey }: Props) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EmailTemplateForm>({
    mode: "onTouched",
    defaultValues: {
      emailTemplateName: "",
      language: "",
      campaignKey,
      subject: "",
      htmlBody: "",
      textBody: "",
      preview: true,
    },
  });
  const formWatch = watch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { emailTemplateKey } = useParams() as { emailTemplateKey?: string };

  const { isLoading: lngSettingLoading, data: lngSettingData } = useQuery(
    ["getCampaignLanguageSettings", campaignKey],
    async () => {
      const resp = await spryClient.getCampaignLanguageSettings({ campaignKey });
      return resp.campaignLanguageSettings;
    }
  );
  const languages = useMemo(() => {
    if (lngSettingData && lngSettingData.length > 0) {
      return lngSettingData.map((lang) => (
        <option key={lang.language} value={lang.language}>
          {LanugageDescription[`${lang.language}`]}
        </option>
      ));
    }
  }, [lngSettingData]);


  const {
    isLoading,
    data: emailTemplate,
    error: templateError,
  } = useQuery(
    ["getEmailTemplate", emailTemplateKey],
    async () => {
      const {
        emailTemplates: [matchtemplate],
      } = await spryClient.getEmailTemplates({ emailTemplateKey, campaignKey });

      return matchtemplate;
    },
    { enabled: !!emailTemplateKey }
  );

  const {
    isLoading: contentsLoading,
    // data: emailContents,
    error: contentsError,
  } = useQuery(
    ["loadEmailTemplateContents", emailTemplate],
    async () => {
      const resp = await spryClient.loadEmailTemplateContents({
        emailTemplateKey: emailTemplate?.emailTemplateKey!,
        campaignKey,
      });
      reset({
        htmlBody: resp.htmlBody || "",
        subject: resp.subject,
        textBody: resp.textBody || "",
        emailTemplateName: emailTemplate?.emailTemplateName,
        language: emailTemplate?.language,
      });
      return resp;
    },
    { enabled: !!emailTemplate }
  );

  if (emailTemplateKey && (isLoading || contentsLoading || lngSettingLoading)) {
    return (
      <div className="spinner">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (emailTemplateKey && (contentsError || templateError)) {
    showToast({
      content: "Oops, api error.",
      duration: 3000,
      error: true,
    });
    // setToast({...toast, show: true, content: 'Oops, api error.'});
    return (
      <div className="spinner">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  const handleAdd = (values: EmailTemplateForm) => {
    const emailTpl = {
      emailTemplateName: values.emailTemplateName,
      language: values.language,
      campaignKey: values.campaignKey,
    };

    const emailContents = {
      campaignKey,
      emailTemplateKey: emailTemplateKey!,
      subject: values.subject || "",
      htmlBody: values.htmlBody || "",
      textBody: values.textBody || "",
    };
    setIsSubmitting(true);

    if (emailTemplateKey) {
      spryClient.saveEmailTemplateContents(emailContents)
        .then((resp) => {
          if (resp) {
            setIsSubmitting(false);
            showToast({
              content: " Email template saved successfully.",
              duration: 3000,
              error: false,
            });
          }
        })
        .catch((e) => {
          setIsSubmitting(false);
          showToast({
            content: "Oops,api error",
            duration: 3000,
            error: true,
          });
        });
    }

    if (!emailTemplateKey) {
      spryClient.createEmailTemplate(emailTpl)
        .then((resp) => {
          const templateKey = resp.emailTemplateKey;

          const newEmailContents = {
            ...emailContents,
            emailTemplateKey: templateKey,
            subject: values.subject || "",
            htmlBody: values.htmlBody || "",
            textBody: values.textBody || "",
          };

          spryClient.saveEmailTemplateContents(newEmailContents)
            .then((resp) => {
              showToast({
                content: "Email template saved successfully. ",
                duration: 3000,
                error: false,
              });
              setIsSubmitting(false);
            })
            .catch((e) => {
              setIsSubmitting(false);
              showToast({
                content: "Oops,api error",
                duration: 3000,
                error: true,
              });
            });
        })
        .catch((e) => {
          setIsSubmitting(false);
          showToast({
            content: "Oops,api error",
            duration: 3000,
            error: true,
          });
        });
    }
  };

  return (
    <div className="dashboardContent campaignDetail tabsCont addPg">
      <form onSubmit={handleSubmit(handleAdd)}>
        <div id="tab07" className="tab-contents emailtemplates">
          <div className="head inner">
            <h3>
              <Link
                to={`/campaign/${campaignKey}/emailtemplates`}
                className="backBtn"
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
              Email templates
            </h3>
            {!isSubmitting ? (
              <button type="submit" className="btn">
                Save Email Template
              </button>
            ) : (
              <Button variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Saving...{" "}
              </Button>
            )}
          </div>

          <div className="formContent">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="emailTemplateName">Email Template Name</label>
                  <input
                    className="form-control"
                    {...register("emailTemplateName", {
                      required: {
                        value: true,
                        message: "Please enter a template name.",
                      },
                      pattern: { value: TemplateNameRegex, message: "please enter a valid template name." }
                    })}
                    id="emailTemplateName"
                    aria-describedby="Email template name"
                    placeholder="Email template name"
                  />
                  {errors.emailTemplateName && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      {errors.emailTemplateName.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="language">Language</label>
                  <select
                    className="form-control"
                    id="language"
                    {...register("language", {
                      required: {
                        value: true,
                        message: "Please select a language",
                      },
                    })}
                    aria-describedby="Sender name"
                  >
                    <option value="">Select</option>
                    {languages}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="subject">Subject Line</label>
                  <input
                    className="form-control"
                    id="subject"
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Please enter subject line.",
                      },
                    })}
                    aria-describedby="Subject line"
                    placeholder="Subject line"
                  />
                  {errors.subject && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      {errors.subject.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="textEditor">
                  <div className="textEditorheader">
                    <label htmlFor="exampleInputEmail1">HTML Editor</label>
                    <div className="innerArea">
                      <span
                        className={`btn ${formWatch.preview ? "black" : "grey"
                          }`}
                        onClick={(e) => {
                          setValue("preview", true);
                        }}
                      >
                        View rich text
                      </span>
                      <span
                        className={`btn ${formWatch.preview ? "grey" : "black"
                          }`}
                        onClick={(e) => {
                          setValue("preview", false);
                        }}
                      >
                        View source code
                      </span>
                    </div>
                  </div>
                  <div className="body">
                    {formWatch.preview ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formWatch.htmlBody
                            ? replaceTemplateS3Link(formWatch.htmlBody)
                            : "",
                        }}
                      />
                    ) : (
                      <div>
                        <div className="form-group">
                          <label htmlFor="htmlBody">Html Text Editor</label>
                          <textarea
                            className="form-control"
                            id="htmlBody"
                            rows={10}
                            {...register("htmlBody", { required: { value: true, message: "Please enter email contents." } })}
                          />
                          {errors.htmlBody && (
                            <div className="error">
                              <i className="fas fa-exclamation-circle" />
                              {errors.htmlBody.message}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="textBody">Plain Text Editor</label>
                  <textarea
                    className="form-control"
                    rows={10}
                    id="textBody"
                    {...register("textBody", { required: false })}
                  />
                  {errors.textBody && (
                    <div className="error">
                      <i className="fas fa-exclamation-circle" />
                      {errors.textBody.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddEmailTemplate;
