import { useState, useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Spinner } from "react-bootstrap";
import { NavMenu, Header } from "../../Components";
import AddAdmin from "./AddAdmin";
import { Table } from "../../Components";
import { AdminColumns } from "./AdminColumns";
import {
  getUserList,
  deleteUser,
  addUsertoGroup,
  removeFromGroup,
} from "../../api";
import { useStores } from "../../stores";
import { useBodyClass } from "../../utils/hooks";
import { showToast } from "../../Components/Toast/ToastManager";
import Popup from "../../Components/Popup";
import { Modal } from "react-bootstrap";
import EditAdmin from "./EditAdmin";
import dayjs from "dayjs";

export type User = {
  role: string;
  username: string;
  name: string;
  email: string;
  updateDate: string;
  enabled: boolean;
  status: string;
};

const Admins = (): JSX.Element => {
  useBodyClass("adminPg");
  const { userStore } = useStores();

  const [show, setShow] = useState(false);
  const [showEditBar, setShowEditBar] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingState,
    data: usersData,
    refetch,
  } = useQuery("getUserList", async () => {
    const res: any = await getUserList(userStore.user.token);
    const users = res
      ? res.data.users.map((user: User) => {
          const formattedDate = dayjs(user.updateDate).format("MM/DD/YYYY");
          return {
            ...user,
            firstName: user.name
              ? user.name
                  .split(" ")
                  .slice(0, -1)
                  .join(" ")
                  .charAt(0)
                  .toUpperCase() +
                user.name.split(" ").slice(0, -1).join(" ").slice(1)
              : "",
            lastName: user.name
              ? user.name
                  .split(" ")
                  .slice(-1)
                  .join(" ")
                  .charAt(0)
                  .toUpperCase() +
                user.name.split(" ").slice(-1).join(" ").slice(1)
              : "",
            updateDate: formattedDate,
          };
        })
      : [];

    return users;
  });

  const { mutate: addUsertoAdmin, isLoading: loadingAdd } = useMutation(
    addUsertoGroup,
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("getUserList");
        refetch();
        showToast({
          content: `${selectedUser.username} has been assigned with the ${selectedRole} role.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: `Sorry, Error while assigning user with the ${selectedRole} role.`,
          duration: 3000,
          error: true,
        });
      },
    }
  );

  useMutation(removeFromGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserList");
      refetch();
      showToast({
        content: `${selectedUser.username} has been removed from the ${selectedRole} role.`,
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      showToast({
        content: `Sorry, Error while removing ${selectedRole} role from user.`,
        duration: 3000,
        error: true,
      });
    },
  });

  const { mutate: deleteAdminUser, isLoading: Deleting } = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries("getUserList");
        showToast({
          content: ` ${selectedUser.username} has been deleted successfully.`,
          duration: 3000,
          error: false,
        });
      },
      onError: () => {
        //setShowModal(true);
        showToast({
          content: "Sorry, Deleting user failed.",
          duration: 3000,
          error: false,
        });
      },
    }
  );

  const columns = useMemo(
    () =>
      AdminColumns({
        setShow,
        setShowModal,
        setSelectedUser,
        setisOpenAdd,
        setSelectedRole,
        setShowEditBar,
        refetch,
        userStore,
      }),
    [
      setShow,
      setShowModal,
      setSelectedUser,
      setisOpenAdd,
      setSelectedRole,
      setShowEditBar,
      refetch,
      userStore,
    ]
  );

  const calldelete = () => {
    deleteAdminUser({
      userName: selectedUser.username,
      token: userStore.user.token,
    });

    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Popup
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
            action1={calldelete}
            header="Delete ?"
            message={`Are you sure you want to delete ${selectedUser.username} ?`}
            yesMessage="Yes, Delete"
            cancelMessage="Cancel"
          />
        </div>
      )}
      <div className="container-fluid p-0">
        {/* <button
          onClick={() =>
            showToast({
              title: "Toast title",
              content: "Toast body",
              duration: 3000,
            })
          }
        >ClickMe
        </button> */}
        <div className="row no-gutters">
          <NavMenu />
          <div className="col-sm-12 col-md-10">
            <Header />
            {isLoadingState ? (
              <div className="dashboardContent">
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              </div>
            ) : (
              <div className="dashboardContent">
                <div className="head">
                  <span
                    className="btn addbtn"
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    Add user
                  </span>
                </div>
                <AddAdmin
                  showSidebar={show}
                  closeHandler={setShow}
                  refetch={refetch}
                  userEmail={selectedUser && selectedUser.email}
                />
                {selectedUser && (
                  <EditAdmin
                    showSidebar={showEditBar}
                    closeHandler={setShowEditBar}
                    addUsertoAdmin={addUsertoAdmin}
                    refetch={refetch}
                    userEmail={selectedUser && selectedUser.email!}
                    userRole={selectedUser && selectedUser.role!}
                    userFirstName={selectedUser && selectedUser.firstName!}
                    userLastName={selectedUser && selectedUser.lastName!}
                  />
                )}
                {isLoadingState || loadingAdd || Deleting ? (
                  <div className="spinner">
                    <Spinner animation="border" variant="secondary" />
                  </div>
                ) : (
                  usersData && (
                    <Table
                      columns={columns}
                      data={usersData.length > 0 ? usersData : []}
                      tablePageSize={10}
                      sortbyid="username"
                      descending={false}
                    />
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={isOpenAdd} onHide={() => {}} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Change Role</h3>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Role</label>
            <select
              className="form-control"
              value={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.currentTarget.value);
              }}
            >
              <option
                value="Admin"
                disabled={selectedUser?.role === "Admin" ? true : false}
              >
                Admin
              </option>
              <option
                value="Standard"
                disabled={selectedUser?.role === "Standard" ? true : false}
              >
                Standard
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={async () => {
              setisOpenAdd(false);
              addUsertoAdmin({
                userName: selectedUser.username,
                role: selectedRole,
                token: userStore.user.token,
              });
            }}
            disabled={selectedRole === selectedUser?.role ? true : false}
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setisOpenAdd(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Admins;
