import { useEffect } from "react";
import GlobalFilter from "../GlobalFilter";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
} from "react-table";
import { compareIgnoreCase } from "../../utils/helpers";

type Props = {
  columns: any;
  data: any;
  disabledSort?: boolean;
  tablePageSize: number;
  setselectedPage?: Function;
  selectedPage?: number;
  tableTitle?: string;
  sortbyid?: string;
  descending?: boolean;
};

export const Table = ({
  columns,
  data,
  disabledSort,
  tablePageSize,
  setselectedPage,
  selectedPage,
  tableTitle,
  sortbyid,
  descending,
}: Props) => {
  const maxPageShow = 8;

  const tableInstance = useTable(
    {
      columns,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(
            row1.values[columnName],
            row2.values[columnName]
          )
        },
      },
      data,
      disableSortBy: disabledSort,
      initialState: {
        sortBy: [{ id: sortbyid ? sortbyid : "", desc: descending }],
        pageSize: tablePageSize,
        pageIndex: selectedPage ? selectedPage : 0,
      },
      useRowSelect,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = tableInstance;

  useEffect(() => {
    setselectedPage && setselectedPage(pageIndex);
    return () => {
      setselectedPage && setselectedPage(0);
    };
  }, [pageIndex, setselectedPage]);

  const caculateShowPage = (pgcnt: any, pgidx: any) => {
    const totalPages = [...Array(pgcnt)].map((_, i) => i);

    if (pgidx + maxPageShow > totalPages.length) {
      return totalPages.slice(maxPageShow * -1);
    } else {
      return totalPages.slice(pgidx, pgidx + maxPageShow);
    }
  };
  // console.log(selectedPage)

  return (
    <>
      {(tableTitle === "Recent Participants" ||
        tableTitle === "Latest Entries" ||
        tableTitle === "Winning Timestamps" ||
        tableTitle === "Campaigns"|| tableTitle === "Quotes"||
        tableTitle === "Campaign Cognito Users"|| tableTitle === "Keywords") && (
        <div className="searchCont">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            tableheader={tableTitle}
            setselectedPage={setselectedPage}
          />
        </div>
      )}
      <div className="dataGrid" style={{ width: "100%" }}>
        <table className="table" {...getTableProps()} style={{ width: '100%' }}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      // Apply the header cell props

                      return (
                        <th
                          scope="col"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              title: undefined,

                              style: {
                                width: column.width,
                                
                              },
                            })
                          )}
                        >
                          {
                            // Render the header
                            column.render("Header")
                          }
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i
                                  className="fas fa-angle-down"
                                  style={{ paddingLeft: "5px" }}
                                ></i>
                              ) : (
                                <i
                                  className="fas fa-angle-up"
                                  style={{ paddingLeft: "5px" }}
                                ></i>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.length > 0 ? (
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr style={{ fontSize: "16px"}} {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell: any) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()} >
                              {
                                // Render the cell contents
                                cell.render("Cell")
                                
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )
            }
          </tbody>
        </table>
        {data.length > tablePageSize && (
          <div className="paginationCont">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="Previous"
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    <span aria-hidden="true">&lt;</span>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                {pageIndex > 0 &&
                  pageIndex + maxPageShow <= pageOptions.length ? (
                  <>
                    <li className="page-item ">
                      <span
                        className="page-link"
                        onClick={() => gotoPage(0)}
                      >
                        1
                      </span>
                    </li>
                    <li className="page-item ">
                      <span className="page-link">...</span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {caculateShowPage(pageCount, pageIndex).map(function (page) {
                  return (
                    <li
                      className={
                        page === pageIndex ? "page-item active" : "page-item"
                      }
                      key={page}
                      onClick={() => gotoPage(page)}
                    >
                      <span className="page-link">
                        {page + 1}
                      </span>
                    </li>
                  );
                })}
                {pageIndex < pageCount - maxPageShow ? (
                  <>
                    <li className="page-item ">
                      <span className="page-link">...</span>
                    </li>
                    <li className="page-item ">
                      <span
                        className="page-link"
                        onClick={() => gotoPage(pageCount - 1)}
                      >
                        {pageCount}
                      </span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={nextPage}
                    aria-label="Next"
                    disabled={!canNextPage}
                  >
                    <span aria-hidden="true">&gt;</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};
