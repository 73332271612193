export enum EUserRoles {
  "owner" = "0",
  "view" = "1",
  "edit" = "2",
  "admin" = "admin",
}

export enum EQuoteStatuses {
  "draft" = "0",
  "estimationReady" = "1",
  "decisionReady" = "2",
  "approved" = "3",
  "rejected" = "4",
  "changesRequested" = "5",
  "onHold" = "6",
  "archived" = "7",
}

export enum EQuoteType {
  "programQuote" = "0",
  "changeRequest" = "1",
}

export enum EProgramStatus {
  "draft" = "0",
  "quoteApproved" = "1",
  "setupRequired" = "2",
  "inProgress" = "3",
  "released" = "4",
  "archived" = "5",
  "onHold" = "6",
}

export enum ESwaggerTags {
  "public" = "Public Endpoints",
  "admin" = "Admin Endpoints",
}

export enum EBearerAuth {
  "public" = "customer-cognito-jwt",
  "admin" = "admin-cognito-jwt",
}

export enum EFeatureType {
  "program-module" = "0",
  "proof-of-purchase" = "1",
  "engagement-mechanic" = "2",
  "media-upload" = "3",
}

export enum EQuestionTypes {
  // One line answer
  "text" = "0",
  // Multi line text area
  "textarea" = "1",
  // Radio button for one selection
  "radio" = "2",
  // Multi selection checkboxes
  "checkbox" = "3",
}

export enum EEnvironmentTypes {
  "staging" = "0",
  "production" = "1",
}

export enum ENotificationTypes {
  "milestone" = "0",
  "info" = "1",
  "alert" = "2",
}

export enum ETaskNames {
  "SubmitQuote" = "Submit your quote",
  "ReviewQuote" = "Review your quote",
  "ProgramDetails" = "Provide program details",
  "DesignAssets" = "Provide design assets",
  "DesignFeedback" = "Provide design feedback",
  "ApproveDesign" = "Approve design",
}

export type TRecord = {
  [key: string]: any;
};

type TTime = {
  updated_at: string;
  created_at: string;
};
type TDate = {
  start_date: string;
  end_date: string;
};

export type TUser = {
  id: string;
  email: string;
  role: EUserRoles | "";
  company:
  | ({
    id: string;
    domain: string;
    name: string;
  } & TTime)
  | null;
};

export type TBrand = {
  id: string;
  name: string;
};

export type TAnswer = {
  id: string;
  questionLabel: string;
  value: string;
};

export type TQuote = {
  id: string;
  additional_fields: string;
  internalId: string;
  program_name: string;
  program_description: string;
  program_brand: { id: string, name: string };
  status: string;
  type: string;
  company: TCompany;
  status_reason: string;
  program: {
    id: string;
    status: string;
    start_date: string;
    end_date: string;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  };
  current_address: {
    line1: string;
    line2: string;
    city: string;
    country: string;
    id: string;
    postal_code: string;
    state: string;
  };
  answers: TAnswer[];
  features: {
    id: string;
    name: string;
    type: string;
    description: string;
  }[];
  comments: TComment[];
} & TTime &
  TDate;

export type TCompany = {
  id: string;
  domain: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export type TComment = {
  id: string;
  content: string;
  fileUrl: string;
  creator: Pick<TUser, "id" | "role" | 'email'> & {
    authId: string;
  } & TTime;
} & TTime;

export type TTask = {
  id: string;
  name: string;
  description: string;
  completed?: string;
  completedOn?: string;
  submitted: string;
  submittedOn?: string;
  created_at: string;
  updated_at: string;
  dueDate: string;

} & TTime;

export type TProgram = {
  id: string;
  program_name: string;
  program_description: string;
  approvedQuote: TQuote;
  comments: TComment[];
  end_date: string;
  start_date: string;
  status: number;
  quotes: TQuote[];
  answers: TAnswer[];
  tasks: TTask[];
  company: TCompany;
  assets: TAsset[] | undefined;
  features: {
    id: string;
    name: string;
    type: string;
  }[];
  environments: {
    id: string,
    type: string,
    name: string,
    url: string,
    created_at: string,
    updated_at: string
  }[]
  current_address: {
    line1: string;
    line2: string;
    city: string;
    country: string;
    id: string;
    postal_code: string;
    state: string;
  };
} & TTime;

export type TAsset = {
  name: string;
  type: string;
  size: string;
  url: string;
  key: string;
  lastModified:string;

};

export type TMembership = {
  id: string;
  user: TUser;
  team: TTeam;
} & TTime;

export type TTeam = {
  id: string;
  name: string;
  programs: TProgram[];
  memberships: TMembership[];
};
export type DynamicFieldData = {
  label: string;
  inputType: ControlType;
  name: string;
  validation?: any;
  options?: SelectOption[];
  status: string
  disabled?: boolean
  default?: any
  optional?: boolean

};


export type TQuestion = {
	label: string;
	description?: string;
	inputType: string;
	name: string;
	status?: string;
	questionType: string;
	placeHolder?: string;
};

export type TAnswerOption = {
	label: string;
	value: string;
	description?: string;
	children?: TQuestion[] | [TQuestion[]];
	components?: TQuestion[];
};

export type TModuleConfig = TQuestion & {
	options?: TAnswerOption[];
	default?: any;
	additional?: any;
};

export type TProgramSetup = {
	title: string;
	name: string;
	description?: string;
	programType: string;
	value: string;
	group: TModuleConfig[] | [TModuleConfig[]];
	seq: number;
	finished: number;
};

export interface SelectOption {
  label: string;
  value: string;
  name?: string;
  id?: string;
  components?: any[];
}
export type ControlType =
  | "text"
  | "select"
  | "number"
  | "checkbox"
  | "radios"
  | "textarea"
  | "date"
  | "custom_brand"
  | "custom_address"
  | "time"
  | "data_capture"
  | "sweepstake";

export enum EContentType {
  'text' = 'text',
  'markdown' = 'markdown',
  'image' = 'image',
  'document' = 'document',
  'video' = 'video',
  'other' = 'other',
}
export type ICMSItem = {
  id: string;
  slug: string;
  title: string;
  subtitle: string;
  enabled: boolean;
  type: {
    title?: string
    slug: string
    content_type: EContentType
  }
  children: ICMSItem[];
} & TTime

export interface InputProps {
  type: 'text' | 'radio' | 'email' | 'select' | 'checkbox'
  name: string
  value: string | number | boolean
  placeholder?: string
  label?: string

  typeValue?: 'boolean' | 'number'
  validations?: Validation[]
  options?: Opt[]
  readonly?: boolean
}

export interface Opt {
  value: string | number
  desc: string
}


export interface Validation {
  type: 'required' | 'isEmail' | 'minLength' | 'isTrue' | 'oneOf'
  value?: string | number | boolean
  message: string
  ref?: string
}

export type FormSection = 'quote' | 'program'
