import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { generateKey } from "@sprycore/spry-api-client";
import { spryClient } from "../../../api";
import { emailRegex } from "../../../utils/helpers";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { useCSVReader } from "react-papaparse";

type typeProp = {
  campaignKey: string;
};

const participantFields: Record<string, string> = {
  firstname: "firstName",
  lastname: "lastName",
  phone: "phone",
  email: "email",
  address: "address",
  address2: "address2",
  city: "city",
  country: "country",
  postal: "postal",
  zip: "postal",
  province: "province",
  state: "province"
}

type HeaderTypes = { type: "tag", tagPrefix: string } | { type: "field", field: string } | { type: "unknown", header: string }
type ImportConfigType = {
  headers: HeaderTypes[]
  rows: {
    data: string[],
    matchingEmail: Participant[]
    perfectMatch: Participant | undefined
    email: string | undefined
    validEmail: boolean
    invalidTag: boolean
  }[]
}

export default function CampaignParticipantImport({ campaignKey }: typeProp) {

  const queryClient = useQueryClient()
  const [readStatus, setReadStatus] = useState("")
  const { CSVReader } = useCSVReader()
  const [importConfig, setImportConfig] = useState<ImportConfigType>()
  const [validEmailOnly, setValidEmailOnly] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [status, setStatus] = useState("")
  const [globalTags, setGlobalTags] = useState("")

  const participantsQuery = useQuery(
    ["getParticipants", campaignKey],
    async () => {
      const { participants } = await spryClient.getParticipants({ campaignKey })
      return participants
    })

  const participants = participantsQuery.data || []
  const participantsByEmail: Record<string, typeof participants> = {}
  participants.forEach(x => (participantsByEmail[x.email ?? ""] ??= []).push(x))

  const loading = participantsQuery.isLoading

  function processCSV(data: string[][]) {
    setImportConfig(undefined)
    data = data.map(x => x.map(c => c.trim()))
    for (let row of data) {
      while (row[row.length - 1] === "") { row.pop() }
    }
    data = data.filter(x => x.length)
    const headers: HeaderTypes[] | undefined = data.shift()?.map(x => {
      if (x.startsWith("tag:")) { return { type: "tag", tagPrefix: x.substring(4).toLowerCase() } }
      const field = participantFields[x.toLowerCase().replaceAll(/\s/g, "")]
      if (field) { return { type: "field", field } }
      return { type: "unknown", header: x }
    })
    if (!headers?.length || !data.length) {
      setReadStatus("No data")
      return
    }
    const emailHeaderIndex = headers.findIndex(x => x.type === "field" && x.field === "email")
    const tagHeaders = headers.map((x, i) => x.type === "tag" ? { index: i, tagPrefix: x.tagPrefix } : undefined!).filter(x => x)
    const rows = data.map(row => {
      const email = row[emailHeaderIndex] || undefined
      const validEmail = email ? emailRegex.test(email) : false
      const matchingEmail = !email ? [] : participantsByEmail[email] ?? []
      const perfectMatch = matchingEmail.filter(participant => headers.every((h, i) => h.type !== "field" || (row[i] || "") === ((participant as any)[h.field] || "")))[0]
      const invalidTag = tagHeaders.filter(x => row[x.index]).some(x => {
        const tag = x.tagPrefix + ":" + row[x.index]
        return tag.length > 50 || tag.indexOf(",") !== -1
      })
      return {
        data: row,
        matchingEmail,
        perfectMatch,
        email,
        validEmail,
        invalidTag
      }
    })
    setImportConfig({ headers, rows })
  }

  function RecordsTable(props: { filteredData: ImportConfigType["rows"] }) {
    if (!importConfig) { return <></> }
    let missingEmail = 0
    let invalidEmail = 0
    let hasExtraFields = false
    props.filteredData.forEach(x => {
      if (x.data.length > importConfig.headers.length) { hasExtraFields = true }
      if (!x.email) { ++missingEmail }
      else if (!x.validEmail) { ++invalidEmail }
    })
    return <>
      <p>
        Record count: {props.filteredData.length}<br />
        Missing email addresses: {missingEmail}<br />
        Invalid email addresses: {invalidEmail}<br />
        {hasExtraFields && <><span style={{ color: "red" }}>WARNING - Extra fields exist without a header, which will be ignored!</span><br /></>}
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            {importConfig.headers.map((x, i) => <th key={i}>
              {x.type === "tag" && "[tag prefix]"}
              {x.type === "unknown" && "[metadata]"}
            </th>)}
            {hasExtraFields && <th style={{ backgroundColor: "#ccc" }}></th>}
          </tr>
          <tr>
            <th>#</th>
            {importConfig.headers.map((x, i) => <th key={i}>
              {x.type === "field" && x.field}
              {x.type === "tag" && x.tagPrefix}
              {x.type === "unknown" && x.header}
            </th>)}
            {hasExtraFields && <th style={{ backgroundColor: "#ccc" }}><b>FIELDS WITHOUT HEADERS</b></th>}
          </tr>
        </thead>
        <tbody>
          {props.filteredData.map((x, i) => <tr key={i} style={{
            backgroundColor: !x.email ? "#eee" : !x.validEmail ? "#ccc" : undefined
          }}>
            <td>{i + 1}</td>
            {importConfig.headers.map((h, i) => <td key={i}>{x.data[i] ?? ""}</td>)}
            {hasExtraFields && <td style={{ backgroundColor: "#ccc" }}>
              {x.data.slice(importConfig.headers.length).join(", ")}
            </td>}
          </tr>)}
        </tbody>
      </table>
    </>
  }

  const toImport: ImportConfigType["rows"] = []
  const duplicates: ImportConfigType["rows"] = []
  const toUpdate: ImportConfigType["rows"] = []
  const invalidTags: ImportConfigType["rows"] = []
  importConfig?.rows.forEach(x => {
    if (validEmailOnly && !x.validEmail) { return }
    if (x.invalidTag) { invalidTags.push(x) }
    else if (x.perfectMatch) { duplicates.push(x) }
    else if (x.matchingEmail.length) { toUpdate.push(x) }
    else { toImport.push(x) }
  })

  async function doImportOrUpdate(update: boolean, filteredData: ImportConfigType["rows"]) {
    const config = importConfig
    let errors = 0
    let calls = 0
    if (!config || inProgress) { return }
    if (update && filteredData.some(x => !x.matchingEmail.length)) { throw new Error("Record being updated is missing an email match") }
    const tags = globalTags.split(',').map(x => x.trim().toLowerCase()).filter(x => x)
    if (tags.some(x => x.length > 50)) {
      setStatus("Invalid tag")
      return
    }
    setInProgress(true)
    try {
      const data = filteredData.slice()
      while (data.length) {
        setStatus(`${data.length} remaining [${errors} out of ${calls} api calls threw errors]`)
        ++calls
        try {
          await spryClient.addBulkParticipantData({
            campaignKey,
            tags,
            participants: data.splice(0, 5000).map(x => {
              let record: any = {
                tags: [],
                metadata: {}
              }
              record.sessionKey = update ? x.matchingEmail[0].sessionKey : generateKey()
              config.headers.forEach((h, i) => {
                if (h.type === "field") {
                  if (x.data[i]) { record[h.field] = x.data[i] }
                }
                else if (h.type === "tag") {
                  if (x.data[i]) { record.tags.push(`${h.tagPrefix}:${x.data[i]}`.toLowerCase()) }
                }
                else if (h.type === "unknown") {
                  if (x.data[i]) { record.metadata[h.header] = x.data[i] }
                }
              })
              if (!record.tags.length) { delete record.tags }
              if (!Object.keys(record.metadata).length) { delete record.metadata }
              return record
            })
          })
        }
        catch (e: any) {
          console.error(e)
          ++errors
        }
      }
    }
    finally {
      setInProgress(false)
      setImportConfig(undefined)
      if (!errors) { setStatus("Done") }
      else { setStatus(`Done, but ${errors} out of ${calls} api calls threw errors (check console)`) }
      queryClient.invalidateQueries(["getParticipants", campaignKey])
    }
  }

  async function importParticipants(filteredData: ImportConfigType["rows"]) {
    if (inProgress || !filteredData.length) { return }
    if (!window.confirm(`Import ${filteredData.length} NEW participant records?`)) { return }
    await doImportOrUpdate(false, filteredData)
  }
  async function updateParticipants(filteredData: ImportConfigType["rows"]) {
    if (inProgress || !filteredData.length) { return }
    if (!window.confirm(`Update ${filteredData.length} EXISTING participant records?`)) { return }
    await doImportOrUpdate(true, filteredData)
  }

  return (
    <>
      <div className="dashboardContent campaignDetail">
        <div className="head inner">
          <h2 style={{ float: "none" }}>Participant Import</h2>
          {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
          {!loading && <>
            <p>
              Existing campaign participants: {participants.length}<br />
              {status && <><span>Status: {status}</span><br /></>}
            </p>
            <div>
              <CSVReader onUploadAccepted={(csv: any) => processCSV(csv.data)}>
                {(props: any) => <>
                  <button className="btn float-left" {...props.getRootProps()}>Load CSV</button>
                </>}
              </CSVReader>
              {readStatus}
            </div>
            <br />
            <br />
            <br />
            {importConfig && <div>
              <p>
                CSV records: {importConfig.rows.length}<br />
                <input type="checkbox" checked={validEmailOnly} onChange={e => setValidEmailOnly(e.target.checked)} /> Valid email addresses only<br />
                <label>Tags to be added to all imports/updates (example: sometag, someothertag, tag3):</label>
                <input value={globalTags} onChange={e => setGlobalTags(e.target.value)} /><br />
              </p>
              <h3 style={{ float: "none", paddingTop: "1em" }} className="mb-5">Can be imported</h3>
              {toImport.length > 0 && <button className="btn float-left" disabled={inProgress} onClick={() => importParticipants(toImport)}>Import</button>}<br /><br /><br />
              <RecordsTable filteredData={toImport} />
              <h3 style={{ float: "none", paddingTop: "1em" }}>Can be updated (matching an existing participant's email)</h3>
              {toUpdate.length > 0 && <button className="btn float-left" disabled={inProgress} onClick={() => updateParticipants(toUpdate)}>Update</button>}<br />
              <RecordsTable filteredData={toUpdate} />
              <h3 style={{ float: "none", paddingTop: "1em" }}>Cannot be imported (Would have an invalid tag)</h3>
              <RecordsTable filteredData={invalidTags} />
              <h3 style={{ float: "none", paddingTop: "1em" }}>Duplicates</h3>
              <p>A participant is considered duplicate if all participant fields match an existing participant.  CSV records without an email address will not be considered duplicates</p>
              <RecordsTable filteredData={duplicates} />
            </div>}
          </>}
        </div>
      </div>
    </>
  );
}
