import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom"
import { useQuery } from "react-query";
import { spryClient } from "../../../api";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Table from "../../../Components/CheckBoxTable";
import { GiftCardsColumns } from "./GiftCardsColumns";
import {
  GiftCard
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { showToast } from "../../../Components/Toast/ToastManager";
import Popup from "../../../Components/Popup";
import { CSVLink } from "react-csv";
import { useStores } from "../../../stores";
import { toJS } from "mobx";


function GiftcardDetails({ campaignKey }: { campaignKey: string }) {
  const { giftCardTypeKey } = useParams() as { giftCardTypeKey: string }
  const [selecttedGiftCards, setSelectedGiftcards] = useState<GiftCard[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { userStore } = useStores();

  const navigate = useNavigate()

  const { data: giftCardType } = useQuery("getGiftcardTypes", async () => {
    const res = await spryClient.getGiftCardTypes({
      campaignKey,
      giftCardTypeKey,
    });
    return res.giftCardTypes[0];
  });
  const giftcardTypename = giftCardType?.giftCardTypeName;

  const {
    isLoading: loadingGiftcards,
    data: giftCards,
    refetch: refetchCards,
  } = useQuery(
    ["getGiftCards", giftcardTypename],
    async () => {
      const res = await spryClient.getGiftCards({
        campaignKey,
        giftCardTypeKey,
      });
      const updatedCards = res.giftCards.map((record) => {
        let addType = { ...record, typename: giftcardTypename };
        return addType;
      });
      return updatedCards;
    },
    {
      // The query will not execute until the userId exists
      enabled: !!giftcardTypename,
    }
  );
  const backHandler = () => {
    navigate(`/campaign/${campaignKey}/giftcards`);
  };

  const deleteMultipleGiftcards = async () => {

    setIsOpenDelete(false);
    setLoading(true);
    if (selecttedGiftCards) {
      try {
        const deleteAllGiftCards = Promise.all(
          selecttedGiftCards.map(async function (record: GiftCard) {
            await spryClient.deleteGiftCard({
              giftCardKey: record.giftCardKey,
            });
          })
        );
        await deleteAllGiftCards
          .then((res) => {
            refetchCards();
            showToast({
              content: "gift cards deleted successfully",
              duration: 3000,
              error: false,
            });
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);

            showToast({
              content: "Oops,api error",
              duration: 3000,
              error: true,
            });
          });
      } catch (e) {
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
      }
    }
  };
  const reportData = useMemo(() => {
    if (giftCards) {
      const unassinged = giftCards.filter((card) => !card.assignmentTime);
      const reportData = unassinged.map((card) => {
        return {
          "Creation Date/Time": card.creationTime.toLocaleString(),
          "Last Updated Date/Time": card.updateTime.toLocaleString(),
          "Gift Card Type": card.typename,
          Value: card.value,
          "Primary Code": card.primaryCode || "N/A",
          "Secondary Code": card.secondaryCode || "N/A",
          "Gift Card Link": card.directLinkUrl || "N/A",
          "PDF Url": card.pdfUrl || "N/A",
          "Image Url": card.imageUrl || "N/A",
          "Expiry Date/Time": card.expiryTime || "N/A",
          "Bar code Format": card.barcodeFormat || "N/a",
          "Bar Code Data": card.barcodeValue || "N/A",
        };
      });
      return reportData;
    }
  }, [giftCards]);

  const columns = useMemo(
    () => GiftCardsColumns({
      setSelectedGiftcards,
      setIsOpenDelete,
      giftCardType,
      navigate
    }), [
    setSelectedGiftcards,
    setIsOpenDelete,
    giftCardType,
    navigate
  ]
  );

  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont">
        <div id="tab08" className="tab-contents giftcards">
          <div className="head giftcard-details">
            <h3>
              <button className="btn-noborder" onClick={backHandler}>
                <i className="fas fa-arrow-left"></i>
              </button>
              {giftCardType?.giftCardTypeName}
            </h3>
            <div style={{ float: "right" }}>
              <button
                className="btn delete"
                onClick={() => {
                  setIsOpenDelete(true);
                }}
                style={{ marginRight: "20px" }}
                disabled={selecttedGiftCards.length > 0 ? false : true}
              >
                Delete
              </button>
              {toJS(userStore.user.role === "Admin") &&
                <CSVLink
                  filename={`Unassigned-Giftcards-Report-${new Date().toLocaleDateString()}`}
                  className={(reportData && reportData?.length > 0) ? "btn btn--medium btn--mobile-small" : "btn btn--medium btn--mobile-small disabled"}
                  data={reportData ? reportData : []}
                  asyncOnClick={true}
                  target="_blank"
                >
                  Export Unassigned
                </CSVLink>
              }

              <button
                className="btn"
                onClick={() => {
                  navigate(`/campaign/${campaignKey}/giftcards/${giftCardTypeKey}/upload`);
                }}
                style={{ marginLeft: "20px" }}
              >
                Upload gift cards
              </button>
            </div>
          </div>
          <div className="main-content">
            {loading || loadingGiftcards ? (
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : giftCards && giftCards.length > 0 ? (
              <Table
                columns={columns}
                data={giftCards}
                tablePageSize={15}
                setSelectedRows={setSelectedGiftcards}
                sortbyid="ranking"
                tableTitle="gift cards"
              />
            ) : (
              <p className="table-nodata">
                {" "}
                There are no gift cards to display.
              </p>
            )}
          </div>
        </div>
      </div>

      <Popup
        isOpen={isOpenDelete}
        closeModal={() => setIsOpenDelete(false)}
        header="Delete gift card type"
        message={`Are you sure you want to delete gift card(s)?`}
        action1={deleteMultipleGiftcards}
        yesMessage="Yes, Delete"
        cancelMessage="Cancel"
      ></Popup>
    </>
  );
}

export default GiftcardDetails;
