import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { spryClient } from "../../../api";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";

import "./switch.css";
import { useNavigate } from "react-router-dom";

type typeProp = {
  campaignKey: string;
};

type AddPrizeForm = {
  campaignKey: string;
  prizeName: string;
  value: number;
  description: string;
  inventoryActive: boolean;
  inventoryCount: number;
  allowNegativeInventory: boolean;
};

const AddPrize = ({ campaignKey }: typeProp) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    prizeName: "",
    description: "",
    value: 0,
    inventoryCount: 0,
    inventoryActive: false,
    allowNegativeInventory: false,
  };
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddPrizeForm>({
    mode: "onTouched",
    defaultValues: {
      ...initialValues,
    },
  });
  const formWatch = watch();
  useEffect(() => {
    if (!formWatch.allowNegativeInventory) {
      setValue(
        "inventoryCount",
        formWatch.inventoryCount < 0 ? 0 : formWatch.inventoryCount
      );
    }
  }, [formWatch.allowNegativeInventory,setValue,formWatch.inventoryCount]);

  const handleAddPrize = async ({
    prizeName,
    description,
    value,
    inventoryCount,
    inventoryActive,
    allowNegativeInventory,
  }: AddPrizeForm) => {
    if (loading) {
      return;
    }
    setLoading(true);
    await spryClient
      .createPrize({ campaignKey, prizeName, value:+value, description })
      .then(async (res) => {
        await spryClient
          .adjustPrizeInventory({
            active: inventoryActive,
            addInventoryCount: inventoryCount,
            allowNegative: allowNegativeInventory,
            prizeKey: res.prizeKey,
          })
          .then((res) => {
            showToast({
              content: "Prize has been added successfully.",
              duration: 3000,
              error: false,
            });
            setLoading(false);
            navigate(`/campaign/${campaignKey}/prizing`);
          })
          .catch((e) => {
            showToast({
              content: "Error while adjusting the inventory.",
              duration: 3000,
              error: true,
            });
            setLoading(false);
          })
          .catch((e) => {
            showToast({
              content: "Error while creating the prize.",
              duration: 3000,
              error: true,
            });
            setLoading(false);
          });
      })
      .catch((e) => {
        showToast({
          content: `${e.toString()}`,
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
  };
  
  return (
    <>
      <div className="dashboardContent campaignDetail tabsCont addpg prizing">
        {loading ? (
          <div className="spinner">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <div id="tab04" className="tab-contents addprize">
         <form onSubmit={handleSubmit(handleAddPrize)}>

            <div className="head inner">
              <h3>
                <button
                  className="backBtn"
                  type="button"
                  onClick={() => navigate(`/campaign/${campaignKey}/prizing`)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                Add a prize
              </h3>
              <button type="submit" className="btn">
                Add Prize
              </button>
            </div>
            <div>
                <div className="formContent">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Prize Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("prizeName", {
                        required: {
                          value: true,
                          message: "Please enter prize name",
                        },
                      })}
                      aria-describedby="Prize Name"
                      placeholder="Prize Name"
                      id="exampleInputEmail1"
                    />
                    {errors.prizeName && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.prizeName.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("description", {
                        required: {
                          value: true,
                          message: "Please enter description",
                        },
                      })}
                      name="description"
                      aria-describedby="Description"
                      placeholder="Description"
                      id="exampleInputEmail1"
                    />
                    {errors.description && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.description.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group shortInput">
                    <label htmlFor="exampleInputEmail1">$ Value</label>
                    <input
                      type="number"
                      {...register("value", {
                        required: {
                          value: true,
                          message: "Please enter a value of the prize",
                        },
                        min:{value:0.01,message:"Please enter a valid prize value"}
                      })}
                      className="form-control"
                      aria-describedby="Value"
                      placeholder="Value"
                      id="exampleInputEmail1"
                      step={0.01}
                    />
                    {errors.value && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.value.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group shortInput">
                    <label htmlFor="exampleInputEmail1">
                      Current Inventory
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("inventoryCount", {
                        required: false,
                        min: {
                          value: formWatch.allowNegativeInventory ? -9999 : 0,
                          message: "Invalid inventory count",
                        },
                      })}
                      name="inventoryCount"
                      aria-describedby="inventoryCount"
                      placeholder="currentInventory"
                      id="exampleInputEmail1"
                      disabled={formWatch.inventoryActive ? false : true}
                    />
                    {errors.inventoryCount && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.inventoryCount.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group toggleBtn">
                    <p>Enable inventory for this prize</p>
                    <div className="swtichCont">
                      <label className="switch" htmlFor="checkbox">
                        <input
                          type="checkbox"
                          id="checkbox"
                          {...register("inventoryActive", { required: false })}
                          className="form-check-input"
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </div>
                  <div className="form-group toggleBtn">
                    <p>Allow Negative inventory for this prize</p>
                    <div className="swtichCont">
                      <label className="switch" htmlFor="checkbox2">
                        <input
                          type="checkbox"
                          id="checkbox2"
                          {...register("allowNegativeInventory", {
                            required: false,
                          })}
                          className="form-check-input"
                          disabled={formWatch.inventoryActive ? false : true}
                         
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </div>
                </div>
            </div>
            </form>

          </div>
        )}
      </div>
    </>
  );
};

export default AddPrize;
