export const PrizingColumns = () => {
  const COLUMNS = [
    {
      Header: "Date / Time",
      accessor: "dateTime",
      width: "20%",
    },
    {
      Header: "Prize Pool",
      accessor: "prizePool",
      width: "25%",
    },
    {
      Header: "Prize Name",
      accessor: "prizeName",
      width: "25%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "25%",
    },

  ];
  return COLUMNS;
};
