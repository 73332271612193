import React from 'react'
import { Modal } from 'react-bootstrap';

const Popup = ({ isOpen, closeModal, header, message, action1, action2, yesMessage, cancelMessage }: { isOpen: boolean, closeModal: React.MouseEventHandler, header: string, message: string, action1?: React.MouseEventHandler, action2?: React.MouseEventHandler, yesMessage?: string, cancelMessage?: string }) => {
  return (
    <>
      <Modal show={isOpen} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={closeModal}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          <h3> {header}</h3>
          <div className="form-group">

            <p> {message}</p>
          </div>


        </Modal.Body>
        <Modal.Footer>
          {yesMessage &&
            <button
              type='button'
              className='btn btn-primary small'
              onClick={action1}
            >
              {yesMessage}
            </button>}
          {cancelMessage &&
            <button
              type='button'
              className='btn btn-secondary small outline'
              onClick={closeModal}
            >
              {cancelMessage}
            </button>}
        </Modal.Footer>
      </Modal>
    </>
  )
}



export default Popup