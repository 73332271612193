import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useBodyClass, disableBrowserHistory } from "../../../../utils/hooks";
import { Spinner, Modal } from "react-bootstrap";
import { showToast } from "../../../../Components/Toast/ToastManager";
import { PrizingColumns } from "./PrizingColumns";
import { RecentActivityColumns } from "./RecentActivityColumns";
import { Table } from "../../../../Components";
import { spryClient } from "../../../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Participant,
  Prize,
  PrizePool,
  PrizeWinner,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { useForm } from "react-hook-form";
import {
  dateFormatHelper,
  emailRegex,
  looseNorthAmericanPhoneRegex,
  provinces,
  stringRegex,
} from "../../../../utils/helpers";
import { Row } from "react-table";

type typeProp = {
  campaignKey: string;
  allPrizes: Prize[] | undefined;
  allPrizePools: PrizePool[] | undefined;
};

const ParticipantDetails = ({
  campaignKey,
  allPrizes,
  allPrizePools,
}: typeProp) => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Participant>({
    mode: "onTouched",
  });
  useBodyClass("adminPg");
  disableBrowserHistory();
  const location = useLocation();
  const { sessionKey } = useParams() as { sessionKey: string };
  const pageNumber = (location.state as { page: number }) || 0;
  const QueryKey = {
    campaignKey,
    sessionKey,
  };
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [emailparams, setemailparams] = useState<any>();
  const [showspinner, setshowspinner] = useState(false);
  let prizedata;
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState("");

  const {
    isLoading: loadingParticipant,
    data: participant,
    refetch: refetchParticipant,
  } = useQuery("getParticipants", async () => {
    const res = await spryClient.getParticipants({ campaignKey, sessionKey });
    return res.participants[0];
  });

  const emailTemplatesQuery = useQuery(
    ["getEmailTemplates", campaignKey],
    async () => {
      const res = await spryClient.getEmailTemplates({ campaignKey })
      return res.emailTemplates
    })
  const sentEmailsQuery = useQuery(
    ["getSentEmails", sessionKey],
    async () => {
      const res = await spryClient.getSentEmails({ campaignKey, sessionKey })
      return res.sentEmails
    }
  )

  const { isLoading: loadPrizeWinners, data: prizeWinners } = useQuery(
    "getPrizeWinners",
    async () => {
      const res = await spryClient.getPrizeWinners({ campaignKey, sessionKey });
      return res.prizeWinners;
    }
  );
  const emailFeedbackQuery = useQuery(
    ["getEmailFeedbacks", sessionKey],
    async () => {
      return await spryClient.getEmailFeedbacks({ campaignKey, sessionKey })
    })

  useEffect(() => {
    if (participant) {
      reset({
        firstName: participant?.firstName || "",
        lastName: participant?.lastName || "",
        email: participant?.email || "",
        phone: participant?.phone ? participant.phone : "",
        address: participant?.address ? participant.address : "",
        address2: participant?.address2 ? participant.address2 : "",
        city: participant?.city ? participant.city : "",
        province: participant?.province ? participant.province : "",
        country: participant?.country ? participant.country : "",
        postal: participant?.postal ? participant.postal : "",
        metadata: participant?.metadata ? participant.metadata : null,
      });
    }
  }, [participant, reset]);
  const { data: recentActivityData } = useQuery(
    ["getFlows", QueryKey],
    async () => {
      const res = await spryClient.getFlows(QueryKey);
      const updatedflows = res.flows.map((flow) => {
        let creationtime = dayjs(flow.creationTime, timezone).format(
          "MM/DD/YYYY hh:mm:ss A"
        );
        let record = { ...flow, creationtime };
        return record;
      });
      return updatedflows;
    }
  );

  const deleteParticipant = async (sessionKey: string) => {
    setShowDeleteModal(false);

    if (loading) {
      return;
    }
    setLoading(true);

    try {
      await spryClient.nukeParticipant({ campaignKey, sessionKey });
      setLoading(false);
      showToast({
        content: `${participant?.email} has been deleted successfully.`,
        duration: 10000,
        error: false,
      });

      navigate(`/campaign/${campaignKey}/participants`);
    } catch (e) {
      setLoading(false);
      showToast({
        content: "Oops,api error",
        duration: 3000,
        error: true,
      });
    }
  };

  const findPrizeName = (prizeKey: String, allPrizes: Prize[] | undefined) => {
    const matchedPrize =
      allPrizes &&
      allPrizes.filter((prize) => {
        return prize.prizeKey === prizeKey;
      });
    if (matchedPrize) {
      return matchedPrize[0]?.prizeName;
    } else {
      return "";
    }
  };
  const findPrizePoolName = (
    prizePoolKey: String,
    allPrizePools: PrizePool[] | undefined
  ) => {
    const matchedPrizePool =
      allPrizePools &&
      allPrizePools.filter((prize) => {
        return prize.prizePoolKey === prizePoolKey;
      });
    if (matchedPrizePool) {
      return matchedPrizePool[0].prizePoolName;
    } else {
      return "";
    }
  };
  const findprizestatus = (prizeWinner: PrizeWinner) => {
    let status = prizeWinner.forfeitTime
      ? "Forfeited"
      : prizeWinner.fulfillTime
        ? "Fulfilled"
        : prizeWinner.declarationAndRelease
          ? "Claimed"
          : "Pending";
    return status;
  };

  if (participant && prizeWinners) {
    prizedata =
      prizeWinners.length > 0
        ? prizeWinners.map((prize) => {
          let prizes = findPrizeName(prize.prizeKey, allPrizes);
          let poolnames = findPrizePoolName(
            prize.prizePoolKey,
            allPrizePools
          );
          let prizestatus = findprizestatus(prize);
          let details = {
            dateTime: prize.creationTime.toLocaleString(),
            prizePool: poolnames,
            prizeName: prizes,
            status: prizestatus,
          };
          return details;
        })
        : [];

    // setPrizeData(prizeslist)
  }

  const recentActivityColumns = useMemo(() => RecentActivityColumns(), []);

  const [showModal, setShowModal] = useState(false);

  const prizingColumns = useMemo(() => PrizingColumns(), []);

  const handleback = () => {
    navigate(`/campaign/${campaignKey}/participants`, {
      state: { page: pageNumber.page },
    });
  };
  async function sendemail() {
    setShowModal(false);
    setshowspinner(true);
    try {
      await spryClient.sendEmail(emailparams);
    } catch (ee) {
      setShowModal(false);
      showToast({
        content: "Oops,api error",
        duration: 3000,
        error: true,
      });
      // setToast({...toast, show: true, content: 'Oops, api error.'});
      setshowspinner(false);
    }
    setshowspinner(false);
    await spryClient.addFlow({
      campaignKey,
      sessionKey,
      tag: "emailsent",
      ip: "",
      metadata: {
        page: "email resent from admin",
      },
    });
    showToast({
      content: "Email sent successfully",
      duration: 3000,
      error: false,
    });
  }
  const resendemail = () => {
    sendemail();
  };

  async function updateParticipant(data: Participant) {
    setshowspinner(true);
    if (participant) {
      try {
        await spryClient.updateParticipant({
          campaignKey,
          ...participant,
          ...data,
          metadata: { ...participant.metadata },
        });

        refetchParticipant();
        await spryClient.addFlow({
          campaignKey,
          sessionKey,
          tag: "participantupdate",
          ip: "",
          metadata: {
            page: "Participant details updated from admin",
          },
        });
        setshowspinner(false);

        showToast({
          content: "Participant details has been updated successfully",
          duration: 3000,
          error: false,
        });
      } catch (ee) {
        setShowModal(false);
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
      }
    }
  }
  const handlecheck = () => {
    if (confirmCheck === "yes") {
      setConfirmCheck("no");
    } else {
      setConfirmCheck("yes");
    }
  };

  const recentEmailColumns = [
    {
      Header: "Sent",
      accessor: "creationTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: any) => {
        const updateTime = TableInfo.data[TableInfo.row.index].creationTime;
        return dateFormatHelper(updateTime, "MM/DD/YYYY- HH:mm:ss A");
      },
    },
    {
      Header: "Subject",
      accessor: "subject",
      Cell: (tableInfo: any) => {
        const emailTemplateKey = tableInfo.data[tableInfo.row.index].emailTemplateKey
        return (
          <p style={{ fontSize: "14px", marginBottom: "0px" }}>
            {emailTemplatesQuery.data?.find(x => x.emailTemplateKey === emailTemplateKey)?.subject}
          </p>
        );
      },
    },
    {
      Header: "Feedback",
      accessor: "feedback",
      width: "50%",
      Cell: (tableInfo: any) => {
        const sentEmail = tableInfo.data[tableInfo.row.index]
        const sentEmailKey = sentEmail.sentEmailKey
        const deliveries = emailFeedbackQuery.data?.deliveries.filter(x => x.sentEmailKey === sentEmailKey) || []
        const bounces = emailFeedbackQuery.data?.bounces.filter(x => x.sentEmailKey === sentEmailKey) || []
        const opens = emailFeedbackQuery.data?.opens.filter(x => x.sentEmailKey === sentEmailKey) || []
        const clicks = emailFeedbackQuery.data?.clicks.filter(x => x.sentEmailKey === sentEmailKey) || []
        const complaints = emailFeedbackQuery.data?.complaints.filter(x => x.sentEmailKey === sentEmailKey) || []
        const renderingFailures = emailFeedbackQuery.data?.renderingFailures.filter(x => x.sentEmailKey === sentEmailKey) || []
        const reportText: { date: Date, text: string }[] = []
        if (sentEmail.sendError) { reportText.push({ date: sentEmail.creationTime, text: `Send Error: ${sentEmail.sendError}` }) }
        reportText.push(...deliveries.map(x => ({ date: x.creationTime, text: `Delivered` })))
        reportText.push(...opens.map(x => ({ date: x.creationTime, text: `Opened [${x.ip} - ${x.userAgent}]` })))
        reportText.push(...clicks.map(x => ({ date: x.creationTime, text: `Clicked on ${x.link} [${x.ip} - ${x.userAgent}]` })))
        reportText.push(...bounces.map(x => ({ date: x.creationTime, text: `Bounced [${x.permanent ? "Permanent" : "Transient"} - ${x.bounceType}]` })))
        reportText.push(...complaints.map(x => ({ date: x.creationTime, text: `Complaint [${x.complaintType}]` })))
        reportText.push(...renderingFailures.map(x => ({ date: x.creationTime, text: `Rendering Failure: ${x.errorMessage}` })))
        reportText.sort((x, y) => +x.date - +y.date)
        return (
          <p>
            {reportText.map((x, i) => <span key={i}>{x.date.toLocaleString()}: {x.text}<br /></span>)}
          </p>
        );
      },
    },
    {
      Header: "",
      accessor: "emailTrigger",
      width: "5%",
      Cell: (tableInfo: any) => {
        const emailparams = {
          campaignKey,
          emailTemplateKey:
            tableInfo.data[tableInfo.row.index].emailTemplateKey,
          email: tableInfo.data[tableInfo.row.index].email,
          fromName: tableInfo.data[tableInfo.row.index].fromName,
          fromEmail: tableInfo.data[tableInfo.row.index].fromEmail,
          parameters: tableInfo.data[tableInfo.row.index].parameters,
          rawParameters:tableInfo.data[tableInfo.row.index].rawParameters,
          transactional: true
        };
        return (
          <div>
            <i
              style={{ cursor: "pointer" }}
              className="fa fa-envelope"
              aria-hidden="true"
              onClick={() => {
                setemailparams(emailparams);
                setShowModal(true);
              }}
            ></i>
          </div>
        );
      },
    },
  ];

  sentEmailsQuery.data?.sort((x, y) => +x.creationTime - +y.creationTime)

  return (
    <>
      {showspinner ||
        loadingParticipant ||
        loadPrizeWinners ||
        !sentEmailsQuery.data ||
        !emailFeedbackQuery.data ||
        loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div className="dashboardContent campaignDetail tabsCont participantsPg participantDetails">
          <div id="tab07" className="tab-contents">
            <div className="head inner">
              <h3>
                <button onClick={handleback} className="backBtn">
                  <i className="fas fa-arrow-left" />
                </button>
                Participant details
              </h3>
            </div>
            {participant ? (
              <>
                <div className="leftArea">
                  <h3>Info</h3>
                  <form onSubmit={handleSubmit(updateParticipant)}>
                    <div className="form-group">
                      <label htmlFor="firstName">First name</label>
                      <input
                        className="form-control"
                        {...register("firstName", {
                          required: false,
                          pattern: {
                            value: stringRegex,
                            message: "Please enter a valid first name.",
                          },
                        })}
                      />
                    </div>
                    {errors.firstName && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.firstName.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="lastName">Last name</label>
                      <input
                        className="form-control"
                        {...register("lastName", {
                          required: false,
                          pattern: {
                            value: stringRegex,
                            message: "Please enter a valid last name.",
                          },
                        })}
                      />
                    </div>
                    {errors.lastName && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.lastName.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        {...register("email", {
                          required: false,
                          pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email.",
                          },
                        })}
                      />
                    </div>
                    {errors.email && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.email.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="phone">Phone Number</label>
                      <input
                        className="form-control"
                        {...register("phone", {
                          required: false,
                          pattern: {
                            value: looseNorthAmericanPhoneRegex,
                            message: "Please enter a valid phone number.",
                          },
                        })}
                      />
                    </div>
                    {errors.phone && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.phone.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="address">Address 1</label>
                      <input
                        className="form-control"
                        {...register("address", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.address && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.address.message}
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="address2">Address 2</label>
                      <input
                        className="form-control"
                        {...register("address2", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.address2 && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.address2.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="city">City</label>

                      <input
                        className="form-control"
                        {...register("city", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.city && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.city.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="province">Province</label>
                      <select
                        className="form-control"
                        {...register("province", {
                          required: false,
                        })}
                      >
                        <option value="">Select a province</option>
                        {Object.entries(provinces).map(
                          ([key, value], index) => (
                            <option key={index} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {errors.province && (
                      <div className="error">
                        <i className="fas fa-exclamation-circle" />
                        {errors.province.message}
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        {...register("country")}
                        placeholder="Country"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="postal">Postal</label>
                      <input
                        type="text"
                        className="form-control"
                        id="postal"
                        {...register("postal")}
                        placeholder="Country"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="terms">Rules</label>
                      <select
                        className="form-control"
                        id="terms"
                        {...register("metadata.rules")}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="marketing">Optin1</label>
                      <select
                        className="form-control"
                        id="marketing"
                        {...register("metadata.optin1")}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>

                    <div className="actionBtns">
                      <button
                        type="submit"
                        className="btn"
                        style={{ marginRight: "40px" }}
                      >
                        Update
                      </button>
                      {participant?.sessionKey && (
                        <button
                          type="button"
                          className="btn"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </form>
                </div>
                <div className="rightArea">
                  {participant && <>
                    <h3>Tags</h3>
                    <p>{participant.tags.join(", ") || "There are no tags attached to this participant"}</p>
                  </>}

                  <h3>Prizing</h3>

                  <div className="dataGrid">
                    {prizedata &&
                      (prizedata.length > 0 ? (
                        <Table
                          columns={prizingColumns}
                          data={
                            prizedata && prizedata.length > 0 ? prizedata : []
                          }
                          tablePageSize={5}
                        />
                      ) : (
                        <p>There are no prizes awarded. </p>
                      ))}
                  </div>
                  <div className="secondHead">
                    <h3>Recent flow activities</h3>
                  </div>
                  <div className="dataGrid">
                    {recentActivityData && (
                      <Table
                        columns={recentActivityColumns}
                        data={
                          recentActivityData && recentActivityData.length > 0
                            ? recentActivityData
                            : []
                        }
                        sortbyid="creationtime"
                        descending={true}
                        tablePageSize={10}
                      />
                    )}
                  </div>
                  <div className="thirdHead">
                    <h3>Recent emails</h3>
                  </div>
                  <div className="dataGrid">
                    {sentEmailsQuery.data && emailFeedbackQuery.data && emailTemplatesQuery.data ? (
                      <Table
                        columns={recentEmailColumns}
                        data={sentEmailsQuery.data}
                        tablePageSize={3}
                      />
                    ) : (
                      <p>
                        {" "}
                        Currently, There are No emails sent to this particpant.
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p style={{ textAlign: "center" }}>
                {" "}
                There is no participant details to display.{" "}
              </p>
            )}
          </div>
        </div>
      )}

      <Modal show={showDeleteModal} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Delete Participant</h3>
          <div className="form-group">
            <div className="form-group">
              <p>Are you sure you want to delete this participant?</p>
              <label htmlFor="exampleFormControlInput1" className="modal-label">
                {" "}
                Participant email
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={participant?.email || ""}
                disabled
              ></input>
            </div>

            <div className="form-check">
              <label htmlFor="decide">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="decide"
                  value={confirmCheck}
                  onChange={handlecheck}
                ></input>
                <p
                  style={{
                    color: "crimson",
                    fontSize: "12px",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  Click here to delete the participant
                </p>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {participant?.sessionKey && (
            <button
              type="button"
              className="btn btn-primary small"
              onClick={() => deleteParticipant(participant?.sessionKey)}
              disabled={confirmCheck === "yes" ? false : true}
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => { }} centered>
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Resend Email?</h3>
          <p>Are you sure you want to resend this email?</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"> Recipient email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={participant?.email || ""}
              disabled
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {participant?.sessionKey && (
            <button
              type="button"
              className="btn btn-primary small"
              onClick={resendemail}
            >
              Resend
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ParticipantDetails;
