import React, { useEffect, useMemo } from 'react'
import { RenderControl } from './RenderForm';
import { FormBuilder } from './RenderForm/form_builder';
import { z } from 'zod';
import { DynamicFieldData, TModuleConfig, TProgramSetup } from '../../types/portalTypes';

const ProgramModule = ({ title, configure, defaultValues, schema }: { title: string; description: string; configure?: any; defaultValues: any, schema: { [key: string]: DynamicFieldData } }) => {

    const FormSchema = z.object({
        internalId: z.string().optional(),
        program_name: z.string(),
        data_capture: z.any(),
        event: z.any(),
        contest: z.any(),
    });


    return (<>
        <div>
            <h4 style={{ fontWeight: "bold", fontSize: "18px", marginBottom: "2rem" }}> {title}</h4>
        </div>
        {/* <div>{description}</div> */}
        {configure?.group ?

            <>

                <FormBuilder
                    defaultValues={defaultValues}
                    submitHandler={() => { }}
                    validation={FormSchema}
                    schema={schema}
                >
                    {configure.group?.map((f: any) => {

                        return <RenderControl key={f} Field={f} disabled={true} />;
                    })}
                </FormBuilder>
            </>
            : <>
            </>
        }

    </>

    )
}

export default ProgramModule