import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'

type AppProps = {
  showTimePicker?: boolean
  name?: string,
  value: Date,
  onChange: Function,
  readOnly: boolean,
  dateforTime?: Date,
  timeInterval?: number,
  startDate?: Date,
  endDate?: Date,
  startTime?: Date,
  disable?: boolean,
  dependant?: Function

}

function DatePickerCalendar({ showTimePicker, name, value, onChange, readOnly, dateforTime, timeInterval, startDate, startTime, disable, dependant, endDate }: AppProps) {
  const dateFormat = showTimePicker ? 'h:mm aa' : 'MMM d, yyyy';

  return (
    <DatePicker
      selected={(value ? new Date(value) : new Date())}
      onChange={async val => {
        await onChange(val);
        dependant && dependant(val)
      }}
      className="form-control"
      showTimeSelect={showTimePicker}
      showTimeSelectOnly={showTimePicker}
      dateFormat={dateFormat}
      id="exampleFormControlInput1"
      timeIntervals={timeInterval || 1}
      minDate={startDate ? startDate : new Date(2020, 0, 1)}
      maxDate={endDate ? endDate : new Date(2028, 0, 1)}
      minTime={new Date(dayjs().startOf('day').toISOString())}
      maxTime={new Date(dayjs().endOf('day').toISOString())}
      readOnly={readOnly}
      disabled={disable}
    />
  )
}

export default DatePickerCalendar;
