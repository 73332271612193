import IcoTransh from '../../../assets/images/ico-trash.png';
import { copyToClipboard } from '../../../utils/helpers';
import { showToast } from "../../../Components/Toast/ToastManager";

export const MediaColumns = (fn: any) => {

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "filename",
      width: "15%"
    },
    {
      Header: "URL",
      accessor: "url",
      width: "35%",
      Cell: (TableInfo: any) => {

        const url = TableInfo.data[TableInfo.row.index].url
        let display = "https://{s3}/" + url.replace("https://","").split('/').slice(1).join('/')
        return <a href={url} target="_blank" rel="noreferrer">{display}</a>
      }
    },
    {
      Header: "",
      accessor: "key",
      width: "5%",
      Cell: (TableInfo: any) => {

        const url = TableInfo.data[TableInfo.row.index].url;

        return <span onClick={e => {
          copyToClipboard("https://{s3}/" + url.replace("https://","").split('/').slice(1).join('/'));

          showToast({
            content: "Image url has been copied.",
            duration: 3000,
            error: false,
          });
        }} className="btn copy outline">Copy</span>

      },
    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (TableInfo: any) => {

        const rowData = TableInfo.data[TableInfo.row.index];

        return <span className="clickable" onClick={() => { fn(rowData); }}><img src={IcoTransh} alt="Delete" /></span>

      },
    },
  ];

  return COLUMNS;

};
