import { DynamicFieldData, TRecord } from '../../../types/portalTypes'
import { zodResolver } from '@hookform/resolvers/zod';
import { PropsWithChildren } from 'react'
import { useForm } from 'react-hook-form';
import { RenderControl } from '.';
import { Form } from './formelements';

type Props = {
    schema?: { [key: string]: DynamicFieldData };
    validation: any; //z.ZodObject<TRecord>,
    defaultValues: TRecord;
    submitHandler: Function;
    previousStep?: string;
    isFormLoading?: any
};

export const FormBuilder = ({
    schema,
    validation,
    defaultValues,
    submitHandler,
    previousStep,
    isFormLoading,
    children
}: PropsWithChildren<Props>) => {



    const form = useForm({
        resolver: zodResolver(validation),
        defaultValues,

    });

    const onSubmit = (data: any) => {
        submitHandler(data);
    };


    return (<>
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
            >
                {/* Render form controls dynamically */}
                {!schema ? children : Object.values(schema).map((f) => {
                    return (
                        <div className="row" style={{ marginBottom: "1rem" }} key={f.name}>
                            <div className="col-sm-12 col-md-9">
                                <RenderControl
                                    key={f.name}
                                    Field={f}
                                    disabled={f.disabled || false}
                                />
                            </div>
                        </div>
                    );
                })
                }

            </form>
        </Form>
    </>

    );
};
