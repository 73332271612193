import { useParams } from "react-router-dom";

export const ValidKeywords = () => {
  const { campaignKey } = useParams<{ campaignKey?: string }>();

  const validKeywords = [
    "address",
    "birthdate",
    "gender",
    "locale",
    "nickname",
    "phone_number",
    "picture",
    "preferred_username",
    "website",
    "zoneinfo",
  ];

  if (campaignKey === "c852f0037418765fae268f75c4993705") {
    //only for Sodexo campaign we user custom attributes
    validKeywords.push("custom:district", "custom:usertype");
  }

  return validKeywords;
};
