import { useState } from "react";
import NavLogo from "./NavLogo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FooterCopy, SubMenu } from "../../Components";
import { useStores } from "../../stores";
import "./styles.css";
import { toJS } from "mobx";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function NavMenu() {
  const [collapse, setCollapse] = useState(false);

  const navigate = useNavigate();
  const cognito = useAuthenticator()

  const location = useLocation();

  const { userStore } = useStores();

  const [showMenu, setShowMenu] = useState(false);

  const signOut = async () => {
    cognito.signOut();
    navigate("/", { replace: true });
  };

  let userGroup = toJS(userStore.user.role);

  let shortName = userStore.user.name.charAt(0).toUpperCase();
  try {
    shortName += userStore.user.name.split(".")[1].charAt(0).toUpperCase();
  } catch (e) { }

  return (
    <div className="col-sm-12 col-md-2 leftBg">
      <div className="left" id="mobile">
        <nav
          className="navbar navbar-expand-lg navbar-light"

        >
          <NavLogo />
          <button
            className={`navbar-toggler ${collapse ? "collapsed" : ""}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setCollapse(!collapse)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="userLogged">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="HeaderBubbleStyles"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                {shortName}
              </button>
              <div
                className={showMenu ? "dropdown-menu show" : "dropdown-menu"}
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={signOut}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          <div
            className={`collapse navbar-collapse ${collapse ? "show" : ""}`}
            id="navbarNav"
          >
            <div className="main-menu" style={{ width: "100%" }}>
              <ul className="navbar-nav" style={{ flex: 1 }}>
                <li className="nav-item dashboard">
                  <Link className="nav-link" to="/">
                    Dashboard
                  </Link>
                </li>

                {userGroup && userGroup === "Admin" && (
                  <>
                    <li className={`nav-item createrepo ${location.pathname === "/createrepo" ? "active" : ""}`}>
                      <Link className="nav-link" to="/createrepo">
                        Create Repos
                      </Link>
                    </li>

                    <li className={`nav-item admins ${location.pathname === "/users" ? "active" : ""}`}>
                      <Link className="nav-link" to="/users">
                        Users
                      </Link>
                    </li>


                    <li className={`nav-item agencies ${location.pathname === "/" ? "active" : ""}`}>
                      <Link className="nav-link" to="/">
                        Agencies
                      </Link>
                    </li>
                    <li className={`nav-item brands ${location.pathname === "/brands" ? "active" : ""}`}>
                      <Link className="nav-link" to="/brands">
                        Brands
                      </Link>
                    </li>
                  </>
                )}

                <li className={`nav-item campaigns ${location.pathname === "/campaigns" ? "active" : ""}`}>
                  <Link className="nav-link" to="/campaigns">
                    Campaigns
                  </Link>
                </li>
                <li className={`nav-item programs ${location.pathname === "/programs" ? "active" : ""}`}>
                  <Link className="nav-link" to="/programs">
                    Programs
                  </Link>
                </li>
                <li className={`nav-item programs ${location.pathname.includes("/cms") ? "active" : ""}`}>
                  <Link className="nav-link" to="/cms">
                    CMS
                  </Link>
                </li>
              </ul>
              <SubMenu setCollapse={setCollapse} />
            </div>
          </div>
        </nav>
        <FooterCopy />
      </div>
    </div>
  );
};
