import { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import CampaignSchedule from "./CampaignSchedule";
import { spryClient } from "../../../api";
import { Table } from "../../../Components";
import { StateColumns } from "./scheduledStatesColumns";
import { Spinner } from "react-bootstrap";
import { Modal } from "../../../Components/Modal";
import { showToast } from "../../../Components/Toast/ToastManager";
import dayjs from "dayjs";
import { convertcaps } from "../../../utils/helpers";

type typeProp = {
  campaignKey: string;
};

function CampaignManage({ campaignKey }: typeProp) {
  const queryClient = useQueryClient();
  const { mutate: mutateDelete, isLoading: isLoadingDel } = useMutation(
    spryClient.deleteCampaignStateSchedule,
    {
      onSuccess: (data) => {
        showToast({
          content: "State deleted successfully.",
          duration: 3000,
          error: false,
        });

        // console.log("res", data);
      },
      onError: () => {
        showToast({
          content: "Error: please try again!",
          duration: 3000,
          error: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("getCampaignStateSchedules");
      },
    }
  );

  const { isLoading: isLoadingState, data: dataStates } = useQuery(
    ["getCampaignStateSchedules", campaignKey],
    async () => {
      const res = await spryClient.getCampaignStateSchedules({ campaignKey });
      // console.log("res:", res.campaignStateSchedules);

      const updatedStates: {
        state: string;
        startDate: string;
        startTime: string;
      }[] = res.campaignStateSchedules.map(
        (s, index) => {
          return {
            state: convertcaps(s.state),
            campaignStateScheduleKey: s.campaignStateScheduleKey,
            startDate: dayjs(s.startTime).format(
              "MM/DD/YYYY"
            ),
            startTime: dayjs(s.startTime).format(
              "hh:mm A"
            ),
            metadata: s.metadata ? JSON.stringify(s.metadata) : "",
            metadataCopy: s.metadata
              ? JSON.stringify(s.metadata)
              : "",
          };
        }
      );
      return updatedStates;
    }
  );

  const [showModal, setShowModal] = useState(false);

  const [statekey, setStateKey] = useState("");

  const functions = {
    setShowModal,
    setStateKey,
  };
  const columns = StateColumns(functions);



  const calldelete = () => {
    mutateDelete({ campaignStateScheduleKey: statekey });
    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* <div className="dashboardContent campaignDetail tabsCont"> */}
      {showModal && (
        <div className="row no-gutters">
          <Modal
            notification={false}
            calldelete={calldelete}
            handleClose={handleClose}
            modalType="DeleteConfirmPopUp"
            header="Delete ?"
            message="Are you sure you want to delete this state?"
          />
        </div>
      )}

      <div className="dashboardContent campaignDetail">
        <div className="head">
          <h2>Schedule</h2>
        </div>
        <div
          id="tab02"
          className="tab-contents"
          style={{ overflow: "visible" }}
        >
          <div className="manageContent">
            <div className="rigthArea">
              {isLoadingDel || isLoadingState ? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                dataStates &&
                (dataStates.length > 0 ? (
                  <Table
                    columns={columns}
                    data={dataStates}
                    tablePageSize={8}
                    sortbyid="startDate"
                    descending={false}
                  />
                ) : (
                  <p> There are no scheduled states to display. </p>
                ))
              )}
              <div>
                <CampaignSchedule
                  campaignKey={campaignKey}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignManage;
