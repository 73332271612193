import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Header, NavMenu } from "../../Components";
import { Tabs, Tab } from "react-bootstrap";
import Activity from "./Activity";
import Assets from "./Assets";
import Tasks from "./Tasks";
import Quotes from "./Quotes";
import ChangeRequests from "./ChangeRequests";
import Overview from "./Overview";

const Program = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [key, setKey] = useState(searchParams.get("tab") || "activity");

  const state = useParams<{ programId: string }>();
  const handleKeySelection = (key: string | null) => {
    if (key) {
      setKey(key);
      setSearchParams({ tab: key })
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <NavMenu />
        <div className="col-sm-12 col-md-10">
          <Header title={""} />

          <div className="dashboardContent quotes">

            <>
              {state?.programId ?

                <div className="tabs tab10" style={{ position: "relative", height: "100%" }}>
                  <Tabs
                    activeKey={key}
                    onSelect={(key) => handleKeySelection(key)}
                    className="nav nav-tabs"
                    style={{ width: "100%", marginBottom: "25px" }}>
                    <Tab eventKey="activity" title="Activity">
                      <Activity programId={state.programId} active={key === "activity" ? true : false} />
                    </Tab>
                    <Tab eventKey="tasks" title="Tasks" >
                      <Tasks programId={state.programId} active={key === "tasks" ? true : false} />
                    </Tab>
                    <Tab eventKey="quotes" title="Quotes">            
                        <Quotes programId={state.programId} active={key === "quotes" ? true : false} />
                    </Tab>
                    <Tab eventKey="change" title="Change Requests">
                      <ChangeRequests programId={state.programId} active={key === "change" ? true : false} />
                    </Tab>
                    <Tab eventKey="assets" title="Assets">
                      <Assets programId={state.programId} active={key === "assets" ? true : false} />
                    </Tab>
                    <Tab eventKey="overview" title="Overview">
                      <Overview programId={state.programId} active={key === "overview" ? true : false} />

                    </Tab>
                  </Tabs>
                </div>
                : <> </>}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
