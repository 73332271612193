/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cognito_idp_pool_id: "ca-central-1:0474f091-c462-41be-b05e-8c384b8781c6",

  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION || "ca-central-1",
  aws_user_pools_id:
    process.env.REACT_APP_COGNITO_USER_POOL || "ca-central-1_pdO9h8WaD",
  aws_user_pools_web_client_id:
    process.env.REACT_APP_COGNITO_APP_CLIENT || "3lmsu9qj40sa8pmceblo1ha1pd",

  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
