import { dateFormatHelper } from "../../../utils/helpers";
import { Row } from "react-table";
export const PrizeWinnersColumns = () => {

  const COLUMNS = [
    {
      Header: "No",
      width: "5%",
      accessor: "index",
      Cell: (tableInfo: any) => {
        return (
          <p> {tableInfo.row.index + 1}</p>
        )
      },
    },
    {
      Header: "Prize",
      accessor: "prizing",
      width: "20%",
      Cell: (tableInfo: any) => {
        const prizing = tableInfo.data[tableInfo.row.index].prizing.split(',')
        const display = prizing.length > 0 && prizing.map((prize: string) => {
          return <p style={{ fontSize: "14px" }} key={prize} >{prize} </p>
        })
        return display
      },
    },
    {
      Header: "Entry Date",
      accessor: "entryDate",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      width: "12%",
      Cell: (TableInfo: any) => {
        const updateTime = TableInfo.data[TableInfo.row.index].entryDate;
        return dateFormatHelper(updateTime,"MM/DD/YYYY");
      },
    },
    {
      Header: "Entry Time",
      accessor: "entryTime",
      width: "12%",
      Cell: (TableInfo: any) => {
        const updateTime = TableInfo.data[TableInfo.row.index].entryTime;
        return dateFormatHelper(updateTime,"hh:mm:ss A");
      },
    },
    {
      Header: "Entry Name",
      accessor: "fullName",
      width: "15%",

    },
    {
      Header: "Entry Email",
      accessor: "email",
      width: "20%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "20%",
      Cell: (tableInfo: any) => {
        const status = tableInfo.data[tableInfo.row.index].status.split(',')
        const display = status.length > 0 && status.map((status: string,index:number) => {
          return <p style={{ fontSize: "14px" }} key={index}>{status} </p>
        })
        return display
      },


    },
  ];
  return COLUMNS;
};
