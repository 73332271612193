import { LanugageDescription, s3Host } from "./constants";
import dayjs from "dayjs";
export const ENV = /spry-dev/.test(window.location.hostname)
  ? "dev"
  : /spry-qa/.test(window.location.hostname)
  ? "qa"
  : /spry/.test(window.location.hostname)
  ? "prod"
  : "dev";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateFormatHelper = (dt: string, ft: string) => {
  return dayjs(dt, timezone).format(ft);
};
export const addTimeToDate = (date: string, time: string) => {
  let tempDate = dayjs(date);
  let updatedDate_time = dayjs(tempDate)
    .hour(dayjs(time).hour())
    .minute(dayjs(time).minute())
    .second(dayjs(time).second());
  const finalDate = updatedDate_time.toISOString();
  return finalDate;
};
export const getDaysBetween2Dates = (startDate: string, endDate: string) => {
  let currentDate = dayjs(startDate);
  const date_array: string[] = [currentDate.toISOString()];

  while (currentDate.isBefore(dayjs(endDate))) {
    // console.log(currentDate);
    currentDate = dayjs(currentDate).add(1, "day");
    date_array.push(currentDate.toString());
  }
  return date_array;
};

export const copyToClipboard = (copyText: string) => {
  try {
    const s3 = (s3Host as any)[ENV];
    const result = copyText.replace(s3, "{{s3}}");
    const textField = document.createElement("textarea");
    textField.innerText = result;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  } catch (e) {
    console.error(e);
  }
};

export const buildFormData = (values: any) => {
  let formData = new FormData();
  for (let prop in values) {
    // console.log(/term\d/i.test(prop));
    if (/term\d/i.test(prop)) {
      values[prop]
        ? formData.append(prop, 1 as any)
        : formData.append(prop, 0 as any);
    } else {
      formData.append(prop, values[prop]);
    }
  }
  return formData;
};
export function compareIgnoreCase(a: string | number, b: string | number) {
  let r1 = typeof a === "string" ? a.toLowerCase() : a;
  let r2 = typeof b === "string" ? b.toLowerCase() : b;
  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
}

export const getLanguageDescription = (lng: string) => {
  return LanugageDescription[`${lng}`];
};

export const replaceTemplateS3Link = (htmlString: string) => {
  const regex = /{{s3}}/g;
  const result = htmlString.replace(regex, s3Host[ENV]);

  return result;
};
export const convertcaps = (data: string) => {
  return data
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const emailRegex =
  // eslint-disable-next-line
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const looseNorthAmericanPhoneRegex =
  /^\+?1?[.() -]{0,2}[2-9][0-8][0-9][.() -]{0,2}[2-9][0-9]{2}[.() -]?[0-9]{4}$/;
export const canadianPostalCodeRegex = /^[a-z][0-9][a-z][ -]?[0-9][a-z][0-9]$/i;
export const americanZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const stringRegex =
  // eslint-disable-next-line
  /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i;
export const repoNameRegex = /^\w*(?:[-]\w(?![-])\w*)+$/;
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/i;
export const urlRegex =
  /^[0-9a-z-]{1,63}(\.[0-9a-z-]{1,63}){0,10}\.[0-9a-z-]{2,63}$/;
export const TemplateNameRegex = /^[a-zA-Z0-9 \-']{2,64}$/i;

export const provinces = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export function moveHashHeaderLast(columns: any[]) {
  // Find the index of the column with the "#" header
  const hashIndex = columns.findIndex((column) => column.Header === "#");

  // If the "#" header is not found, return the original array
  if (hashIndex === -1) {
    return columns;
  }

  // Remove the column with the "#" header and save it in a variable
  const hashColumn = columns.splice(hashIndex, 1)[0];

  // Push the column with the "#" header to the end of the array
  columns.push(hashColumn);

  // Return the modified array
  return columns;
}

export function uniqueStringArray(data: string[]) {
  const objs: Record<string, boolean> = {}
  return data.filter(x => {
    if (objs[x]) { return false }
    objs[x] = true
    return true
  })
}

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const getBytes = (bytes:number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
};

export const getValueFromObject = (jsonObject:string) =>{

  const parsed = JSON.parse(jsonObject)

  const result :string[]= []

  Object.keys(parsed).map(k=>{
    if(parsed[k]){
      result.push(k)
    }
  })
  return result


}
export function byKey<T>(records: T[], getKey: (t: T) => string) {
	return records.reduce(
		(prev, curr) => {
			const key = getKey(curr);
			if (!prev[key]) {
				prev[key] = [];
			}
			prev[key].push(curr);
			return prev;
		},
		{} as { [key: string]: T[] },
	);
}

