import { useState, useRef, useMemo } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Spinner } from 'react-bootstrap';
import { spryClient } from '../../../api';
import { Table } from '../../../Components';
import { MediaColumns } from './MediaColumns';
import { showToast } from "../../../Components/Toast/ToastManager";

type Props = { campaignKey: string }
function CampaignMedia({ campaignKey }: Props) {

  const [uploading, setUploading] = useState(false);

  const inputEl = useRef<HTMLInputElement>(null);

  const { isLoading, data } = useQuery(
    ['getPublicHostingFiles', campaignKey],
    async () => {
      const resp = await spryClient.getPublicHostingFiles({ campaignKey });

      return resp.publicHostingFiles;
    },
    { enabled: !uploading }
  );

  const handleUpload = () => {
    if (inputEl.current) {
      inputEl.current.value = '';

      inputEl.current.click();
    }
  };


  const columns = useMemo(
    () => {
      const handleDelete = (dataValue: any) => {
        setUploading(true);
        try {
          spryClient.deletePublicHostingFile({ campaignKey, key: dataValue.key }).then(() => {
            setUploading(false);
            showToast({
              content: "Image has been deleted successfully.",
              duration: 3000,
              error: false,
            });
          });
        } catch (error) {
          console.error(error);
          setUploading(false);
          showToast({
            content: "Oops! api error.",
            duration: 3000,
            error: true,
          });
        }
      }
      return MediaColumns(handleDelete)
    },
    [campaignKey]);

  const generateUploadUrl = async (file: {
    filename: string;
    campaignKey: string;
    contentType: string;
  }) => {
    const resp = await spryClient.getFileUploadUrlForPublicHosting({
      campaignKey: file.campaignKey,
      filename: file.filename,
      contentType: file.contentType,
    });

    return {
      uploadUrl: resp.uploadUrl,
      key: resp.key,
    };
  };

  return (
    <div className='dashboardContent campaignDetail tabsCont'>
      <div id='tab06' className='tab-contents'>
        <div className='head inner'>
          <h3>Media</h3>
          <div className='float-right'>
            {uploading ? (
              <div className="spinner">
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                /></div>

            ) : (
              <button className='btn' onClick={handleUpload}>
                Upload
              </button>
            )}
          </div>
          <input
            type='file'
            accept='image/png, image/jpeg'
            style={{ display: 'none' }}
            ref={inputEl}
            onChange={async () => {
              if (inputEl.current && inputEl.current.files) {


                const file = inputEl.current.files[0];

                setUploading(true);

                const urls = await generateUploadUrl({
                  filename: file.name,
                  campaignKey,
                  contentType: file.type,
                });


                axios
                  .put(urls.uploadUrl, file, {
                    headers: {
                      'Content-Type': file.type,
                    },
                  })
                  .then((res) => {

                    setUploading(false);
                  })
                  .catch(e => {

                    setUploading(false);

                  })

              }
            }}
          />
          {/* <input value={image.name} placeholder="Select file" /> */}
        </div>
        <div className='dataGrid mediaTab'>
          {isLoading ? (
            <div className="spinner"><Spinner animation="border" variant="secondary" /></div>
          ) : (
            <Table
              columns={columns}
              data={data ? data : []}
              tablePageSize={15}
              disabledSort={true}

            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignMedia;
