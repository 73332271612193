import { createContext, useContext } from "react"
import UserStore from "./UserStore"
import { CampaignStore } from "./CampaignStore"
import FiltersStore from './FiltersStore'
import { ProgramStore } from "./ProgramStore"

class RootStore {
    public userStore = new UserStore()
    public campaignStore = new CampaignStore()
    public filtersStore = new FiltersStore()
    public programStore = new ProgramStore()
}

export { CampaignStore, UserStore, FiltersStore ,ProgramStore}

export const StoresContext = createContext(new RootStore());

export const useStores = () => useContext(StoresContext);