import { useState, useMemo, useEffect } from "react";
import { showToast } from "../../../Components/Toast/ToastManager";
import Table from "../../../Components/CheckBoxTable";
import { Table as GeneralTable } from "../../../Components/Table";
import { Spinner } from "react-bootstrap";
import { spryClient } from "../../../api";
import { WinningTimestampsColumns } from "./WinningTimestampsColumns";
import { Modal } from "../../../Components/Modal";
import { SnapshotColumns } from "./SnapshotColumns";
import { useNavigate } from "react-router-dom"
import dayjs from 'dayjs'

type typeProp = {
  campaignKey: string;
  allPrizes: any[] | undefined;
  allPrizePools: any[] | undefined;
};
type PrizeData = {
  prizePoolName: string;
  prizeName: string;
  assigned: number;
  unassigned: number;
};
type Timestamps = {
  prizeName: any;
  prizePoolName: any;
  timestamp: string;
  priority: any;
  assigned: string;
  sessionKey: string;
  status: string;
  timeStampKey: any;
};
function CampaignTimestamps({
  campaignKey,
  allPrizes,
  allPrizePools,
}: typeProp) {
  const [showModal, setShowModal] = useState(false);
  const QueryKey = { campaignKey: campaignKey };
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [prizeData, setPrizeData] = useState<PrizeData[]>([]);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Timestamps[]>();
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const findPrizeName = (prizeKey: String, allPrizes: any) => {
    const matchedPrize = allPrizes.filter((prize: any) => {
      return prize.prizeKey === prizeKey;
    });
    return matchedPrize[0].prizeName;
  };
  const findPrizePoolName = (prizePoolKey: String, allPrizePools: any) => {
    const matchedPrizePool = allPrizePools.filter((prizePool: any) => {
      return prizePool.prizePoolKey === prizePoolKey;
    });
    return matchedPrizePool[0].prizePoolName;
  };

  async function getTimestamps() {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await spryClient.getInstantPrizeTimestamps({ ...QueryKey });
    let prizedata: PrizeData[] = [];

    //    console.log(res.instantPrizeTimestamps);
    const updatedData = res.instantPrizeTimestamps.map((record: any) => {
      const timestamp_data = {
        prizeName: allPrizes && findPrizeName(record.prizeKey, allPrizes),
        prizePoolName:
          allPrizePools &&
          findPrizePoolName(record.prizePoolKey, allPrizePools),
        timestamp: dayjs(
          record.winningTimestamp, timezone).format(
            "MM/DD/YYYY - hh:mm A"),
        priority: record.priority,
        assigned: "N/A",
        sessionKey: "N/A",
        status: "Unassigned",
        timeStampKey: record.instantPrizeTimestampKey,
      };
      let prizepool_found = prizedata.findIndex(
        (data) =>
          data.prizePoolName === timestamp_data.prizePoolName &&
          timestamp_data.prizeName === data.prizeName
      );
      if (prizepool_found !== -1) {
        prizedata[prizepool_found].unassigned =
          prizedata[prizepool_found].unassigned + 1;
      } else {
        prizedata.push({
          prizePoolName: timestamp_data.prizePoolName,
          assigned: 0,
          unassigned: 1,
          prizeName: timestamp_data.prizeName,
        });
      }
      return timestamp_data;
    });


    const winners = await spryClient.getPrizeWinners({ campaignKey });
    const winned_timestamps = winners.prizeWinners.map((winner) => {
      let name =
        winner.participant.firstName + " " + winner.participant.lastName;
      let status = "";
      if (winner.fulfillTime) {
        status = "fulfilled";
      } else if (winner.forfeitTime) {
        status = "forfeited";
      } else if (winner.declarationAndRelease) {
        status = "claimed";
      } else {
        status = "pending";
      }
      const timestamp_data = {
        prizeName: allPrizes && findPrizeName(winner.prizeKey, allPrizes),
        prizePoolName:
          allPrizePools &&
          findPrizePoolName(winner.prizePoolKey, allPrizePools),
        timestamp: winner.winningTimestamp
          ? dayjs(winner.winningTimestamp, timezone).format(
            "MM/DD/YYYY - hh:mm A")
          : "N/A",
        priority: "N/A",
        assigned: name,
        sessionKey: winner.participant.sessionKey,
        status: status,
        timeStampKey: "N/A",
      };
      if (status !== "forfeited") {
        let prizepool_found = prizedata.findIndex(
          (data) =>
            data.prizePoolName === timestamp_data.prizePoolName &&
            timestamp_data.prizeName === data.prizeName
        );
        if (prizepool_found !== -1) {
          prizedata[prizepool_found].assigned =
            prizedata[prizepool_found].assigned + 1;
        } else {
          prizedata.push({
            prizePoolName: timestamp_data.prizePoolName,
            assigned: 1,
            unassigned: 0,
            prizeName: timestamp_data.prizeName,
          });
        }
      }

      return timestamp_data;
    });
    const final_timestamps = updatedData.concat(winned_timestamps);
    setPrizeData(prizedata);
    setData(final_timestamps);
    setLoading(false);

    return final_timestamps;
  }

  const handleAdd = () => {
    navigate(`/campaign/${campaignKey}/timestamps/add`);
  };

  const columns = useMemo(
    () => WinningTimestampsColumns({
      campaignKey,
      navigate
    }),
    [campaignKey, navigate]);

  const deleteMultipleTimestamp = async () => {
    setShowModal(false);
    if (loading) {
      return;
    }
    setLoading(true);
    const deleteall = Promise.all(
      selectedRows.map(async function (timeStamp: any) {
        await spryClient.deleteInstantPrizeTimestamp({
          instantPrizeTimestampKey: timeStamp.timeStampKey,
        });
      })
    );
    await deleteall
      .then(async (res) => {
        await getTimestamps();
        showToast({
          content: "Timestamps deleted successfully",
          duration: 3000,
          error: false,
        });
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
        setLoading(false);
      });
    // setEditTimestamps(false)
  };

  const deleteAllUnassignedTimeStamps = async () => {
    setShowDeleteAllModal(false);
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { deletedCount } =
        await spryClient.bulkDeleteInstantPrizeTimestamps({
          campaignKey: campaignKey,
          // entryFinishTime: "",
          // entryStartTime:""
        });
      if (deletedCount > 0) {
        showToast({
          content: `All ${deletedCount} Timestamps deleted successfully`,
          duration: 3000,
          error: false,
        });
      } else {
        showToast({
          content: "Oops,api error",
          duration: 3000,
          error: true,
        });
      }
      getTimestamps();
      setLoading(false);
    } catch (e) {
      console.log(e);
      showToast({
        content: "Oops,api error",
        duration: 3000,
        error: true,
      });
      getTimestamps();
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowDeleteAllModal(false);
  };

  const prizeInfoColumns = useMemo(() => SnapshotColumns(), []);

  useEffect(() => {
    getTimestamps();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Modal
            calldelete={deleteMultipleTimestamp}
            handleClose={handleClose}
            notification={false}
            message="Are you sure you want to delete these timestamps?"
            modalType="DeleteConfirmPopUp"
            header="Delete Timestamps?"
          />
        </div>
      )}

      {showDeleteAllModal && (
        <div className="row no-gutters">
          <Modal
            calldelete={deleteAllUnassignedTimeStamps}
            handleClose={handleClose}
            notification={false}
            message="Are you sure you want to delete all unassigned timestamps?"
            modalType="DeleteConfirmPopUp"
            header="Delete All Unassigned Timestamps"
          />
        </div>
      )}
      <div className="dashboardContent campaignDetail tabsCont">
        <div
          id="tab05"
          className="tab-contents timestamps"
          style={{ overflow: "visible" }}
        >
          <div className="head inner" style={{ marginBottom: 0 }}>
            <h3>Winning Timestamps</h3>
            <button
              className="btn addTimestamp add-btn"
              onClick={handleAdd}
            >
              Add timestamps
            </button>

            <button
              className="btn delete table-delbtn"
              data-toggle="modal"
              data-target="#deleteModal2"
              onClick={() => setShowModal(true)}
              disabled={selectedRows.length < 1}
            >
              Delete
            </button>

            <button
              className="btn delete table-delbtn"
              data-toggle="modal"
              data-target="#deleteModal2"
              onClick={() => setShowDeleteAllModal(true)}
              disabled={!data?.length || loading}
            >
              Delete All Unassigned
            </button>

            {/* <button className="btn addTimestamp" onClick={handleAdd}>Add timestamps</button> */}
          </div>
          {prizeData &&
            (prizeData.length > 0 ? (
              <div className="chartArea">
                <div className="snapshotArea">
                  {loading ? (
                    <div className="spinner">
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  ) : (
                    <GeneralTable
                      columns={prizeInfoColumns}
                      data={prizeData && prizeData.length > 0 ? prizeData : []}
                      tablePageSize={6}
                    // onPageChange = {handlepagechange}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            ))}

          <div>
            {loading ? (
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              data &&
              (data.length > 0 ? (
                <Table
                  columns={columns}
                  data={data}
                  tablePageSize={15}
                  setSelectedRows={setSelectedRows}
                  tableTitle="Winning timestamps"
                />
              ) : (
                <p> There are no timestamps to display.</p>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignTimestamps;
