import { Row, TableProps } from "react-table";

export const QuoteColumns = (setSearchParams: any) => {
  const COLUMNS = [
    {
      Header: "No",
      width: "5%",
      accessor: "no",
      Cell: (row: any) => {
        return <div>{Number(row.row.id) + 1}</div>;
      },
    },
    {
      Header: "Quote Id",
      accessor: "id",
      Cell: (TableInfo: TableProps) => {
        let quoteId = TableInfo.data[TableInfo.row.index].id
        return <button className="linkButton" onClick={() => setSearchParams({tab:"quotes",qId:quoteId,filter:"basic_config"})}>{quoteId}</button>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (TableInfo: TableProps) => {
        let status = TableInfo.data[TableInfo.row.index].quote_programStatus
        return <div>{status} </div>
      }
    },
    {
      Header: "Quote Type",
      accessor: "type",

    },

    // {
    //   Header: "Program Name",
    //   accessor: "programName",
    //   Cell: (TableInfo: TableProps) => {
    //     return <Link to={""}>{TableInfo.data[TableInfo.row.index].programName}</Link>;
    //   },
    // },
    {
      Header: "Company",
      accessor: "companyName",
    },

    {
      Header: "Created",
      accessor: "creationTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].creationTime
          ? TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "Updated",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "Internal Id",
      accessor: "internalId",
    },
    {
      Header: "Estimation",
      accessor: "estimation",
    },
    {
      Header: "Estimation Pdf",
      accessor: "estimationUrl",
      Cell: (TableInfo: TableProps) => {
        const filename = TableInfo.data[TableInfo.row.index].filename

        return filename ? <a href={TableInfo.data[TableInfo.row.index].estimationUrl} rel="noreferrer" target="_blank">{filename}</a> : ""
        // return TableInfo.data[TableInfo.row.index].estimationUrl
      },
    },
  ];
  return COLUMNS;
};
