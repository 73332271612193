import { Link } from 'react-router-dom';
import { dateFormatHelper } from '../../../utils/helpers';
import idelete from '../../../assets/images/ico-trash.png';

const styles = {
  button: {
    border: "none",
    backgroundColor: "transparent"
  },
} as const;


export const EmailTemplateColumns = (fns: {
  calldelete: () => void,
  setEmailTemplateKey: (para: string) => void
}) => {

  const COLUMNS = [
    {
      Header: "No",
      accessor: "No",
      width: "5%",
      Cell: (TableInfo: any) => {

        const nmb = TableInfo.row.index + 1;

        return nmb;
      }
    },
    {
      Header: "Email Template Name",
      accessor: "emailTemplateName",
      width: "35%",
      Cell: (TableInfo: any) => {

        const name = TableInfo.data[TableInfo.row.index].emailTemplateName;

        const key = TableInfo.data[TableInfo.row.index].emailTemplateKey;

        return <Link to={`../addemailtemplate/${key}`}>{name}</Link>;

      }
    },
    {
      Header: "Language",
      accessor: "language",
      width: "5%"
    },
    {
      Header: "Updated",
      accessor: "updateTime",
      width: "15%",
      Cell: (TableInfo: any) => {

        const updateTime = TableInfo.data[TableInfo.row.index].updateTime;

        return dateFormatHelper(updateTime,"MM/DD/YYYY");
      },
    },
    {
      Header: '',
      accessor: 'emailTemplateKey',
      width: "5%",

      Cell: (TableInfo: any) => {

        const emailTemplateKey = TableInfo.data[TableInfo.row.index].emailTemplateKey;

        return <button type='button' className="delete-Button" style={styles.button} onClick={() => {
          fns.calldelete();
          fns.setEmailTemplateKey(emailTemplateKey)
        }}
        ><span><img src={idelete} alt="delete" className="trash-img"></img></span> </button>
      }
    }
  ];

  return COLUMNS;

};
