import { useNavigate, useParams } from "react-router-dom"
import { spryClient } from "../../../api"
import { useQuery } from "react-query"
import { Spinner } from "react-bootstrap"
import { useState } from "react"
import { EmailBatchTarget, EmailBounceFeedback, EmailClickFeedback, EmailComplaintFeedback, EmailDeliveryFeedback, EmailOpenFeedback, EmailRenderingFailureFeedback, Participant, SentEmail } from "@sprycore/spry-api-client/dist/ReturnTypes"

type CampaignEmailBatchReportProps = {
    campaignKey: string
}

export default function CampaignEmailBatchReport({ campaignKey }: CampaignEmailBatchReportProps) {
    const navigate = useNavigate()

    const { emailBatchKey } = useParams() as { emailBatchKey: string }
    const [showParticipants, setShowParticipants] = useState(false)

    const emailBatchQuery = useQuery(
        ["getEmailBatches", campaignKey, emailBatchKey],
        async () => {
            const { emailBatches: [emailBatch] } = await spryClient.getEmailBatches({ campaignKey, emailBatchKey })
            return emailBatch
        })
    const emailBatchTargetsQuery = useQuery(
        ["getEmailBatchTargets", campaignKey, emailBatchKey],
        async () => {
            const { emailBatchTargets } = await spryClient.getEmailBatchTargets({ campaignKey, emailBatchKey })
            return emailBatchTargets
        })
    const participantsQuery = useQuery(
        ["participants", campaignKey],
        async () => {
            const { participants } = await spryClient.getParticipants({ campaignKey })
            return participants
        })
    const sentEmailsQuery = useQuery(
        ["getSentEmails", campaignKey, emailBatchKey],
        async () => {
            const { sentEmails } = await spryClient.getSentEmails({ campaignKey, emailBatchKey })
            return sentEmails
        })
    const emailFeedbacksQuery = useQuery(
        ["getEmailFeedbacks", campaignKey, emailBatchKey],
        async () => {
            return await spryClient.getEmailFeedbacks({ campaignKey, emailBatchKey })
        })


    const loading = emailBatchQuery.isLoading || emailBatchTargetsQuery.isLoading || participantsQuery.isLoading || sentEmailsQuery.isLoading || emailFeedbacksQuery.isLoading
    const emailBatch = loading ? undefined : emailBatchQuery.data
    const emailBatchTargets = loading ? undefined : emailBatchTargetsQuery.data
    const participants = loading ? undefined : participantsQuery.data
    const sentEmails = loading ? undefined : sentEmailsQuery.data
    const emailFeedbacks = loading ? undefined : emailFeedbacksQuery.data

    const participantsBySessionKey: Record<string, Participant> = {}
    participants?.forEach(x => participantsBySessionKey[x.sessionKey] = x)

    const sentEmailsBySessionKey: Record<string, SentEmail[]> = {}
    sentEmails?.forEach(x => (sentEmailsBySessionKey[x.sessionKey ?? ""] ??= []).push(x))

    const targetsBySessionKey: Record<string, EmailBatchTarget> = {}
    emailBatchTargets?.forEach(x => targetsBySessionKey[x.sessionKey] = x)

    const sendErrorSummary = {
        success: 0,
        errorCounts: {} as Record<string, number>
    }
    sentEmails?.forEach(x => {
        if (!x.sendError) { ++sendErrorSummary.success }
        else {
            sendErrorSummary.errorCounts[x.sendError] ??= 0
            ++sendErrorSummary.errorCounts[x.sendError]
        }
    })

    const feedbackSummary = {
        bounces: [...new Set(emailFeedbacks?.bounces.map(x => x.sentEmailKey) || [])].length,
        complaints: [...new Set(emailFeedbacks?.complaints.map(x => x.sentEmailKey) || [])].length,
        opens: [...new Set(emailFeedbacks?.opens.map(x => x.sentEmailKey) || [])].length,
        clicks: [...new Set(emailFeedbacks?.clicks.map(x => x.sentEmailKey) || [])].length,
        renderingFailures: [...new Set(emailFeedbacks?.renderingFailures.map(x => x.sentEmailKey) || [])].length,
        deliveries: [...new Set(emailFeedbacks?.deliveries.map(x => x.sentEmailKey) || [])].length
    }
    const feedbackBySentEmailKey = {} as Record<string, {
        bounces: EmailBounceFeedback[]
        complaints: EmailComplaintFeedback[]
        opens: EmailOpenFeedback[]
        clicks: EmailClickFeedback[]
        renderingFailures: EmailRenderingFailureFeedback[]
        deliveries: EmailDeliveryFeedback[]
    }>
    function getFeedbackForSentEmail(sentEmailKey: string) {
        return feedbackBySentEmailKey[sentEmailKey] ??= { bounces: [], complaints: [], opens: [], clicks: [], renderingFailures: [], deliveries: [] }
    }
    emailFeedbacks?.bounces.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).bounces.push(x))
    emailFeedbacks?.complaints.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).complaints.push(x))
    emailFeedbacks?.opens.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).opens.push(x))
    emailFeedbacks?.clicks.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).clicks.push(x))
    emailFeedbacks?.renderingFailures.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).renderingFailures.push(x))
    emailFeedbacks?.deliveries.forEach(x => getFeedbackForSentEmail(x.sentEmailKey).deliveries.push(x))

    return <>
        <div className="dashboardContent campaignDetail tabsCont emailBatches">            
            <div className="head inner">
                <h2><button className="backBtn" 
            onClick={() => navigate(`/campaign/${campaignKey}/email-batches`)}><i className="fas fa-arrow-left" aria-hidden="true"></i></button>Email Batch {emailBatch && ` [${emailBatch.name}]`} Send Report</h2>
            </div>
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {emailBatch && emailBatchTargets && participants && sentEmails && emailFeedbacks && <>

                    <h3>Summary</h3>
                    <p>
                        Remaining to be sent: {emailBatchTargets.length}<br />
                    </p>
                    <div>
                        Total Sent: {sentEmails.length}
                        <table>
                            <tbody>
                                <tr>
                                    <td>No error</td>
                                    <td>{sendErrorSummary.success}</td>
                                </tr>
                                {Object.keys(sendErrorSummary.errorCounts).map(x => <tr key={x}>
                                    <td>{x}</td>
                                    <td>{sendErrorSummary.errorCounts[x]}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        Feedback:
                        <table>
                            <tbody>
                                <tr>
                                    <td>Deliveries</td>
                                    <td>{feedbackSummary.deliveries}</td>
                                </tr>
                                <tr>
                                    <td>Bounces</td>
                                    <td>{feedbackSummary.bounces}</td>
                                </tr>
                                <tr>
                                    <td>Complaints</td>
                                    <td>{feedbackSummary.complaints}</td>
                                </tr>
                                <tr>
                                    <td>Opens</td>
                                    <td>{feedbackSummary.opens}</td>
                                </tr>
                                <tr>
                                    <td>Clicks</td>
                                    <td>{feedbackSummary.clicks}</td>
                                </tr>
                                <tr>
                                    <td>Rendering Failures</td>
                                    <td>{feedbackSummary.renderingFailures}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3>Targets</h3>
                    {!showParticipants && <button className="btn col-2" onClick={() => setShowParticipants(true)}>Show</button>}
                    {showParticipants && <>
                        <button className="btn col-2" onClick={() => setShowParticipants(false)}>Hide</button>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Send Result</th>
                                        <th>Feedback</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {participants.map(x => {
                                        const target = targetsBySessionKey[x.sessionKey]
                                        const sent = sentEmailsBySessionKey[x.sessionKey]
                                        if (!target && !sent) { return undefined }
                                        if (target) {
                                            return <tr key={x.sessionKey}>
                                                <td>{x.firstName} {x.lastName}</td>
                                                <td>{target.email}</td>
                                                <td>Queued</td>
                                                <td></td>
                                            </tr>
                                        }
                                        return sent.map(s => {
                                            const feedback = getFeedbackForSentEmail(s.sentEmailKey)
                                            return <tr key={s.sentEmailKey}>
                                                <td>{x.firstName} {x.lastName}</td>
                                                <td>{s.email}</td>
                                                <td>{s.sendError || "Sent"}</td>
                                                <td>
                                                    {feedback.deliveries.length > 0 && <span>Delivered: {feedback.deliveries[0].creationTime.toLocaleString()}<br /></span>}
                                                    {feedback.opens.length > 0 && <span>First Open: {feedback.opens[0].creationTime.toLocaleString()}<br /></span>}
                                                    {feedback.clicks.length > 0 && <span>First Click: {feedback.clicks[0].creationTime.toLocaleString()}<br /></span>}
                                                    {feedback.bounces.map((f, i) => <span key={`bounce-${f.sentEmailKey}-${i}`}>Bounced: {f.creationTime.toLocaleString()} - [{f.permanent ? "Permanent" : "Transient"}] {f.bounceType}<br /></span>)}
                                                    {feedback.complaints.map((f, i) => <span key={`complaint-${f.sentEmailKey}-${i}`}>Complaint: {f.creationTime.toLocaleString()} - {f.complaintType}<br /></span>)}
                                                    {feedback.renderingFailures.map((f, i) => <span key={`renderingFailure-${f.sentEmailKey}-${i}`}>Rendering Failure: {f.errorMessage}<br /></span>)}
                                                </td>
                                            </tr>
                                        })
                                    }).filter(x => x).flat()}
                                </tbody>
                            </table>
                        </div>
                    </>}
                </>}
            </div>
        </div>
    </>
}