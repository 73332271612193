import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { spryClient } from '../../../api';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { convertcaps } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import dayjs from "dayjs"


function Giftcard({ campaignKey }: { campaignKey: string }) {

    const { giftCardTypeKey, giftCardKey } = useParams() as { giftCardTypeKey: string, giftCardKey: string }
    const navigate = useNavigate()

    const { isLoading: loadingGiftcardtype, data: giftCardType } = useQuery('getGiftcardTypes', async () => {
        const res = await spryClient.getGiftCardTypes({ campaignKey, giftCardTypeKey })
        return res.giftCardTypes[0]

    })
    const { isLoading: loadingGiftcard, data: giftCard } = useQuery('getGiftCards', async () => {
        const res = await spryClient.getGiftCards({ campaignKey, giftCardTypeKey, giftCardKey })
        return res.giftCards[0]
    })
    const prizeWinnerkey = giftCard?.prizeWinnerKey
    const { isLoading: loadPrizeWinner, data: PrizeWinner } = useQuery(['getPrizeWinner', prizeWinnerkey], async () => {
        const res = await spryClient.getPrizeWinners({ campaignKey, prizeWinnerKey: prizeWinnerkey })
        return res.prizeWinners[0]
    },
        {
            // The query will not execute until the userId exists
            enabled: !!prizeWinnerkey,
        })


    const backHandler = () => {
        navigate(`/campaign/${campaignKey}/giftcards/${giftCardTypeKey}`)
    }

    return (
        <>
            <div className="dashboardContent campaignDetail tabsCont">

                <div id="tab09" className="tab-contents giftcards">

                    {(loadingGiftcard || loadingGiftcardtype || loadPrizeWinner) ? <div className="spinner"><Spinner animation="border" variant="secondary" /></div> :
                        <>
                            <div className="head giftcard-details">
                                <h3><button className="btn-noborder" onClick={backHandler}><i className="fas fa-arrow-left"></i></button>{giftCardType?.giftCardTypeName} details</h3>

                            </div>
                            <div className="main-content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>$Value</label>
                                        <p>{giftCard?.value.toFixed(2)}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Created</label>
                                        <p>{giftCard?.creationTime.toLocaleString()}</p>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Gift card type</label>
                                        <p>{giftCardType?.giftCardTypeName}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Last updated</label>
                                        <p>{giftCard?.updateTime.toLocaleString()}</p>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Code 1</label>
                                        <p>{giftCard?.primaryCode}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Assigned</label>
                                        <p>{giftCard?.assignmentTime ? dayjs(giftCard.assignmentTime).format('M/DD/YYYY, h:mm:ss A') : "N/A"}</p>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Code 2</label>
                                        <p>{giftCard?.secondaryCode}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Winner name</label>
                                        {PrizeWinner?.participant.firstName ?
                                            <Link to={`/campaign/${campaignKey}/participants/${PrizeWinner?.participant.sessionKey}`} target="_blank" className="linkButton giftcards-link">{PrizeWinner?.participant.firstName && convertcaps(PrizeWinner.participant.firstName)} {PrizeWinner?.participant.lastName && convertcaps(PrizeWinner.participant.lastName)} </Link> : <p>N/A</p>}
                                    </div>

                                </div>
                                <div className="row" style={{ marginBottom: "20px" }}>
                                    <div className="col-md-6">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Card link</label>
                                        {giftCard?.directLinkUrl ?
                                            <a href={giftCard?.directLinkUrl || "#"} className="linkButton giftcards-link">{giftCard.directLinkUrl} </a> : <p> N/A</p>}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label style={{ width: "100%", "fontWeight": "bold" }}>Card expiry</label>
                                        <p>{giftCard?.expiryTime?.toLocaleString() || "N/A"}</p>
                                    </div>


                                </div>


                            </div>
                        </>
                    }
                </div>
            </div>



        </>
    )
}

export default Giftcard