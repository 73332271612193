import { Dropdown } from "react-bootstrap";
import { GiftCardType } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { NavigateFunction } from "react-router-dom";


export const GiftCardsColumns = (fns: {
  setSelectedGiftcards: Function
  setIsOpenDelete: Function
  giftCardType: GiftCardType | undefined
  navigate: NavigateFunction
}) => {

  const COLUMNS = [
    {
      Header: "Value",
      width: "1%",
      accessor: "value",
    },

    {
      Header: 'Gift Card Type',
      accessor: 'typename',
      width: "7%",
    },


    {
      Header: 'Code 1',
      accessor: 'primaryCode',
      width: "8%",

    },
    {
      Header: 'Last Updated',
      accessor: 'updateTime',
      width: "10%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].updateTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },

    },
    {
      Header: 'Created',
      accessor: 'creationTime',
      width: "10%",
      Cell: (tableInfo: any) => {
        const displaydate = new Date(tableInfo.data[tableInfo.row.index].creationTime).toLocaleString()
        return (
          <p>{displaydate}</p>
        );
      },

    },
    {
      Header: 'Assigned',
      accessor: 'assignmentTime',
      width: "10%",
      Cell: (tableInfo: any) => {
        return (
          <p>{tableInfo.data[tableInfo.row.index].assignmentTime ? "Yes" : "No"}</p>
        );
      },

    },
    {
      Header: "",
      accessor: "campaignKey",
      width: "5%",
      Cell: (tableInfo: any) => {
        const campaignKey = tableInfo.data[tableInfo.row.index].campaignKey;
        const typekey: string = tableInfo.data[tableInfo.row.index].giftCardTypeKey;
        const cardkey: string = tableInfo.data[tableInfo.row.index].giftCardKey;
        const dropdownToggleStyles = {
          background: "none",
          border: "none",
          boxShadow: "none",
        };

        return (

          <Dropdown>
            <Dropdown.Toggle
              className="subMenu"
              id="dropdownMenuButton"
              bsPrefix="p-0"
              style={dropdownToggleStyles}
            >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu >
              <Dropdown.Item className="dropdown-item"
                onClick={(e) => {
                  fns.navigate(`/campaign/${campaignKey}/giftcards/${typekey}/giftcard/${cardkey}`,
                    { state: tableInfo.data[tableInfo.row.index] })
                }}
              >
                View Details
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-item"
                onClick={async (e) => {
                  fns.setSelectedGiftcards([tableInfo.data[tableInfo.row.index]]);
                  fns.setIsOpenDelete(true)


                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },





  ]
  return COLUMNS
}



